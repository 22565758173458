<form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit('submitBtn')" *ngIf="carregouDados">
  <reuse-anuncio-detail *ngIf="anuncio" [anuncio]="anuncio" [exibirBotaoTenhoInteresse]="false">
  </reuse-anuncio-detail>
  <p-panel header="Publicação" *ngIf="exibirBotaoPublicar()" class="panel-publicacao">
    <div class="col-12 frase">
      <p-checkbox formControlName="validacaoServico"
        label="Declaro estar ciente dos termos do Decreto nº 9.764, de 11 de abril de 2019." binary="true"></p-checkbox>
    </div>
    <div class="col-12 frase">
      <p-checkbox formControlName="validacaoProprietario"
        label="Declaro que sou proprietário do bem a ser doado ou serviço a ser prestado." binary="true"></p-checkbox>
    </div>
    <div class="col-12 frase">
      <p-checkbox formControlName="validacaoBem"
        label="Declaro que a doação não é um bem público ou de empresa privada." binary="true"
        *ngIf="!isPessoaJuridica()"></p-checkbox>
    </div>
    <div class="col-12 frase">
      <p-checkbox formControlName="validacaoDemanda"
        label="Declaro que inexistem demandas judiciais ou administrativas sobre o bem a ser doado." binary="true"
        *ngIf="!isAnuncioServico()"></p-checkbox>
    </div>
    <div class="col-12 frase">
      <p-checkbox formControlName="validacaoConteudo"
        label="Declaro estar ciente que o anúncio será submetido a um filtro previamente à sua publicação. Anúncios ilícitos ou imorais serão rejeitados, podendo o responsável ser acionado nas esferas administrativas, cíveis e criminais."
        binary="true"></p-checkbox>
    </div>
    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
      <button id="submitBtn" name="submitBtn" type="submit" pButton [label]="nomeBotaoSubmit()"
        class="ui-button-success" [disabled]="form.invalid" class="ui-button-success"></button>
    </div>
  </p-panel>
</form>

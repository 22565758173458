import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPrimengModule } from '../app-primeng.module';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MensagemItemComponent } from './components/mensagem-item/mensagem-item.component';
import { MensagensPageComponent } from './components/mensagem-page/mensagens-page.component';
import { MensagemDetailPageComponent } from './components/mensagem-datail-page/mensagem-detail-page.component';
import { MensagemService } from './services/mensagem.service';
import { MensagemRoutingModule } from './mensagem-routing.module';
import { MensagensListaComponent } from './mensagens-lista/mensagens-lista.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    AppPrimengModule,
    MensagemRoutingModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MomentModule
  ],
  exports: [
  ],
  declarations: [
    MensagemItemComponent,
    MensagensPageComponent,
    MensagensListaComponent,
    MensagemDetailPageComponent
  ],
  providers: [
    MensagemService,
  ]
})
export class MensagemModule { }

<!-- Tabela detalhe de Bloqueios -->
<p-table class="ui-g-12" [columns]="cols" [value]="bloqueios" [(selection)]="bloqueiosSelecionados"
[scrollable]="true" scrollHeight="200px" [resizableColumns]="true" dataKey="id"
(onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
(onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event)">
  <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn>
            {{col.header}}
        </th>
        <th style="width: 5%" class="text-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bloqueio >
      <tr [pSelectableRow]="bloqueio">
        <td class="ui-resizable-column text-left">{{bloqueio.bloqueioTipo}}</td>
        <td class="ui-resizable-column text-center">{{bloqueio.anuncio}}</td>
        <td class="ui-resizable-column text-center">{{bloqueio.dataAcesso|StringDTOToDate|amDateFormat:'DD/MM/YYYY HH:mm:ss'}} </td>
        <td class="ui-resizable-column text-center">{{bloqueio.usuario.cpf}}</td>
        <td style="width: 5%" class="text-center">
            <p-tableCheckbox [value]="bloqueio"></p-tableCheckbox>
        </td>
      </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" >
      <tr>
          <td [attr.colspan]="5">
              Nenhum registro encontrado.
          </td>
      </tr>
  </ng-template>
</p-table>

<div class="ui-g-12 botoes">
    <div class="alinhamento">
      <p-button #submitBtn [disabled]="buttonStatus" label="Remover selecionados" (click)="removerBloqueio(submitBtn)"
        styleClass="ui-button-danger" icon="fa fa-trash"></p-button>
    </div>
  </div>

<div *ngIf="!entidadeNaoEncontrada">
  <p-panel header="{{tituloFormulario()}}">
    <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">

      <div class="text-right" *ngIf="podeAlterarDados()">
        <p-message severity="info" text="* Campos obrigatórios"></p-message>
      </div>

      <div>
        <p id="observacao">Somente poderão ser cadastradas entidades de governo. Empresas privadas provenientes do Gov.Br, vinculadas a CPFs só podem ter seus dados visualizados.</p>
      </div>

      <div class="ui-g">

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('numero')" class="subtitulo">
            Número{{podeAlterarDados()?'*':''}}</div>
          <input title="Entre com o Número" type="text" pInputText formControlName="numero" class="campoInput"
            placeholder="Número" *ngIf="podeAlterarDados()" (keypress)="numberOnlyEvent($event)">
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('numero').value}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('nome')" class="subtitulo">
            Nome{{podeAlterarDados()?'*':''}}</div>
          <input type="text" pInputText formControlName="nome" class="campoInput" placeholder="Nome"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('nome').value ?
            formulario.get('nome').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('sigla')" class="subtitulo">
            Sigla{{podeAlterarDados()?'*':''}}</div>
          <input type="text" pInputText formControlName="sigla" class="campoInput" placeholder="Sigla"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('sigla').value ?
            formulario.get('sigla').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div class="subtitulo">Órgão{{podeAlterarDados()?'*':''}}</div>
          <p-dropdown [options]="listaOrgao" formControlName="orgao" [filter]="true" optionLabel="idNome" dataKey="id"
            *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('orgao').value ?
            formulario.get('orgao').value.id + ' - '
            +
            formulario.get('orgao').value.nome : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('entidadeTipo')" class="subtitulo">
            Tipo{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaEntidadeTipo" formControlName="entidadeTipo" optionLabel="nome" dataKey="id"
            *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('entidadeTipo').value ?
            formulario.get('entidadeTipo').value.nome : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('entidadeEsfera')" class="subtitulo">
            Esfera{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaEntidadeEsfera" formControlName="entidadeEsfera" optionLabel="nome" dataKey="id"
            *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('entidadeEsfera').value ?
            formulario.get('entidadeEsfera').value.nome : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('uf')" class="subtitulo">
            UF{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaUf" formControlName="uf" optionLabel="sigla" dataKey="id"
            *ngIf="podeAlterarDados()" (onChange)="onChangeUf()" #uf autoWidth="false" [style]="{'width':'100%'}">
          </p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('uf').value ?
            formulario.get('uf').value.sigla : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('municipio')" class="subtitulo">
            Município{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaMunicipio" formControlName="municipio" #municipio [filter]="true"
            optionLabel="nome" dataKey="id" *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}">
          </p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('municipio').value ?
            formulario.get('municipio').value.nome : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('bairro')" class="subtitulo">
            Bairro</div>
          <input type="text" pInputText formControlName="bairro" class="campoInput" placeholder="Bairro"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('bairro').value ?
            formulario.get('bairro').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('endereco')" class="subtitulo">
            Endereço</div>
          <input type="text" pInputText formControlName="endereco" class="campoInput" placeholder="Endereço"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('endereco').value ?
            formulario.get('endereco').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('enderecoNumero')" class="subtitulo">
            Nº Endereço</div>
          <input type="text" pInputText formControlName="enderecoNumero" class="campoInput" placeholder="Nº Endereço"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('enderecoNumero').value ?
            formulario.get('enderecoNumero').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('cep')" class="subtitulo">
            CEP</div>
          <p-inputMask type="text" mask="99999-999" *ngIf="podeAlterarDados()" formControlName="cep"
            placeholder="99999-999" class="campoInput"></p-inputMask>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('cep').value ?
            formulario.get('cep').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('telefone1')" class="subtitulo">
            Telefone 1</div>
          <p-inputMask type="text" mask="(99) 99999999?9" *ngIf="podeAlterarDados()" formControlName="telefone1"
            placeholder="(99) 999999999" autoWidth="false" [style]="{'width':'100%'}"></p-inputMask>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('telefone1').value ?
            formulario.get('telefone1').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('telefone2')" class="subtitulo">
            Telefone 2</div>
          <p-inputMask type="text" mask="(99) 99999999?9" *ngIf="podeAlterarDados()" formControlName="telefone2"
            placeholder="(99) 999999999" autoWidth="false" [style]="{'width':'100%'}"></p-inputMask>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('telefone2').value ?
            formulario.get('telefone2').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('email')" class="subtitulo">
            E-mail</div>
          <input type="email" pInputText formControlName="email" class="campoInput" placeholder="email@dominio.com"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('email').value ?
            formulario.get('email').value : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('usuarioResponsavel')" class="subtitulo">
            Usuário Responsável
          </div>
          <p-dropdown [options]="listaUsuario" formControlName="usuarioResponsavel" #usuarioResponsavel [filter]="true"
            optionLabel="nome" dataKey="id" *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}">
          </p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('usuarioResponsavel').value ?
            formulario.get('usuarioResponsavel').value.nome : '-'}}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('utilizaSiads')" class="subtitulo">
            Utiliza SIADS{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaSimNao" formControlName="utilizaSiads" [filter]="true" optionLabel="nome"
            dataKey="id" *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('utilizaSiads').value.nome }}</div>
        </div>

        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('ativo')" class="subtitulo">
            Ativo{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaSimNao" formControlName="ativo" [filter]="true" optionLabel="nome" dataKey="id"
            *ngIf="podeAlterarDados()" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('ativo').value.nome }}</div>
        </div>

        <div class="ui-g-4 ui-sm-12"></div>

      </div>

      <div style="margin-top: 10px" class="buttons text-right">
        <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"
          *ngIf="!podeAlterarDados()"></button>
        <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"
          *ngIf="podeAlterarDados()"></button>
        <button id="submitBtn" name="submitBtn" type="submit" pButton label="Salvar" class="ui-button-primary"
          class="ui-button-primary" *ngIf="podeAlterarDados()"></button>
      </div>
    </form>
  </p-panel>
  <p-confirmDialog key="modalInclusaoStatusExcluido" header="Confirmação" width="425" #cd>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
        class="ui-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
        class="ui-button-success"></button>
    </p-footer>
  </p-confirmDialog>
</div>
<div *ngIf="entidadeNaoEncontrada" class="registroNaoEncontrado">
  <h2>Entidade não encontrada.</h2>
  <div>
    <a routerLink="/">Voltar para a Página principal</a>
  </div>
</div>

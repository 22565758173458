<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label class="ui-g-12 noPadding" for="tipoPesquisa">Tipo de Pesquisa</label>
          <div class="ui-g-6 noPadding">
            <p-radioButton name="tipoPesquisa" value="CPF" label="CPF" [(ngModel)]="filtro.tipoPesquisa"
              inputId="tipoPesquisaCpf"></p-radioButton>
          </div>
          <div class="ui-g-6 noPadding">
            <p-radioButton name="tipoPesquisa" value="CNPJ" label="CNPJ" [(ngModel)]="filtro.tipoPesquisa"
              inputId="tipoPesquisaCnpj"></p-radioButton>
          </div>
        </div>
        <div *ngIf="tipoPesquisaCpf()" class="ui-g-12">
          <label for="cpf">CPF</label>
          <p-inputMask id="cpf" name="cpf" [ngModel]="filtro.cpf" unmask="true" mask="999.999.999-99" autocomplete="off"
            placeholder="CPF" title="Entre com o CPF" type="text" styleClass="width100" (onBlur)="onBlurCpf($event)">
          </p-inputMask>
        </div>
        <div *ngIf="tipoPesquisaCpf()" class="ui-g-12">
          <label for="nome">Nome do Usuário</label>
          <input pInputText id="nome" name="nome" [(ngModel)]="filtro.nome" maxlength="200" placeholder="Nome"
            type="text" class="width100" />
        </div>
        <div *ngIf="tipoPesquisaCnpj()" class="ui-g-12">
          <label for="cnpj">CNPJ</label>
          <p-inputMask id="cnpj" name="cnpj" [ngModel]="filtro.cnpj" unmask="true" mask="99.999.999/9999-99"
            autocomplete="off" placeholder="CNPJ" title="Entre com o CNPJ" type="text" styleClass="width100"
            (onBlur)="onBlurCnpj($event)"></p-inputMask>
        </div>
        <div *ngIf="tipoPesquisaCnpj()" class="ui-g-12">
          <label for="razaoSocial">Razão Social</label>
          <input pInputText id="razaoSocial" name="razaoSocial" [(ngModel)]="filtro.razaoSocial" maxlength="200"
            placeholder="Razão Social" title="Entre com a Razão Social" type="text" class="width100" />
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash"
          (click)="limparConsulta()"></p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Consultar" styleClass="ui-button-success" icon="fa fa-search" (click)="consultar()"></p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

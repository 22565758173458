<reuse-anuncio-nao-encontrado *ngIf="anuncioNaoEncontrado"></reuse-anuncio-nao-encontrado>

<div *ngIf="!anuncioNaoEncontrado">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-steps [model]="passos" [(activeIndex)]="passo" #steps></p-steps>
    </div>
    <div class="ui-g-12" [hidden]="passo!=0">
      <reuse-cadastro-anuncio-privado-identificacao-doador (onNavegarPasso)="onNavegarPasso($event)"
        #reuseCadastroAnuncioPrivadoIdentificacaoDoador>
      </reuse-cadastro-anuncio-privado-identificacao-doador>
    </div>
    <div class="ui-g-12" [hidden]="passo!=1">
      <reuse-cadastro-anuncio-privado-doacao (onNavegarPasso)="onNavegarPasso($event)"
        (onExibirAbaDonatario)="onExibirAbaDonatario($event)" #reuseCadastroAnuncioPrivadoDoacao>
      </reuse-cadastro-anuncio-privado-doacao>
    </div>
    <div class="ui-g-12" [hidden]="habilitarAbaDonatario ? passo!=2 : (passo >= 0 && passo <=3)">
      <reuse-cadastro-anuncio-privado-favorecido (onNavegarPasso)="onNavegarPasso($event)"
        #reuseCadastroAnuncioPrivadoFavorecido></reuse-cadastro-anuncio-privado-favorecido>
    </div>
    <div class="ui-g-12" [hidden]="!habilitarAbaDonatario ? passo!=2 : passo!=3">
      <reuse-cadastro-anuncio-privado-fotos (onNavegarPasso)="onNavegarPasso($event)" #reuseCadastroAnuncioPrivadoFotos>
      </reuse-cadastro-anuncio-privado-fotos>
    </div>
    <div class="ui-g-12" [hidden]="!habilitarAbaDonatario ? passo!=3 : passo!=4">
      <reuse-cadastro-anuncio-privado-resumo (onNavegarPasso)="onNavegarPasso($event)"
        #reuseCadastroAnuncioPrivadoResumo></reuse-cadastro-anuncio-privado-resumo>
    </div>
  </div>

</div>

<aviso-responsabilidade></aviso-responsabilidade>

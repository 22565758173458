import { MaterialTipo } from 'app/anuncio/models/material-tipo.model';
import { AnuncioChip } from './anuncio-chip.model';
import { AnuncioSituacao } from './anuncio-situacao.model';

export class FiltroMeusAnunciosPrivado {
  pesquisa: string;
  materialTipos: MaterialTipo[];
  anuncioSituacoes: AnuncioSituacao[];
  materialTiposSelecionados: MaterialTipo[];
  anuncioSituacoesSelecionados: AnuncioSituacao[];
  periodoInclusao: Date[];
  anuncios: AnuncioChip[];
}

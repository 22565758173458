import  * as  moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { FiltroBloqueioUsuarioPrivado } from 'app/bloqueio-usuario-privado/models/filtro-bloqueio-usuario-privado.model';
import { BloqueioUsuarioPrivadoService } from 'app/bloqueio-usuario-privado/services/bloqueio-usuario-privado.service';
import { ParametrosBloqueioUsuarioPrivado } from 'app/bloqueio-usuario-privado/models/parametros-bloqueio-usuario-privado.model';
import { MessageService } from 'primeng/components/common/messageservice';
import { UtilService } from 'app/shared/services/util.service';
import { InputMask } from 'primeng/inputmask';

@Component({
  selector: 'reuse-bloqueio-usuario-privado-page',
  templateUrl: './bloqueio-usuario-privado-page.component.html',
  styleUrls: ['./bloqueio-usuario-privado-page.component.scss']
})
export class BloqueioUsuarioPrivadoPageComponent implements OnInit {

  filtro: FiltroBloqueioUsuarioPrivado = new FiltroBloqueioUsuarioPrivado();

  constructor(
    private bloqueioService: BloqueioUsuarioPrivadoService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService,
    private messageService: MessageService,
    private utilService: UtilService) {
    }

  ngOnInit() {
    //tipo de pesquisa padrao
    this.filtro.tipoPesquisa = 'CPF';

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosBloqueioUsuarioPrivado = this.bloqueioService.obterParametrosUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.consultarFiltros(parametros);
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    let breadcrumbItems = [
      {icon:'fa fa-lock', label:'Bloquear Usuários Privados', command: (onclick)=>{this.router.navigate(['bloqueio-usuario-privado'], {queryParams: { dt: new Date().getTime()}})}}
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosBloqueioUsuarioPrivado) {
    //TODO Criar retorno filtros caso necessário.

    this.definirFiltrosSelecionados(parametros);
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosBloqueioUsuarioPrivado) {
    this.filtro.tipoPesquisa = !parametros.tipoPesquisa ? 'CPF' : parametros.tipoPesquisa;
    this.filtro.cpf = parametros.cpf;
    this.filtro.nome = parametros.nome;
    this.filtro.cnpj = parametros.cnpj;
    this.filtro.razaoSocial = parametros.razaoSocial;
  }

 /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'bloqueio-usuario-privado-lista' monitora a URL para carregar novos bloqueios.
   */
  consultar() {
    this.validarCampos();
    this.consultarComParametrosFiltro();
  }

  consultarComParametrosFiltro() {
    this.router.navigate(['/bloqueio-usuario-privado'], {
      queryParams: {
        tpConsulta: 1, //Consulta "completa"
        tipoPesquisa: this.filtro.tipoPesquisa,
        cpf: this.filtro.cpf,
        nome: this.filtro.nome,
        cnpj: this.filtro.cnpj,
        razaoSocial: this.filtro.razaoSocial,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparConsulta() {
    this.filtro.tipoPesquisa = 'CPF';
    this.filtro.cpf = '';
    this.filtro.nome = '';
    this.filtro.cnpj = '';
    this.filtro.razaoSocial = '';
    this.consultarComParametrosFiltro();
  }

  validarCampos() {
    //Se preenchido, verifica se o CPF é válido
    if (this.filtro.tipoPesquisa == 'CPF' && this.filtro.cpf && !this.utilService.validaCpf(this.filtro.cpf)) {
      this.messageService.add({severity:'error', summary:'Validação', detail:'CPF inválido.'});
      return false;
    }
    //Se preenchido, verifica se o CNPJ é válido
    if (this.filtro.tipoPesquisa == 'CNPJ' && this.filtro.cnpj && !this.utilService.validaCnpj(this.filtro.cnpj)) {
      this.messageService.add({severity:'error', summary:'Validação', detail:'CNPJ inválido.'});
      return false;
    }

    //Verifica se pelo meno um campo está preenchido
    if (this.filtro.tipoPesquisa == 'CPF' && !this.filtro.cpf && !this.filtro.nome) {
      this.messageService.add({severity:'error', summary:'Validação', detail:'Preencha ao menos um filtro para realizar a pesquisa'});
      return false;
    }
    if (this.filtro.tipoPesquisa == 'CNPJ' && !this.filtro.cnpj && !this.filtro.razaoSocial) {
      this.messageService.add({severity:'error', summary:'Validação', detail:'Preencha ao menos um filtro para realizar a pesquisa'});
      return false;
    }
  }

  public onBlurCpf(event) {
    this.filtro.cpf = this.utilService.removerNaoNumerico(event.target.value);
  }

  public onBlurCnpj(event) {
    this.filtro.cnpj = this.utilService.removerNaoNumerico(event.target.value);
  }

  public tipoPesquisaCpf() {
    return this.filtro.tipoPesquisa == 'CPF';
  }

  public tipoPesquisaCnpj() {
    return this.filtro.tipoPesquisa == 'CNPJ';
  }

  // //Precisa???
  // dataParaString(data, formato: string) {
  //   return moment(data, formato).format(formato);
  // }

  // //Precisa???
  // stringParaData(data:string, formato: string) {
  //   return moment(data, formato).toDate();
  // }
}

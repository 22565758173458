import { MaterialTipo } from "./material-tipo.model";

export class MaterialCategoria {

  constructor(id: number){
    this.id = id;
  }

  id: number;
  nome: string;

  materialTipo: MaterialTipo;
}

import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import {Message} from 'primeng/components/common/api';

@Component({
  selector: 'reuse-mensagens',
  templateUrl: './mensagens.component.html',
  styleUrls: ['./mensagens.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MensagensComponent implements OnInit {
  msgsStick: Message[] = [];
  msgsNoStick: Message[] = [];
  lifeNoStick: any;

  constructor(
    private messageService: MessageService) { }

  ngOnInit() {
    this.messageService.messageObserver.subscribe(
      (msgs: any) => {
        let messages;
        this.msgsStick = [];
        this.msgsNoStick = [];
        

        if (msgs) {
          if (msgs['length']) {
            messages = msgs;
          } else {
            messages = [msgs];
          }

          //mensagem permantente se for de erro
          if (messages[0].severity === 'error' || messages[0].severity === 'warn' || messages[0].severity === 'info') {
            this.msgsStick = messages;
          } else {
            this.msgsNoStick = messages;
          }

         
        } 
      }
    )
  }

  onClickedOutside(e: Event) {
        //apaga as mensagens
        this.messageService.clear();
  }

}

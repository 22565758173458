import { AnuncianteTipo } from 'app/anuncio/models/anuncio-anunciante.tipo.model';
import { ParametrosRelatorioAnuncios } from './../../models/parametros-relatorio-anuncios';
import { MaterialCategoria } from './../../models/material-categoria.model';
import { MaterialTipo } from './../../models/material-tipo.model';
import { AnuncioChip } from './../../models/anuncio-chip.model';
import { ResultadoPesquisaInternaAnuncio } from './../../models/resultado-pesquisa-anuncio.model';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { AnuncioSituacao } from './../../models/anuncio-situacao.model';
import { FiltroRelatorioAnuncio } from './../../models/filtro-relatorio-anuncio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { CadastroUsuarioGovernoService } from 'app/cadastro-usuario-governo/services/cadastro-usuario-governo.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { Orgao } from 'app/anuncio/models/orgao.model';
import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { OrgaoService } from 'app/anuncio/services/orgao.service';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';

@Component({
  selector: 'app-relatorio-anuncio-page',
  templateUrl: './relatorio-anuncio-page.component.html',
  styleUrls: ['./relatorio-anuncio-page.component.scss']
})
export class RelatorioAnuncioPageComponent extends AnunciosInternoPage implements OnInit {
  filtro: FiltroRelatorioAnuncio = new FiltroRelatorioAnuncio();

  // lista orgao disponiveis para pesquisa
  listaOrgao: Orgao[] = [];

  // lista entidades disponiveis para pesquisa
  listaEntidade: Entidade[] = [];

  @ViewChild('entidade')
  entidadeDropdown: Dropdown;

  @ViewChild('orgao')
  orgaoDropdown: Dropdown;

  constructor(
    private anuncioService: AnuncioService,
    private cadastroUsuarioGovernoService: CadastroUsuarioGovernoService,
    private orgaoService: OrgaoService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService) {
    super();
  }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;

    const parametros: ParametrosRelatorioAnuncios =
      this.anuncioService.obterParametrosRelatorioAnuncios(parametrosUrl);

    this.configurarCalendario();
    this.criarBreadcrumb();
    this.recuperarOrgaos();
    this.consultarFiltros(parametros);
    this.recuperarEntidades();
    this.entidadeDropdown.emptyFilterMessage = 'Nenhuma entidade encontrada.';
    this.orgaoDropdown.emptyFilterMessage = 'Nenhum orgão encontrado.';
  }


  private recuperarOrgaos(): void {
    this.orgaoService.listarOrgaosAtivos()
      .subscribe(
        (orgaos) => {
          this.listaOrgao = [];
          for (const orgao of orgaos) {
            orgao.idNome = orgao.id + ' - ' + orgao.nome;
            this.listaOrgao.push(orgao);
          }
          // adiciona opção 'Selecione' (vazia)
          this.listaOrgao.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });
        }
      );
  }


  // onChange para para dropdown 'Entidade'.
  public recuperarEntidades(): void {
    // limpa o filtro de entidade, caso o orgao seja modificado.
    this.filtro.entidade = null;
    // limpa o filtro do drowpdown da entidade, caso o orgao seja modificado.
    this.entidadeDropdown.filterValue = '';
    // limpa o filtro do drowpdown do orgão, caso o orgao seja modificado.
    this.orgaoDropdown.filterValue = '';

    if (this.filtro.orgao == null || this.filtro.orgao.id == null) {
      this.listaEntidade = [];
    } else {
      this.carregarEntidade();
    }
  }

  // Carrega a opção de 'Entidades' a partir do 'Orgao' selecionado.
  private carregarEntidade(): void {
    this.cadastroUsuarioGovernoService.obterEntidadePorOrgao(this.filtro.orgao.id)
      .subscribe(
        (entidades) => {
          this.listaEntidade = [];
          for (const entidade of entidades) {
            entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
            this.listaEntidade.push(entidade);
          }
          this.listaEntidade.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
        }
      );
  }

  // Configuração da barra 'Breadcrumb'.
  criarBreadcrumb() {
    const breadcrumbItems = [
      { icon: 'fa fa-vcard', label: 'Relatório de Anúncios', command: (onclick) => { this.consultarComParametrosFiltro(); } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosRelatorioAnuncios) {
    this.anuncioService.consultarFiltrosRelatorioDeAnuncios()
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {
          this.filtro.anuncioSituacoes = resultado.anuncioSituacoes;
          this.filtro.anuncianteTipos = resultado.anuncianteTipos;
          this.filtro.materialCategorias = resultado.materialCategorias;
          this.filtro.materialTipos = resultado.materialTipos;

          this.definirFiltrosSelecionados(parametros);
        });
  }

  definirFiltrosSelecionados(parametros: ParametrosRelatorioAnuncios) {
    const anuncios: AnuncioChip[] = parametros.anuncioCodigos.split(',').filter(v => v !== '').map(v => <AnuncioChip>{ value: v, display: v });
    this.filtro.anuncioCodigos = anuncios;

    const anuncioSituacoes: AnuncioSituacao[] = parametros.
      anuncioSituacoes.split(',').filter(v => v !== '').map((nome) => <AnuncioSituacao>{ nome });
    this.filtro.anuncioSituacoesSelecionadas = anuncioSituacoes;

    const periodoInclusao: Date[] =
      parametros.periodoInclusao.split(',').filter(v => v !== '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInclusao.length !== 0) {
      this.filtro.periodoInclusao = periodoInclusao;
    }

    const periodoAutorizacao: Date[] =
      parametros.periodoAutorizacaoDoacao.split(',').filter(v => v !== '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoAutorizacao.length !== 0) {
      this.filtro.periodoAutorizacaoDoacao = periodoAutorizacao;
    }

    const anuncianteTipos: AnuncianteTipo[] = parametros.anuncianteTipos.
      split(',').filter(v => v !== '').map((nome) => <AnuncianteTipo>{ nome });
    this.filtro.anuncianteTiposSelecionados = anuncianteTipos;

    const materialTipos: MaterialTipo[] = parametros.materialTipos.split(',').filter(v => v !== '').map((nome) => <MaterialTipo>{ nome });
    this.filtro.materialTiposSelecionados = materialTipos;

    const materialCategorias: MaterialCategoria[] = parametros.
      materialCategorias.split(',').filter(v => v !== '').map((nome) => <MaterialCategoria>{ nome });
    this.filtro.materialCategoriasSelecionadas = materialCategorias;

    this.filtro.entidade = parametros.idDonatario ? new Entidade(parseInt(parametros.idDonatario, 10)) : null;
    this.filtro.orgao = parametros.idOrgaoDonatario ? new Orgao(parseInt(parametros.idOrgaoDonatario, 10)) : null;

  }

  public aplicarFiltros(): void {
    const anuncioCodigos: string = this.filtro.anuncioCodigos.map(m => m.display).join(',');
    const anuncianteTipos: string = this.filtro.anuncianteTiposSelecionados.map(m => m.nome).join(',');
    const anuncioSituacoes: string = this.filtro.anuncioSituacoesSelecionadas.map(m => m.nome).join(',');
    const materialTipos: string = this.filtro.materialTiposSelecionados.map(m => m.nome).join(',');
    const materialCategorias: string = this.filtro.materialCategoriasSelecionadas.map(m => m.nome).join(',');

    let periodoInclusao = '';
    if (this.filtro.periodoInclusao != null) {
      periodoInclusao = this.filtro.periodoInclusao.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }
    let periodoAutorizacaoDoacao = '';
    if (this.filtro.periodoAutorizacaoDoacao != null) {
      periodoAutorizacaoDoacao = this.filtro.periodoAutorizacaoDoacao.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosRelatorioAnuncios = this.anuncioService.obterParametrosRelatorioAnuncios(parametrosUrl);

    this.router.navigate([OrigemEnum.RELATORIO_ANUNCIO], {
      queryParams: {
        anuncioCodigos: anuncioCodigos,
        anuncianteTipos: anuncianteTipos,
        anuncioSituacoes: anuncioSituacoes,
        materialTipos: materialTipos,
        materialCategorias: materialCategorias,
        periodoInclusao: periodoInclusao,
        periodoAutorizacaoDoacao: periodoAutorizacaoDoacao,
        idOrgaoDonatario: (this.filtro.orgao && this.filtro.orgao.id) ? this.filtro.orgao.id : null,
        idDonatario: (this.filtro.entidade && this.filtro.entidade.id) ? this.filtro.entidade.id : null,
        sortOrder: parametros.sortOrder,
        dt: new Date().getTime() // altera a url para forcar recarregar a pagina
      }
    });
  }

  // Chama uma nova URL adicionando todos os filtros selecionados.
  // Componente 'cadastro-usuario-governo-lista' monitora a URL para carregar novos bloqueios.
  consultarComParametrosFiltro() {
    let idOrgaoDonatario: number;
    let idDonatario: number;

    if (this.filtro !== null && this.filtro.orgao !== null) {
      idOrgaoDonatario = this.filtro.orgao.id;
    } else {
      idOrgaoDonatario = null;
    }


    if (this.filtro !== null && this.filtro.entidade !== null) {
      idDonatario = this.filtro.entidade.id;
    } else {
      idDonatario = null;
    }

    this.router.navigate([OrigemEnum.RELATORIO_ANUNCIO], {
      queryParams: {
        anuncioCodigos: this.filtro.anuncioCodigos,
        anuncianteTipos: this.filtro.anuncianteTiposSelecionados,
        anuncioSituacoes: this.filtro.anuncioSituacoesSelecionadas,
        materialTipos: this.filtro.materialTiposSelecionados,
        materialCategorias: this.filtro.materialCategoriasSelecionadas,
        periodoInclusao: this.filtro.periodoInclusao,
        periodoAutorizacaoDoacao: this.filtro.periodoAutorizacaoDoacao,
        idOrgaoDonatario: idOrgaoDonatario,
        idDonatario: idDonatario,
        dt: new Date().getTime()
      }
    });
  }

  // Limpa os filtros selecionados e chama a consulta.
  limparFiltros() {
    this.filtro = new FiltroRelatorioAnuncio();
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosRelatorioAnuncios =
      this.anuncioService.obterParametrosRelatorioAnuncios(parametrosUrl);
    this.filtro.periodoInclusao = null;
    this.filtro.periodoAutorizacaoDoacao = null;
    this.filtro.orgao = new Orgao(null);
    this.filtro.entidade = new Entidade(null);
    this.recuperarOrgaos();
    this.consultarFiltros(parametros);
    this.recuperarEntidades();
    this.consultarComParametrosFiltro();
  }

  public limparPeriodoInclusao(input): void {
    this.filtro.periodoInclusao = null;
    this.fecharCalendario(input);
  }

  public limparPeriodoAutorizacao(input): void {
    this.filtro.periodoAutorizacaoDoacao = null;
    this.fecharCalendario(input);
  }

  fecharCalendario(input) {
    input.overlayVisible = false;
  }
}

<carousel pauseOnFocus="true" noPause="false" interval="interval">
  <slide>
    <div>
      <h2>Conectando quem tem a quem precisa.</h2>
      <div class="ajuda">
        <span>O Sistema Doações é uma solução desenvolvida pelo Ministério da Economia,
          que oferta bens móveis e serviços para a administração pública, disponibilizados pelos próprios órgãos
          de governo ou oferecidos por particulares de forma não onerosa. O Sistema Doações é uma ferramenta que
          desburocratiza e garante a transparência aos processos de incorporação e transferência de patrimônio da União,
          otimizando a gestão do recurso público com consumo consciente e sustentável. Para mais informações, contate a
          equipe
          responsável por meio do <a (click)="goFale()">Fale Conosco</a>.</span>
      </div>
    </div>
  </slide>

  <slide>
    <div>
      <h2>Como funciona</h2>
      <div class="ajuda">
        <span>No Portal poderão ser publicados anúncios sobre materiais de consumo, bens móveis
          e
          serviços, tais como papéis, canetas, mesas, cadeiras, materiais de informática, softwares,
          medicamentos, serviços intelectuais, artísticos, de manutenção.
          O órgão, entidade ou particular (pessoa física ou jurídica) identifica bens ou serviços que
          queira doar e anuncia no Doações.Gov. O anúncio pode ser consultado por qualquer cidadão, porém o
          recebimento de um bem ou serviço anunciado estará restrito aos órgãos e entidades da Administração Pública
          Federal.</span>
      </div>
    </div>
  </slide>
  <slide>
    <div>
      <h2>Como doar</h2>
      <div class="ajuda">
        <span>O gestor responsável pelo patrimônio ou o particular interessado em doar clica no
          botão
          <a (click)="queroDoar()">QUERO DOAR</a>, que o direcionará para tela de login pelo Senha-Rede para o gestor de
          patrimônio
          ou para o Portal Gov.BR para particulares, que acessará com CPF do doador ou representante
          legal da pessoa jurídica. O cadastro do anúncio é bem simples e intuitivo, com informações sobre o bem ou
          serviço,
          campo para inserir fotos e outras informações necessárias para os órgãos donatários poderem demonstrar
          interesse.</span>
      </div>
    </div>
  </slide>
  <slide>
    <div>
      <h2>Como receber</h2>
      <div class="ajuda">
        <span>O órgão interessado em receber o bem ou serviço clica no botão TENHO INTERESSE,
          indicando
          a quantidade pretendida, dados de contato e, caso entenda necessário, campo para observações ao doador.
          Caso a doação seja feita por particular, cabe aos órgãos ou entidades donatários entrar em contato
          com o doador para concluir a doação, mediante assinatura do Termo de Doação.</span>
      </div>
    </div>
  </slide>
</carousel>

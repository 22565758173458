import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { Anuncio } from '../../models/anuncio.model';
import { AuthService } from '../../../shared/services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { CadastroAnuncioPrivadoFotosComponent } from '../cadastro-anuncio-privado-fotos/cadastro-anuncio-privado-fotos.component';
import { CadastroAnuncioPrivadoIdentificacaoDoadorComponent } from '../cadastro-anuncio-privado-identificacao-doador/cadastro-anuncio-privado-identificacao-doador.component';
import { CadastroAnuncioPrivadoDoacaoComponent } from '../cadastro-anuncio-privado-doacao/cadastro-anuncio-privado-doacao.component';
import { CadastroAnuncioPrivadoFavorecidoComponent } from '../cadastro-anuncio-privado-favorecido/cadastro-anuncio-privado-favorecido.component';
import { CadastroAnuncioPrivadoResumoComponent } from '../cadastro-anuncio-privado-resumo/cadastro-anuncio-privado-resumo.component';
import { AnuncioSituacaomEnum } from 'app/shared/enum/anuncio-situacao.enum';
import { RouteService } from 'app/shared/services/route.service';


@Component({
  selector: 'reuse-cadastro-anuncio-privado-page',
  templateUrl: './cadastro-anuncio-privado-page.component.html',
  styleUrls: ['./cadastro-anuncio-privado-page.component.scss']
})
export class CadastroAnuncioPrivadoPageComponent implements OnInit {

  PASSO_IDENTIFICACAO_DOADOR = 'Identificação Doador';
  PASSO_DOACAO = 'Doação';
  PASSO_FOTOS = 'Fotos';
  PASSO_DONATARIO = 'Donatário';
  PASSO_RESUMO = 'Resumo';

  menuItemMeusAnuncios = { icon: 'fa fa-newspaper-o', label: 'Meus Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_PRIVADO]) } };

  //passos para alterar anúncio
  passos: MenuItem[];
  passo: number = 0;

  numeroAnuncio: number;
  habilitarAbaDonatario: boolean = false;
  anuncioNaoEncontrado: boolean = false;

  //anúncio carregado a partir do 'numeroAnuncio'
  anuncio: Anuncio;

  @ViewChild('reuseCadastroAnuncioPrivadoIdentificacaoDoador') reuseCadastroAnuncioPrivadoIdentificacaoDoador: CadastroAnuncioPrivadoIdentificacaoDoadorComponent;
  @ViewChild('reuseCadastroAnuncioPrivadoDoacao') reuseCadastroAnuncioPrivadoDoacao: CadastroAnuncioPrivadoDoacaoComponent;
  @ViewChild('reuseCadastroAnuncioPrivadoFavorecido') reuseCadastroAnuncioPrivadoFavorecido: CadastroAnuncioPrivadoFavorecidoComponent;
  @ViewChild('reuseCadastroAnuncioPrivadoFotos') reuseCadastroAnuncioPrivadoFotos: CadastroAnuncioPrivadoFotosComponent;
  @ViewChild('reuseCadastroAnuncioPrivadoResumo') reuseCadastroAnuncioPrivadoResumo: CadastroAnuncioPrivadoResumoComponent;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService,
    private cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    private appMessageService: AppMessageService,
    private routeService: RouteService,
  ) { }

  ngOnInit() {
    this.monitorarParametros();
  }

  get usuarioLogado(): Usuario {
    return this.authService.usuario;
  }

  private monitorarParametros(): void {
    this.route.paramMap.subscribe(params => {
      const numero = params.get('numero');

      if (numero == 'novo') {
        this.numeroAnuncio = null;
        this.criarBreadcrumbInclusao();
        this.criarSteps();
        this.passo = 0;
        this.selecionarPasso(this.passo);
        this.anuncioNaoEncontrado = false;

      } else {
        //verifica se 'numero' é valor válido
        if (isNaN(+numero)) {
          this.anuncioNaoEncontrado = true;
        } else {
          this.numeroAnuncio = +numero;
          this.carregarAnuncio();
        }
      }
    });
  }

  private carregarAnuncio(): void {
    //carrega o anúncio que se está tentando alterar
    this.anuncioService.getAnuncio(this.numeroAnuncio, OrigemEnum.MEUS_ANUNCIOS_PRIVADO)
      .subscribe(
        anuncio => {
          this.anuncio = anuncio;

          if (anuncio) {
            this.habilitarAbaDonatario = anuncio.possuiDonatario;

            //Se o anúncio existe, verifica se pode ser alterado pelo usuario
            if (!anuncio.donoAnuncio) {
              this.anuncioNaoEncontrado = true;
              this.messageService.add({ severity: 'error', summary: 'Operação não permitida', detail: 'Anúncio não pertence ao usuário logado.' });
              this.router.navigate(['/']);
              return;
            }

            if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.AGUARDANDO_COMPLEMENTACAO) {
              if (!anuncio.permissoes.podeAtenderComplementacao) {
                this.anuncioNaoEncontrado = true;
                this.messageService.add({ severity: 'error', summary: 'Operação não permitida', detail: 'Situação do anúncio não permite atender complementação pelo usuário privado.' });
                this.router.navigate(['/']);
                return;
              }
            } else if (!anuncio.permissoes.podeAlterar) {
              this.anuncioNaoEncontrado = true;
              this.messageService.add({ severity: 'error', summary: 'Operação não permitida', detail: 'Situação do anúncio não permite alteração pelo usuário privado.' });
              this.router.navigate(['/']);
              return;
            }

            //carrega os componentes da página
            this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
              () => {
                this.carregarComponentes();
              },
              () => {
                this.routeService.voltar('/');
              });
          } else {
            //não existe anúncio para edição
            this.anuncioNaoEncontrado = true;
          }
        }
      );
  }

  /**
   * Inicia os componentes da página.
   */
  private carregarComponentes(): void {
    this.criarBreadcrumbAlteracao();
    this.criarSteps();
    this.selecionarPasso(this.passo);
  }

  /**
   * Configuração da barra 'Breadcrumb' para Alteração do Anúncio.
   */
  private criarBreadcrumbAlteracao(): void {
    let breadcrumbItems = [this.menuItemMeusAnuncios,
    { label: 'Anúncio ' + this.numeroAnuncio, command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_PRIVADO + '/' + this.numeroAnuncio]) } },
    { label: 'Cadastro' }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Configuração da barra 'Breadcrumb' para Inclusão do Anúncio.
   */
  private criarBreadcrumbInclusao(): void {
    let breadcrumbItems = [this.menuItemMeusAnuncios, { label: 'Novo Anúncio' }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Barra de etapas padrão.
   */
  private criarSteps(): void {
    this.passos = [
      { label: this.PASSO_IDENTIFICACAO_DOADOR },
      { label: this.PASSO_DOACAO },
      { label: this.PASSO_DONATARIO, disabled: !this.habilitarAbaDonatario },
      { label: this.PASSO_FOTOS },
      { label: this.PASSO_RESUMO }
    ];

    if (!this.habilitarAbaDonatario) {
      this.passos.splice(2, 1);
    }
  }

  public onNavegarPasso(params: number[]) {
    this.numeroAnuncio = params[0];
    this.selecionarPasso(params[1]);
  }

  public onExibirAbaDonatario(habilitar: boolean) {
    this.habilitarAbaDonatario = habilitar;
    this.criarSteps();
  }

  /**
   * Seleciona o passo, conforme 'passo' da url.
   *
   * @param passo
   */
  private selecionarPasso(passo: number) {
    var passoAnterior = this.passo;
    this.passo = passo;

    if (this.passo == null || this.passo < 0) {
      this.passo = 0;
      return;
    }

    //Vai para o proximo passo caso a aba esteja desabilitada
    // if (this.passos[this.passo].disabled) {
    //   if (this.passo > passoAnterior) {
    //     //Estou avancando, logo, pulo dois passos
    //     this.passo = this.passo + 1;
    //   } else if (this.passo < passoAnterior) {
    //     //Estou voltando, logo, volto dois passos
    //     this.passo = this.passo - 1;
    //   }
    // }


    if (this.passo < 0) {
      this.passo = 0;
    } else if (this.passo > 4) {
      this.passo = 4;
      return;
    }

    if (this.passo === 0 && this.reuseCadastroAnuncioPrivadoIdentificacaoDoador) {
      // carrega o anuncio novamente quando o usuario utiliza o botao voltar e proximo
      this.reuseCadastroAnuncioPrivadoIdentificacaoDoador.atualizaAnuncio(this.numeroAnuncio);
      return;
    }

    if (this.passo === 1 && this.reuseCadastroAnuncioPrivadoDoacao) {
      // carrega o anuncio novamente quando o usuario utiliza o botao voltar e proximo
      this.reuseCadastroAnuncioPrivadoDoacao.atualizaAnuncio(this.numeroAnuncio);
      return;
    }

    if (this.habilitarAbaDonatario && this.passo === 2 && this.reuseCadastroAnuncioPrivadoFavorecido) {
      // carrega o anuncio novamente quando o usuario utiliza o botao voltar e proximo
      this.reuseCadastroAnuncioPrivadoFavorecido.atualizaAnuncio(this.numeroAnuncio);
      return;
    }

    if ((this.habilitarAbaDonatario && this.passo === 3 && this.reuseCadastroAnuncioPrivadoFotos) ||
      (!this.habilitarAbaDonatario && this.passo === 2 && this.reuseCadastroAnuncioPrivadoFotos)) {
      // carrega o anuncio novamente quando o usuario utiliza o botao voltar e proximo
      this.reuseCadastroAnuncioPrivadoFotos.atualizaAnuncio(this.numeroAnuncio, this.habilitarAbaDonatario);
      return;
    }

    if ((this.habilitarAbaDonatario && this.passo === 4 && this.reuseCadastroAnuncioPrivadoResumo) ||
      (!this.habilitarAbaDonatario && this.passo === 3 && this.reuseCadastroAnuncioPrivadoResumo)) {
      // carrega o anuncio novamente quando o usuario utiliza o botao voltar e proximo
      this.reuseCadastroAnuncioPrivadoResumo.atualizaAnuncio(this.numeroAnuncio, this.habilitarAbaDonatario);
      return;
    }
  }

  public onNovoNumeroAnuncio(numero: number) {
    this.numeroAnuncio = numero;

    //Altera o numero do anuncio em todos os filhos
  }
}

import { SelectItem } from 'primeng/components/common/api';
import { Mensagem } from '../../models/mensagem.model';

/**
 * Define comportamentos comuns para os componentes que listam anúncios na parte interna (não público).
 */
export abstract class MensagensIntenaLista {

  inicio = 0;
  tamanhoPagina = 10;
  totalRegistros = 0;
  origem: string;
  mensagens: Mensagem[] = [];

  // ordenações de anúncios disponíveis.
  ordenacoes: SelectItem[];

  // ordem selecionada.
  ordem: number;

  /**
   * Opções de ordenação das minhas mensagens.
   */
  montarOpcoesOrdenacao() {
    this.ordenacoes = [
      {label:'Mais recente', value:1},
      {label:'Não Lidos', value:2},
      {label:'Mais antigo', value:3},
      {label:'Alfabética', value:4}
    ];
  }

  /**
   * A cada troca de página, consulta 10 novas mensagens no backend.
   *
   * @param event
   */
  paginar(event) {
    this.inicio = event.page * this.tamanhoPagina;
    //após 'inicio' sem alterado, realiza consulta
    this.consultarMensagensComParametrosUrl();
  }

  /**
   * Realiza a ordenação das minhas mensagens no backend.
   *
   * @param event
   */
  aplicarOrdenacao(event) {
    this.inicio = 0;
    //após 'ordem' ser alterada, realiza consulta
    this.consultarMensagensComParametrosUrl();
  }

  public abstract consultarMensagensComParametrosUrl();
}

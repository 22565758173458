<p-panel header="Dados Básicos">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">

    <div class="ui-g">
      <div class="ui-g-6">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-6">
        <div class="text-right">
          <p-message severity="info" text="* Campos obrigatórios"></p-message>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <div [class.erro-form]="verificarCampoInvalido('titulo')">Título*</div>
        <input type="text" pInputText formControlName="titulo" style="width:100%" *ngIf="podeAlterarDados()"
          placeholder="Título" maxlength="255"/>
        <div *ngIf="!podeAlterarDados()">{{formulario.get('titulo').value}}</div>
      </div>

      <div class="ui-g-12">
        <div [class.erro-form]="verificarCampoInvalido('descricao')">Descrição*</div>
        <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="descricao" placeholder="Descrição"
          *ngIf="podeAlterarDados()"></textarea>
        <div *ngIf="!podeAlterarDados()">{{formulario.get('descricao').value}}</div>
      </div>

      <div class="ui-g-12">
        <div>Justificativa da doação</div>
        <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="justificativaDoacao"
          placeholder="Justificativa da doação" *ngIf="podeAlterarDados()"></textarea>
        <div *ngIf="!podeAlterarDados()">{{formulario.get('justificativaDoacao').value}}</div>
      </div>

      <div class="ui-g-2 ui-sm-12">
        <div [class.erro-form]="verificarCampoInvalido('quantidade')">Quantidade*</div>
        <p-inputMask id="quantidade" maxlength="5" mask="9?9999" name="quantidade" formControlName="quantidade" autocomplete="off"
          type="text">
        </p-inputMask>
      </div>

      <div class="ui-g-6 ui-sm-12">
        <div [class.erro-form]="verificarCampoInvalido('unidadeFornecimento')">Fornecimento em*</div>
        <p-dropdown [options]="listaUnidadeFornecimento" formControlName="unidadeFornecimento" optionLabel="nome"
          dataKey="id" [filter]="true" *ngIf="podeAlterarDados()"></p-dropdown>
        <div *ngIf="!podeAlterarDados()">{{formulario.get('unidadeFornecimento').value.nome}}</div>
      </div>

      <div class="ui-g-4 ui-sm-12">
        <div [class.erro-form]="verificarCampoInvalido('codigoMaterial')">Código do Material SIADS</div>
        <input type="text" pInputText formControlName="codigoMaterial" placeholder="Código do Material SIADS"
          *ngIf="podeAlterarDados()" maxlength="20"/>
        <div *ngIf="!podeAlterarDados()">{{formulario.get('codigoMaterial').value}}</div>
      </div>
    </div>

    <p-fieldset legend="Material" [style]="{'margin-top':'10px'}">
      <div class="ui-g">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('materialTipo')">Tipo do Material / Serviço*</div>
          <p-dropdown [options]="listaMaterialTipo" formControlName="materialTipo"
            (onChange)="onChangeMaterialCategoria()" #materialTipo optionLabel="nome" dataKey="id"
            *ngIf="podeAlterarDados()"></p-dropdown>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('materialTipo').value.nome}}</div>
        </div>

        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('materialCategoria')">Categoria do Material / Serviço*</div>
          <p-dropdown [options]="listaMaterialCategoria" formControlName="materialCategoria" [filter]="true"
            [autoWidth]="false" [style]="{'width':'100%'}" optionLabel="nome" dataKey="id" *ngIf="podeAlterarDados()">
          </p-dropdown>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('materialCategoria').value.nome}}</div>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset legend="Ônus ou Encargos" [style]="{'margin-top':'10px'}" *ngIf="exibeOnusOuEncargos()">
      <div class="ui-g">
        <!--<div class="ui-g-4 ui-sm-12">
          <p-checkbox formControlName="possuiOnusOuEncargos" label="Possui ônus ou encargos?" binary="true">
          </p-checkbox>
          <div *ngIf="!podeAlterarDados()">Possui ônus ou encargos? {{formulario.get('possuiOnusOuEncargos').value ?
            'Sim' : 'Não'}}</div>
        </div>-->

        <div class="ui-g-4 ui-sm-12">
          <div class="ui-g-12">
            <label class="ui-g-12 noPadding" for="possuiOnusOuEncargos">Possui Ônus ou Encargos*</label>
            <p-radioButton formControlName="possuiOnusOuEncargos" name="possuiOnusOuEncargos" [value]="true"
              label="Sim">
            </p-radioButton>
            <p-radioButton formControlName="possuiOnusOuEncargos" name="possuiOnusOuEncargos" [value]="false"
              label="Não" [style]="{'margin-left': '20px'}">
            </p-radioButton>
          </div>
        </div>

        <div class="ui-g-8 ui-sm-12" *ngIf="exibeJustificativaOnusOuEncargos()">
          <div [class.erro-form]="verificarCampoInvalido('descricaoOnusOuEncargos')">Descrição de ônus ou encargos*
          </div>
          <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="descricaoOnusOuEncargos"
            #descricaoOnusOuEncargos id="descricaoOnusOuEncargos" name="descricaoOnusOuEncargos"
            placeholder="Justificativa de ônus ou encargos"></textarea>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('descricaoOnusOuEncargos').value}}</div>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset legend="Localização" [style]="{'margin-top':'10px'}">
      <div class="ui-g">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('uf')">UF*</div>
          <p-dropdown [options]="listaUf" formControlName="uf" (onChange)="onChangeMunicipio()" #uf [filter]="true"
            optionLabel="sigla" dataKey="id" *ngIf="podeAlterarDados()"></p-dropdown>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('uf').value.sigla}}</div>
        </div>
        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('municipio')">Municipio*</div>
          <p-dropdown [options]="listaMunicipio" formControlName="municipio" [filter]="true" optionLabel="nome"
            dataKey="id" *ngIf="podeAlterarDados()"></p-dropdown>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('municipio').value.nome}}</div>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset legend="Contato" [style]="{'margin-top':'10px','margin-bottom':'10px'}">
      <div class="ui-g">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoNome')">Nome*</div>
          <input type="text" pInputText formControlName="contatoNome" style="width:100%" placeholder="Nome"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()">{{formulario.get('contatoNome').value}}</div>
        </div>
      </div>
      <div class="ui-g">
        <div class="ui-g-12 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoEmail')">Email*</div>
          <input type="text" pInputText formControlName="contatoEmail" style="width:100%" placeholder="Email"
            *ngIf="podeAlterarDados()" />
          <div *ngIf="!podeAlterarDados()">{{formulario.get('contatoEmail').value}}</div>
        </div>
      </div>
      <div class="ui-g">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoTelefone1')">Telefone Principal*</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="contatoTelefone1"
            placeholder="(99) 999999999" *ngIf="podeAlterarDados()"></p-inputMask>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('contatoTelefone1').value}}</div>
        </div>
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoTelefone2')">Telefone Secundário</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="contatoTelefone2"
            placeholder="(99) 999999999" *ngIf="podeAlterarDados()"></p-inputMask>
          <div *ngIf="!podeAlterarDados()">{{formulario.get('contatoTelefone2').value}}</div>
        </div>
      </div>
    </p-fieldset>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" *ngIf="anuncioAlterado" class="ui-button-secondary"
        (click)="onVoltar()"></button>
      <button type="button" pButton label="Cancelar" *ngIf="!anuncioAlterado" class="ui-button-secondary"
        (click)="onCancelar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary"></button>
    </div>

  </form>

</p-panel>

import { RelatorioAnuncioDTO } from './../../models/relatorio-anuncio-dto.model';
import { ResultadoPesquisaRelatorioAnuncio } from './../../models/resultado-pesquisa-relatorio-anuncio.model';
import { ParametrosRelatorioAnuncios } from './../../models/parametros-relatorio-anuncios';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { UtilService } from 'app/shared/services/util.service';
import { Table } from 'primeng/table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-relatorio-anuncio-lista',
  templateUrl: './relatorio-anuncio-lista.component.html',
  styleUrls: ['./relatorio-anuncio-lista.component.scss']
})
export class RelatorioAnuncioListaComponent implements OnInit {

  @ViewChild('mainTable') mainTable: Table;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados = false;

  // Status do botão "Remover". Habilitado ou não.
  buttonStatus = false;
  tpConsulta: number;
  totalRegistros = 0;
  cols: any[];
  anuncios: RelatorioAnuncioDTO[];
  usuarioSelecionado: Usuario;

  sortField: string;
  sortOrder: string;
  inicio = 0;
  tamanhoPagina = 50;
  colunasParaExportacao: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private utilService: UtilService,
    private anuncioService: AnuncioService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.cols = [
      { field: 'dataPublicacaoAnuncio', header: 'Data da Publicação', width: '200px' },
      { field: 'dataEnvioAnuncio', header: 'Data do Envio', width: '200px' },
      { field: 'numeroAnuncio', header: 'Número do Anúncio', width: '175px' },
      { field: 'tipoMaterial', header: 'Tipo do Material / Serviço', width: '200px' },
      { field: 'categoriaMaterialServico', header: 'Categoria do Material / Serviço', width: '400px' },
      { field: 'descricaoMaterialServico', header: 'Descrição do Material / Serviço', width: '400px' },
      { field: 'unidadeMedidaDoacao', header: 'Unidade de Medida do Material / Serviço', width: '300px' },
      { field: 'quantidade', header: 'Quantidade', width: '150px' },
      { field: 'valorTotalMercado', header: 'Valor Total de Mercado (R$)', width: '210px' },
      { field: 'localizacaoBemOuLocalPrestacaoServico', header: 'Localização do Bem / Prestação de Serviço', width: '400px' },
      { field: 'dataFinalizacaoAnuncio', header: 'Data de Finalização', width: '200px' },
      { field: 'identificacaoDonatario', header: 'Identificação do Donatário', width: '400px' },
      { field: 'esferaDonatario', header: 'Esfera do Donatário', width: '200px' },
      { field: 'quantidadeAutorizada', header: 'Quantidade Autorizada', width: '200px' },
      { field: 'dataDoacao', header: 'Data da Doação', width: '175px' },
      { field: 'quantidadeDoada', header: 'Quantidade Doada', width: '200px' },
      { field: 'valorTotalDoado', header: 'Valor Total Doado (R$)', width: '200px' },
      { field: 'identificacaoDoador', header: 'Identificação do Doador', width: '400px' },
      //{ field: 'orgaoDoador', header: 'Órgão do Doador', width: '200px' },
      { field: 'localizacaoDoador', header: 'Localização do Doador', width: '400px' },
      { field: 'justificativaDoacao', header: 'Justificativa da Doação', width: '400px' }
    ];

    this.colunasParaExportacao = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));

    this.monitorarUrl();
  }

  /**
   * Consultar usuarios
   * -Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de usuarios.
   */
  monitorarUrl() {
    this.anuncios = [];

    this.route.queryParams.subscribe(
      (params) => {
        const parametros: ParametrosRelatorioAnuncios =
          this.anuncioService.obterParametrosRelatorioAnuncios(params);

        // Limpa os dados
        this.anuncios = [];
        this.totalRegistros = 0;
        this.carregouDados = false;

        if (!this.tpConsulta) {
          // Eh a primeira deteccao de mudanca de url (onInit).
          this.tpConsulta = 1; // Consulta "completa"
          parametros.tpConsulta = this.tpConsulta + '';
        }

        if (!parametros.inicio) {
          parametros.inicio = '0';
        }

        this.anuncioService.consultarAnunciosRelatorio(parametros).subscribe(
          (resultado: ResultadoPesquisaRelatorioAnuncio) => {
            this.mainTable.first = resultado.inicio; // bug do component, reatribui paginacao
            this.anuncios = resultado.relatorioAnuncio;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.sortField = resultado.sortField;
            this.sortOrder = resultado.sortOrder;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  consultarComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosRelatorioAnuncios =
      this.anuncioService.obterParametrosRelatorioAnuncios(parametrosUrl);

    this.router.navigate(['/' + OrigemEnum.RELATORIO_ANUNCIO], {
      queryParams: {
        anuncianteTipos: parametros.anuncianteTipos,
        anuncianteSituacoes: parametros.anuncioSituacoes,
        anuncioCodigos: parametros.anuncioCodigos,
        materialCategorias: parametros.materialCategorias,
        materialTipos: parametros.materialTipos,
        periodoInclusao: parametros.periodoInclusao,
        periodoAutorizacaoDoacao: parametros.periodoAutorizacaoDoacao,
        idOrgaoDonatario: parametros.idOrgaoDonatario,
        idDonatario: parametros.idDonatario,
        inicio: this.inicio,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        totalRegistros: this.totalRegistros,
        tpConsulta: 2,  // tipo de consulta, para paginacao, nao tem necessidade de total de registros
        dt: new Date().getTime(), // altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
 * A cada troca de página, consulta 10 novas mensagens no backend.
 *
 * @param event
 */
  onPage(event) {
    console.log(event.page);
    console.log(event.first);
    this.inicio = event.first;
    // após 'inicio' sem alterado, realiza consulta
    this.consultarComParametrosUrl();
  }

  /**
  * A cada troca de página, consulta 10 novas mensagens no backend.
  *
  * @param event
  */
  onSort($event: SortEvent) {
    this.sortField = $event.field;
    this.sortOrder = $event.order === -1 ? 'DESC' : 'ASC';
    this.consultarComParametrosUrl();
  }

  exportarParaPdf(): void {
    const doc = new jsPDF('l', 'pt', [2000, 1000]);
    doc['autoTable'](this.colunasParaExportacao, this.anuncios,
      {
        styles: {
          overflow: 'linebreak'
        },
        columnWidth: 'wrap',
        columnStyles: {
          0: { columnWidth: 100 },
          1: { columnWidth: 100 },
          2: { columnWidth: 100 },
          3: { columnWidth: 100 },
          4: { columnWidth: 100 },
          5: { columnWidth: 100 },
          6: { columnWidth: 100 },
          7: { columnWidth: 100 },
          8: { columnWidth: 100 },
          9: { columnWidth: 100 },
          10: { columnWidth: 100 },
          11: { columnWidth: 100 },
          12: { columnWidth: 100 },
          13: { columnWidth: 100 },
          14: { columnWidth: 100 },
          15: { columnWidth: 100 },
          16: { columnWidth: 100 },
          17: { columnWidth: 100 },
          18: { columnWidth: 100 },
          19: { columnWidth: 100 },
        },
        theme: 'striped'
      });

    doc.save('relatorioDeAnuncios.pdf');
  }
}

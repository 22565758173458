import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { CadastroEntidadeService } from 'app/cadastro-entidade/services/cadastro-entidade.service';
import { ResultadoPesquisaCadastroEntidade } from 'app/cadastro-entidade/models/resultado-pesquisa-cadastro-entidade.model';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { UtilService } from 'app/shared/services/util.service';
import { Table } from 'primeng/table';
import { CpfPipe } from 'app/shared/pipes/cpf.pipe';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { ParametrosCadastroEntidade } from 'app/cadastro-entidade/models/parametros-cadastro-entidade.model';

@Component({
  selector: 'reuse-cadastro-entidade-lista',
  templateUrl: './cadastro-entidade-lista.component.html',
  styleUrls: ['./cadastro-entidade-lista.component.scss']
})
export class CadastroEntidadeListaComponent implements OnInit {

  @ViewChild('mainTable') mainTable: Table;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  //Status do botão "Remover". Habilitado ou não.
  buttonStatus: boolean = false;
  tpConsulta: number;
  totalRegistros = 0;
  cols: any[];
  entidades: Entidade[];
  entidadeSelecionada: Entidade;

  sortField: string;
  sortOrder: string;
  inicio = 0;
  tamanhoPagina = 10;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private utilService: UtilService,
    private cadastroEntidadeService: CadastroEntidadeService,
    private messageService: MessageService,
    private anuncioService: AnuncioService
  ) { }

  ngOnInit() {
    this.cols = [
      { field: 'numero', header: 'Número/CNPJ', width: '180px' },
      { field: 'nome', header: 'Nome', width: '180px' },
      { field: 'orgao', header: 'Órgão', width: '180px' },
      { field: 'tipo', header: 'Tipo', width: '180px' },
      { field: 'esfera', header: 'Esfera', width: '100px' },
      { field: 'utilizaSiads', header: 'Utiliza Siads', width: '130px' },
      { field: 'ativo', header: 'Ativo', width: '100px' }

    ];

    this.monitorarUrl();
  }


  /**
   * Consultar entidades
   * -Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de entidades.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosCadastroEntidade = this.cadastroEntidadeService.obterParametrosCadastroEntidadeUrl(params);

        //Limpa os dados
        this.entidades = [];
        this.totalRegistros = 0;
        this.carregouDados = false;

        if (!this.tpConsulta) {
          //Eh a primeira deteccao de mudanca de url (onInit).
          this.tpConsulta = 1; //Consulta "completa"
          parametros.tpConsulta = this.tpConsulta + '';
        }

        if (parametros.tpConsulta == '1') { //Consulta completa
          this.mainTable.reset();
        }

        this.cadastroEntidadeService.consultarEntidade(parametros).subscribe(
          (resultado: ResultadoPesquisaCadastroEntidade) => {
            this.mainTable.first = resultado.inicio; //bug do component, reatribui paginacao
            this.entidades = resultado.entidades;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.sortField = resultado.sortField;
            this.sortOrder = resultado.sortOrder;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
 * Clique botão 'Excluir entidade'.
 */
  public excluirEntidade(entidade: Entidade): void {
    this.confirmationService.confirm({
      key: `modalExcluirEntidade`,
      message: 'Confirma a exclusão da entidade?' + this.retornaDadosEntidade(entidade),
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmouExclusaoEntidade(entidade.id);
      }
    });
  }

  /**
 * Clique botão 'Inativar entidade'.
 */
  public inativarEntidade(entidade: Entidade): void {

    this.anuncioService.obterAnunciosPorEntidade(entidade.id)
      .subscribe((qtd) => {
        this.confirmationService.confirm({
          key: `modalInativarEntidade`,
          message: 'Confirma a inativação da entidade? Se sim, os anúncios (qtd: ' + qtd
            + ') dessa entidade serão inativados e os usuários dela não poderão entrar no sistema Doações' + this.retornaDadosEntidade(entidade),
          header: 'Confirmação',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.confirmouInativarEntidade(entidade.id);
          }
        });
      });
  }

  public retornaDadosEntidade(entidade: Entidade): string {
    return '<br><br><b>Entidade:</b> (' + new CpfPipe().transform(entidade.numero) +
      ') ' + entidade.nome;
  }

  /**
   * excluir entidade
   */
  private confirmouExclusaoEntidade(idEntidade: number) {
    this.cadastroEntidadeService.excluirEntidade(idEntidade).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

        //Recarrega a página após exluir entidade.
        this.monitorarUrl();
      });
  }

  private confirmouInativarEntidade(idEntidade: number) {
    this.cadastroEntidadeService.inativarEntidade(idEntidade).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

        //Recarrega a página após exluir entidade.
        this.monitorarUrl();
      });
  }

  /**
   * Clique botão 'Alterar entidade'.
   */
  public alterarEntidade(entidade: Entidade): void {
    this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ENTIDADE, entidade.id]);
  }

  /**
   * Clique botão 'Visualizar entidade'.
   visualizar
   */
  public visualizarEntidade(entidade: Entidade): void {
    this.router.navigate(['/' + OrigemEnum.VISUALIZAR_CADASTRO_ENTIDADE, entidade.id]);
  }

  /**
   * Clique botão 'Incluir entidade'.
   */
  public incluirEntidade(): void {
    this.router.navigate(['/' + OrigemEnum.INCLUIR_CADASTRO_ENTIDADE]);
  }

  consultarComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosCadastroEntidade = this.cadastroEntidadeService.obterParametrosCadastroEntidadeUrl(parametrosUrl);

    this.router.navigate(['/' + OrigemEnum.LISTAR_CADASTRO_ENTIDADE], {
      queryParams: {
        numero: parametros.numero,
        nome: parametros.nome,
        idEntidadeTipo: parametros.idEntidadeTipo,
        idEntidadeEsfera: parametros.idEntidadeEsfera,
        idOrgao: parametros.idOrgao,
        idUtilizaSiads: parametros.idUtilizaSiads,
        idAtivo: parametros.idAtivo,
        inicio: this.inicio,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        totalRegistros: this.totalRegistros,
        tpConsulta: 2,  // tipo de consulta, para paginacao, nao tem necessidade de total de registros
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
 * A cada troca de página, consulta 10 novas mensagens no backend.
 *
 * @param event
 */
  onPage(event) {
    this.inicio = event.first;
    //após 'inicio' sem alterado, realiza consulta
    this.consultarComParametrosUrl();
  }

  /**
  * A cada troca de página, consulta 10 novas mensagens no backend.
  *
  * @param event
  */
  onSort($event: SortEvent) {
    this.sortField = $event.field;
    this.sortOrder = $event.order == -1 ? 'DESC' : 'ASC';
    this.consultarComParametrosUrl();
  }

  public onNovaEntidade(): void {
    this.router.navigate(['/' + OrigemEnum.INCLUIR_CADASTRO_ENTIDADE, 'novo']);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorHandlerService } from 'app/anuncio/services/api-error-handler.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { JwtService } from '../components/login/jwt.service';
import { Usuario } from '../../anuncio/models/usuario.model';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';


const TOKEN_KEY = 'reuse.token';
@Injectable()
export class AuthService {
  redirectUrlSuccess: string;
  redirectUrlCancel: string;

  private _usuario: Usuario;

  constructor(
    private router: Router,
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService,
    private jwtService: JwtService,
    private messageService: MessageService) {
  }

  get isLoggedIn(): boolean {
    return this.authToken ? true : false;
  }

  private get authToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  private set authToken(value: string) {
    if (value) {
      localStorage.setItem(TOKEN_KEY, value);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }

  /**
 * Altera o token com o valor recebido em cada response do backend..
 */
  public alterarToken(value: string) {
    this.authToken = value;
  }

  logoutBackend() {
    console.log('logoutBackend')

    const url = `${environment.apiUrl}/auth/logout`;
    return this.http.delete(url)
      .pipe(
        tap(() => console.log('retorno logout backend')),
        //ignora possivel erro do servidor
        //catchError(this.errorHandler.handle()),
        finalize(() => {
          this.logoutFrontend();
        }));
  }

  logoutFrontend() {
    console.log('authToken undefined');
    this.authToken = undefined;

    console.log('_usuario undefined');
    this._usuario = undefined;

    console.log('localStorage clear');
    localStorage.clear();

    console.log('sessionStorage clear');
    sessionStorage.clear();

    console.log('localStorage clear');
    localStorage.removeItem(TOKEN_KEY);
    
    console.log('navigate');
    this.router.navigate(['/']);
  }

  login(usuario, senha, tokenCaptcha): Observable<string> {
    const url = `${environment.apiUrl}/auth/loginGoverno`;
    const data = { usuario: usuario, password: senha, tokenCaptcha: tokenCaptcha };
    return this.http.post(url, data)
      .pipe(
        tap((resposta: any) => this.registrarLogin(resposta.token)),
        catchError(this.errorHandler.handle())
      );
  }


  buscarUrlLoginGovBr(): Observable<any> {
    const url = `${environment.apiUrl}/auth/loginGovBr`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  loginGovBr(token) {
    this.registrarLogin(token);
  }

  erroLoginGovBr(mensagemErroLoginGovBr) {

    this.messageService.add({
      severity: 'error',
      summary: 'Operação não permitida',
      detail: mensagemErroLoginGovBr
    });

    catchError(this.errorHandler.handle());
  }

  private registrarLogin(token: string) {
    this.authToken = token;
  }

  public getAuthorizationHeader(): string {
    return `Bearer ${this.authToken}`;
  }

  get usuario(): Usuario {
    if (this.isLoggedIn && this._usuario == null) {
      this._usuario = this.jwtService.getUsuario(this.authToken);
    }
    return this._usuario;
  }

  public captchaMocked(): Observable<boolean> {
    const url = `${environment.apiUrl}/auth/captchaMocked`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public hasRole(expectedRoles: string[]): boolean {
    let usuario = this.usuario;

    if (!expectedRoles) {
      return true;
    }

    if (!usuario || !usuario.perfis) {
      return false;
    }

    for (let er = 0; er < expectedRoles.length; er++) {
      for (let ur = 0; ur < usuario.perfis.length; ur++) {
        if (expectedRoles[er] == usuario.perfis[ur]) {
          return true;
        }
      }
    }
    return false;
  }
}

<footer class="br-footer pt-3" id="footer">
    <div class="container-fluid">
    <div class="row justify-content-between w-100 m-0">
      <div class="col d-flex align-items-center">
        <a href="http://acessoainformacao.gov.br" target="_blank">
          <img src="./../assets/img/logo-acesso-info.png" height="40" title="Acesso a informação" alt="Acesso a informação">
        </a>
      </div>
      <div class="col text-right">
        <a class="item-rodape" href="http://www.economia.gov.br" target="_blank">
          <img src="./../assets/img/logo_ministerio_economia_brasil.png" title="Pátria amada Brasil" alt="Pátria amada Brasil">
        </a>
      </div>
    </div>
    <hr>
    <div class="info">
      <div class="text-down-01 text-medium pb-3">
          Todo o conteúdo deste site está publicado sob a licença <strong><a rel="license" href="https://creativecommons.org/licenses/by-nd/3.0/deed.pt_BR">Creative Commons Atribuição-SemDerivações 3.0 Não Adaptada</a>.</strong>
      </div>
    </div>
  </div>
</footer>

/**
   * 0 = Indefinido
   * 1 = Sim
   * 2 = Nao
   */
export class SimNao {

  constructor(id: number, nome?: string) {
    this.id = id;
    this.nome = nome;
  }

  id: number;
  nome: string;
}

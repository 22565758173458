import { Municipio } from "./municipio.model";
import { Entidade } from "./entidade.model";
import { Perfil } from "./perfil.model";

export class Usuario {
  id: number;
  nome: string;
  nomeAbreviado: string;
  emailGoverno: string;
  telefone1Governo: string;
  telefone2Governo: string;
  emailPrivado: string;
  telefone1Privado: string;
  telefone2Privado: string;
  cpf: string;
  numeroEntidade: string;
  nomeEntidade: string;
  siglaEntidade: string;
  entidadeUtilizaSiads: boolean;
  perfis: string[];
  
  entidade: Entidade;
  enderecoPrivado: string;
  enderecoNumeroPrivado: string;
  bairroPrivado: string;
  cepPrivado: string;
  municipioPrivado: Municipio;
  lstPerfis: Perfil[];
  excluido: boolean;
  governo: boolean;
  privado: boolean;
}

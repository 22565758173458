import { Orgao } from './orgao.model';
import { AnuncioSituacao } from 'app/anuncio/models/anuncio-situacao.model';
import { Entidade } from './entidade.model';
import { AnuncioChip } from './anuncio-chip.model';

export class FiltroConfirmarDoacao {
  periodoPublicacao: Date[];
  anuncioTitulo: string;
  anuncioNumeros: AnuncioChip[];
  donatarios: Entidade[];
  donatariosSelecionados: Entidade[];
  orgao: Orgao;
  donatario: Entidade;
  idDonatatio: string;
  anuncioSituacoes: AnuncioSituacao[];
  anuncioSituacoesSelecionadas: AnuncioSituacao[];
  inicio: string;
  ordem: string;

}

import { AnuncioInteresseSituacao } from './../../models/anuncio-interesse-situacao.model';
import { AnuncioInteresseService } from './../../services/anuncio-interesse.service';
import { AnuncioInteresse } from './../../models/anuncio-interesse.model';
import { ModalConfirmarDoacaoComponent } from './../confirmar-doacao-anuncio-privado/modal-confirmar-doacao.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { CadastroAnuncioService } from 'app/anuncio/services/cadastro-anuncio.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AnuncioUtil } from 'app/shared/services/anuncio.util';
import { AuthService } from 'app/shared/services/auth.service';
import { RouteService } from 'app/shared/services/route.service';
import { UtilService } from 'app/shared/services/util.service';
import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Anuncio } from '../../models/anuncio.model';

@Component({
  selector: 'reuse-anuncio-detail',
  templateUrl: './anuncio-detail.component.html',
  styleUrls: ['./anuncio-detail.component.scss']
})
export class AnuncioDetailComponent implements OnInit {

  @Input()
  anuncio: Anuncio;

  @Input()
  exibirBotaoTenhoInteresse: boolean;

  @Input()
  exibirBotaoExcluir: boolean;

  @Input()
  exibirBotaoEditar: boolean;

  @Input()
  exibirBotaoExcluirAnuncioPrivadoCentralCompras: boolean;

  @Input()
  exibirBotaoInativar: boolean;

  @Input()
  exibirBotoesAutorizarPublicacao: boolean;

  @Input()
  exibirBotaoRepublicacao: boolean;

  @Input()
  exibirDadosVisualizacao: boolean;

  @Input()
  exibirInteressados: boolean;

  @Input()
  exibirBotaoConfirmarDoacao: boolean;

  @Output()
  clickTenhoInteresse = new EventEmitter();

  @Output()
  clickExcluirAnuncioPrivado = new EventEmitter();

  @Output()
  clickExcluirAnuncioPrivadoCentralCompras = new EventEmitter();

  @Output()
  denunciaChanges = new EventEmitter();

  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  exibirModalSolicitarComplementacao: boolean = false;
  exibirModalIndeferirAnuncio: boolean = false;
  exibirModalExcluirAnuncioPrivadoCentralCompras: boolean = false;
  exibirModalDenunciarAnuncio: boolean = false;
  exibirModalAnalisarDenuncia: boolean = false;
  aceitarDenuncia: boolean = false;

  @ViewChild('motivoExclusao') motivoExclusaoElement: ElementRef;
  @ViewChild('motivoIndeferimento') motivoIndeferimentoElement: ElementRef;
  @ViewChild('complementacao') complementacaoElement: ElementRef;
  @ViewChild('botaoDenunciar') botaoDenunciarElement: ElementRef;

  @ViewChild('reuseModalConfirmarDoacao') reuseModalConfirmarDoacao: ModalConfirmarDoacaoComponent;

  anuncioInteresseUsuario: AnuncioInteresse = new AnuncioInteresse();
  //formulário
  formulario: FormGroup;

  origem: string;

  constructor(
    private route: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public anuncioUtil: AnuncioUtil,
    private confirmationService: ConfirmationService,
    private routeService: RouteService,
    private anuncioService: AnuncioService,
    private cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    private router: Router) { }

  ngOnInit() {
    this.origem = this.route.snapshot.data['origem'];

    this.configurarGaleriaImagem();
    this.carregarGaleriaImagem(this.anuncio);
    this.criarFormularioSolicitarComplementacao();
    this.criarFormularioIndeferirAnuncio();

    if (this.origem === OrigemEnum.CONFIRMAR_DOACAO) {
      this.anuncioInteresseUsuario = this.anuncio.interesses[0];
    }
  }


  private configurarGaleriaImagem(): void {
    this.galleryOptions = [
      {
        "imageSize": "contain", "previewCloseOnEsc": true, "previewZoom": true, "previewRotate": true,
        "previewKeyboardNavigation": true, "previewZoomStep": 0.5, "width": "400px", "height": "300px"
      },
      { "breakpoint": 700, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
      { "breakpoint": 600, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
      { "breakpoint": 500, "width": "200px", "height": "200px", "thumbnailsColumns": 2 },
      { "breakpoint": 400, "width": "200px", "height": "200px", "thumbnailsColumns": 2 },
      { "breakpoint": 300, "width": "100px", "height": "100px", "thumbnailsColumns": 2 }
    ];
  }

  private carregarGaleriaImagem(anuncio: Anuncio): void {
    if (anuncio.fotos.length == 0) {
      //carregar foto exemplo
      // const foto = 'https://loremflickr.com/' + (Math.random() > .5 ? '320/200' : '240/320') + '/furniture/?random=' + Math.floor(Math.random() * 10000) + 1;
      const foto_galeria = 'assets/img/sem-imagem-galeria.png';
      const foto_grande = 'assets/img/sem-imagem-grande.png';
      this.galleryImages.push({
        small: foto_galeria, medium: foto_galeria, big: foto_grande
      });
    } else {
      //carregar fotos reais
      anuncio.fotos.forEach(foto => {
        this.galleryImages.push({
          small: foto.fotoGaleria, medium: foto.fotoGaleria, big: foto.fotoGrande
        });
      });
    }
  }

  public onTenhoInteresse(): void {
    this.clickTenhoInteresse.emit();
  }

  public confirmarDoacao(): void {
    this.reuseModalConfirmarDoacao.exibirModal(this.anuncioInteresseUsuario);
  }

  public exibirDetalhes(): boolean {
    if (
      this.anuncioUtil.anuncioGoverno(this.anuncio) &&
      (this.anuncio.donoAnuncio || this.authService.hasRole(['GOVERNO', 'CENTRALCOM', 'GESTOR', 'SEGES'])) &&
      this.origem !== OrigemEnum.CONFIRMAR_DOACAO) {
      return true;
    }
    return false;
  }



  private confirmouPublicacaoAnuncioPrivado(): void {
    this.anuncioService.publicarAnuncioPrivado(this.anuncio.id).subscribe(
      (resultado: any) => {
        this.sucessoRecarregarpagina(resultado.mensagem);
      });
  }

  private confirmouRepublicacaoAnuncioPrivado(): void {
    this.anuncioService.republicarAnuncioPrivado(this.anuncio.id).subscribe(
      (resultado: any) => {
        this.sucessoRepublicarRecarregarpagina(resultado.mensagem);
      });
  }

  private confirmouExclusaoAnuncio(): void {
    this.anuncioService.excluirAnuncio(this.anuncio.id).subscribe(
      (resultado: any) => {
        this.sucessoRecarregarPagina(resultado.mensagem);
      });
  }

  private confirmouInativarAnuncio(): void {
    this.anuncioService.inativarAnuncio(this.anuncio.id).subscribe(
      (resultado: any) => {
        //reusei do excluir
        this.sucessoRecarregarPagina(resultado.mensagem);
      });

  }

  public onSubmitSolicitarComplementacao(submitBtn: HTMLButtonElement): void {
    if (!this.validarFormulario()) {
      return;
    }

    submitBtn.disabled = true;
    this.anuncioService.solicitarComplementacao(this.formulario, this.anuncio).subscribe(
      (resultado: any) => {
        this.sucessoRecarregarpagina(resultado.mensagem);
      }, () => { submitBtn.disabled = false; });
  }

  public onSubmitIndeferirAnuncio(submitBtn: HTMLButtonElement): void {
    if (!this.validarFormulario()) {
      return;
    }

    submitBtn.disabled = true;
    this.anuncioService.indeferirAnuncioPrivado(this.formulario, this.anuncio).subscribe(
      (resultado: any) => {
        this.sucessoRecarregarpagina(resultado.mensagem);
      }, () => { submitBtn.disabled = false; });
  }

  public onSubmitExcluirAnuncioPrivadoCentralCompras(submitBtn: HTMLButtonElement): void {
    if (!this.validarFormulario()) {
      return;
    }

    submitBtn.disabled = true;
    this.anuncioService.excluirAnuncioPrivadoCentralCompras(this.formulario, this.anuncio).subscribe(
      (resultado: any) => {
        this.sucessoRecarregarpaginaExcluirPrivadoCentralCompras(resultado.mensagem);
        submitBtn.disabled = false;
      }, () => { submitBtn.disabled = false; });
  }

  public onSubmitDenunciarAnuncio(submitBtn: HTMLButtonElement): void {
    if (!this.validarFormulario()) {
      return;
    }
    submitBtn.disabled = true;
    this.anuncioService.denunciarAnuncio(this.formulario, this.anuncio).subscribe(
      (resultado: any) => {
        this.denunciaChanges.emit();
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
        this.exibirModalDenunciarAnuncio = false;
        submitBtn.disabled = false;
        this.botaoDenunciarElement.nativeElement.disabled = true;
      }, () => { submitBtn.disabled = false; });
  }

  public onSubmitAnalisarDenuncia(submitBtn: HTMLButtonElement): void {
    if (!this.validarFormulario()) {
      return;
    }
    submitBtn.disabled = true;

    let req = null;

    if (this.aceitarDenuncia) {
      req = this.anuncioService.aceitarDenucia(this.formulario, this.anuncio.denunciaEmAnalise.id);
    } else {
      req = this.anuncioService.rejeitarDenucia(this.formulario, this.anuncio.denunciaEmAnalise.id);
    }

    req.subscribe(
      (resultado: any) => {
        this.denunciaChanges.emit();
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
        submitBtn.disabled = true;
        this.exibirModalAnalisarDenuncia = false;
      }, () => { submitBtn.disabled = false; });
  }

  private validarFormulario(): boolean {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (this.formulario.valid) {
      return true;
    }

    this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    return false;
  }

  public prepararExcluir(): void {
    this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
      () => {
        this.confirmationService.confirm({
          key: `modal_${this.anuncio.id}`,
          message: `Confirma a exclusão do Anúncio ${this.anuncio.id}?`,
          accept: () => {
            this.confirmouExclusaoAnuncio();
          }
        });
      });
  }

  public prepararInativarAnuncio() {
    this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
      () => {
        this.confirmationService.confirm({
          key: `modal_${this.anuncio.id}`,
          message: `Confirma a inativação do Anúncio ${this.anuncio.id}?`,
          accept: () => {
            this.confirmouInativarAnuncio();
          }
        });
      }
    );
  }

  public prepararRepublicarAnuncio(): void {
    this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
      () => {
        this.confirmationService.confirm({
          key: `modal_${this.anuncio.id}`,
          message: `Confirma a republicação do Anúncio ${this.anuncio.id}?`,
          accept: () => {
            this.confirmouRepublicacaoAnuncioPrivado();
          }
        });
      });
  }

  public prepararExcluirAnuncioPrivadoCentralCompras(): void {
    this.cadastroAnuncioService.bloquearAnuncioPrivado(this.anuncio.id).subscribe(
      () => {
        this.anuncio.motivo = '';
        this.criarFormularioExcluirAnuncioPrivadoCentralCompras();
        this.exibirModalExcluirAnuncioPrivadoCentralCompras = true;
        setTimeout(() => { this.motivoExclusaoElement.nativeElement.focus(); }, 0);
      });
  }

  public prepararComplementacaoAnuncioPrivado(): void {
    this.cadastroAnuncioService.bloquearAnuncioPrivado(this.anuncio.id).subscribe(
      () => {
        this.criarFormularioSolicitarComplementacao();
        this.exibirModalSolicitarComplementacao = true;
        setTimeout(() => { this.complementacaoElement.nativeElement.focus(); }, 0);
      });
  }

  public prepararIndeferirAnuncioPrivado(): void {
    this.cadastroAnuncioService.bloquearAnuncioPrivado(this.anuncio.id).subscribe(
      () => {
        this.criarFormularioIndeferirAnuncio();
        this.exibirModalIndeferirAnuncio = true;
        setTimeout(() => { this.motivoIndeferimentoElement.nativeElement.focus(); }, 0);
      });
  }

  public prepararPublicarAnuncioPrivado(): void {
    this.cadastroAnuncioService.bloquearAnuncioPrivado(this.anuncio.id).subscribe(
      () => {
        this.confirmationService.confirm({
          key: `modal_${this.anuncio.id}`,
          message: `Confirma a publicação do Anúncio ${this.anuncio.id}?`,
          accept: () => {
            this.confirmouPublicacaoAnuncioPrivado();
          },
          reject: () => {
            this.cadastroAnuncioService.desbloquearAnuncioPrivado(this.anuncio.id).subscribe(() => { });
          }
        });
      });
  }

  public prepararDenunciarAnuncio(): void {
    this.criarFormularioDenunciarAnuncio();
    this.exibirModalDenunciarAnuncio = true;
  }

  public prepararAnalisarDenuncia(aceitar: boolean) {
    this.criarFormularioAnalisarDenuncia();
    this.exibirModalAnalisarDenuncia = true;
    this.aceitarDenuncia = aceitar;
  }

  public ocultarModalSolicitarComplementacao() {
    this.exibirModalSolicitarComplementacao = false;
    this.cadastroAnuncioService.desbloquearAnuncioPrivado(this.anuncio.id).subscribe(() => { });
  }

  public ocultarModalIndeferirAnuncio() {
    this.exibirModalIndeferirAnuncio = false;
    this.cadastroAnuncioService.desbloquearAnuncioPrivado(this.anuncio.id).subscribe(() => { });
  }

  public ocultarModalExcluirAnuncioPrivadoCentralCompras() {
    this.exibirModalExcluirAnuncioPrivadoCentralCompras = false;
    this.cadastroAnuncioService.desbloquearAnuncioPrivado(this.anuncio.id).subscribe(() => { });
  }

  public ocultarModalDenunciarAnuncio(): void {
    this.exibirModalDenunciarAnuncio = false;
  }

  public ocultarModalAnalisarDenuncia(): void {
    this.exibirModalAnalisarDenuncia = false;
  }

  /**
    * Verifica se o campo está inválido.
    *
    * @param nomeCampo
    */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  private criarFormularioSolicitarComplementacao() {
    this.formulario = this.formBuilder.group({
      complementacao: [this.anuncio.complementacao, [Validators.required]],
    });
  }

  private criarFormularioIndeferirAnuncio() {
    this.formulario = this.formBuilder.group({
      motivo: [this.anuncio.motivo, [Validators.required]],
    });
  }

  private criarFormularioExcluirAnuncioPrivadoCentralCompras() {
    this.formulario = this.formBuilder.group({
      motivo: [this.anuncio.motivo, [Validators.required]],
    });
  }

  private criarFormularioDenunciarAnuncio() {
    this.formulario = this.formBuilder.group({
      motivo: [this.anuncio.motivo, [Validators.required]],
    });
  }

  private criarFormularioAnalisarDenuncia() {
    this.formulario = this.formBuilder.group({
      motivo: [this.anuncio.motivo, [Validators.required]],
    });
  }

  private sucessoRecarregarpagina(mensagem) {
    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: mensagem });

    //Volta para a página de autorizar pulicação de anuncio privado
    this.routeService.voltar(OrigemEnum.AUTORIZAR_PUBLICACAO);
  }

  private sucessoRecarregarPagina(mensagem) {
    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: mensagem });

    //Volta para a página de origem
    this.routeService.voltar(this.origem);
  }

  private sucessoRecarregarpaginaExcluirPrivadoCentralCompras(mensagem) {
    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: mensagem });

    // Volta para a página de autorizar pulicação de anuncio privado
    this.routeService.voltar(OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO);
  }

  private sucessoRepublicarRecarregarpagina(mensagem) {

    this.messageService.add({ severity: 'success', summary: 'Aviso', detail: mensagem });

    // Volta para a página de autorizar pulicação de anuncio privado
    this.routeService.voltar(OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO);


  }

  public exibeEntidade(): boolean {
    return (this.anuncio.entidade && this.anuncio.entidade.id != -1);
  }

  public exibirDadosContato(): boolean {
    return (this.anuncio.donoAnuncio || this.authService.hasRole(['GOVERNO', 'CENTRALCOM', 'GESTOR', 'SEGES']));
  }

  public titutloAnalisarDenuncia(): string {
    return `Motivo para ${this.aceitarDenuncia ? 'aceitar' : 'rejeitar'} denúncia`;
  }

  apresentaInformacoesDemonstracaoInteresse(): boolean {
    if (this.origem === OrigemEnum.CONFIRMAR_DOACAO) {
      return true;
    }
    return false;
  }

  public classAnuncioInteresseSituacao(situacao: AnuncioInteresseSituacao) {
    return 'corAnuncioInteresseSituacao' + situacao.id;
  }

  get totalQuantidadeInteresse() {
    return this.anuncio.interesses.reduce((total, ai) => total + ai.quantidadeInteresse, 0);
  }

  get totalQuantidadeAvaliacao() {
    return this.anuncio.interesses.reduce((total, ai) => total + ai.quantidadeAvaliacao, 0);
  }

  get totalQuantidadeDoada() {
    return this.anuncio.interesses.reduce((total, ai) => total + ai.quantidadeEntregue, 0);
  }

  get totalValorDoado() {
    return this.anuncio.interesses.reduce((total, ai) => (total + parseFloat(ai.valorTotalDoado.replace(/\./g, '').replace(',', '.'))), 0);
  }

  get totalQuantidadeEntregue() {
    return this.anuncio.interesses.reduce((total, ai) => total + ai.quantidadeEntregue, 0);
  }

  get totalRestante() {
    return (this.anuncio.quantidade - this.totalQuantidadeAvaliacao);
  }

  // TODO
  public alterarAnuncio(event: MouseEvent): void {
    // Quando clicar no botão Editar, deve disparar o evento 'clicouAnuncio'
    event.stopPropagation();

    if (this.anuncioUtil.anuncioGoverno(this.anuncio)) {
      this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_GOVERNO, this.anuncio.id]);
    } else {
      this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
        () => {
          this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_PRIVADO, this.anuncio.id]);
        });
    }
  }
}

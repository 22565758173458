import { EntidadeEsfera } from "app/anuncio/models/entidade-esfera.model";
import { EntidadeTipo } from "app/anuncio/models/entidade-tipo.model";
import { Municipio } from "app/anuncio/models/municipio.model";
import { Orgao } from "app/anuncio/models/orgao.model";
import { SimNao } from "app/anuncio/models/sim-nao";

export class FiltroCadastroEntidade {
  numero: string;
  nome: string;
  utilizaSiads: SimNao;
  ativo: SimNao;
  entidadeTipo: EntidadeTipo;
  entidadeEsfera: EntidadeEsfera;
  orgao: Orgao;
  municipio: Municipio;
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { Router } from '@angular/router';

@Component({
  selector: 'reuse-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {

  constructor(
    private appMessageService: AppMessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.criarBreadcrumb();
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb():void {
    let breadcrumbItems = [
      {icon:'fa fa-question', label:'Perguntas Frequentes', command: (onclick)=>{this.router.navigate(['faq'])}}
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

}

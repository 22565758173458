<div class="content-home">
  <article class="col-12">
    <div class="row pl-3">
      <h1>Bem-vindo(a) ao <strong>Doações <span>gov.br</span></strong></h1>
    </div>
    <div class="d-flex flex-wrap justify-content-between">
      <div class="col-md-6 col-sm-12 p-0 d-flex">
        <reuse-info-slides></reuse-info-slides>
      </div>
      <div class="col-md-6 col-sm-12 d-flex">
        <div class="banner-home-img">
          <img class="img-banner" src="../assets/images/home.png" alt="">
        </div>
      </div>
    </div>
  </article>
</div>

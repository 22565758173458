import { AuthService } from './../../../shared/services/auth.service';
import { SimNao } from 'app/anuncio/models/sim-nao';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from 'app/anuncio/models/resultado-pesquisa-anuncio.model';
import { Uf } from 'app/anuncio/models/uf.model';
import { FiltroAnuncio } from 'app/anuncio/models/filtro-anuncio.model';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { MaterialTipo } from '../../models/material-tipo.model';
import { MaterialSituacao } from '../../models/material-situacao.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { ParametrosAnuncios } from '../../models/parametros-anuncios.model';
import { PesquisaAnuncioPrincipal } from 'app/anuncio/provider/dados.provider';
import { AnuncianteTipo } from 'app/anuncio/models/anuncio-anunciante.tipo.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AnuncioSituacao } from 'app/anuncio/models/anuncio-situacao.model';
import { AnuncioChip } from 'app/anuncio/models/anuncio-chip.model';

@Component({
  selector: 'reuse-anuncio-list-page',
  templateUrl: './anuncio-list-page.component.html',
  styleUrls: ['./anuncio-list-page.component.scss']
})
export class AnuncioListPageComponent extends AnunciosInternoLista implements OnInit {

  ANUNCIOS = OrigemEnum.ANUNCIOS;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;
  filtro: FiltroAnuncio = <FiltroAnuncio>{};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService,
    private dadosPesquisaAnuncioPrincipal: PesquisaAnuncioPrincipal,
    private appMessageService: AppMessageService,
    public authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.criarBreadcrumb();
    this.montarOpcoesOrdenacao();
    this.monitorarUrl();
  }

  private consultar(parametros: ParametrosAnuncios) {
    this.anuncioService.list(parametros.pesquisa, parametros.anuncianteTiposS, parametros.materialTiposS, parametros.materialCategoriasS, parametros.materialSituacoesS, parametros.ufsS, parametros.inicio, parametros.ordem, parametros.tpConsulta, parametros.onusS, parametros.anuncioSituacoesS, parametros.numerosAnuncios)
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {

          this.anuncios = resultado.anuncios;
          this.inicio = resultado.inicio;

          if (parametros.tpConsulta != 3) {
            this.totalRegistros = resultado.totalRegistros;
            this.dadosPesquisaAnuncioPrincipal.totalRegistros = resultado.totalRegistros;
          } else {
            this.totalRegistros = this.dadosPesquisaAnuncioPrincipal.totalRegistros;
          }

          let lstAnuncianteTipoS: AnuncianteTipo[] = [];
          let lstMaterialTipoS: MaterialTipo[] = [];
          let lstMaterialCategoriaS: MaterialCategoria[] = [];
          let lstMaterialSituacaoS: MaterialSituacao[] = [];
          let lstUfS: Uf[] = [];
          let lstOnus: SimNao[] = [];
          let lstAnuncioSituacoes: AnuncioSituacao[] = [];

          this.converterLstAnuncianteTipo(lstAnuncianteTipoS, parametros.anuncianteTiposS);
          this.converterLstMaterialTipo(lstMaterialTipoS, parametros.materialTiposS);
          this.converterLstMaterialCategoria(lstMaterialCategoriaS, parametros.materialCategoriasS);
          this.converterLstSituacao(lstMaterialSituacaoS, parametros.materialSituacoesS);
          this.converterLstUf(lstUfS, parametros.ufsS);
          this.converterLstOnus(lstOnus, parametros.onusS);
          this.converterLstAnuncioSituacoes(lstAnuncioSituacoes, parametros.anuncioSituacoesS);

          // Se o tipo da consulta for para filtro e total de registros pega os dados da classe dadosPesquisaAnuncioPrincipal de store.
          if (parametros.tpConsulta == 2 || parametros.tpConsulta == 3 || (parametros.tpConsulta == 1 &&
            (this.dadosPesquisaAnuncioPrincipal.listaAnuncianteTipo != null
              || this.dadosPesquisaAnuncioPrincipal.listaMaterialTipo != null
              || this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria != null
              || this.dadosPesquisaAnuncioPrincipal.listaMaterialSituacao != null
              || this.dadosPesquisaAnuncioPrincipal.listaUf != null
              || this.dadosPesquisaAnuncioPrincipal.listaOnus != null
              || this.dadosPesquisaAnuncioPrincipal.anuncioSituacoes != null))) {


            this.filtro = <FiltroAnuncio>{
              anuncianteTiposD: this.dadosPesquisaAnuncioPrincipal.listaAnuncianteTipo,
              anuncianteTiposS: lstAnuncianteTipoS,
              materialTiposD: this.dadosPesquisaAnuncioPrincipal.listaMaterialTipo,
              materialTiposS: lstMaterialTipoS,
              materialCategoriasD: this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria,
              materialCategoriasS: lstMaterialCategoriaS,
              materialSituacoesD: this.dadosPesquisaAnuncioPrincipal.listaMaterialSituacao,
              materialSituacoesS: lstMaterialSituacaoS,
              ufsD: this.dadosPesquisaAnuncioPrincipal.listaUf,
              ufsS: lstUfS,
              onusD: this.dadosPesquisaAnuncioPrincipal.listaOnus,
              onusS: lstOnus,
              anuncioSituacoesD: this.dadosPesquisaAnuncioPrincipal.anuncioSituacoes,
              anuncioSituacoesS: lstAnuncioSituacoes,
            };

          } else {
            this.dadosPesquisaAnuncioPrincipal.listaAnuncianteTipo = resultado.anuncianteTipos;
            this.dadosPesquisaAnuncioPrincipal.listaMaterialTipo = resultado.materialTipos;
            this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria = resultado.materialCategorias;
            this.dadosPesquisaAnuncioPrincipal.listaMaterialSituacao = resultado.materialSituacoes;
            this.dadosPesquisaAnuncioPrincipal.listaUf = resultado.ufs;
            this.dadosPesquisaAnuncioPrincipal.listaOnus = resultado.onus;
            this.dadosPesquisaAnuncioPrincipal.totalRegistros = resultado.totalRegistros;
            this.dadosPesquisaAnuncioPrincipal.anuncioSituacoes = resultado.anuncioSituacoes;

            this.filtro = <FiltroAnuncio>{
              anuncianteTiposD: resultado.anuncianteTipos,
              anuncianteTiposS: lstAnuncianteTipoS,
              materialTiposD: resultado.materialTipos,
              materialTiposS: lstMaterialTipoS,
              materialCategoriasD: resultado.materialCategorias,
              materialCategoriasS: lstMaterialCategoriaS,
              materialSituacoesD: resultado.materialSituacoes,
              materialSituacoesS: lstMaterialSituacaoS,
              ufsD: resultado.ufs,
              ufsS: lstUfS,
              onusD: resultado.onus,
              onusS: lstOnus,
              anuncioSituacoesD: resultado.anuncioSituacoes,
              anuncioSituacoesS: lstAnuncioSituacoes,
            };
          }

          const numerosAnuncios: AnuncioChip[] = parametros.numerosAnuncios.split(',').filter(v => v != '').map(v => <AnuncioChip>{ value: v, display: v });
          this.filtro.numerosAnuncios = numerosAnuncios;

          // Generate the URL:
          let url = this.router.createUrlTree([OrigemEnum.ANUNCIOS], {
            queryParams: {
              pesquisa: parametros.pesquisa,
              inicio: this.inicio,
              anuncianteTiposD: parametros.anuncianteTiposD,
              anuncianteTiposS: parametros.anuncianteTiposS,
              materialTiposD: parametros.materialTiposD,
              materialTiposS: parametros.materialTiposS,
              materialCategoriasD: parametros.materialCategoriasD,
              materialCategoriasS: parametros.materialCategoriasS,
              materialSituacoesD: parametros.materialSituacoesD,
              materialSituacoesS: parametros.materialSituacoesS,
              ufsD: parametros.ufsD,
              ufsS: parametros.ufsS,
              onusD: parametros.onusD,
              onusS: parametros.onusS,
              anuncioSituacoesD: parametros.anuncioSituacoesD,
              anuncioSituacoesS: parametros.anuncioSituacoesS,
              numerosAnuncios: parametros.numerosAnuncios,
              ordem: this.ordem
            }
          }).toString();

          // Change the URL without navigate:
          //this.location.go(url);
          window.history.replaceState('', '', url);

          // Atualiza a variavel para mostrar a lista de anuncios
          this.carregouDados = true;
          window.scrollTo(0, 0);
        }
      );

  }

  onFiltroModificado(filtro: FiltroAnuncio) {
    const anuncianteTiposD = filtro.anuncianteTiposD.map(anuncianteTipo => anuncianteTipo.id).join(',');
    const anuncianteTiposS = filtro.anuncianteTiposS.map(anuncianteTipo => anuncianteTipo.id).join(',');
    const materialTiposD = filtro.materialTiposD.map(materialTipo => materialTipo.id).join(',');
    const materialTiposS = filtro.materialTiposS.map(materialTipo => materialTipo.id).join(',');
    const materialCategoriasD = filtro.materialCategoriasD.map(materialCategoria => materialCategoria.id).join(',');
    const materialCategoriasS = filtro.materialCategoriasS.map(materialCategoria => materialCategoria.id).join(',');
    const materialSituacoesD = filtro.materialSituacoesD.map(situacao => situacao.id).join(',');
    const materialSituacoesS = filtro.materialSituacoesS.map(situacao => situacao.id).join(',');
    const ufsD = filtro.ufsD.map(uf => uf.id).join(',');
    const ufsS = filtro.ufsS.map(uf => uf.id).join(',');
    const onusD = filtro.onusD.map(i => i.id).join(',');
    const onusS = filtro.onusS.map(i => i.id).join(',');
    const numerosAnuncios = filtro.numerosAnuncios.map(m => m.display).join(',');
    const anuncioSituacoesD = filtro.anuncioSituacoesD.map(i => i.id).join(',');
    const anuncioSituacoesS = filtro.anuncioSituacoesS.map(i => i.id).join(',');

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosAnuncios = this.anuncioService.obterParametrosAnunciosUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.ANUNCIOS], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        inicio: 0, //filtro alterado vai para a primeira pagina
        anuncianteTiposD: anuncianteTiposD,
        anuncianteTiposS: anuncianteTiposS,
        materialTiposD: materialTiposD,
        materialTiposS: materialTiposS,
        materialCategoriasD: materialCategoriasD,
        materialCategoriasS: materialCategoriasS,
        materialSituacoesD: materialSituacoesD,
        materialSituacoesS: materialSituacoesS,
        ufsD: ufsD,
        ufsS: ufsS,
        onusD: onusD,
        onusS: onusS,
        anuncioSituacoesD: anuncioSituacoesD,
        anuncioSituacoesS: anuncioSituacoesS,
        numerosAnuncios: numerosAnuncios,
        ordem: parametros.ordem,
        tpConsulta: 2,  // Consulta filtros
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  private converterLstAnuncianteTipo(objs: AnuncianteTipo[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new AnuncianteTipo(parseInt(id)));
      });
    }
  }

  private converterLstMaterialTipo(objs: MaterialTipo[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new MaterialTipo(parseInt(id)));
      });
    }
  }

  private converterLstMaterialCategoria(objs: MaterialCategoria[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new MaterialCategoria(parseInt(id)));
      });
    }
  }

  private converterLstSituacao(objs: MaterialSituacao[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new MaterialSituacao(parseInt(id)));
      });
    }
  }

  private converterLstUf(objs: Uf[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new Uf(parseInt(id)));
      });
    }
  }

  private converterLstOnus(objs: SimNao[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new SimNao(parseInt(id)));
      });
    }
  }

  private converterLstAnuncioSituacoes(objs: AnuncioSituacao[], str: string) {
    if (str.trim()) {
      str.split(',').forEach(id => {
        objs.push(new AnuncioSituacao(parseInt(id)));
      });
    }
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parametros, realiza nova consulta de anuncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosAnuncios = this.anuncioService.obterParametrosAnunciosUrl(params);
        this.ordem = parametros.ordem;
        this.consultar(parametros);
      });
  }

  /**
    * Consulta os anuncios da tela principal, utilizando os parametros e filtros da url.
    */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosAnuncios = this.anuncioService.obterParametrosAnunciosUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.ANUNCIOS], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        anuncianteTiposD: parametros.anuncianteTiposD,
        anuncianteTiposS: parametros.anuncianteTiposS,
        materialTiposD: parametros.materialTiposD,
        materialTiposS: parametros.materialTiposS,
        materialCategoriasD: parametros.materialCategoriasD,
        materialCategoriasS: parametros.materialCategoriasS,
        materialSituacoesD: parametros.materialSituacoesD,
        materialSituacoesS: parametros.materialSituacoesS,
        ufsD: parametros.ufsD,
        ufsS: parametros.ufsS,
        onusD: parametros.onusD,
        onusS: parametros.onusS,
        anuncioSituacoesD: parametros.anuncioSituacoesD,
        anuncioSituacoesS: parametros.anuncioSituacoesS,
        numerosAnuncios: parametros.numerosAnuncios,
        inicio: this.inicio,
        ordem: this.ordem,
        tpConsulta: 3 // Consulta paginacao
      }
    });
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [
      { icon: 'fa fa-search', label: 'Anúncios', command: (onclick) => { this.router.navigate(['/' + OrigemEnum.ANUNCIOS]) } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaterialTipo } from '../../models/material-tipo.model';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { UnidadeFornecimento } from '../../models/unidadeFornecimento.model';
import { Uf } from '../../models/uf.model';
import { Municipio } from '../../models/municipio.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';
import { RespostaSalvarAnuncioDadosBasicos } from '../../models/resposta-salvar-anuncio-dados-basicos.model';
import { AbstractCadastroAnuncio } from '../cadastro-anuncio-page/abstract-cadastro-anuncio';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { RouteService } from 'app/shared/services/route.service';
import { MunicipioService } from 'app/anuncio/services/municipio.service';
import { MaterialTipoEnum } from 'app/shared/enum/material-tipo.enum';

@Component({
  selector: 'reuse-cadastro-anuncio-dados-basicos',
  templateUrl: './cadastro-anuncio-dados-basicos.component.html',
  styleUrls: ['./cadastro-anuncio-dados-basicos.component.scss']
})
export class CadastroAnuncioDadosBasicosComponent extends AbstractCadastroAnuncio implements OnInit {

  //formulário
  formulario: FormGroup;

  //lista de tipo de materiais disponíveis para exibir no combobox
  listaMaterialTipo: MaterialTipo[] = [];
  //lista de categorias disponíveis para exibir no combobox
  listaMaterialCategoria: MaterialCategoria[] = [];
  //lista de unidades de fornecimento disponíveis para exibir no combobox
  listaUnidadeFornecimento: UnidadeFornecimento[] = [];
  //lista de ufs disponíveis para exibir no combobox
  listaUf: Uf[] = [];
  //lista de municípios disponíveis para exibir no combobox
  listaMunicipio: Municipio[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  @Input()
  anuncioAlterado: boolean = false;

  @Output()
  novoNumeroAnuncio = new EventEmitter<number>();

  constructor(
    private formBuilder: FormBuilder,
    private cadastroAnuncioService: CadastroAnuncioService,
    private municipioService: MunicipioService,
    private utilService: UtilService,
    private messageService: MessageService,
    private router: Router,
    private routeService: RouteService,
    public appMessageService: AppMessageService) {
    super(appMessageService);
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  ngOnInit() {
    this.passo = 0;
  }

  ngOnChanges() {
    //para receber novos valores em '@Input() numeroAnuncio', deve implementar 'ngOnChanges'
    this.obterDadosBasicos();
  }
  private obterDadosBasicos(): void {
    this.cadastroAnuncioService.obterAnuncioDadosBasicos(this.numeroAnuncio)
      .subscribe(
        (dados) => {
          //dados do anúncio
          this.anuncio = dados.anuncio;

          this.listaMaterialTipo = dados.listaMaterialTipo;
          //adiciona opção 'Selecione' (vazia)
          this.listaMaterialTipo.unshift(<MaterialTipo>{ id: null, nome: 'Selecione' });

          this.listaUnidadeFornecimento = dados.listaUnidadeFornecimento;
          //adiciona opção 'Selecione' (vazia)
          this.listaUnidadeFornecimento.unshift(<UnidadeFornecimento>{ id: null, nome: 'Selecione' });

          this.listaUf = dados.listaUf;
          //adiciona opção 'Selecione' (vazia)
          this.listaUf.unshift(<Uf>{ id: null, sigla: 'Selecione' });

          this.criarFormulario();
          this.carregarMaterialCategoria();
          this.carregarMunicipio();

          this.carregouDados = true;
          window.scrollTo(0, 0);

          this.utilService.verificarRegistroBloqueado(this.anuncio, true);
        }
      );
  }

  /**
   * Cria o formulário para inserção ou edição.
   */
  private criarFormulario() {

    const materialCategoria: MaterialCategoria = this.anuncio.materialCategoria;
    const municipio: Municipio = this.anuncio.municipio;

    this.formulario = this.formBuilder.group({
      titulo: [this.anuncio.titulo, [Validators.required]],
      descricao: [this.anuncio.descricao, [Validators.required]],
      materialTipo: [materialCategoria ? materialCategoria.materialTipo : null, [Validators.required, this.utilService.validarIdEntidade]],
      materialCategoria: [materialCategoria, [Validators.required, this.utilService.validarIdEntidade]],
      codigoMaterial: [this.anuncio.codigoMaterial],
      quantidade: [this.anuncio.quantidade, [Validators.required]],
      unidadeFornecimento: [this.anuncio.unidadeFornecimento, [Validators.required, this.utilService.validarIdEntidade]],
      uf: [municipio ? municipio.uf : null, [Validators.required, this.utilService.validarIdEntidade]],
      municipio: [municipio, [Validators.required, this.utilService.validarIdEntidade]],
      contatoNome: [this.anuncio.contatoNome, [Validators.required]],
      contatoEmail: [this.anuncio.contatoEmail, [Validators.required, Validators.email]],
      contatoTelefone1: [this.anuncio.contatoTelefone1, [Validators.required, this.utilService.validarTelefone]],
      contatoTelefone2: [this.anuncio.contatoTelefone2],
      possuiDonatario: [this.anuncio.possuiDonatario],
      possuiOnusOuEncargos: [this.anuncio.possuiOnusOuEncargos],
      descricaoOnusOuEncargos: [this.anuncio.descricaoOnusOuEncargos],
      justificativaDoacao: [this.anuncio.justificativaDoacao],
    });

    this.formulario.get('possuiOnusOuEncargos').valueChanges.subscribe(possuiOnusOuEncargos => {
      const descricaoOnusOuEncargos = this.formulario.get('descricaoOnusOuEncargos');
      if (possuiOnusOuEncargos) {
        descricaoOnusOuEncargos.setValidators([Validators.required]);
      } else {
        descricaoOnusOuEncargos.setValidators(null);
        descricaoOnusOuEncargos.setValue('');
      }
      descricaoOnusOuEncargos.updateValueAndValidity();
    });

    this.controleDesabilitarFormulario();
  }

  /**
   * Verifica se deve desabilitar campos do formulário.
   */
  private controleDesabilitarFormulario(): void {
    if (!this.podeAlterarDados()) {
      this.formulario.disable();
    }
  }

  /**
   * Somente anúncios originados na Web podem ser alterados,
   * anúncios do Grande Porte não podem ser alterados.
   * Verifica se registro está bloqueado.
   */
  public podeAlterarDados(): boolean {
    return this.anuncio.anuncioOrigem.nome == 'Web'
      && !this.anuncio.mensagemBloqueio;
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    // Nesse primeiro caso nao precisa salvar, pois somente pode alterar as fotos.
    if (!this.podeAlterarDados()) {
      this.onProximo();
    } else if (this.formulario.valid) {
      this.cadastroAnuncioService.salvarDadosBasicos(this.formulario, this.anuncio).subscribe(
        (resultado: RespostaSalvarAnuncioDadosBasicos) => {
          if (this.numeroAnuncio == null) {
            this.novoNumeroAnuncio.emit(resultado.idAnuncio);
          }
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
          this.onProximo();
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  /**
   * onChange para para dropdown 'Categoria do Material / Serviço'.
   */
  public onChangeMaterialCategoria(): void {
    this.formulario.get('materialCategoria').setValue({ 'id': null });
    this.carregarMaterialCategoria();
  }

  /**
   * Carrega a opção de 'Categorias' a partir do 'Material Tipo' selecionado.
   */
  private carregarMaterialCategoria(): void {
    const materialTipoSelecionado: any = this.formulario.get('materialTipo').value;
    if (materialTipoSelecionado == null || materialTipoSelecionado.id == null) {
      //caso não exista 'tipo' selecionado, remove as opção de 'categoria'
      this.listaMaterialCategoria = [];
      this.formulario.get('materialCategoria').disable();
    } else {
      this.cadastroAnuncioService.obterMaterialCategorias(materialTipoSelecionado.id)
        .subscribe(
          (categorias) => {
            this.listaMaterialCategoria = categorias;
            this.listaMaterialCategoria.unshift(<MaterialCategoria>{ id: null, nome: 'Selecione' });

            if (this.podeAlterarDados()) {
              this.formulario.get('materialCategoria').enable();
            }
          }
        );
    }
  }

  /**
   * onChange para para dropdown 'Município'.
   */
  public onChangeMunicipio(): void {
    this.formulario.get('municipio').setValue({ 'id': null });
    this.carregarMunicipio();
  }

  /**
   * Carrega a opção de 'Municípios' a partir da 'Uf' selecionada.
   */
  private carregarMunicipio(): void {
    // const idUf:number = this.formulario.get('uf').value.id;
    const ufSelecionado: any = this.formulario.get('uf').value;
    if (ufSelecionado == null || ufSelecionado.id == null) {
      //caso não exista 'uf' selecionado, remove as opção de 'municípios'
      this.listaMunicipio = [];
      this.formulario.get('municipio').disable();
    } else {
      this.municipioService.listarMunicipiosAtivos(ufSelecionado.id)
        .subscribe(
          (municipios) => {
            this.listaMunicipio = municipios;
            this.listaMunicipio.unshift(<Municipio>{ id: null, nome: 'Selecione' });
            if (this.podeAlterarDados()) {
              this.formulario.get('municipio').enable();
            }
          }
        );
    }
  }

  /**
   * Clique botão 'Cancelar'.
   */
  public onCancelar(): void {
    if (this.numeroAnuncio == null) {
      // navega para página de meus anúncio
      this.onVoltar();
    } else {
      this.cadastroAnuncioService.cancelarCadastroAnuncio(this.numeroAnuncio).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

          // navega para página de meus anúncio
          this.onVoltar();
        });
    }
  }

  /**
   * Clique botão 'Voltar'.
   */
  public onVoltar(): void {
    // navega para página de meus anúncio
    this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_GOVERNO);
  }

  public exibeOnusOuEncargos() {
    return !this.isAnuncioServico();
  }

  public exibeJustificativaOnusOuEncargos(): boolean {
    return this.formulario.get('possuiOnusOuEncargos').value;
  }

  private isAnuncioServico(): boolean {
    let materialTipoSelecionado: any;
    if (this.formulario) {
      materialTipoSelecionado = this.formulario.get('materialTipo').value;
    } else {
      materialTipoSelecionado = (this.anuncio.materialCategoria ? this.anuncio.materialCategoria.materialTipo : null);
    }

    if ((materialTipoSelecionado && materialTipoSelecionado.id && materialTipoSelecionado.id == MaterialTipoEnum.SERVICO) || !materialTipoSelecionado || !materialTipoSelecionado.id) {
      return true;
    }
    return false;
  }
}

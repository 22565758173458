<p-panel header="Donatário">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">
    <div class="ui-g">
      <div class="ui-g-6">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-6">
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <div [class.erro-form]="verificarCampoInvalido('orgaoFavorecida')">Órgão*</div>
        <p-dropdown [options]="listaOrgaoFavorecida" formControlName="orgaoFavorecida"
          (onChange)="onChangeEntidadeFavorecida()" #orgaoFavorecida [filter]="true" optionLabel="idNome" dataKey="id">
        </p-dropdown>
      </div>
      <div class="ui-g-12">
        <div [class.erro-form]="verificarCampoInvalido('entidadeFavorecida')">Entidade do Donatário*</div>
        <p-dropdown [options]="listaEntidadeFavorecida" formControlName="entidadeFavorecida"
          (onChange)="onChangeMensagemFavorecida()" #entidadeFavorecida [filter]="true" optionLabel="nomeNumero"
          dataKey="id"></p-dropdown>
      </div>
      <div class="ui-g-12">
        <div [class.erro-form]="verificarCampoInvalido('mensagemFavorecida')">Mensagem ao Donatário </div>
        <textarea [rows]="5" pInputTextarea formControlName="mensagemFavorecida"
          placeholder="Mensagem ao Donatário"></textarea>
      </div>
    </div>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary"></button>
    </div>

  </form>

</p-panel>

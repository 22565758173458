import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MensagemService } from '../../services/mensagem.service';
import { MenuItem } from 'primeng/components/common/menuitem';
import { Mensagem } from '../../models/mensagem.model';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { RouteService } from '../../../shared/services/route.service';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AppMessage } from '../../../_services/app.message/app.message.model';

@Component({
  selector: 'reuse-mensagem-detail-page',
  templateUrl: './mensagem-detail-page.component.html',
  styleUrls: ['./mensagem-detail-page.component.scss']
})
export class MensagemDetailPageComponent implements OnInit {

  mensagem: Mensagem;
  numeroMensagem: number;
  mensagemNaoEncontrada: boolean = false;

  constructor(
    private mensagemService: MensagemService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router,
    private routeService: RouteService,
    private appMessageService: AppMessageService) {
  }

  ngOnInit() {
    this.mensagemNaoEncontrada = false;
    this.numeroMensagem = +this.route.snapshot.paramMap.get('numero');
    this.criarBreadcrumb();

    this.mensagemService.lerMensagem(this.numeroMensagem)
      .subscribe(
        mensagem => {
          this.mensagem = mensagem;
          if (!this.mensagem) {
            this.mensagemNaoEncontrada = true;
          }
        }, () => {
          this.mensagem = undefined;
          this.mensagemNaoEncontrada = true;
        }
      );
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    const telaMensagens: MenuItem = {
      icon: 'fa fa-envelope',
      label: 'Mensagens',
      command: () => {
        this.router.navigate(['/mensagens'], { queryParams: { mensagemSituacoes: 'Lida,Não lida' } });
      }
    };

    const telaDetalhe: MenuItem = { label: 'Mensagem ' + this.numeroMensagem, command: (onclick) => { this.router.navigate(['mensagem/' + this.numeroMensagem], { queryParams: { dt: new Date().getTime() } }) } };

    let breadcrumbItems = [telaMensagens, telaDetalhe];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  marcarNaoLida() {
    //Ao ser acionada, altera a Situação da Mensagem para 'Não Lida', remove o Usuário e a Data e Hora da primeira leitura e retorna para a lista de Mensagens
    this.mensagemService.marcarNaoLida(this.numeroMensagem)
      .subscribe(
        mensagem => {
          if (mensagem) {
            this.voltar();
          }
        }
      );
  }

  restaurarLida() {
    //Ao ser acionada, altera a Situação da Mensagem para 'Lida', remove o Usuário e a Data e Hora de exlusão
    this.mensagemService.restaurarLida(this.numeroMensagem)
      .subscribe(
        mensagem => {
          if (mensagem) {
            this.voltar();
          }
        }
      );
  }

  voltar() {
    this.routeService.voltar('mensagens');
  }

  /**
   * Exibe Dialog para confirmar exclusão.
   */
  onExcluir() {
    this.confirmationService.confirm({
      message: 'Confirma a exclusão da mensagem?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.confirmarExclusao();
      }
    });
  }

  confirmarExclusao() {
    // Ao ser acionada, o sistema deve exibir a mensagem 544097: AL001 solicitando confirmação. Caso o usuário não confirme, nenhuma ação é realizada. Caso o usuário confirme o sistema exclui a mensagem e retorna para a lista de Mensagens.
    this.mensagemService.excluir(this.numeroMensagem)
      .subscribe(
        mensagem => {
          if (mensagem) {
            this.voltar();
          }
        }
      );
  }

}

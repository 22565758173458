import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AnuncioInteresse } from '../../models/anuncio-interesse.model';
import { Anuncio } from '../../models/anuncio.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnuncioInteresseService } from '../../services/anuncio-interesse.service';

@Component({
  selector: 'reuse-analisar-interesse',
  templateUrl: './analisar-interesse.component.html',
  styleUrls: ['./analisar-interesse.component.scss']
})
export class AnalisarInteresseComponent implements OnInit {

  @Input()
  anuncio: Anuncio;

  //evento para avisar que finalizou a análise
  @Output()
  analiseEfetuada = new EventEmitter<AnuncioInteresse>();

  //indica se deve exibir o modal de analisar interesse
  exibirModalAnalisarInteresse: boolean = false;

  //formulário 'Tenho Interesse'
  formulario: FormGroup;

  //anuncio interesse a ser analisado
  anuncioInteresse: AnuncioInteresse;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private anuncioInteresseService: AnuncioInteresseService,
  ) { }

  ngOnInit() {
  }

  /**
   * Exibir modal.
   */
  exibirModal(anuncioInteresse: AnuncioInteresse) {
    this.anuncioInteresse = anuncioInteresse;

    this.criarFormulario();
    this.exibirModalAnalisarInteresse = true;
  }

  onCancelar() {
    this.ocultarModal();
  }

  /**
   * Ocultar modal.
   */
  ocultarModal() {
    this.exibirModalAnalisarInteresse = false;
  }

  onSubmit() {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (this.formulario.valid) {
      this.anuncioInteresse.quantidadeAvaliacao = this.formulario.get('quantidadeAvaliacao').value;
      this.anuncioInteresse.observacaoAvaliacao = this.formulario.get('observacaoAvaliacao').value;
      this.anuncioInteresse.idAnuncio = this.anuncio.id;

      this.anuncioInteresseService.autorizarDoacao(this.anuncioInteresse).subscribe(
        (resultado: any) => {
          this.messageService.add({severity: 'success', summary: 'Sucesso', detail: resultado.mensagem});
          this.analiseEfetuada.emit(this.anuncioInteresse);
          this.ocultarModal();
        });
    } else {
      this.messageService.add({severity: 'error', summary: 'Erro de Validação', detail: 'Verifique os campos com erro.'});
    }
  }

  private criarFormulario() {
    this.formulario = this.formBuilder.group({
      quantidadeAvaliacao: [this.anuncioInteresse.quantidadeInteresse || 0, [Validators.required, Validators.min(0), Validators.max(this.anuncioInteresse.quantidadeInteresse)]],
      observacaoAvaliacao: [this.anuncioInteresse.observacaoAvaliacao]
    });
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  verificarCampoInvalido(nomeCampo: string) {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }
}

import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { CadastroUsuarioGovernoService } from 'app/cadastro-usuario-governo/services/cadastro-usuario-governo.service';
import { ParametrosCadastroUsuarioGoverno } from 'app/cadastro-usuario-governo/models/parametros-cadastro-usuario-governo.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { FiltroCadastroUsuarioGoverno } from 'app/cadastro-usuario-governo/models/filtro-cadastro-usuario-governo.model';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { Orgao } from 'app/anuncio/models/orgao.model';
import { UtilService } from 'app/shared/services/util.service';
import { RouteService } from 'app/shared/services/route.service';
import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { OrgaoService } from 'app/anuncio/services/orgao.service';

@Component({
  selector: 'reuse-cadastro-usuario-governo-page',
  templateUrl: './cadastro-usuario-governo-page.component.html',
  styleUrls: ['./cadastro-usuario-governo-page.component.scss']
})
export class CadastroUsuarioGovernoPageComponent implements OnInit {

  filtro: FiltroCadastroUsuarioGoverno = new FiltroCadastroUsuarioGoverno();

  //lista orgao disponiveis para pesquisa
  listaOrgao: Orgao[] = [];

  //lista entidades disponiveis para pesquisa
  listaEntidade: Entidade[] = [];

  @ViewChild('entidade')
  entidadeDropdown: Dropdown;

  @ViewChild('orgao')
  orgaoDropdown: Dropdown;

  constructor(
    private cadastroUsuarioGovernoService: CadastroUsuarioGovernoService,
    private orgaoService: OrgaoService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private appMessageService: AppMessageService,
    private routeService: RouteService,) {
  }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosCadastroUsuarioGoverno = this.cadastroUsuarioGovernoService.obterParametrosCadastroUsuarioGovernoUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.listarOrgao();
    this.consultarFiltros(parametros);
    this.onChangeEntidade();
    this.entidadeDropdown.emptyFilterMessage = 'Nenhuma entidade encontrada.';
    this.orgaoDropdown.emptyFilterMessage = 'Nenhum orgão encontrado.';
  }

  private listarOrgao(): void {
    this.orgaoService.listarOrgaosAtivos()
      .subscribe(
        (orgaos) => {
          this.listaOrgao = [];
          for (let orgao of orgaos) {
            orgao.idNome = orgao.id + ' - ' + orgao.nome;
            this.listaOrgao.push(orgao);
          }

          //adiciona opção 'Selecione' (vazia)
          this.listaOrgao.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });
        }
      );
  }

  /**
   * onChange para para dropdown 'Entidade'.
   */
  public onChangeEntidade(): void {

    // limpa o filtro de entidade, caso o orgao seja modificado.
    this.filtro.entidade = null;
    // limpa o filtro do drowpdown da entidade, caso o orgao seja modificado.
    this.entidadeDropdown.filterValue = '';
    // limpa o filtro do drowpdown do orgão, caso o orgao seja modificado.
    this.orgaoDropdown.filterValue = '';

    if (this.filtro.orgao == null || this.filtro.orgao.id == null) {
      this.listaEntidade = [];
    } else {
      this.carregarEntidade();
    }
  }

  /**
   * Carrega a opção de 'Entidades' a partir do 'Orgao' selecionado.
   */
  private carregarEntidade(): void {
    this.cadastroUsuarioGovernoService.obterEntidadePorOrgao(this.filtro.orgao.id)
      .subscribe(
        (entidades) => {
          this.listaEntidade = [];
          for (let entidade of entidades) {
            entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
            this.listaEntidade.push(entidade);
          }
          this.listaEntidade.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
        }
      );
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    let breadcrumbItems = [
      { icon: 'fa fa-vcard', label: 'Cadastrar Usuários de Governo', command: (onclick) => { this.consultarComParametrosFiltro(); } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
 * Chama o backend para carregar todos os filtros utilizados na página.
 * Realizado somente quando a página é iniciada.
 *
 * @param parametros
 */
  consultarFiltros(parametros: ParametrosCadastroUsuarioGoverno) {
    this.definirFiltrosSelecionados(parametros);
  }

  /**
   * Marca os  selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosCadastroUsuarioGoverno) {
    this.filtro.nome = parametros.nome;
    this.filtro.cpf = parametros.cpf;
    this.filtro.entidade = parametros.idEntidade ? new Entidade(parseInt(parametros.idEntidade)) : null;
    this.filtro.orgao = parametros.idOrgao ? new Orgao(parseInt(parametros.idOrgao)) : null;
  }

  /**
  * Chama uma nova URL adicionando todos os filtros selecionados.
  * Componente 'bloqueio-usuario-privado-lista' monitora a URL para carregar novos bloqueios.
  */
  consultar() {
    this.validarCampos();
    this.consultarComParametrosFiltro();
  }

  /**
    * Chama uma nova URL adicionando todos os filtros selecionados.
    * Componente 'cadastro-usuario-governo-lista' monitora a URL para carregar novos bloqueios.
    */
  consultarComParametrosFiltro() {
    this.router.navigate(['/' + OrigemEnum.LISTAR_CADASTRO_USUARIO_GOVERNO], {
      queryParams: {
        nome: this.filtro.nome,
        cpf: this.filtro.cpf,
        idEntidade: (this.filtro.entidade && this.filtro.entidade.id) ? this.filtro.entidade.id : null,
        idOrgao: (this.filtro.orgao && this.filtro.orgao.id) ? this.filtro.orgao.id : null,
        tpConsulta: 1,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  validarCampos() {
    this.filtro.cpf = this.utilService.removerNaoNumerico(this.filtro.cpf);
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.cpf = "";
    this.filtro.nome = "";
    this.filtro.entidade = null;
    this.filtro.orgao = null;
    this.listaEntidade = [];
    this.consultarComParametrosFiltro();
  }

  public onBlurCpf(event) {
    this.filtro.cpf = this.utilService.removerNaoNumerico(event.target.value);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MensagensIntenaLista } from '../components/commom/mensagens-intena-lista';
import { MensagemService } from '../services/mensagem.service';
import { ParametrosMensagens } from '../models/parametros-mensagens.model';
import { ResultadoPesquisaMensagem } from '../models/resultado-pesquisa-mensagem.model';


@Component({
  selector: 'reuse-mensagens-lista',
  templateUrl: './mensagens-lista.component.html',
  styleUrls: ['./mensagens-lista.component.scss']
})
export class MensagensListaComponent extends MensagensIntenaLista implements OnInit {

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private mensagemService: MensagemService) {
      super();
    }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
    this.origem = String(this.route.snapshot.data['origem']);
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta ne mensagens
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosMensagens = this.mensagemService.obterParametrosUrl(params);
        this.ordem = parametros.ordem;

        this.mensagemService.consultarMensagens(parametros).subscribe(
          (resultado: ResultadoPesquisaMensagem) => {
            this.mensagens = resultado.mensagens;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0,0);
          });
      });
  }

  /**
   * Consulta as mensagens, utilizando os parâmetros/filtros da url.
   */
  consultarMensagensComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMensagens = this.mensagemService.obterParametrosUrl(parametrosUrl);

    this.router.navigate(['/mensagens'], {
      queryParams: {
        mensagemSituacoes: parametros.mensagemSituacoes,
        periodoLeitura: parametros.periodoLeitura,
        anuncios: parametros.anuncios,
        titulo: parametros.titulo,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}

<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label for="numeroAnuncio">Número do Anúncio</label>
          <tag-input id="numeroAnuncio" name="numeroAnuncio" [(ngModel)]='filtro.anuncios'
            [separatorKeyCodes]="separadores" [validators]="somenteNumero" placeholder="Número"
            secondaryPlaceholder="Filtrar por Número" addOnBlur="true" theme='bootstrap'>
          </tag-input>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()">
        </p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="aplicarFiltros()">
        </p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

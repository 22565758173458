import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { LoggedGuardService } from './shared/services/logged-guard.service';
import { FaqComponent } from './shared/components/faq/faq.component';
import { IndexComponent } from './shared/components/index/index.component';
import { SobreComponent } from './shared/components/sobre/sobre.component';
import { FaleConoscoComponent } from './shared/components/fale-conosco/fale-conosco.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedGuardService]
  },
  {
    path: 'fale',
    component: FaleConoscoComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'sobre',
    component: SobreComponent,
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'StringDTOToDate'})
export class StringDTOToDatePipe implements PipeTransform {
  constructor() { }

  transform(date: string) {
    let vetDatePart = date.split('-');
    return new Date(+vetDatePart[0], (+vetDatePart[1]-1), +vetDatePart[2], +vetDatePart[3], +vetDatePart[4], +vetDatePart[5]);
  }

  public transformDateToStringWithHours(date: Date) {
    let resultDate = date.toISOString().replace('T', '-')[0];
    return resultDate;
  }

  public transformDateToStringWithoutHours(date: Date) {
    let resultDate = date.toISOString().split('T')[0];
    return resultDate;
  }

  public transformStringToStringWithoutHours(date: string) {
    let vetDatePart = date.split('-');
    let resultDate = vetDatePart[0] + '-' + vetDatePart[1] + '-' + vetDatePart[2];
    return resultDate;
  }

  public transformStringToStringWithoutHoursLocal(date: string) {
    let vetDatePart = date.split('-');
    let resultDate = vetDatePart[2] + '/' + vetDatePart[1]  + '/' + vetDatePart[0];
    return resultDate;
  }

}

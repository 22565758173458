import { RouteService } from 'app/shared/services/route.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { MudancaDoacaoDto } from './../../models/mudancao-doacao-dto';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AnuncioInteresse } from '../../models/anuncio-interesse.model';
import { Anuncio } from '../../models/anuncio.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnuncioInteresseService } from '../../services/anuncio-interesse.service';

@Component({
  selector: 'reuse-modal-finalizar-autorizacao-doacao',
  templateUrl: './modal-finalizar-autorizacao-doacao.component.html',
  styleUrls: ['./modal-finalizar-autorizacao-doacao.component.scss']
})
export class ModalFinalizarAutorizacaoDoacaoComponent implements OnInit {

  @Input()
  anuncio: Anuncio;

  // evento para avisar que finalizou a análise
  @Output()
  analiseEfetuada = new EventEmitter<MudancaDoacaoDto>();

  // indica se deve exibir o modal de analisar interesse
  exibirModalFinalizarAutorizacaoDoacao = false;

  // formulário 'Finalizar Autorização Doação'
  formulario: FormGroup;

  // Objeto mudancaDoacaoDto para decidir como será exibido o modal
  mudancaDoacaoDto: MudancaDoacaoDto;

  idAnuncio: number;

  motivoMudancaQtdeDoacao: string = null;
  motivoMudancaOrdemDoacao: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private anuncioInteresseService: AnuncioInteresseService,
    private routeService: RouteService
  ) { }

  ngOnInit() {
  }

  /**
   * Exibir modal.
   */
  exibirModal(idAnuncio: number, mudancaoDoacaoDto: MudancaDoacaoDto) {
    this.idAnuncio = idAnuncio;
    this.mudancaDoacaoDto = mudancaoDoacaoDto;
    this.mudancaDoacaoDto.idAnuncio = idAnuncio;
    this.criarFormulario();
    this.exibirModalFinalizarAutorizacaoDoacao = true;
  }

  onCancelar() {
    this.ocultarModal();
  }

  /**
   * Ocultar modal.
   */
  ocultarModal() {
    this.exibirModalFinalizarAutorizacaoDoacao = false;
  }

  onSubmit() {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (this.formulario.valid) {
      if (this.mudancaDoacaoDto.necessitaMotivoMudancaOrdemDoacao) {
        this.mudancaDoacaoDto.motivoMudancaOrdemDoacao = this.formulario.get('motivoMudancaOrdemDoacao').value;
      }

      if (this.mudancaDoacaoDto.necessitaMotivoMudancaQtdeDoacao) {
        this.mudancaDoacaoDto.motivoMudancaQtdeDoacao = this.formulario.get('motivoMudancaQtdeDoacao').value;
      }

       this.anuncioInteresseService.finalizarAutorizarDoacao(this.mudancaDoacaoDto.idAnuncio, this.mudancaDoacaoDto).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
          // this.analiseEfetuada.emit(this.mudancaDoacaoDto);
          this.ocultarModal();
          this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_INTERESSES);
        });

    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro de Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  private criarFormulario() {
    this.formulario = this.formBuilder.group({
      motivoMudancaOrdemDoacao: [this.mudancaDoacaoDto.motivoMudancaOrdemDoacao,
      (this.mudancaDoacaoDto.necessitaMotivoMudancaOrdemDoacao) ? [Validators.required] : []],
      motivoMudancaQtdeDoacao: [this.mudancaDoacaoDto.motivoMudancaQtdeDoacao,
      (this.mudancaDoacaoDto.necessitaMotivoMudancaQtdeDoacao) ? [Validators.required] : []]
    });
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  verificarCampoInvalido(nomeCampo: string) {
    const campo = this.formulario.get(nomeCampo);
    // inválido e alterado
    return campo.invalid && campo.dirty;
  }
}

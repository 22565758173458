import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ApiErrorHandlerService } from '../../../anuncio/services/api-error-handler.service';
import { Observable } from 'rxjs';
import { VersaoBackend } from '../footer/models/versao-backend.model';

@Injectable({
  providedIn: 'root'
})
export class SobreService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  public obterVersao(): Observable<VersaoBackend> {
    const url = `${environment.apiUrl}/versao`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroAnuncio } from 'app/anuncio/models/filtro-anuncio.model';
import { PesquisaAnuncioPrincipal } from 'app/anuncio/provider/dados.provider';
import { AuthService } from 'app/shared/services/auth.service';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';

@Component({
  selector: 'reuse-anuncio-filter',
  templateUrl: './anuncio-filter.component.html',
  styleUrls: ['./anuncio-filter.component.scss']
})
export class AnuncioFilterComponent extends AnunciosInternoPage implements OnInit, OnChanges {

  @Input()
  filtro: FiltroAnuncio = <FiltroAnuncio>{};

  @Output() filtroModificado = new EventEmitter<FiltroAnuncio>();

  mostraSituacaoMaterial: boolean;

  constructor(private appMessageService: AppMessageService,
    private dadosPesquisaAnuncioPrincipal: PesquisaAnuncioPrincipal, public authService: AuthService) {
    super();
    this.appMessageService.getMessage().subscribe(appMessage => {
      if (appMessage.messageType == AppMessageType.PESQUISAR) {
        this.filtro.anuncianteTiposS = [];
        this.filtro.materialTiposS = [];
        this.filtro.materialCategoriasS = [];
        this.filtro.materialSituacoesS = [];
        this.filtro.ufsS = [];
        this.filtro.onusS = [];
        this.filtro.anuncioSituacoesS = [];
        this.filtro.numerosAnuncios = [];
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changes_filtro = changes['filtro'];
    if (changes_filtro) {
      this.filtro = changes_filtro.currentValue;
    }
    this.filtrarCategorias();
  }

  filtrarCategorias() {
    if (this.filtro.materialTiposS !== undefined) {
      this.mostrarSituacaoMaterial();
      if (this.filtro.materialTiposS.length > 0) {
        // Filtrando as categorias por tipo
        this.filtro.materialCategoriasD = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria.filter(obj =>
          this.filtro.materialTiposS.map(obj2 => obj2.id).includes(obj.materialTipo.id)
        );

        // Recebe todas as categorias filtrando as selecionadas
        // Para evitar erro quando o materialCategoriasS só possui id e não possui o obj TipoMaterial
        if (this.filtro.materialCategoriasS.length > 0) {

          this.filtro.materialCategoriasS = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria.filter(obj =>
            this.filtro.materialCategoriasS.map(obj2 => obj2.id).includes(obj.id)
          );
        }

        // Filtrando as categorias selecionadas por tipo
        this.filtro.materialCategoriasS = this.filtro.materialCategoriasS.filter(obj =>
          this.filtro.materialTiposS.map(obj2 => obj2.id).includes(obj.materialTipo.id));

      } else {
        this.filtro.materialCategoriasD = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria;
      }
    }
  }

  mostrarSituacaoMaterial() {
    this.mostraSituacaoMaterial = true;
    if ((this.filtro.materialTiposS.length === 1) && (this.filtro.materialTiposS[0].id === 3)) {
      this.mostraSituacaoMaterial = false;
      this.filtro.materialSituacoesS = [];
    }
  }

  aplicarFiltros() {
    this.filtroModificado.emit(<FiltroAnuncio>{
      anuncianteTiposD: this.dadosPesquisaAnuncioPrincipal.listaAnuncianteTipo,
      anuncianteTiposS: this.filtro.anuncianteTiposS,
      materialTiposD: this.dadosPesquisaAnuncioPrincipal.listaMaterialTipo,
      materialTiposS: this.filtro.materialTiposS,
      materialCategoriasD: this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria,
      materialCategoriasS: this.filtro.materialCategoriasS,
      materialSituacoesD: this.dadosPesquisaAnuncioPrincipal.listaMaterialSituacao,
      materialSituacoesS: this.filtro.materialSituacoesS,
      ufsD: this.dadosPesquisaAnuncioPrincipal.listaUf,
      ufsS: this.filtro.ufsS,
      onusD: this.dadosPesquisaAnuncioPrincipal.listaOnus,
      onusS: this.filtro.onusS,
      anuncioSituacoesD: this.dadosPesquisaAnuncioPrincipal.anuncioSituacoes,
      anuncioSituacoesS: this.filtro.anuncioSituacoesS,
      numerosAnuncios: this.filtro.numerosAnuncios,
    });
  }

  limparFiltros() {
    this.filtro.anuncianteTiposS = [];
    this.filtro.materialTiposS = [];
    this.filtro.materialCategoriasS = [];
    this.filtro.materialSituacoesS = [];
    this.filtro.ufsS = [];
    this.filtro.onusS = [];
    this.filtro.anuncioSituacoesS = [];
    this.filtro.numerosAnuncios = [];
    this.aplicarFiltros();
    this.mostraSituacaoMaterial = true;
  }

}

<div class="d-flex flex-wrap login-fundo">

  <div
    class="col-md-6 col-sm-12 d-flex flex-wrap justify-content-center align-content-around pl-5 order-sm-1 order-md-0">
    <img class="img-logo pb-5 col-12 pt-3 text-center" src="../assets/images/doacoes-govbr-logo_120.png" alt="imagem">
    <img class="img-banner p-5 col-12" src="../assets/images/home.png" alt="">
  </div>

  <div class="col-md-6 col-sm-12 d-flex flex-wrap pl-5 pr-5 pb-5 ">
    <div class="col-12 mb-3">
      <span class="textoBemVindo">Acesse o Sistema</span>
      <br>
      <span class="textoCredenciais">Selecione o seu perfil</span>
      <div class="br-card col-12 flex-wrap pr-5 pl-5 mt-3">
        <div class="row d-flex justify-content-start align-items-center pt-1 pb-1 flex-nowrap" *ngIf="exibirBotoesTiposLogin">
          <i class="fa fa-user-tie"></i>
          <span class="ml-2"> Sou um usuário privado</span>
          <button pButton type="button" label=" " data-message="Login Usuário Privado" tabindex="0" [disabled]="loginPrivadoHabilitado" (click)="exibirLoginPrivado(true)"
            class="ui-button-icon-only ml-auto"
            [icon]="loginPrivadoHabilitado ? 'fa fa-chevron-up': 'fa fa-chevron-down'"></button>
        </div>
        <div *ngIf="loginPrivadoHabilitado" class="col-12">
          <!-- <br>
          <img src="/assets/img/gov_br_logo.png" (click)="buscarUrlLoginGovBr()" title="Entrar com gov.br" alt="Link para realizar o login pelo gov.br">
          <br> -->
          <span class="textoGovBr">
            O gov.br é um serviço online de identificação e autentificação digital do cidadão em um único meio, para
            acesso aos diversos serviços públicos digitais.
          </span>
          <br>
          <div class="col-12 text-right pb-5 pt-3">
            <!-- <button (click)="buscarUrlLoginGovBr()" pButton type="button" label="Entrar com gov.br" class="br-button primary"></button> -->
            <!-- <button pButton type="button" label="VOLTAR" (click)="cancelar()" class="br-button"></button> -->
            <button (click)="buscarUrlLoginGovBr()" class="ui-button-text ui-clickable br-button primary" type="button">Entrar com&nbsp;<span class="text-black">gov.br</span>
            </button>
          </div>
        </div>
      </div>
      <div class="br-card col-12 flex-wrap pr-5 pl-5 mb-auto">
        <div class="row d-flex justify-content-start align-items-center pt-1 pb-1 flex-nowrap" *ngIf="exibirBotoesTiposLogin">
          <i class="fa fa-users"></i>
          <span class="ml-2">Sou de um órgão público</span>
          <button pButton type="button" label=" " data-message="Login Órgão Público'" tabindex="0" [disabled]="loginGovernoHabilitado" (click)="exibirLoginGoverno(true)"
            class="ui-button-icon-only ml-auto"
            [icon]="loginGovernoHabilitado ? 'fa fa-chevron-up': 'fa fa-chevron-down'"></button>
        </div>
        <div *ngIf="loginGovernoHabilitado" class="col-12 pb-0">
          <form #form="ngForm" (ngSubmit)="loginGoverno(usuarioEl.value, senhaEl.value)">
            <div class="col-12">
              <p class="textoSenhaRede pb-3 pt-2">Acesso via SENHA-REDE</p>
              <p-inputMask #usuarioEl title="Entre com usuário do SENHA-REDE." ngModel type="text" id="usuario"
                name="usuario" mask="999.999.999-99" required autocomplete="off" styleClass="br-input mb-3"
                placeholder="&#xf007;&nbsp;&nbsp;usuário">
              </p-inputMask>
            </div>

            <div class="col-12">
              <input #senhaEl ngModel type="password" id="senha" name="senha" class="br-input ui-inputtext" pPassword
                required maxlength="50" placeholder="&#xf023;&nbsp;&nbsp;senha" />
              <div class="text-right">
                <a class="lostpass" href="#">Esqueceu a senha?</a>
              </div>
            </div>

            <p-captcha *ngIf="!captchaMocked" #captcha [siteKey]="recaptchaKey"
              (onResponse)="showResponse($event.response)"></p-captcha>
            <div class="col-12">
              <div class="col-12 text-right pb-5 pt-3">
                <button [disabled]="form.invalid || (!captchaMocked && !tokenCaptcha)" pButton type="submit"
                  label="Entrar" class="br-button ui-clickable primary"></button>
                <!-- <button pButton type="button" label="VOLTAR" (click)="cancelar()" class="br-button"></button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<reuse-anuncio-nao-encontrado *ngIf="anuncioNaoEncontrado"></reuse-anuncio-nao-encontrado>

<div *ngIf="!anuncioNaoEncontrado">
  <div class="ui-g">
    <div class="ui-g-12">
      <p-steps [model]="passos" [(activeIndex)]="passo" #steps></p-steps>
    </div>
    <div class="ui-g-12" [hidden]="passo!=0">
      <reuse-cadastro-anuncio-dados-basicos [numeroAnuncio]="numeroAnuncio" [anuncioAlterado]="anuncioAlterado" (novoNumeroAnuncio)="onNovoNumeroAnuncio($event)"></reuse-cadastro-anuncio-dados-basicos>
    </div>
    <div class="ui-g-12" *ngIf="jaExibiuPasso[1]" [hidden]="passo!=1">
      <reuse-cadastro-anuncio-detalhes [numeroAnuncio]="numeroAnuncio"></reuse-cadastro-anuncio-detalhes>
    </div>
    <div class="ui-g-12" *ngIf="jaExibiuPasso[2]" [hidden]="passo!=2">
      <reuse-cadastro-anuncio-fotos [numeroAnuncio]="numeroAnuncio"></reuse-cadastro-anuncio-fotos>
    </div>
    <div class="ui-g-12" *ngIf="jaExibiuPasso[3]" [hidden]="passo!=3">
      <reuse-cadastro-anuncio-resumo [numeroAnuncio]="numeroAnuncio"></reuse-cadastro-anuncio-resumo>
    </div>
  </div>

</div>

<aviso-responsabilidade></aviso-responsabilidade>

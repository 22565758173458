<div class="ui-g-12">
  <p-button label="Incluir Usuário" styleClass="ui-button-success" icon="fa fa-plus-circle" (click)="onNovoUsuario()">
  </p-button>
</div>
<!-- Tabela detalhe de Usuarios -->
<p-table #mainTable class="ui-g-12" [columns]="cols" [value]="usuarios" [scrollable]="true" [resizableColumns]="true"
  dataKey="id" [paginator]="true" [lazy]="true" [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
  (onPage)="onPage($event)" [(first)]="inicio" [customSort]="true" (onSort)="onSort($event)">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
        <p-sortIcon [field]="col.field" ariaLabel="Sordenação ativa"
          ariaLabelDesc="Clique para ordenar de forma descendente"
          ariaLabelAsc="Clique para ordenar de forma ascendente"></p-sortIcon>
        {{col.header}}
      </th>
      <th style="width: 130px" class="text-center">Ações</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-usuario>
    <tr>
      <td class="ui-resizable-column text-left txt-lista">{{usuario.cpf|cpf}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{usuario.nome}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{usuario.entidade.numero}} - {{usuario.entidade.nome}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{usuario.entidade.orgao && usuario.entidade.orgao.id != -1 ?
        usuario.entidade.orgao.id + ' - ' : null}} {{usuario.entidade.orgao ? usuario.entidade.orgao.nome : null}}</td>
      <td style="width: 130px" class="ui-resizable-column text-center">
        <button pButton type="button" title="Visualizar" class="ui-button ui-button-rounded" icon="fa fa-search"
          (click)="visualizarUsuario(usuario)"></button>
        <span class="espaco-r-5"></span>
        <button pButton type="button" title="Alterar" class="ui-button ui-button-rounded" icon="fa fa-edit"
          (click)="alterarUsuario(usuario)"></button>
        <span class="espaco-r-5"></span>
        <button pButton type="button" title="Excluir" class="ui-button ui-button-rounded" icon="fa fa-trash"
          (click)="excluirUsuario(usuario)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="5">
        Nenhum registro encontrado.
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog key="modalExcluirUsuario" header="Confirmação" width="550" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
      class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
      class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BloqueioUsuarioPrivadoPageComponent } from './components/desbloqueio-anuncio-page/bloqueio-usuario-privado-page.component';
import { BloqueioUsuarioPrivadoListaComponent } from './components/desbloqueio-anuncio-lista/bloqueio-usuario-privado-lista.component';


const routes: Routes = [
  {
    path: 'bloqueio-usuario-privado',
    component: BloqueioUsuarioPrivadoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['CENTRALCOM', 'SEGES']
    },
    children: [
      {
        path: '',
        component: BloqueioUsuarioPrivadoListaComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BloqueioUsuarioPrivadoRoutingModule { }

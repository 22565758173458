<p-dialog modal=true header="Confirmar Doação" [(visible)]="exibirModalConfirmarDoacao" [width]="600">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()"
    *ngIf="exibirModalConfirmarDoacao && carregouInteresseAnuncio">

    <div class="ui-g">
      <div class="ui-g-5">
        <div [class.erro-form]="verificarCampoInvalido('dataEntrega')">Data Publicação da Doação *:</div>
        <p-calendar id="dataEntrega" name="dataEntrega" formControlName="dataEntrega" [locale]="pt"
          dateFormat="dd/mm/yy" [showIcon]="true" #dataEntrega [readonlyInput]="true" [style]="{'width':'100%'}"
          [maxDate]="today" [inputStyle]="{'width':'calc(100% - 2em)'}"
          title="Deverá considerar a data de publicação no DOU do Termo/Contrato de doação.">
          <p-footer>
            <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker">
              <p-button icon="pi pi-check" class="button-datapicker-ok" (onClick)="fecharCalendario(dataEntrega)">
              </p-button>
              <p-button icon="pi pi-times" class="button-datapicker-cancel" (onClick)="limparDataEntrega(dataEntrega)">
              </p-button>
            </div>
          </p-footer>
        </p-calendar>
      </div>

      <div class="ui-g-7">
        <div [class.erro-form]="verificarCampoInvalido('anuncioInteresseUtilizacao')">Utilização *:</div>
        <p-dropdown [options]="formasUtilizacao" formControlName="anuncioInteresseUtilizacao"
          #anuncioInteresseUtilizacao></p-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-5">
        <div [class.erro-form]="verificarCampoInvalido('quantidadeEntregue')">Quantidade Doada *:</div>
        <p-spinner size="5" thousandSeparator="." formControlName="quantidadeEntregue" appBlockCopyPaste>
        </p-spinner>
      </div>
      <div class="ui-g-7">
        <div [class.erro-form]="verificarCampoInvalido('observacaoEntrega')">Observação:</div>
        <textarea [rows]="6" style="width: 100%" pInputTextarea formControlName="observacaoEntrega"></textarea>
      </div>
    </div>

    <p-footer>
      <div class="buttons text-right">
        <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"></button>
        <button type="submit" pButton label="Confirmar" class="ui-button-success" [disabled]="form.invalid"></button>
      </div>
    </p-footer>
  </form>

</p-dialog>

import { Injectable } from '@angular/core';
import { SimNao } from 'app/anuncio/models/sim-nao';
import { AnuncioChip } from '../models/anuncio-chip.model';
import { AnuncioSituacao } from '../models/anuncio-situacao.model';
import { UsuarioTipo } from '../models/anuncio-usuario.tipo.model';
import { MaterialCategoria } from '../models/material-categoria.model';
import { MaterialSituacao } from '../models/material-situacao.model';
import { MaterialTipo } from '../models/material-tipo.model';
import { Uf } from '../models/uf.model';

@Injectable()
export class PesquisaAnuncioPrincipal {
  public listaAnuncianteTipo: UsuarioTipo[];
  public listaMaterialTipo: MaterialTipo[];
  public listaMaterialCategoria: MaterialCategoria[];
  public listaMaterialSituacao: MaterialSituacao[];
  public listaUf: Uf[];
  public listaOnus: SimNao[];
  public totalRegistros: number;
  public anuncioSituacoes: AnuncioSituacao[];
  public constructor() { }
}

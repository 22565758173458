<div *ngIf="!usuarioNaoEncontrado">
  <p-panel header="{{tituloFormulario()}}">
    <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">

      <div class="text-right" *ngIf="podeAlterarDados()">
        <p-message severity="info" text="* Campos obrigatórios"></p-message>
      </div>

      <div class="ui-g">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('cpf')" style="margin-left: 7px;" class="subtitulo">
            CPF{{podeAlterarDados()?'*':''}}</div>
          <p-inputMask title="Entre com o CPF" type="text" formControlName="cpf" mask="999.999.999-99" placeholder="CPF"
            autocomplete="off" (change)="onChangeCpf()" *ngIf="podeAlterarDados()" [style]="{'margin-left': '7px'}">
          </p-inputMask>
          <div *ngIf="!podeAlterarDados()" style="margin-left: 7px;" class="conteudoSubtitulo">
            {{formulario.get('cpf').value|cpf}}</div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-8">
            <div [class.erro-form]="verificarCampoInvalido('nome')" class="subtitulo">
              Nome{{podeAlterarDados()?'*':''}}</div>
            <input type="text" pInputText formControlName="nome" class="campoInput" placeholder="Nome"
              *ngIf="podeAlterarDados()" />
            <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('nome').value}}</div>
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-8 ui-sm-12">
            <div [class.erro-form]="verificarCampoInvalido('emailGoverno')" class="subtitulo">
              Email{{podeAlterarDados()?'*':''}}
            </div>
            <input type="text" pInputText formControlName="emailGoverno" class="campoInput" placeholder="Email"
              maxlength="255" *ngIf="podeAlterarDados()" />
            <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('emailGoverno').value}}</div>
          </div>

          <div class="ui-g-3 ui-sm-12">
            <div [class.erro-form]="verificarCampoInvalido('telefone1Governo')" class="subtitulo">
              Telefone Principal{{podeAlterarDados()?'*':''}}</div>
            <p-inputMask type="text" mask="(99) 99999999?9" formControlName="telefone1Governo"
              placeholder="(99) 999999999" *ngIf="podeAlterarDados()"></p-inputMask>
            <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('telefone1Governo').value}}
            </div>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="!isInclusao()">
          <div class="ui-g-6 ui-sm-12">
            <div class="subtitulo">Perfis</div>
            <div>{{retornaLstPerfis()}}</div>
          </div>
        </div>
      </div>
      <p-fieldset legend="Entidade Vinculada" [style]="{'margin-top':'10px'}">
        <div class="ui-g-12 divFieldSet">
          <div [class.erro-form]="verificarCampoInvalido('orgao')" class="subtitulo">
            Órgão</div>
          <p-dropdown [options]="listaOrgao" formControlName="orgao" (onChange)="onChangeEntidade()" #orgao
            [filter]="true" optionLabel="idNome" dataKey="id" *ngIf="podeAlterarDados()"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('orgao').value ?
            formulario.get('orgao').value.id + ' - ' +
            formulario.get('orgao').value.nome : '-'}}</div>
        </div>
        <div class="ui-g-12 divFieldSet">
          <div [class.erro-form]="verificarCampoInvalido('entidade')" class="subtitulo">
            Entidade{{podeAlterarDados()?'*':''}}
          </div>
          <p-dropdown [options]="listaEntidade" formControlName="entidade" #entidade [filter]="true"
            optionLabel="nomeNumero" dataKey="id" *ngIf="podeAlterarDados()"></p-dropdown>
          <div *ngIf="!podeAlterarDados()" class="conteudoSubtitulo">{{formulario.get('entidade').value ?
            formulario.get('entidade').value.numero
            + ' - ' + formulario.get('entidade').value.nome : '-'}}</div>
        </div>
      </p-fieldset>

      <div style="margin-top: 10px" class="buttons text-right">
        <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"
          *ngIf="!podeAlterarDados()"></button>
        <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"
          *ngIf="podeAlterarDados()"></button>
        <button id="submitBtn" name="submitBtn" type="submit" pButton label="Salvar" class="ui-button-primary"
          class="ui-button-primary" *ngIf="podeAlterarDados()"></button>
      </div>
    </form>
  </p-panel>
  <p-confirmDialog key="modalInclusaoUsuarioStatusExcluido" header="Confirmação" width="425" #cd>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
        class="ui-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
        class="ui-button-success"></button>
    </p-footer>
  </p-confirmDialog>
</div>
<div *ngIf="usuarioNaoEncontrado" class="registroNaoEncontrado">
  <h2>Usuário não encontrado.</h2>
  <div>
    <a routerLink="/">Voltar para a Página principal</a>
  </div>
</div>

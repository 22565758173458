<div class="limites-nav anuncioPage" [ngClass]="getClasseAnuncioPrivadoOuGoverno()">
  <reuse-anuncio-detail *ngIf="carregouDados" [anuncio]="anuncio"
    [exibirBotaoTenhoInteresse]="exibirBotaoTenhoInteresse()" [exibirBotaoEditar]="exibirBotaoEditar()" [exibirBotaoExcluir]="exibirBotaoExcluir()"
    [exibirBotaoExcluirAnuncioPrivadoCentralCompras]="exibirBotaoExcluirAnuncioPrivadoCentralCompras()"
    [exibirBotaoInativar]="exibirBotaoInativar()" [exibirBotoesAutorizarPublicacao]="exibirBotoesAutorizarPublicacao()"
    [exibirBotaoRepublicacao]="exibirBotaoRepublicacao()" [exibirDadosVisualizacao]="exibirDadosVisualizacao()"
    [exibirInteressados]="exibirInteressados()" [exibirBotaoConfirmarDoacao]="exibirBotaoConfirmarDoacao()" (clickTenhoInteresse)="tenhoInteresse()"
    (denunciaChanges)="onDenunciaChanges()">
  </reuse-anuncio-detail>

  <div id="divAnunciosInteresses" style="margin-bottom: 20px;margin-top: 20px">
    <reuse-interesse-detail #reuseInteresseDetail></reuse-interesse-detail>
  </div>
  <div id="divTenhoInteresse" style="margin-bottom: 20px;margin-top: 20px">
    <reuse-tenho-interesse #reuseTenhoInteresse (concluiuAlteracao)="onConcluiuAlteracao()"></reuse-tenho-interesse>
  </div>

  <reuse-anuncio-nao-encontrado *ngIf="anuncioNaoEncontrado"></reuse-anuncio-nao-encontrado>
  <!-- TODO Adição botão voltar detalhamento anúncio -->
  <!-- <div *ngIf="anuncio" class="botao-voltar">
      <reuse-botao-voltar [anuncioBotaoVoltar] = "anuncio"></reuse-botao-voltar>
    </div> -->
</div>

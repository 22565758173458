import { MudancaDoacaoDto } from './../models/mudancao-doacao-dto';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { AnuncioInteresse } from '../models/anuncio-interesse.model';
import { Anuncio } from '../models/anuncio.model';

@Injectable({
  providedIn: 'root'
})
export class AnuncioInteresseService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  public obterAnuncioInteressado(idAnuncio: number, deveBloquear: boolean): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/obter-anuncio-interesse`;

    const params = new HttpParams()
      .set('idAnuncio', String(idAnuncio))
      .set('deveBloquear', String(deveBloquear));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public obterInteressadosParaAutorizarDoacao(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/meus-anuncios-interesse/obter-interessados-para-autorizar-doacao`;

    const params = new HttpParams()
      .set('idAnuncio', String(idAnuncio));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  // public obterInteressadosConfirmarDoacao(idAnuncio: number): Observable<any> {
  //   const url = `${environment.apiUrl}/anuncio-interesse/obter-interessados-confirmar-doacao`;

  //   const params = new HttpParams()
  //     .set('idAnuncio', String(idAnuncio));

  //   return this.http.get(url, { params })
  //     .pipe(
  //       catchError(this.errorHandler.handle()));
  // }

  public obterInteressadosMeusAnuncios(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/obter-interessados-meus-anuncios`;

    const params = new HttpParams()
      .set('idAnuncio', String(idAnuncio));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public salvarEdicao(form: FormGroup, idAnuncio: number, idAnuncioInteresse: number): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/salvar`;
    const tenhoInteresse = <AnuncioInteresse>{
      id: idAnuncioInteresse,
      idAnuncio: idAnuncio,
      quantidadeInteresse: form.get('quantidadeInteresse').value,
      observacaoInteresse: form.get('observacaoInteresse').value || '',
      nomeContato: form.get('nomeContato').value || '',
      telefoneContato: form.get('telefoneContato').value || '',
      emailContato: form.get('emailContato').value || '',
    };

    return this.http.post(url, tenhoInteresse)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public cancelarEdicao(idAnuncioInteresse: number): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/cancelar`;

    const params = new HttpParams()
      .set('idAnuncioInteresse', String(idAnuncioInteresse));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public excluirInteresse(idAnuncio: number, idAnuncioInteresse: number): Observable<any> {
    const url = `${environment.apiUrl}/meus-interesses/excluir`;
    const tenhoInteresse = <AnuncioInteresse>{
      id: idAnuncioInteresse,
      idAnuncio: idAnuncio
    }

    return this.http.post(url, tenhoInteresse)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  /**
   * Alterar a AnaliseInteresse com informações da análise.
   *
   * @param form
   * @param idAnuncio
   * @param idAnuncioInteresse
   */
  public alterarAnaliseInteresse(form: FormGroup, anuncio: Anuncio, anuncioInteresse: AnuncioInteresse): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/alterar-analise-interesse`;
    const analiseInteresse = <AnuncioInteresse>{
      id: anuncioInteresse.id,
      idAnuncio: anuncio.id,
      quantidadeAvaliacao: form.get('quantidadeAvaliacao').value,
      observacaoAvaliacao: form.get('observacaoAvaliacao').value || '',
    };

    return this.http.post(url, analiseInteresse)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  /**
   * Alterar a AnaliseInteresse com informações da análise.
   *
   * @param form
   * @param idAnuncio
   * @param idAnuncioInteresse
   */
  public confirmarDoacao(anuncioInteresse: AnuncioInteresse): Observable<any> {
    const url = `${environment.apiUrl}/confirmar-doacao/`;
    // const dadosConfirmarDoacao = <AnuncioInteresse>{
    //   id: anuncioInteresse.id,
    //   idAnuncio: anuncio.id,
    //   quantidadeEntregue: anuncioInteresse.quantidadeEntregue,
    //   dataEntrega: anuncioInteresse.dataEntrega,
    //   observacaoEntrega: anuncioInteresse.observacaoEntrega || '',
    //   anuncioInteresseUtilizacao: anuncioInteresse.anuncioInteresseUtilizacao
    // };

    return this.http.post(url, anuncioInteresse)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public obterAnuncioParaConfirmarDoacao(codigo: number): Observable<any> {
    const url = `${environment.apiUrl}/confirmar-doacao/${codigo}`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public finalizarAprovacaoInteresse(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/anuncio-interesse/finalizar-aprovacao-interesse`;

    const params = new HttpParams()
      .set('idAnuncio', String(idAnuncio));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public autorizarDoacao(anuncioInteresse: AnuncioInteresse): Observable<any> {
    const url = `${environment.apiUrl}/meus-anuncios-interesse/autorizar-doacao`;

    return this.http.post(url, anuncioInteresse)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public verificarNecessidadeDeJustificativaAutorizarDoacao(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/meus-anuncios-interesse/motivo-mudanca-doacao`;

    const params = new HttpParams()
      .set('idAnuncio', String(idAnuncio));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public finalizarAutorizarDoacao(idAnuncio: number, dto: MudancaDoacaoDto): Observable<any> {
    const url = `${environment.apiUrl}/meus-anuncios-interesse/finalizar-autorizar-doacao/${idAnuncio}`;

    return this.http.post(url, dto)
      .pipe(
        catchError(this.errorHandler.handle()));
  }
}



import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from '../../anuncio/services/api-error-handler.service';
import { ParametrosCadastroUsuarioGoverno } from '../models/parametros-cadastro-usuario-governo.model';
import { ResultadoPesquisaCadastroUsuarioGoverno } from '../models/resultado-pesquisa-cadastro-usuario-governo.model';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { FormGroup } from '@angular/forms';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { CadastroUsuarioGoverno } from '../models/cadastro-usuario-governo.model';

@Injectable()
export class CadastroUsuarioGovernoService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  /**
 * Consultar usuario por id
 */
  public obterCadastroUsuario(idUsuario: number, tipoOperacao: string): Observable<CadastroUsuarioGoverno> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/obter-cadastro-usuario/${idUsuario}/${tipoOperacao}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
   * Consultar usuario com status de excluido por cpf
   */
  public obterUsuarioExcluidoPorCpf(cpf: string): Observable<Usuario> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/obter-usuario-excluido-cpf/${cpf}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
   * Consultar usuarios pelo filtro
   */
  public consultarUsuario(parametros: ParametrosCadastroUsuarioGoverno): Observable<ResultadoPesquisaCadastroUsuarioGoverno> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/listagem`;
    const params = new HttpParams()
      .set('nome', parametros.nome)
      .set('cpf', parametros.cpf)
      .set('idEntidade', parametros.idEntidade)
      .set('idOrgao', parametros.idOrgao)
      .set('inicio', parametros.inicio)
      .set('sortField', parametros.sortField)
      .set('sortOrder', parametros.sortOrder)
      .set('totalRegistros', parametros.totalRegistros)
      .set('tpConsulta', parametros.tpConsulta);

    return this.http.get(url, { params }).pipe(catchError(this.errorHandler.handle()));
  }

  /**
   * Excluir Usuario por ID
   */
  public excluirUsuario(idUsuario: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/excluir-usuario/${idUsuario}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
 * Incluir Usuario com status de excluido por ID
 */
  public confirmouInclusaoUsuarioStatusExcluido(idUsuario: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/inclusao-usuario-status-excluido/${idUsuario}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
   * Alterar Usuario
   */
  public alterarUsuario(formulario: FormGroup, usuario: Usuario): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/alterar-usuario`;

    usuario.nome = formulario.get('nome').value;
    usuario.entidade = formulario.get('entidade').value;
    usuario.emailGoverno = formulario.get('emailGoverno').value;
    usuario.telefone1Governo = formulario.get('telefone1Governo').value;

    return this.http.post(url, usuario).pipe(catchError(this.errorHandler.handle()));
  }

  /**
 * Incluir Usuario
 */
  public incluirUsuario(formulario: FormGroup): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/incluir-usuario`;
    const usuario = <Usuario>{
      cpf: formulario.get('cpf').value,
      nome: formulario.get('nome').value,
      entidade: formulario.get('entidade').value,
      emailGoverno: formulario.get('emailGoverno').value,
      telefone1Governo: formulario.get('telefone1Governo').value,
    };

    return this.http.post(url, usuario).pipe(catchError(this.errorHandler.handle()));
  }

  public obterParametrosCadastroUsuarioGovernoUrl(params): ParametrosCadastroUsuarioGoverno {
    const parametros: ParametrosCadastroUsuarioGoverno = {
      nome: params['nome'] || '',
      cpf: params['cpf'] || '',
      idEntidade: params['idEntidade'] || 0,
      idOrgao: params['idOrgao'] || 0,
      inicio: params['inicio'] || '',
      sortField: params['sortField'] || '',
      sortOrder: params['sortOrder'] || '',
      totalRegistros: params['totalRegistros'] || 0,
      tpConsulta: params['tpConsulta'] || 1
    };

    return parametros;
  }

  public obterEntidadePorOrgao(idOrgao: number): Observable<Entidade[]> {
    const url = `${environment.apiUrl}/cadastro-usuario-governo/listar-entidade-por-orgao/${idOrgao}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

}

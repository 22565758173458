<div *ngIf="totalRegistros > 0">
  <div class="ui-g barraTotalOrdem">
    <div class="ui-g-6">
      <p-message severity="info" text="Total registros: {{totalRegistros}}"></p-message>
    </div>
  </div>
</div>
<p-table #mainTable class="ui-g-12" [columns]="cols" [value]="anuncios" [scrollable]="true" [resizableColumns]="true" dataKey="id"
  [paginator]="true" [lazy]="true" [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
  (onPage)="onPage($event)" [(first)]="inicio" [customSort]="true" (onSort)="onSort($event)">
<!--<p-table #mainTable class="ui-g-12" exportFilename="relatorioDeAnuncios" csvSeparator=";" [columns]="cols" [value]="anuncios" [scrollable]="true" [resizableColumns]="true"
  dataKey="id" [paginator]="true" [rows]="tamanhoPagina" [totalRecords]="totalRegistros">-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 20%" *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
        <p-sortIcon [field]="col.field" ariaLabel="Sordenação ativa"
          ariaLabelDesc="Clique para ordenar de forma descendente"
          ariaLabelAsc="Clique para ordenar de forma ascendente"></p-sortIcon>
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-anuncio>
    <tr>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.dataPublicacaoAnuncio}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.dataEnvioAnuncio}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.numeroAnuncio}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.tipoMaterial}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.categoriaMaterialServico}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.descricaoMaterialServico}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.unidadeMedidaDoacao}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.quantidade}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.valorTotalMercado}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.localizacaoBemOuLocalPrestacaoServico}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.dataFinalizacaoAnuncio}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.identificacaoDonatario}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.esferaDonatario}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.quantidadeAutorizada}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.dataDoacao}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.quantidadeDoada}}</td>
      <td class="ui-resizable-column text-right txt-lista">{{anuncio.valorTotalDoado}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.identificacaoDoador}}</td>
      <!--<td class="ui-resizable-column text-left txt-lista">{{anuncio.orgaoDoador}}</td>-->
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.localizacaoDoador}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{anuncio.justificativaDoacao}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="5">
        Nenhum registro encontrado.
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngIf="totalRegistros > 0">
  <div class="ui-g-12">
    <p-button label="Exportar para CSV" styleClass="ui-button-success" icon="fas fa-download" (click)="mainTable.exportCSV()">
    </p-button>
    <p-button id="btExportarPdf" label="Exportar para PDF" styleClass="ui-button-success" icon="fas fa-download" (click)="exportarParaPdf()">
    </p-button>
  </div>
</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { Router } from '@angular/router';
import { VersaoBackend } from '../footer/models/versao-backend.model';
import { SobreService } from './sobre.service';

@Component({
  selector: 'reuse-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SobreComponent implements OnInit {

  versaoFrontend = 'versão do frontend';
  versaoBackend: VersaoBackend;

  constructor(
    private appMessageService: AppMessageService,
    private router: Router,
    private sobreService: SobreService,
  ) { }

  ngOnInit() {
    this.criarBreadcrumb();
    this.loadVersion();
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb():void {
    let breadcrumbItems = [
      {icon:'fa fa-info', label:'Sobre', command: (onclick)=>{this.router.navigate(['sobre'])}}
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  private loadVersion() {
    this.sobreService.obterVersao().subscribe(
      resultado => {
         this.versaoBackend = resultado;
      }
    )
  }

}

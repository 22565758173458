import { Injectable } from "@angular/core";
import { Anuncio } from "app/anuncio/models/anuncio.model";
import { AnuncioSituacaomEnum } from "../enum/anuncio-situacao.enum";
import { MaterialTipoEnum } from "../enum/material-tipo.enum";
import { StringDTOToDatePipe } from "../pipes/string-dto-to-date.pipe";

@Injectable({
  providedIn: 'root'
})
export class AnuncioUtil {

  constructor() { }

  public anuncioPrivado(anuncio: Anuncio): boolean {
    return anuncio && anuncio.privado;
  }

  public anuncioGoverno(anuncio: Anuncio): boolean {
    return anuncio && !anuncio.privado;
  }

  public servico(anuncio: Anuncio): boolean {
    return anuncio && anuncio.materialCategoria && anuncio.materialCategoria.materialTipo && anuncio.materialCategoria.materialTipo.id == MaterialTipoEnum.SERVICO;
  }

  public emRascunho(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.EM_RASCUNHO;
  }

  public pendenteAutorizacao(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.PENDENTE_AUTORIZACAO;
  }

  public complementacaoEnviada(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.COMPLEMENTACAO_ENVIADA;
  }

  public aguardandoComplementacao(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.AGUARDANDO_COMPLEMENTACAO;
  }

  public indeferido(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ANUNCIO_INDEFERIDO;
  }

  public semInteressado(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ANUNCIO_SEM_INTERESSADO;
  }

  public ativo(anuncio: Anuncio): boolean {
    return anuncio && anuncio.anuncioSituacao && anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ATIVO;
  }

  padRight(text: string, char: string, size: number): string {
    while (text.length < size) text = text + char;
    text = text.substring(0, size);
    return text;
  }

  public dataAnuncio(anuncio: Anuncio): string {
    if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.EM_RASCUNHO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.PENDENTE_AUTORIZACAO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.AGUARDANDO_COMPLEMENTACAO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.COMPLEMENTACAO_ENVIADA) {
      return this.criadoEm(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ANUNCIO_INDEFERIDO) {
      return this.indefiridoEm(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ATIVO) {
      return this.disponivelAte(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ANUNCIO_FINALIZADO) {
      return this.finalizadoEm(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.EM_ANALISE_DENUNCIA) {
      return this.disponivelAte(anuncio);
    } else {
      return this.publicadoEm(anuncio);
    }
  }

  public dataAnuncioDetalhe(anuncio: Anuncio): string {
    if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.EM_RASCUNHO) {
      return this.criadoEm(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.PENDENTE_AUTORIZACAO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.AGUARDANDO_COMPLEMENTACAO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.COMPLEMENTACAO_ENVIADA) {
      return this.criadoEm(anuncio) + '\n' + this.disponivelAte(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ANUNCIO_INDEFERIDO) {
      return this.criadoEm(anuncio) + '\n' + this.indefiridoEm(anuncio);
    } else if (anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.ATIVO
      || anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.APROVADO) {
      return this.publicadoEm(anuncio) + '\n' + this.disponivelAte(anuncio);
    } else {
      return this.publicadoEm(anuncio) + '\n' + this.finalizadoEm(anuncio);
    }
  }

  public criadoEm(anuncio: Anuncio): string {
    if (anuncio.dataEnvio) {
      return 'Criado em ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataEnvio);
    } else {
      return 'Criado em ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataAnuncio);
    }

  }

  public indefiridoEm(anuncio: Anuncio): string {
    return 'Indeferido em ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataAvaliacao);
  }

  public disponivelAte(anuncio: Anuncio): string {
    return 'Disponível até ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataExpiracao);
  }

  public finalizadoEm(anuncio: Anuncio): string {
    return 'Finalizado em ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataExpiracao);
  }

  public publicadoEm(anuncio: Anuncio): string {
    return 'Publicado em ' + new StringDTOToDatePipe().transformStringToStringWithoutHoursLocal(anuncio.dataAnuncio);
  }

}

<p-panel header="Publicação" [style]="{'margin-bottom':'10px'}" *ngIf="exibirBotaoPublicar()">
  <div class="col-12 buttons justify-content-between p-3 flex">
    <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
    <button #submitBtn type="submit" pButton label="Concluir e Publicar" class="ui-button-success"
      (click)="onConfirmar(submitBtn)"></button>
  </div>
</p-panel>

<reuse-anuncio-detail *ngIf="anuncio" [anuncio]="anuncio" [exibirBotaoTenhoInteresse]="false"
  [exibirBotoesAutorizarPublicacao]="false" [exibirBotaoExcluir]="false"
  [exibirBotaoExcluirAnuncioPrivadoCentralCompras]="false" [exibirDadosVisualizacao]="false"
  [exibirInteressados]="false"></reuse-anuncio-detail>

<div *ngIf="totalRegistros > 0">
  <div class="ui-g">
    <div class="ui-g-6">
      <p-message severity="info" text="Total registros: {{totalRegistros}}"></p-message>
    </div>
    <div class="ui-g-6">
      <p-dropdown [options]="ordenacoes" [(ngModel)]="ordem"
        (onChange)="aplicarOrdenacao($event)"></p-dropdown>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-g-12" *ngFor="let mensagem of mensagens">
      <reuse-mensagem-item
        [mensagem]="mensagem">
      </reuse-mensagem-item>
    </div>
  </div>
  <p-paginator [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
    (onPageChange)="paginar($event)" [(first)]="inicio"></p-paginator>
</div>
<div *ngIf="carregouDados && totalRegistros == 0">
  <p-message severity="warn" text="Nenhum registro encontrado."></p-message>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPrimengModule } from '../app-primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { CadastroEntidadePageComponent } from './components/cadastro-entidade-page/cadastro-entidade-page.component';
import { CadastroEntidadeListaComponent } from './components/cadastro-entidade-lista/cadastro-entidade-lista.component';
import { CadastroEntidadeRoutingModule } from './cadastro-entidade-routing.module';
import { CadastroEntidadeService } from './services/cadastro-entidade.service';
import { CadastroEntidadeEditPageComponent } from './components/cadastro-entidade-edit-page/cadastro-entidade-edit-page.component';


@NgModule({
  imports: [
    CommonModule,
    AppPrimengModule,
    ReactiveFormsModule,
    CadastroEntidadeRoutingModule,
    TagInputModule,
    SharedModule,
    MomentModule
  ],
  declarations: [
    CadastroEntidadePageComponent,
    CadastroEntidadeListaComponent,
    CadastroEntidadeEditPageComponent,
  ],
  providers: [
    CadastroEntidadeService,
  ],
  exports: [
  ],
})
export class CadastroEntidadeModule { }

import  * as  moment from 'moment';
import { FormControl } from '@angular/forms';

/**
 * Define comportamentos comuns para os componentes de filtro dos anúncios na parte interna (não público).
 */
export abstract class AnunciosInternoPage {

  // idioma do calendário
  pt: any;

  // teclas utilizadas para separar os valores dos anúncios informados.
  separadores: number[] = [];

  /**
   * Configuração para o componente 'p-calendar', utilizar português.
   */
  configurarCalendario() {
    this.pt = {
      firstDayOfWeek: 1,
      dayNames: [ "domingo","segunda","terça","quarta","quinta","sexta","sábado" ],
      dayNamesShort: [ "dom","seg","ter","qua","qui","sex","sáb" ],
      dayNamesMin: [ "D","S","T","Q","Q","S","S" ],
      monthNames: [ "janeiro","fevereiro","março","abril","maio","junho","julho","agosto","setembro","outubro","novembro","dezembro" ],
      monthNamesShort: [ "jan","fev","mar","abr","mai","jun","jul","ago","set","out","nov","dez" ],
      today: 'Hoje',
      clear: 'Limpar'
    }
  }

  popularSeparadoresTagInput() {
    // espaço
    this.separadores.push(32);
    // virgula
    this.separadores.push(188);
    // virgula numpad
    this.separadores.push(108);
    // tab
    this.separadores.push(9);
    // enter
    this.separadores.push(13);
  }

  dataParaString(data, formato: string) {
    return moment(data, formato).format(formato);
  }

  stringParaData(data:string, formato: string) {
    return moment(data, formato).toDate();
  }

  fecharCalendario(input) {
    input.overlayVisible = false;
  }

  somenteNumero(control: FormControl) {
    let valorDigitado:string = control.value;
    let valorNumerico: string = valorDigitado.replace(/[^0-9]/g, '');
    if (valorDigitado == valorNumerico) {
      return null;
    }
    return "somenteNumero";
  }
}

import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Params } from '@angular/router';

@Injectable()
export class RouteService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //if (this.currentUrl && this.currentUrl !== event.url) {
        this.previousUrl = this.currentUrl;
        //}
        this.currentUrl = decodeURIComponent(event.url);
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public goUrl(url: string) {
    //Se for undefined, vai para a tela padrao
    if (!url) {
      url = '';
    }

    let params: Params = [];

    url = this.removeDomain(url);
    let vetUrl = url.split('?');
    url = vetUrl[0];
    var additionalURL = vetUrl[1];
    if (additionalURL) {
      vetUrl = additionalURL.split('&');
      for (var i = 0; i < vetUrl.length; i++) {
        const vetParam = vetUrl[i].split('=');
        params[vetParam[0]] = vetParam[1];
      }
    }

    this.router.navigate([url], { queryParams: params });
  }

  /**
   * Para usar o voltar, a tela que anterior deve adicionar em seu contrutor.
   * Exemplo. Tela 1 chama a Tela 2. A Tela 1 deve ter em seu construtor este serviço. Assim a Tela 2 pode usar esse método.
   * @param urlDefault
   */
  public voltar(urlDefault: string) {
    if (!urlDefault.startsWith('/')) {
      urlDefault = '/' + urlDefault;
    }
    if (this.getPreviousUrl().indexOf(urlDefault) >= 0) {
      this.goUrl(this.getPreviousUrl());
    } else {
      this.router.navigate([urlDefault]);
    }
  }

  private removeDomain(url) {
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    return url.substring(url.indexOf('/'));
  }

  public updateURLParameter(url: string, param: string, paramVal: any): string {
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
      tempArray = additionalURL.split("&");
      for (var i = 0; i < tempArray.length; i++) {
        if (tempArray[i].split('=')[0] != param) {
          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
  }

  public getParamURL(strUrl: string, param: string) {
    var url = new URL(strUrl);
    return url.searchParams.get(param);
  }

  public isUrl(url: string): boolean {
    let route = this.currentUrl;
    let vetRoute = route.split('?');
    return (vetRoute[0].substring(1).startsWith(url));
  }
}

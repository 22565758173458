<div class="ui-g" *ngIf="mensagem">
  <div class="ui-g-12">
    <p-panel>
        <p-header [ngClass]="mensagem.situacao.id == 3 ? 'tituloExcluida' : 'titulo'">
            {{mensagem.titulo}}
        </p-header>

        <table>
          <tr>
            <td class="txt-min contentBold">Data:</td>
            <td class="txt-min">{{mensagem?.dataMensagem|StringDTOToDate|amDateFormat:'DD/MM/YYYY HH:mm:ss'}}</td>
          </tr>
          <tr>
              <td class="txt-min contentBold">Lida por:</td>
              <td class="txt-min">{{mensagem?.usuarioLeitura?.nome}} em {{mensagem?.dataLeitura|StringDTOToDate|amDateFormat:'DD/MM/YYYY HH:mm:ss'}}</td>
          </tr>
          <tr *ngIf="mensagem.situacao.id === 3">
              <td class="txt-min contentBold">Excluída por:</td>
              <td class="txt-min">{{mensagem?.usuarioExclusao?.nome}} em {{mensagem?.dataExclusao|StringDTOToDate|amDateFormat:'DD/MM/YYYY HH:mm:ss'}}</td>
          </tr>
        </table>

        <p></p>
        <div class="corpoMensagem" [innerHTML]="mensagem.corpoMensagem"></div>

        <p-footer>
            <div class="col-12 d-flex justify-content-end p-2">
              <button pButton label="Voltar" icon="fa fa-angle-left" (click)="voltar()" class="ui-button-secondary mr-auto"></button>
              <span class="espaco-r-5"></span>
              <button *ngIf="mensagem.situacao.id === 1" pButton label="Excluir" icon="fa fa-trash" (click)="onExcluir()" class="ui-button-danger"></button>
              <span *ngIf="mensagem.situacao.id === 1" class="espaco-r-5"></span>
              <button *ngIf="mensagem.situacao.id === 1" pButton label="Marcar como Não Lida" icon="fa fa-check" (click)="marcarNaoLida()" class="ui-button-success"></button>
              <button *ngIf="mensagem.situacao.id === 3" pButton label="Restaurar para Lida" icon="fa fa-check" (click)="restaurarLida()"  class="ui-button-success"></button>
            </div>
        </p-footer>
    </p-panel>
  </div>
</div>

<p-confirmDialog header="Confirmação" width="425" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()" class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()" class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>

<div *ngIf="mensagemNaoEncontrada" class="mensagemNaoEncontrada">
  <h2>Mensagem não encontrada.</h2>
  <div>
    <a routerLink="/">Voltar para a Página principal</a>
  </div>
</div>


export class Orgao {

  constructor(id: number){
    this.id = id;
  }

  id: number;
  nome: string;

  idNome: string;
}

<p-panel header="Identificação Doador">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">

    <div class="ui-g">
      <div class="ui-g-6">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-6">
        <div class="text-right">
          <p-message severity="info" text="* Campos obrigatórios"></p-message>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('pessoaTipo')">Tipo de Doador*</div>
          <p-dropdown [options]="listaPessoaTipo" formControlName="pessoaTipo"
            (onChange)="onChangeEntidadeUsuarioPrivado()" #pessoaTipo optionLabel="sigla" dataKey="id"></p-dropdown>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="isPessoaJuridica()">
        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('entidadeUsuarioPrivado')">Doador*</div>
          <p-dropdown [options]="listaEntidadeUsuarioPrivado" formControlName="entidadeUsuarioPrivado"
            (onChange)="onChangeCpfSocioMajoritario()" #entidadeUsuarioPrivado optionLabel="nomeNumero" dataKey="id">
          </p-dropdown>
        </div>
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('cpfSocioMajoritario')">CPF Sócio Majoritário*</div>
          <p-inputMask title="Informe o CPF do sócio majoritário referente ao CNPJ selecionado." ngModel type="text"
            formControlName="cpfSocioMajoritario" mask="999.999.999-99" placeholder="999.999.999-99" required
            autocomplete="off"></p-inputMask>
        </div>
      </div>

      <div class="ui-g-12">
        <div class="ui-g-8">
          <div [class.erro-form]="verificarCampoInvalido('nome')">Nome*</div>
          <input type="text" pInputText formControlName="nome" style="width:100%" placeholder="Nome" readonly />
        </div>
      </div>

      <div class="ui-g-12">
        <div class="ui-g-6 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('email')">Email*</div>
          <input type="text" pInputText formControlName="email" style="width:100%" placeholder="Email"
            maxlength="255" />
        </div>

        <div class="ui-g-3 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('telefone1')">Telefone Principal*</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="telefone1" placeholder="(99) 999999999">
          </p-inputMask>
        </div>

        <div class="ui-g-3 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('telefone2')">Telefone Secundário</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="telefone2" placeholder="(99) 999999999">
          </p-inputMask>
        </div>
      </div>
    </div>

    <p-fieldset legend="Localização" [style]="{'margin-top':'10px'}">
      <div class="ui-g-12 divFieldSet">
        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('endereco')">Endereço*</div>
          <input type="text" pInputText formControlName="endereco" style="width:100%" maxlength="255"
            placeholder="Endereço" />
        </div>
        <div class="ui-g-3 ui-sm-12 divFieldSetLeft">
          <div [class.erro-form]="verificarCampoInvalido('enderecoNumero')">Número*</div>
          <input (keypress)="numberOnlyEvent($event)" type="text" pInputText formControlName="enderecoNumero"
            placeholder="Número" maxlength="40" />
        </div>
      </div>
      <div class="ui-g-12 divFieldSet">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('bairro')">Bairro*</div>
          <input type="text" pInputText formControlName="bairro" style="width:100%" maxlength="100"
            placeholder="Bairro" />
        </div>
        <div class="ui-g-6 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('cep')">CEP*</div>
          <p-inputMask type="text" mask="99999-999" formControlName="cep" placeholder="99999-999"></p-inputMask>
        </div>
      </div>
      <div class="ui-g-12 divFieldSet">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('uf')">UF*</div>
          <p-dropdown [options]="listaUf" formControlName="uf" (onChange)="onChangeMunicipio()" #uf [filter]="true"
            optionLabel="sigla" dataKey="id"></p-dropdown>
        </div>
        <div class="ui-g-5 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('municipio')">Municipio*</div>
          <p-dropdown [options]="listaMunicipio" formControlName="municipio" [filter]="true" optionLabel="nome"
            dataKey="id"></p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary" class="ui-button-primary"></button>
    </div>

  </form>

</p-panel>

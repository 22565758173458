import { Router } from "@angular/router";
import { Anuncio } from "../../models/anuncio.model";
import { Input } from "@angular/core";
import { CadastroAnuncioService } from "../../services/cadastro-anuncio.service";
import { AppMessageService } from "../../../_services/app.message/app.message.service";
import { AppMessageType } from "../../../_services/app.message/app.message.type";
import { AppMessage } from "../../../_services/app.message/app.message.model";

/**
 * Define comportamentos comuns para os componentes de cadastro de anúncio.
 */
export abstract class AbstractCadastroAnuncio {

  //número do anúncio a ser modificado
  @Input()
  numeroAnuncio: number;

  //anúncio carregado a partir do 'numeroAnuncio'
  anuncio: Anuncio;

  passo: number;

  public appMessageService: AppMessageService;

  constructor(public app: AppMessageService) {
    this.appMessageService = app;
  }

  protected abstract getRouter():Router;

  protected abstract getCadastroAnuncioService():CadastroAnuncioService;

  /**
   * Somente anúncios originados na Web podem ser alterados,
   * anúncios do Grande Porte não podem ser alterados.
   * Verifica se registro está bloqueado.
   */
  public podeAlterarDados():boolean {
    return this.anuncio.anuncioOrigem.nome == 'Web'
      && !this.anuncio.mensagemBloqueio;
  }

  public navegarPasso(passo: number){
    //informa ao pai que houve uma mudanca de pagina
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.CHILD_MESSAGE, passo));
  }

  /**
   * Clique botão 'Voltar'.
   */
  public onVoltar() {
    this.navegarPasso(this.passo-1);
  }

    /**
   * Clique botão 'Próximo'.
   */
  public onProximo() {
    this.navegarPasso(this.passo+1);
  }

}

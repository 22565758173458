<p-table #mainTable class="ui-g-12" [columns]="colsPai" [value]="registros" [scrollable]="true"
  [resizableColumns]="true" dataKey="id" [paginator]="true" [lazy]="true" [rows]="tamanhoPagina"
  [totalRecords]="totalRegistros" (onPage)="onPage($event)" [(first)]="inicio" [customSort]="true"
  (onSort)="onSort($event)">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
        <p-sortIcon [field]="col.field" ariaLabel="Sordenação ativa"
          ariaLabelDesc="Clique para ordenar de forma descendente"
          ariaLabelAsc="Clique para ordenar de forma ascendente"></p-sortIcon>
        {{col.header}}
      </th>
      <th style="width: 5%" class="text-center">Ações</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-registroPai>
    <tr [pSelectableRow]="registroPai" [title]="showTooltip(registroPai)">
      <td *ngIf="tipoPesquisaCpf()" class="ui-resizable-column text-left">
        <i *ngIf="registroPai.filhos.length > 0"
          [ngClass]="registroPai.expandido ? 'fa fa-minus-circle clickable' : 'fa fa-plus-circle clickable'"
          (click)="expandirRegistro(registroPai)" title="{{registroPai.expandido ? 'Recolher' : 'Expandir'}}"></i>
        {{registroPai.codigo | cpf}}
      </td>
      <td *ngIf="tipoPesquisaCnpj()" class="ui-resizable-column text-left">
        <i *ngIf="registroPai.filhos.length > 0"
          [ngClass]="registroPai.expandido ? 'fa fa-minus-circle clickable' : 'fa fa-plus-circle clickable'"
          (click)="expandirRegistro(registroPai)" title="{{registroPai.expandido ? 'Recolher' : 'Expandir'}}"></i>
        {{registroPai.codigo | cnpj}}
      </td>
      <td class="ui-resizable-column text-left">{{registroPai.nome}}</td>
      <td *ngIf="registroPai.bloqueado" class="ui-resizable-column text-center corBloqueado">Sim</td>
      <td *ngIf="!registroPai.bloqueado" class="ui-resizable-column text-center corDesbloqueado">Não</td>
      <td *ngIf="registroPai.bloqueio" class="ui-resizable-column text-left clickable"
        [ngClass]="registroPai.bloqueado ? 'corBloqueado' : 'corDesbloqueado'"
        (click)="prepararExibirHistorico(registroPai)">{{registroPai.bloqueio.motivo}}</td>
      <td *ngIf="!registroPai.bloqueio"></td>
      <td style="width: 5%" class="text-center">
        <p-button *ngIf="!registroPai.bloqueado" title="Bloquear" (click)="prepararBloquearDesbloquear(registroPai)"
          styleClass="ui-button-danger" icon="fa fa-lock"></p-button>
        <p-button *ngIf="registroPai.bloqueado" title="Desbloquear" (click)="prepararBloquearDesbloquear(registroPai)"
          styleClass="ui-button-success" icon="fa fa-unlock"></p-button>
      </td>
    </tr>
    <tr *ngIf="registroPai.filhos.length > 0 && registroPai.expandido">
      <td colspan="7">
        <p-table class="ui-g-12" [columns]="colsFilhos" [value]="registroPai.filhos" [scrollable]="true"
          [resizableColumns]="true" dataKey="id">
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [style.width]="col.width">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
        {{col.header}}
      </th>
      <th style="width: 5%" class="text-center">Ações</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-registroFilho>
    <tr [pSelectableRow]="registroFilho" [title]="showTooltip(registroFilho)">
      <td *ngIf="tipoPesquisaCpf()" class="ui-resizable-column text-left">{{registroFilho.codigo | cnpj}}</td>
      <td *ngIf="tipoPesquisaCnpj()" class="ui-resizable-column text-left">{{registroFilho.codigo | cpf}}</td>
      <td class="ui-resizable-column text-left">{{registroFilho.nome}}</td>
      <td *ngIf="registroFilho.bloqueado" class="ui-resizable-column text-center corBloqueado">Sim</td>
      <td *ngIf="!registroFilho.bloqueado" class="ui-resizable-column text-center corDesbloqueado">Não</td>
      <td *ngIf="registroFilho.bloqueio" class="ui-resizable-column text-left clickable"
        [ngClass]="registroFilho.bloqueado ? 'corBloqueado' : 'corDesbloqueado'"
        (click)="prepararExibirHistorico(registroFilho)">{{registroFilho.bloqueio.motivo}}</td>
      <td *ngIf="!registroFilho.bloqueio"></td>
      <td style="width: 5%" class="text-center">
        <p-button *ngIf="!registroFilho.bloqueado" title="Bloquear" (click)="prepararBloquearDesbloquear(registroFilho)"
          styleClass="ui-button-danger" icon="fa fa-lock"></p-button>
        <p-button *ngIf="registroFilho.bloqueado" title="Desbloquear"
          (click)="prepararBloquearDesbloquear(registroFilho)" styleClass="ui-button-success" icon="fa fa-unlock">
        </p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
</td>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
  <tr>
    <td *ngIf="carregandoDados" [attr.colspan]="7">Carregando...</td>
    <td *ngIf="!carregandoDados && algumFiltroPreenchido" [attr.colspan]="7">Nenhum registro encontrado.</td>
    <td *ngIf="!carregandoDados && !algumFiltroPreenchido" [attr.colspan]="7">Preencha ao menos um filtro para realizar
      a pesquisa.</td>
  </tr>
</ng-template>
</p-table>

<!--<button pButton label="Reset" (click)="onClick(table)"></button> -->

<p-dialog modal=true [(visible)]="exibirModalConfirmacao" [header]="textoHeaderModalConfirmacao" width="425">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="bloquearDesbloquear(submitBtn)"
    *ngIf="exibirModalConfirmacao">
    <div *ngIf="bloqueioTipoCpf()" class="detalheBloqueado">Usuário: {{bloqueio.codigo|cpf}}</div>
    <div *ngIf="bloqueioTipoCnpj()" class="detalheBloqueado">Empresa: {{bloqueio.codigo|cnpj}}</div>
    <div [class.erro-form]="verificarCampoInvalido('motivo')">Motivo*</div>
    <textarea #motivo id="motivo" name="motivo" [rows]="5" style="width: 100%" maxlength="300" pInputTextarea
      formControlName="motivo" placeholder="Informe aqui o número do processo, prazo e motivação."></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="ocultarModalConfirmacao()"
        class="ui-button-secondary button-dialog"></button>
      <span class="espaco-r-5"></span>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>

<p-dialog #dialogHistorico id="dialogHistorico" name="dialogHistorico" modal=true [(visible)]="exibirModalHistorico"
  [header]="textoHeaderModalHistorico" width="900" dismissableMask="true">
  <p-table #tableHistorico id="tableHistorico" name="tableHistorico" class="ui-g-12" [columns]="colsHistorico"
    [value]="historico" [scrollable]="true" scrollHeight="400px" [resizableColumns]="true" dataKey="id">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
          <p-sortIcon [field]="col.field" ariaLabel="Sordenação ativa"
            ariaLabelDesc="Clique para ordenar de forma descendente"
            ariaLabelAsc="Clique para ordenar de forma ascendente"></p-sortIcon>
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-bloqueio>
      <tr [pSelectableRow]="bloqueio">
        <td class="ui-resizable-column">{{bloqueio.usuarioOperacao.cpf|cpf}}</td>
        <td class="ui-resizable-column">{{bloqueio.dataOperacao|amDateFormat:'DD/MM/YYYY HH:mm'}}</td>
        <td [ngClass]="bloqueio.bloqueado ? 'corBloqueado' : 'corDesbloqueado'" class="ui-resizable-column">
          {{bloqueio.bloqueado?'Bloqueado':'Desbloqueado'}} </td>
        <td class="ui-resizable-column">{{bloqueio.motivo}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="4">
          Nenhum registro encontrado.
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

import { Entidade } from './entidade.model';
import { BloqueioRegistro } from './bloqueio-registro.model';
import { AnuncioInteresseSituacao } from './anuncio-interesse-situacao.model';

export class AnuncioInteresse extends BloqueioRegistro {
  id: number;
  idAnuncio: number;
  entidade: Entidade;
  dataInteresse: string;
  quantidadeInteresse: number;
  nomeContato: string;
  telefoneContato: string;
  emailContato: string;
  quantidadeAvaliacao: number; // Quantidade autorizada
  observacaoInteresse: string;
  observacaoAvaliacao: string;
  dataEntrega: string; // Data da Doação
  quantidadeEntregue: number; // Quantidade doada
  valorTotalDoado: string;
  favorecida: boolean;
  observacaoEntrega: string;
  anuncioInteresseUtilizacao: string;
  situacao: AnuncioInteresseSituacao;
  dataAvaliacao: string;
}

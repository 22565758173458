import { AppMessageType } from "./app.message.type";

export class AppMessage {
  messageType: AppMessageType;
  data: any;

  constructor(messageType: AppMessageType, data?: any) {
    this.messageType = messageType;
    this.data = data;
  }
}

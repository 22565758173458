import { AnuncioDetalhe } from './anuncio-detalhe.model';
import { UnidadeFornecimento } from './unidadeFornecimento.model';
import { Entidade } from './entidade.model';
import { MaterialCategoria } from './material-categoria.model';
import { Municipio } from './municipio.model';
import { AnuncioInteresse } from './anuncio-interesse.model';
import { BloqueioRegistro } from './bloqueio-registro.model';
import { AnuncioSituacao } from './anuncio-situacao.model';
import { AnuncioFoto } from './anuncio-foto.model';
import { AnuncioOrigem } from './anuncio-origem.model';
import { UsuarioTipo } from './anuncio-usuario.tipo.model';
import { Usuario } from './usuario.model';
import { AnuncioPermissoes } from './anuncio-permissoes.model';
import { Denuncia } from './denuncia.model';

export class Anuncio extends BloqueioRegistro {

  id: number;
  titulo: string;
  descricao: string;
  complementacao: string;
  motivo: string;
  entidade: Entidade;
  usuario: Usuario;
  entidadeFavorecida: Entidade;
  materialCategoria: MaterialCategoria;
  codigoMaterial: string;
  quantidade: number;
  unidadeFornecimento: UnidadeFornecimento;
  municipio: Municipio;
  detalhes: AnuncioDetalhe[];
  interesses: AnuncioInteresse[];
  anuncioSituacao: AnuncioSituacao;
  anuncioOrigem: AnuncioOrigem;
  quantidadeVisualizacoes: number;

  dataExpiracao: string;
  dataEnvio: string;
  dataAnuncio: string;
  dataAvaliacao: string;
  dataValidade: string;
  dataUltimaVisualizacao: string;

  qtdInteresseMeuAnuncio: number;

  contatoNome: string;
  contatoTelefone1: string;
  contatoTelefone2: string;
  contatoEmail: string;
  localizacao: string;
  valorUnitarioMercado: number;
  valorTotalMercado: number;
  cpfUsuario: string;
  nomeUsuario: string;

  cpfSocioMajoritario: string;

  mensagemFavorecida: string;

  fotos: AnuncioFoto[];
  usuarioTipo: UsuarioTipo;
  donoAnuncio: boolean;

  possuiOnusOuEncargos: boolean;
  possuiDonatario: boolean;
  descricaoOnusOuEncargos: string;
  justificativaDoacao: string;

  privado: boolean;
  expirou: boolean;

  permissoes: AnuncioPermissoes;
  denunciaEmAnalise: Denuncia;
}

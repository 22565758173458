import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { Anuncio } from '../../models/anuncio.model';
import { AuthService } from '../../../shared/services/auth.service';
import { Usuario } from '../../models/usuario.model';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';

@Component({
  selector: 'reuse-cadastro-anuncio-page',
  templateUrl: './cadastro-anuncio-page.component.html',
  styleUrls: ['./cadastro-anuncio-page.component.scss']
})
export class CadastroAnuncioPageComponent implements OnInit {

  menuItemMeusAnuncios = { icon: 'fa fa-newspaper-o', label: 'Meus Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO]) } };

  //passos para alterar anúncio
  passos: MenuItem[];
  passo: number;
  jaExibiuPasso: boolean[] = [false, false, false, false, false];

  numeroAnuncio: number;
  anuncioNaoEncontrado: boolean = false;

  //anúncio carregado a partir do 'numeroAnuncio'
  anuncio: Anuncio;

  //Indica se o anuncio foi alterado alguma vez depois de ter sido mudado para Rascunho
  anuncioAlterado: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService,
    private cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    private appMessageService: AppMessageService,
  ) { }

  ngOnInit() {
    this.monitorarAppMessage();
    this.monitorarParametros();
  }

  ngOnDestroy() {
    if (this.numeroAnuncio) {
      //caso esteja alterando um anúncio, remove o bloqueio ao sair do componente
      this.cadastroAnuncioService.removerBloqueioAnuncioGoverno(this.numeroAnuncio)
        .subscribe(
        );
    }
  }

  get usuarioLogado(): Usuario {
    return this.authService.usuario;
  }

  private monitorarParametros(): void {
    this.route.paramMap.subscribe(params => {
      const numero = params.get('numero');

      if (numero == 'novo') {
        //anúncio novo

        this.numeroAnuncio = null;

        this.criarBreadcrumbInclusao();
        this.criarSteps();

        this.passo = 0;

      } else {
        //anúncio já existe, irá alterar

        //verifica se 'numero' é valor válido
        if (isNaN(+numero)) {
          //número inválido
          this.anuncioNaoEncontrado = true;
        } else {
          this.numeroAnuncio = +numero;
          this.carregarAnuncio();
        }
      }
    });
  }

  private carregarAnuncio(): void {
    //carrega o anúncio que se está tentando alterar
    this.anuncioService.getAnuncio(this.numeroAnuncio, OrigemEnum.MEUS_ANUNCIOS_GOVERNO)
      .subscribe(
        anuncio => {

          this.anuncio = anuncio;

          if (anuncio) {
            //carrega os componentes da página
            this.carregarComponentes();
          } else {
            //não existe anúncio para edição
            this.anuncioNaoEncontrado = true;
          }
        }
      );
  }

  /**
   * Inicia os componentes da página.
   */
  private carregarComponentes(): void {
    this.criarBreadcrumbAlteracao();
    this.criarSteps();
    this.selecionarPasso(this.passo);
  }

  /**
   * Configuração da barra 'Breadcrumb' para Alteração do Anúncio.
   */
  private criarBreadcrumbAlteracao(): void {
    let breadcrumbItems = [this.menuItemMeusAnuncios,
    { label: 'Anúncio ' + this.numeroAnuncio, command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO + '/' + this.numeroAnuncio]) } },
    { label: 'Cadastro' }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Configuração da barra 'Breadcrumb' para Inclusão do Anúncio.
   */
  private criarBreadcrumbInclusao(): void {
    let breadcrumbItems = [this.menuItemMeusAnuncios, { label: 'Novo Anúncio' }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Barra de etapas padrão.
   */
  private criarSteps(): void {
    this.passos = [
      { label: 'Dados Básicos' },
      { label: 'Detalhes' },
      { label: 'Fotos' },
      { label: 'Resumo' }
    ];
  }

  /**
   * Seleciona o passo, conforme 'passo' da url.
   *
   * @param passo
   */
  private selecionarPasso(passo: number) {
    this.passo = passo;
    if (this.passo == null) {
      this.passo = 0;
    } else if (this.passo < 0) {
      this.passo = 0;
    } else if (this.passo > 3) {
      this.passo = 3;
    }

    //Se navegou para a aba de Dados Basicos, verifica se o anuncio foi alterado.
    //O componente DadosBasicos precisa dessa informacao para exibir controles, tal como o botao Cancelar.
    if (this.passo == 0) {
      this.verificarAnuncioAlterado();
    }

    this.jaExibiuPasso[passo] = true;
  }

  private monitorarAppMessage() {
    this.appMessageService.getMessage().subscribe(appMessage => {
      if (appMessage.messageType == AppMessageType.CHILD_MESSAGE) {
        this.selecionarPasso(appMessage.data);
      }
    });
  }

  public verificarAnuncioAlterado() {
    if (!this.anuncio) {
      this.anuncioAlterado = false;
      return;
    }

    this.cadastroAnuncioService.anuncioAlterado(this.numeroAnuncio)
      .subscribe(
        (anuncioAlterado) => {
          //dados do anúncio
          this.anuncioAlterado = anuncioAlterado;
        });
  }

  public onNovoNumeroAnuncio(numero: number) {
    this.numeroAnuncio = numero;
  }
}

import { AnuncioInteresse } from './../../models/anuncio-interesse.model';
import { AnuncioInteresseService } from './../../services/anuncio-interesse.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Anuncio } from 'app/anuncio/models/anuncio.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MenuItem } from 'primeng/components/common/menuitem';
import { TenhoInteresseComponent } from '../tenho-interesse/tenho-interesse.component';
import { AuthGuard } from '../../../shared/services/auth-guard.service';
import { InteresseDetailComponent } from '../interesse-detail/interesse-detail.component';
import { UtilService } from '../../../shared/services/util.service';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AnuncioUtil } from 'app/shared/services/anuncio.util';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'reuse-anuncio-detail-page',
  templateUrl: './anuncio-detail-page.component.html',
  styleUrls: ['./anuncio-detail-page.component.scss']
})
export class AnuncioDetailPageComponent implements OnInit {

  numeroAnuncio: number;
  //componente de origem para página de detalhe do anúncio
  origem: string;

  anuncio: Anuncio;
  anuncioNaoEncontrado: boolean = false;
  tenhoInteresseComponent: TenhoInteresseComponent;
  interesseDetail: InteresseDetailComponent;

  carregouDados: boolean = false;

  @ViewChild('reuseTenhoInteresse') reuseTenhoInteresse: TenhoInteresseComponent;
  @ViewChild('reuseInteresseDetail') reuseInteresseDetail: InteresseDetailComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService,
    private anuncioInteresseService: AnuncioInteresseService,
    private authService: AuthService,
    private guardService: AuthGuard,
    private utilService: UtilService,
    private appMessageService: AppMessageService,
    public anuncioUtil: AnuncioUtil
  ) { }

  ngOnInit() {
    this.numeroAnuncio = +this.route.snapshot.paramMap.get('numero');
    this.origem = this.route.snapshot.data['origem'];
    this.criarBreadcrumb();

    this.anuncioService.getAnuncio(this.numeroAnuncio, this.origem)
      .subscribe(
        anuncio => {
          this.anuncio = anuncio;

          if (this.anuncio) {
            this.carregouDados = true;

            // exibe componente 'tenho-interesse' caso necessário
            this.reuseTenhoInteresse.exibirComponente(this.anuncio);

            // verifica se deve existir interesse para o anuncio detalhado componente OrigemEnum.MEUS_ANUNCIOS_INTERESSES
            this.carregarCompomenteInteressados();

            this.utilService.verificarRegistroBloqueado(anuncio, true);

            if (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO) {
              this.anuncioInteresseService.obterInteressadosMeusAnuncios(this.anuncio.id)
                .subscribe(
                  (anunciosInteresses) => {
                    this.anuncio.interesses = anunciosInteresses;
                  });
            }

          } else {
            this.anuncioNaoEncontrado = true;
          }
        }, () => {
          this.anuncio = undefined;
          this.anuncioNaoEncontrado = true;
        }
      );
  }

  public exibirBotaoRepublicacao(): boolean {
    // se a origem da chamada do detalhe for REPUBLICAR exibe o botao e exibe componente
    if (this.origem !== OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO) {
      return false;
    }
    return this.anuncio.permissoes.podeRepublicar;
  }

  public exibirBotaoTenhoInteresse(): boolean {
    // se a origem da chamada do detalhe for MEUS_INTERESSES ou AUTORIZAR_PUBLICACAO, nao exibe o botao e exibe componente
    if (
      this.origem === OrigemEnum.MEUS_INTERESSES ||
      this.origem === OrigemEnum.AUTORIZAR_PUBLICACAO ||
      this.origem === OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO) {
      return false;
    }

    return this.anuncio.permissoes.podeDemonstrarInteresse;
  }

  public exibirBotaoConfirmarDoacao(): boolean {
    if (this.origem === OrigemEnum.CONFIRMAR_DOACAO) {
      return true;
    }
    return false;
  }

  public exibirBotaoExcluir(): boolean {
    // se a origem da chamada do detalhe for MEUS_INTERESSES ou AUTORIZAR_PUBLICACAO, nao exibe o botao e exibe componente
    return (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO)
      && this.anuncio.permissoes.podeExcluir;
  }

  // TODO
  public exibirBotaoEditar(): boolean {
    // se a origem da chamada do detalhe for MEUS_INTERESSES ou AUTORIZAR_PUBLICACAO, nao exibe o botao e exibe componente
    return (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO)
      && this.anuncio.permissoes.podeAlterar;
  }

  public exibirBotaoInativar(): boolean {
    // se a origem da chamada do detalhe for MEUS_INTERESSES ou AUTORIZAR_PUBLICACAO, nao exibe o botao e exibe componente
    return (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO)
      && this.anuncio.permissoes.podeInativar;
  }

  public exibirBotaoExcluirAnuncioPrivadoCentralCompras(): boolean {
    // se a origem da chamada do detalhe for MEUS_INTERESSES ou AUTORIZAR_PUBLICACAO, nao exibe o botao e exibe componente
    if (this.origem !== OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO) {
      return false;
    }
    return this.anuncio.permissoes.podeExcluirAnuncioPrivadoCentralCompras;
  }


  public exibirInteressados(): boolean {
    return (
      this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO &&
      this.anuncioUtil.anuncioPrivado(this.anuncio) &&
      this.anuncio.donoAnuncio) || (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO);
  }

  public exibirDadosVisualizacao(): boolean {
    // se a origem da chamada do detalhe for Meus Anuncios, exibe os dados.
    return this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO;
  }

  public exibirBotoesAutorizarPublicacao(): boolean {
    // se a origem da chamada nao for AUTORIZAR_PUBLICACAO, nao exibe o botao
    if (this.origem !== OrigemEnum.AUTORIZAR_PUBLICACAO) { return false; };

    return this.anuncio.permissoes.podeAutorizarPublicacao;
  }

  /**
   * Se possuir na url rota OrigemEnum.MEUS_ANUNCIOS_INTERESSES, então exibir componente.
   */
  private carregarCompomenteInteressados(): void {
    if (this.origem === OrigemEnum.MEUS_ANUNCIOS_INTERESSES) {
      // se estou na rota 'meus-anuncios-interesse/'
      this.reuseInteresseDetail.obterInteressadosParaAutorizarDoacao(this.anuncio);
    } else if (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO) {
      // se estou na rota OrigemEnum.MEUS_ANUNCIOS_GOVERNO
      this.reuseInteresseDetail.obterInteressadosMeusAnuncio(this.anuncio);
    } else if (this.origem === OrigemEnum.CONFIRMAR_DOACAO) {
      this.reuseInteresseDetail.obterInteressadosMeusAnuncio(this.anuncio);
      // this.reuseInteresseDetail.obterInteressadosParaAutorizarDoacao(this.anuncio);
    }

  }

  /**
   * Clique do botão 'Tenho Interesse'.
   */
  public tenhoInteresse(): void {
    const urlCancel = window.location.href;
    // acessar url 'tenho-interesse'
    this.router.navigate(['tenho-interesse'], { relativeTo: this.route })
      .then(s => {
        if (!this.authService.isLoggedIn) {
          // caso não esteja logado, redirecionar para página de login
          this.guardService.goLogin(window.location.href, urlCancel);
        } else {
          // caso esteja logado, exibir componente
          this.reuseTenhoInteresse.exibirComponente(this.anuncio);
        }
      }
      );
  }


  /**
  * Configuração da barra 'Breadcrumb'.
  */
  private criarBreadcrumb(): void {
    let breadCrumbItem = { label: 'Anúncio ' + this.numeroAnuncio, command: () => { this.router.navigate([this.origem + '/' + this.numeroAnuncio], { queryParams: { dt: new Date().getTime() } }); } };
    let telaAnterior: MenuItem;

    // acrescentar antes do menu do Detalhe o Meus Anúncios se for o caso
    if (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO) {
      telaAnterior = { icon: 'fa fa-newspaper-o', label: 'Meus Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO]) } };
    } else if (this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO) {
      telaAnterior = { icon: 'fa fa-newspaper-o', label: 'Meus Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_PRIVADO]) } };
    } else if (this.origem === OrigemEnum.MEUS_INTERESSES) {
      telaAnterior = { icon: 'fa fa-heart', label: 'Meus Interesses', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_INTERESSES]) } };
    } else if (this.origem === OrigemEnum.AUTORIZAR_PUBLICACAO) {
      telaAnterior = { icon: 'fa fa-globe', label: 'Autorizar Publicação', command: (onclick) => { this.router.navigate([OrigemEnum.AUTORIZAR_PUBLICACAO]) } };
    } else if (this.origem === OrigemEnum.MEUS_ANUNCIOS_INTERESSES) {
      telaAnterior = { icon: 'fa fa-gavel', label: 'Autorizar', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_INTERESSES]) } };
    } else if (this.origem === OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO) {
      telaAnterior = { icon: 'fa fa fa-users', label: 'Consultar Anúncio Privado', command: (onclick) => { this.router.navigate([OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO]) } };
    } else if (this.origem === OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO) {
      telaAnterior = { icon: 'fa fa-refresh', label: 'Republicar Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO]) } };
    } else if (this.origem === OrigemEnum.ANUNCIOS) {
      telaAnterior = { icon: 'fa fa-search', label: 'Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.ANUNCIOS]) } };
    } else if (this.origem === OrigemEnum.CONFIRMAR_DOACAO) {
      telaAnterior = { icon: 'fa fa-handshake-o', label: 'Confirmar Doação', command: (onclick) => { this.router.navigate([OrigemEnum.CONFIRMAR_DOACAO]) } };
    }

    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, [telaAnterior, breadCrumbItem]));
  }

  public onConcluiuAlteracao(): void {
    if (this.origem === OrigemEnum.ANUNCIOS) {
      // quando entrou pela página inicial, mantem na página do anúncio
      this.router.navigate(['../', this.anuncio.id], { relativeTo: this.route });
    } else {
      // outros casos volta para a página de origem, listagem de anúncios
      this.router.navigate(['/' + this.origem]);
    }
  }


  public onDenunciaChanges(): void {
    this.ngOnInit();
  }

  /**
   * Verifica se o anúncio é do tipo Governo ou Privado
   */
  public getClasseAnuncioPrivadoOuGoverno(): String {
    if (this.anuncioUtil.anuncioPrivado(this.anuncio)) {
      return "anuncioPrivado";
    } else {
      return "anuncioGoverno";
    }
  }

}

import { Mensagem } from './../../../mensagem/models/mensagem.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AnuncioService } from 'app/anuncio/services/anuncio.service';
import { AnuncioSituacaomEnum } from 'app/shared/enum/anuncio-situacao.enum';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AnuncioUtil } from 'app/shared/services/anuncio.util';
import { AuthService } from 'app/shared/services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Anuncio } from '../../models/anuncio.model';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';

@Component({
  selector: 'reuse-anuncio-item',
  templateUrl: './anuncio-item.component.html',
  styleUrls: ['./anuncio-item.component.scss']
})
export class AnuncioItemComponent implements OnInit {

  @Input() anuncio: Anuncio;
  @Input() origem: string;

  fotoCapa: any = 'assets/img/sem-imagem-galeria.png';
  carregandoFoto = false;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    public anuncioUtil: AnuncioUtil,
    public authService: AuthService,
    public anuncioService: AnuncioService,
  ) { }

  ngOnInit() {
    this.obterFotoCapa();
  }

  public obterFotoCapa() {
    if (this.anuncio.permissoes.podeVisualizarFoto) {
      this.carregandoFoto = true;
      this.anuncioService.obterFotoCapa(this.anuncio).subscribe(data => {
        this.carregandoFoto = false;
        if (data) {
          this.fotoCapa = data.fotoGaleria;
        }
      }, error => {
        this.carregandoFoto = false;
      });
    }
  }

  private createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.fotoCapa = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * Opção disponível apenas para Anúncios que estiverem na
   * Situação 'Ativo' e cuja
   * Data de Validade seja igual ou posterior à data atual do sistema.
   */
  public exibirBotoesAlterarInteresse(): boolean {
    return this.origem === OrigemEnum.MEUS_INTERESSES && this.anuncio.permissoes.podeAlterarInteresse;
  }

  public exibirBotaoAlterarAnuncio(): boolean {
    return (
      this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO ||
      this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO
    ) && this.anuncio.permissoes.podeAlterar;
  }

  public exibirBotaoExcluir(): boolean {
    return (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || OrigemEnum.MEUS_ANUNCIOS_PRIVADO) &&
      this.anuncio.permissoes.podeExcluir;
  }

  public exibirBotaoInativar(): boolean {
    return (this.origem === OrigemEnum.MEUS_ANUNCIOS_GOVERNO || OrigemEnum.MEUS_ANUNCIOS_PRIVADO) &&
      this.anuncio.permissoes.podeInativar;
  }

  public exibirBotaoAtenderComplementacao(): boolean {
    return this.origem === OrigemEnum.MEUS_ANUNCIOS_PRIVADO
      && this.anuncio.permissoes.podeAtenderComplementacao;
  }

  public exibirQuantidadesUnidades(): boolean {
    return this.anuncio.unidadeFornecimento.id != -1;
  }

  public exibirStatusAnuncio() {
    if (this.anuncio.anuncioSituacao.id == AnuncioSituacaomEnum.EM_ANALISE_DENUNCIA) {
      return this.authService.hasRole(['SEGES']);
    }
    return this.anuncio.anuncioSituacao.id != AnuncioSituacaomEnum.ATIVO;
  }

  /**
 * Verifica se o anúncio é do tipo Governo ou Privado
 */
  public getClasseAnuncioPrivadoOuGoverno(): String {
    if (this.anuncioUtil.anuncioPrivado(this.anuncio)) {
      return "anuncioPrivado";
    } else {
      return "anuncioGoverno";
    }
  }

  /**
   * Comportamento ao clicar em um anúncio, redireciona para página de detalhamento.
   */
  public clicouAnuncio(): void {
    this.router.navigate(['/' + this.origem, this.anuncio.id]);
  }

  /**
   * Clique botão 'Alterar interesse'.
   */
  public alterarInteresse(event: MouseEvent): void {
    //quando clicar no botão não deve disparar evento 'clicouAnuncio'
    event.stopPropagation();

    this.router.navigate(['/' + this.origem, this.anuncio.id, 'tenho-interesse']);
  }

  /**
   * Clique botão 'Alterar anúncio'.
   */
  public alterarAnuncio(event: MouseEvent): void {
    //quando clicar no botão não deve disparar evento 'clicouAnuncio'
    event.stopPropagation();

    if (this.anuncioUtil.anuncioGoverno(this.anuncio)) {
      this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_GOVERNO, this.anuncio.id]);
    } else {
      this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
        () => {
          this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_PRIVADO, this.anuncio.id]);
        });
    }
  }

  public atenderComplementacao(event: MouseEvent): void {
    //quando clicar no botão não deve disparar evento 'clicouAnuncio'
    event.stopPropagation();

    this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
      () => {
        this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_PRIVADO, this.anuncio.id]);
      });
  }

  /**
   * Clique botão 'Excluir interesse'.
   * Direciona para a página de detalhes com parâmetro excluir
   */
  public cancelarInteresse(event: MouseEvent): void {
    //quando clicar no botão não deve disparar evento 'clicouAnuncio'
    event.stopPropagation();

    this.router.navigate(['/' + this.origem, this.anuncio.id], { queryParams: { parametro: 'excluir' } });
  }

  /**
   * Clique botão 'Excluir anúncio'.
   */
  public excluirAnuncio(event: MouseEvent): void {
    //quando clicar no botão excluir não deve disparar evento 'clicouAnuncio'
    event.stopPropagation();

    this.confirmationService.confirm({
      key: `modal_${this.anuncio.id}`,
      message: `Confirma a exclusão do Anúncio ${this.anuncio.id}?`,
      accept: () => {
        this.confirmouExclusaoAnuncio();
      }
    });
  }


  public inativarAnuncio(event: MouseEvent): void {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: `modal_${this.anuncio.id}`,
      message: `Confirma a inativação do Anúncio ${this.anuncio.id}?`,
      accept: () => {
        this.confirmouInativarAnuncio();
      }
    });
  }

  private confirmouExclusaoAnuncio(): void {
    this.anuncioService.excluirAnuncio(this.anuncio.id).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
        setTimeout(() => window.location.reload(), 1000);
      });
  }

  private confirmouInativarAnuncio(): void {
    if (this.anuncioUtil.anuncioPrivado(this.anuncio) || this.anuncioUtil.anuncioGoverno(this.anuncio)) {
      this.anuncioService.inativarAnuncio(this.anuncio.id).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'sucess', summary: 'Sucesso', detail: resultado.mensagem });
          setTimeout(() => window.location.reload(), 1000);
        }
      )
    }
  }

  public origemMeusAnunciosInteresse(): boolean {
    return this.origem == OrigemEnum.MEUS_ANUNCIOS_INTERESSES;
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AuthGuard } from '../../services/auth-guard.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IndexComponent implements OnInit {

  constructor(private appMessageService: AppMessageService) { }

  ngOnInit(): void {
    this.criarBreadcrumb();
  }

    /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb():void {
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB));
  }
}

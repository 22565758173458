import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { OrgaoService } from './../../services/orgao.service';
import { CadastroUsuarioGovernoService } from './../../../cadastro-usuario-governo/services/cadastro-usuario-governo.service';
import { Orgao } from './../../models/orgao.model';
import { ParametrosConfirmarDoacao } from '../../models/parametros-confirmar-doacao';
import { AnuncioSituacao } from 'app/anuncio/models/anuncio-situacao.model';
import { FiltroConfirmarDoacao } from '../../models/filtro-confirmar-recebimento-doacao';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Entidade } from '../../models/entidade.model';
import { AnuncioChip } from '../../models/anuncio-chip.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-confirmar-doacao-page',
  templateUrl: './confirmar-doacao-page.component.html',
  styleUrls: ['./confirmar-doacao-page.component.scss']
})
export class ConfirmarDoacaoPageComponent extends AnunciosInternoPage implements OnInit {

  filtro: FiltroConfirmarDoacao = new FiltroConfirmarDoacao();
  anuncioSituacoes: AnuncioSituacao[];

  // lista orgao disponiveis para pesquisa
  listaOrgaos: Orgao[] = [];

  // lista entidades disponiveis para pesquisa
  listaDonatarios: Entidade[] = [];

  @ViewChild('entidade')
  entidadeDropdown: Dropdown;

  @ViewChild('orgao')
  orgaoDropdown: Dropdown;

  constructor(private anuncioService: AnuncioService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService,
    private cadastroUsuarioGovernoService: CadastroUsuarioGovernoService,
    private orgaoService: OrgaoService
  ) {
    super();
  }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosConfirmarDoacao = this.anuncioService.obterParametrosConfirmarDoacao(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.recuperarOrgaos();
    this.recuperarEntidades();
    this.popularSeparadoresTagInput();
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    const breadcrumbItems = [
      {
        icon: 'fa fa-handshake-o', label: 'Confirmar Doação', command: (onclick) => {
          this.router.navigate([OrigemEnum.CONFIRMAR_DOACAO],
            { queryParams: { dt: new Date().getTime() } });
        }
      }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosConfirmarDoacao) {
    this.anuncioService.consultarFiltrosConfirmarDoacao()
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {
          this.filtro.anuncioSituacoes = resultado.anuncioSituacoes;

          this.definirFiltrosSelecionados(parametros);
        });
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosConfirmarDoacao) {
    this.filtro.anuncioTitulo = parametros.anuncioTitulo;

    const periodoPublicacao: Date[] =
      parametros.periodoPublicacao.split(',').filter(v => v !== '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoPublicacao.length !== 0) {
      this.filtro.periodoPublicacao = periodoPublicacao;
    }

    const anuncios: AnuncioChip[] = parametros.anuncioNumeros.split(',').filter(v => v !== '').map(v => <AnuncioChip>{ value: v, display: v });
    this.filtro.anuncioNumeros = anuncios;

    const anuncioSituacoes: AnuncioSituacao[] = parametros.anuncioSituacoes.split(',').
      filter(v => v !== '').map((nome) => <AnuncioSituacao>{ nome });
    this.filtro.anuncioSituacoesSelecionadas = anuncioSituacoes;

    // alterar aqui o tipo de donatarios
    // const donatarios: Entidade[] = parametros.idDonatario.split(',').filter(v => v !== '').map((nome) => <Entidade>{ nome });
    // this.filtro.donatariosSelecionados = donatarios;
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente '-lista' monitora a URL para carregar novos anúncios.
   */
  aplicarFiltros() {
    const periodoPublicacao = (this.filtro.periodoPublicacao) ? this.filtro.periodoPublicacao.
      filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',') : null;
    const anuncioNumeros = (this.filtro.anuncioNumeros) ? this.filtro.anuncioNumeros.map(m => m.display).join(',') : null;
    const anuncioSituacoes = (this.filtro.anuncioSituacoesSelecionadas) ?
      this.filtro.anuncioSituacoesSelecionadas.map(m => m.nome).join(',') : null;
    const idDonatario = (this.filtro.donatario && this.filtro.donatario.id) ? this.filtro.donatario.id : null;
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosConfirmarDoacao = this.anuncioService.obterParametrosConfirmarDoacao(parametrosUrl);

    parametros.tpConsulta = '2';

    this.router.navigate([OrigemEnum.CONFIRMAR_DOACAO], {
      queryParams: {
        anuncioTitulo: this.filtro.anuncioTitulo,
        anuncioNumeros: anuncioNumeros,
        periodoPublicacao: periodoPublicacao,
        anuncioSituacoes: anuncioSituacoes,
        idDonatario: idDonatario,
        inicio: parametros.inicio,
        ordem: parametros.ordem,
        tpConsulta: parametros.tpConsulta,
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.anuncioTitulo = null;
    this.filtro.periodoPublicacao = null;
    this.filtro.anuncioSituacoesSelecionadas = [];
    this.filtro.anuncioNumeros = [];
    this.filtro.donatariosSelecionados = [];
    this.filtro.orgao = new Orgao(null);
    this.filtro.donatario = new Entidade(null);
    this.aplicarFiltros();
  }

  limparPeriodoPublicacao(input) {
    this.filtro.periodoPublicacao = null;
    this.fecharCalendario(input);
  }

  private recuperarOrgaos(): void {
    this.orgaoService.listarOrgaosAtivos()
      .subscribe(
        (orgaos) => {
          this.listaOrgaos = [];
          for (const orgao of orgaos) {
            orgao.idNome = orgao.id + ' - ' + orgao.nome;
            this.listaOrgaos.push(orgao);
          }
          // adiciona opção 'Selecione' (vazia)
          this.listaOrgaos.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });
        }
      );
  }


  // onChange para para dropdown 'Entidade'.
  public recuperarEntidades(): void {

    // limpa o filtro de entidade, caso o orgao seja modificado.
    this.filtro.donatario = null;
    // limpa o filtro do drowpdown da entidade, caso o orgao seja modificado.
    this.entidadeDropdown.filterValue = '';
    // limpa o filtro do drowpdown do orgão, caso o orgao seja modificado.
    this.orgaoDropdown.filterValue = '';

    if (this.filtro.orgao == null || this.filtro.orgao.id == null) {
      this.listaDonatarios = [];
    } else {
      this.carregarEntidade();
    }
  }

  // Carrega a opção de 'Entidades' a partir do 'Orgao' selecionado.
  private carregarEntidade(): void {
    this.cadastroUsuarioGovernoService.obterEntidadePorOrgao(this.filtro.orgao.id)
      .subscribe(
        (entidades) => {
          this.listaDonatarios = [];
          for (const entidade of entidades) {
            entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
            this.listaDonatarios.push(entidade);
          }
          this.listaDonatarios.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
        }
      );
  }


}

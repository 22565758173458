<ng-progress></ng-progress>
<reuse-header></reuse-header>
<main id="main" class="d-flex flex-fill">
  <!-- Define a largura da página -->
  <div class="container-fluid d-flex flex-wrap">
    <div class="col-12">
      <reuse-breadcrumb></reuse-breadcrumb>
    </div>
    <div class="main-content">
      <reuse-mensagens></reuse-mensagens>
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
<reuse-footer></reuse-footer>
<!--
<reuse-sistema-em-manutencao></reuse-sistema-em-manutencao>
-->

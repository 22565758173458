<div *ngIf="totalRegistros > 0">
  <div class="ui-g barraTotalOrdem">
    <div class="ui-g-6">
      <p-message severity="info" text="Total registros: {{totalRegistros}}"></p-message>
    </div>
    <div class="ui-g-6 barraOrdem">
      <p-dropdown [options]="ordenacoes" [(ngModel)]="ordem"
        (onChange)="aplicarOrdenacao($event)"></p-dropdown>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-xl-4 ui-lg-6 ui-md-12 ui-sm-12" style="padding-left: 15px; padding-right: 15px;" *ngFor="let anuncio of anuncios">
      <reuse-anuncio-item
        [anuncio]="anuncio" [origem]="MEUS_ANUNCIOS_INTERESSES">
      </reuse-anuncio-item>
    </div>
  </div>
  <p-paginator [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
    (onPageChange)="paginar($event)" [(first)]="inicio"></p-paginator>
</div>
<div *ngIf="carregouDados && totalRegistros == 0">
  <p-message severity="warn" text="Nenhum registro encontrado."></p-message>
</div>

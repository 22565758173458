<div class="ui-g">
  <div class="ui-xl-6 ui-lg-6 ui-md-12 ui-sm-12">
    <p-fieldset class="governo" legend="Governo" [style]="{'margin-top':'10px'}">
      <p-accordion>
        <p-accordionTab>
          <p-header>
            <span>1. </span>Quem pode acessar e consultar o sistema?
          </p-header>
          <p>A simples consulta dos bens disponibilizados pode ser feita por qualquer pessoa, na área pública do
            sistema.</p>
          <p>A partir de 12 de agosto de 2019, o sistema poderá ser acessado por todos os usuários públicos, desde que
            previamente sejam habilitados no sistema e possuam senha rede, e para os usuários privados por meio do
            Gov.Br.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>2. </span>Como é feita a habilitação no sistema?
          </p-header>
          <p>A habilitação no sistema será feita pelos cadastradores do órgão a que pertence o servidor.</p>
          <p>O cadastrador deverá utilizar a transação "Habilita Usuário em Sistema" do senha rede para habilitar os
            servidores no perfil GOVERNO.</p>
          <p><img height="400px" width="600px" style="margin-left: 25px;"
              src="./../assets/images/faq_cadastro_senha_rede.jpg"></p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>3. </span>Como o cidadão terá acesso ao sistema?
          </p-header>
          <p>O cidadão tem acesso à área pública do sistema, sem a necessidade de realizar o login.</p>
          <p>Caso tenha interesse em doar deve ser realizado o login por meio do Gov.Br.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>4. </span>Como se dará o acesso dos servidores públicos para consulta, divulgação e captação dos bens
            públicos?
          </p-header>
          <p>O usuário, servidor público, deve utilizar sua senha rede para acessar a área pública e privada do sistema.
          </p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>5. </span>O que é área pública do sistema?
          </p-header>
          <p>A área pública contém a consulta de todos os anúncios publicados vigentes, com todas as opções de filtros,
            no seu período de vigência.</p>
        </p-accordionTab>


        <p-accordionTab>
          <p-header>
            <span>6. </span>O que é área privada do sistema?
          </p-header>
          <p>A área privada contém as ações restritas aos órgãos públicos e entidades, tais como a publicação de
            anúncios, demonstração de interesse, acompanhamento e administração destas manifestações e, finalmente,
            autorizar a destinação dos bens.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>7. </span>Como faço o login no sistema Doações?
          </p-header>
          <p>Clique no botão "ENTRAR" localizado no canto superior direito da tela inicial;</p>
          <p>Clique na aba "Sou de um órgão público", para servidores públicos;</p>
          <p>Informe o número do CPF, "Senha" e "Captcha".</p>
          <p>Caso o usuário tente demonstrar interesse por algum anúncio, sem estar logado, deverá efetuar o login
            conforme descrito acima.</p>
          <p><img height="400px" width="600px" style="margin-left: 25px;"
              src="./../assets/images/faq_login_governo.png"></p>
        </p-accordionTab>


        <p-accordionTab>
          <p-header>
            <span>8. </span>Como faço para publicar um anúncio?
          </p-header>
          <p>A publicação dos usuários do SIADS depende da avaliação da situação do bem patrimonial, feita diretamente
            pelo SIADS, no Grande Porte. Após a conclusão da avaliação, o SIADS enviará o anúncio automaticamente para o
            sistema. Os usuários SIADS não conseguirão publicar os anúncios manualmente.</p>
          <p>Para usuários de outros sistemas de gestão patrimonial, que utilizam Senha Rede, a publicação do anúncio é
            manual, utilizando-se o botão "QUERO DOAR".</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>9. </span>Posso alterar meu anúncio?
          </p-header>
          <p>Sim. Clique no Menu do canto superior direito, acione a opção "Meus Anúncios", escolha aquele que será
            objeto de alteração e depois clique no botão "Alterar".</p>
          <p>Para usuários SIADS, é possível alterar somente a(s) foto(s) pelo sistema. No grande Porte, pelo SIADS, é
            possível alterá-lo ou exclui-lo totalmente.</p>
          <p>Para usuários de outros sistemas de gestão patrimonial que utilizam Senha Rede, é possível alterar ou
            excluir o anúncio.</p>
          <p>Toda e qualquer alteração só será possível se o anúncio estiver dentro do prazo de vigência.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>10. </span>Como faço para demonstrar interesse em algum anúncio?
          </p-header>
          <p>O usuário deve estar logado no sistema.</p>
          <p>Ao clicar no anúncio, o sistema abrirá tela com seus detalhes e o usuário deverá clicar no botão "Tenho
            Interesse". Em seguida, deve ser informada a quantidade de bens de interesse, com as observações
            necessárias, se for o caso, e finalmente, salvar as informações.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>11. </span>Como faço para modificar meu interesse por um anúncio?
          </p-header>
          <p>O usuário logado no sistema acessa o menu do canto superior e clica no botão "Meus Interesses",
            depois no botão para edição do anúncio escolhido. Em sua tela de detalhes, o usuário deve fazer as
            alterações e, finalmente, salvá-las.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>12. </span>Como faço para escolher os órgãos públicos ou entidades que deverão receber os bens
            anunciados?
          </p-header>
          <p>Apenas o dono do anúncio poderá realizar autorizar a doação.</p>
          <p>Somente poderá ser autorizada a doação o anúncio que tiver manifestação de interesse e sua
            situação seja FINALIZADO, ou seja, a data limite de manifestação de interesse tenha sido atingida.</p>
          <p>O usuário logado no sistema acessa o menu do canto superior e clica em "Autorizar Doação". Clicando, em
            seguida, no anúncio escolhido. O sistema abrirá tela para que sejam feitas as análises das manifestações.
          </p>
          <p>Clicando no botão "Autorizar", o usuário informa a quantidade de bens a ser atendida e depois clica no
            botão
            "Confirmar".</p>
          <p>Deverá haver ao menos uma análise de um interessado.</p>
          <p>Caso não siga a ordem cronológica de autorização o usuário deverá justificar o motivo pelo qual não seguiu
            a ordem cronológica de interessados.</p>
          <p>Caso não siga a quantidade demonstrada de interesse o usuáiro deverá justificar.</p>
          <p>Caso o usuário indicado como donatário demonstre interesse a qualquer momento ele será considerado o
            primeiro da lista.</p>
          <p>Após a análise de interesses, o usuário deve finalizar a autorização clicando no botão "Finalizar
            Autorização".</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>13. </span>É preciso analisar todas as manifestações de interesse, de todas as instituições?
          </p-header>
          <p>Não, mas deve haver ao menos uma análise de um interessado.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>14. </span>É preciso destinar todos os bens do anúncio ao fazer as análises?
          </p-header>
          <p>Não é necessário, o usuário tem liberdade para fazer a análise, conforme seu entendimento.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>15. </span>Como faço para confirmar a doação?
          </p-header>
          <p>O usuário logado no sistema acessa o menu do canto superior e clica em "Confirmar Doação". Serão listados
            todos os anúncios que tiveram doação aprovada para o interessado logado.</p>
          <p>O usuário deverá selecionar um anúncio da lista para confirmar doação.</p>
          <p>Clicando no botão "Confirmar Doação", o usuário informa a data da doação, a utilização, a quantidade doada
            e a observação.</p>
          <p>Em seguida, o usuário clica em "Confirmar"</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>16. </span>O anúncio permanecerá por quanto tempo no sistema?
          </p-header>
          <p>O anúncio ficará disponível no sistema por 10 dias corridos.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>17. </span>Como ocorrerá a movimentação dos bens após a aprovação pelo anunciante?
          </p-header>
          <p>Para usuários SIADS a movimentação se dará pelo SIADS Grande Porte. Aos usuários de outros sistemas de
            gestão patrimonial, a movimentação ocorre nos seus sistemas de origem.</p>
        </p-accordionTab>

      </p-accordion>
    </p-fieldset>
  </div>
  <div class="ui-xl-6 ui-lg-6 ui-md-12 ui-sm-12">
    <p-fieldset class="privado" legend="Doador" [style]="{'margin-top':'10px'}">
      <p-accordion>
        <p-accordionTab>
          <p-header>
            <span>1. </span>O que pode ser doado por meio do sistema?
          </p-header>
          <p>Bens móveis e serviços.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>2. </span>Qual a diferença entre a manifestação de interesse e o chamamento público?
          </p-header>
          <p>A manifestação de interesse parte do doador, que deve utilizar o sistema para
            manifestar o interesse em doar para a Administração algum tipo de bem móvel ou
            serviço. Essa manifestação pode ser feita diretamente a um órgão ou entidade da
            Administração Pública Federal.</p>
          <p>O chamamento público parte da Administração para o doador, ou seja, quando a
            Administração convoca o setor privado a doar um bem ou serviço para a Administração.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>3. </span>Quem pode acessar e consultar o sistema?
          </p-header>
          <p>A simples consulta dos bens disponibilizados pode ser feita por qualquer pessoa, na
            área pública do sistema.</p>
          <p>O sistema poderá ser acessado por todos os usuários que tenham Senha Rede - para os
            usuários públicos e Login Único - para os usuários privados.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>4. </span>Como tenho acesso ao sistema?
          </p-header>
          <p>O cidadão, caso tenha interesse em doar, deve acessar o sistema por meio do login único
            disponível no Gov.br.</p>
          <p>No caso de pessoa jurídica, é necessário que o representante do CNPJ tenha um
            certificado digital de pessoa jurídica, para cadastrar o CNPJ da pessoa jurídica no login
            único.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>5. </span>O que é o Login Único?
          </p-header>
          <p>O Login Único é um meio de acesso digital do usuário aos serviços públicos digitais.
            Este Login garante a identificação de cada cidadão que acessa os serviços digitais do
            governo.</p>
          <p>Oferece um ambiente de autenticação digital único do usuário aos serviços públicos
            digitais, ou seja, com um único usuário e senha você poderá utilizar todos os serviços
            públicos digitais que estejam integrados com a plataforma de Login Único. Fornece um
            nível de segurança compatível com o grau de exigência, natureza e criticidade dos
            dados e das informações pertinentes ao serviço público solicitado.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>6. </span>Como Cadastrar CNPJ no Login Único?
          </p-header>
          <p>Para cadastrar o CNPJ no Login Único:</p>
          <ul>
            <li>Acesse o item de menu "CNPJ" ou Botão "Obter Confiabilidade abaixo" do selo e-CNPJ</li>
          </ul>
          <p><img height="500px" width="600px" style="margin-left: 25px;" src="./../assets/images/faq_gov_br_1.jpg"></p>
          <ul>
            <li>Insira o Certificado Digital Pessoal Jurídica do CNPJ que deseja cadastrar e
              clique no botão "Validar Informações do eCNPJ"</li>
          </ul>
          <p><img height="400px" width="600px" style="margin-left: 25px;" src="./../assets/images/faq_gov_br_2.jpg"></p>
          <ul>
            <li>Aparecerá tela com as informações do Certificado Digital de Pessoa Jurídica.
              Confirme as informações e clique no botão "Adquirir o Selo"</li>
          </ul>
          <p><img height="300px" width="600px" style="margin-left: 25px;" src="./../assets/images/faq_gov_br_3.jpg"></p>
          <ul>
            <li>Lembrando que o certificado digital de pessoa jurídica deve ser do tipo A3 ou
              A1, compatível com ICP-BRASIL, para cadastrar o CNPJ da pessoa jurídica</li>
          </ul>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>7. </span>Como faço login no sistema Doações?
          </p-header>
          <p>Clique no botão "ENTRAR" localizado no canto superior direito da tela inicial e selecione a
            opção "Sou um usuário privado".</p>
          <p><img height="400px" width="600px" style="margin-left: 25px;"
              src="./../assets/images/faq_login_privado.png"></p>
        </p-accordionTab>


        <p-accordionTab>
          <p-header>
            <span>8. </span>Como posso doar?
          </p-header>
          <p>O interessado deve clicar no botão "QUERO DOAR" e preencher os dados do bem
            móvel e/ou serviço a ser doado.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>9. </span>Como faço para publicar um anúncio?
          </p-header>
          <p>A publicação dos usuários privados depende da liberação do órgão público, que
            poderá pedir complementação das informações inseridas.</p>
          <p>Após a conclusão da avaliação, seu anúncio será publicado automaticamente pelo
            sistema.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>10. </span>O anúncio permanecerá por quanto tempo no sistema?
          </p-header>
          <p>Trecho do decreto número 10.667 de 5 de abril de 2021:</p>
          <p>Parágrafo 1º Quando a doação sem ônus ou encargos for para donatários indicados, o anúncio da doação
            permanecerá disponível pelo período mínimo de dois dias úteis para que estes se candidatem a receber a
            doação. (Redação dada pelo Decreto nº 10.667, de 2021)</p>
          <p>Parágrafo 1º-A O anúncio da doação permanecerá disponível pelo período mínimo de oito dias úteis nas
            seguintes hipóteses: (Incluído pelo Decreto nº 10.667, de 2021)</p>
          <p>I - doações sem ônus ou encargos, sem donatários indicados, para que os órgãos ou as entidades interessados
            se candidatem a receber a doação; e (Incluído pelo Decreto nº 10.667, de 2021)</p>
          <p>II - doações com ônus ou encargos, com ou sem donatários indicados, para que: (Incluído pelo Decreto nº
            10.667, de 2021)</p>
          <p>a) outros doadores interessados apresentem propostas de doações correlatas; e (Incluído pelo Decreto nº
            10.667, de 2021)</p>
          <p>b) os órgãos ou as entidades interessados em receber a doação selecionem a proposta ou as propostas mais
            adequadas aos interesses da administração pública, observado o disposto nos § 1º e § 2º do art. 12, no
            inciso II do caput do art. 19-A e no art. 19-B.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>11. </span>Posso alterar meu anúncio?
          </p-header>
          <p>Sim, desde que não esteja publicado.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>12. </span>Posso indicar um órgão ou entidade para receber a doação?
          </p-header>
          <p>Sim. Pode haver indicação de órgão ou entidade para receber a doação, condicionado
            à manifestação do ente donatário.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>13. </span>Se tiver mais de um interessado posso escolher para quem eu vou doar?
          </p-header>
          <p>Sim.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>14. </span>Meu anúncio não teve interessados, posso republicar?
          </p-header>
          <p>Sim, após o prazo do anúncio e não havendo interessados, você poderá acessar os anúncios por meio de menu
            "Republicar anúncio", não havendo nova análise pela SEGES.</p>
          <p> Para a republicação de doações de serviços é necessário novo cadastro de anúncio.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>15. </span>Posso exigir condições para a minha doação?
          </p-header>
          <p>Sim. O Decreto no 10.667/2021 permite doações com ônus e/ou encargos.</p>
        </p-accordionTab>

        <p-accordionTab>
          <p-header>
            <span>16. </span>Posso desistir da minha doação?
          </p-header>
          <p>Sim. Enquanto o anúncio estiver ativo, o usuário poderá inativá-lo.</p>
        </p-accordionTab>
        <p-accordionTab>
          <p-header>
            <span>17. </span>Posso fazer propaganda da doação?
          </p-header>
          <p>Não. O Decreto no 9.764/2019 veda publicidade das doações, exceto menção
            informativa no sítio eletrônico do doador, após a entrega dos bens móveis ou início da
            prestação dos serviços.</p>
        </p-accordionTab>
        <p-accordionTab>
          <p-header>
            <span>18. </span>Como ocorrerá a movimentação dos bens móveis e/ou serviços após a
            finalização do anuncio?
          </p-header>
          <p>O doador deverá aguardar o contato do órgão ou entidade interessada para a
            formalização da doação.</p>
        </p-accordionTab>
        <p-accordionTab>
          <p-header>
            <span>19. </span>Como faço para autorizar doação?
          </p-header>
          <p>O usuário seleciona a opção de menu "Autorizar Doação".</p>
          <p>O usuário seleciona um dos anúncios apresentados no resultado da consulta.</p>
          <p>Para cada interessado da lista, o usuário seleciona a opção "Autorizar Doação" na última coluna da lista.
          </p>
          <p>O usuário informa a quantidade autorizada e a observação doação.</p>
          <p>O usuário seleciona a opção "Finalizar Autorização"</p>
          <p>O usuário seleciona a opção "Sim"</p>
          <p>Caso a quantidade informada para o primeiro interessado seja diferente da quantidade do anúncio, o usuário
            deve informar uma justificativa.</p>
          <p>Caso a ordem dos interessados não seja respeitada, o usuário deve informar também uma justificativa.</p>
        </p-accordionTab>
      </p-accordion>
    </p-fieldset>
  </div>
</div>

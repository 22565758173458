import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ParametrosAutorizarPublicacao } from 'app/anuncio/models/parametros-autorizar-publicacao.model';


@Component({
  selector: 'reuse-autorizar-publicacao-lista',
  templateUrl: './autorizar-publicacao-lista.component.html',
  styleUrls: ['./autorizar-publicacao-lista.component.scss']
})
export class AutorizarPublicacaoListaComponent extends AnunciosInternoLista implements OnInit {

  AUTORIZAR_PUBLICACAO: OrigemEnum.AUTORIZAR_PUBLICACAO;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
      super();
    }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosAutorizarPublicacao = this.anuncioService.obterParametrosAutorizarPublicacaoUrl(params);
        this.ordem = parametros.ordem;

        this.anuncioService.consultarAutorizarPublicacao(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0,0);
          });
      });
  }

  /**
   * Consulta os anúncios, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosAutorizarPublicacao = this.anuncioService.obterParametrosAutorizarPublicacaoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.AUTORIZAR_PUBLICACAO], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        anuncioSituacoes: parametros.anuncioSituacoes,
        materialTipos: parametros.materialTipos,
        materialCategorias: parametros.materialCategorias,
        materialSituacoes: parametros.materialSituacoes,
        ufs: parametros.ufs,
        periodoInclusao: parametros.periodoInclusao,
        periodoExpiracao: parametros.periodoExpiracao,
        anuncios: parametros.anuncios,
        entidades: parametros.entidades,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}

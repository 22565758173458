
/**
 * Indica o componente de origem para página de detalhe do anúncio.
 */
export enum OrigemEnum {
  ANUNCIOS = 'anuncios',
  MEUS_ANUNCIOS_GOVERNO = 'meus-anuncios-governo',
  MEUS_ANUNCIOS_PRIVADO = 'meus-anuncios-privado',
  CONFIRMAR_DOACAO = 'confirmar-doacao',
  REPUBLICAR_ANUNCIOS_PRIVADO = 'republicar-anuncios-privado',
  MEUS_ANUNCIOS_INTERESSES = 'meus-anuncios-interesse',
  MEUS_INTERESSES = 'meus-interesses',
  AUTORIZAR_PUBLICACAO = 'autorizar-publicacao',
  CONSULTAR_ANUNCIO_PRIVADO = 'consultar-anuncio-privado',
  CADASTRO_ANUNCIO_GOVERNO = 'cadastro-anuncio-governo',
  CADASTRO_ANUNCIO_PRIVADO = 'cadastro-anuncio-privado',
  ALTERAR_CADASTRO_ANUNCIO_PRIVADO = 'alterar-cadastro-anuncio-privado',
  ALTERAR_CADASTRO_ANUNCIO_GOVERNO = 'alterar-cadastro-anuncio-governo',

  ALTERAR_CADASTRO_USUARIO_GOVERNO = 'alterar-cadastro-usuario-governo',
  INCLUIR_CADASTRO_USUARIO_GOVERNO = 'incluir-cadastro-usuario-governo',
  VISUALIZAR_CADASTRO_USUARIO_GOVERNO = 'visualizar-cadastro-usuario-governo',
  LISTAR_CADASTRO_USUARIO_GOVERNO = 'listar-cadastro-usuario-governo',

  LISTAR_CADASTRO_ENTIDADE = 'listar-cadastro-entidade',
  INCLUIR_CADASTRO_ENTIDADE = 'incluir-cadastro-entidade',
  ALTERAR_CADASTRO_ENTIDADE = 'alterar-cadastro-entidade',
  VISUALIZAR_CADASTRO_ENTIDADE = 'visualizar-cadastro-entidade',

  RELATORIO_ANUNCIO = 'relatorio-anuncios'

}

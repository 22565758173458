import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AnuncioInteresseSituacao } from 'app/anuncio/models/anuncio-interesse-situacao.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { RouteService } from 'app/shared/services/route.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnuncioInteresse } from '../../models/anuncio-interesse.model';
import { Anuncio } from '../../models/anuncio.model';
import { AnuncioInteresseService } from '../../services/anuncio-interesse.service';
import { AnalisarInteresseComponent } from '../analisar-interesse/analisar-interesse.component';
import { ModalConfirmarDoacaoComponent } from '../confirmar-doacao-anuncio-privado/modal-confirmar-doacao.component';
import { MudancaDoacaoDto } from './../../models/mudancao-doacao-dto';
import { ModalFinalizarAutorizacaoDoacaoComponent } from './../modal-finalizar-autorizacao-doacao/modal-finalizar-autorizacao-doacao.component';

@Component({
  selector: 'reuse-interesse-detail',
  templateUrl: './interesse-detail.component.html',
  styleUrls: ['./interesse-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InteresseDetailComponent implements OnInit {
  anuncio: Anuncio;
  anuncioInteresses: AnuncioInteresse[];

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDadosAnuncioInteresse: boolean = false;
  carregouDadosMeusAnuncio: boolean = false;

  @ViewChild('reuseAnalisarInteresse') reuseAnalisarInteresse: AnalisarInteresseComponent;
  @ViewChild('reuseModalConfirmarDoacao') reuseModalConfirmarDoacao: ModalConfirmarDoacaoComponent;
  @ViewChild('reuseModalFinalizarAutorizacaoDoacao') reuseModalFinalizarAutorizacaoDoacao: ModalFinalizarAutorizacaoDoacaoComponent;

  constructor(
    private anuncioInteresseService: AnuncioInteresseService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private routeService: RouteService
  ) { }

  ngOnInit() {
  }

  get totalQuantidadeInteresse() {
    return this.anuncioInteresses.reduce((total, ai) => total + ai.quantidadeInteresse, 0);
  }

  get totalQuantidadeAvaliacao() {
    return this.anuncioInteresses.reduce((total, ai) => total + ai.quantidadeAvaliacao, 0);
  }

  get totalQuantidadeDoada() {
    return this.anuncioInteresses.reduce((total, ai) => total + ai.quantidadeEntregue, 0);
  }

  get totalValorDoado() {
    return this.anuncioInteresses.reduce((total, ai) => total + parseInt(ai.valorTotalDoado, 10), 0);
  }

  get totalQuantidadeEntregue() {
    return this.anuncioInteresses.reduce((total, ai) => total + ai.quantidadeEntregue, 0);
  }

  get totalRestante() {
    return (this.anuncio.quantidade - this.totalQuantidadeAvaliacao);
  }

  get totalRestanteConfirmarDoacao() {
    return (this.anuncio.quantidade - this.totalQuantidadeEntregue);
  }

  get carregaDados() {
    return (this.carregouDadosAnuncioInteresse || (this.carregouDadosMeusAnuncio && this.anuncio.anuncioSituacao.nome == "Aprovado"));
  }

  /**
   * Exibe o componente tenho-interesse relacionado aos meus interesses.
   *
   * @param anuncio
   */
  obterInteressadosParaAutorizarDoacao(anuncio: Anuncio) {
    this.anuncio = anuncio;
    // carrega o interesse para inserção ou edição
    this.anuncioInteresseService.obterInteressadosParaAutorizarDoacao(this.anuncio.id)
      .subscribe(
        (anunciosInteresses) => {
          this.anuncioInteresses = anunciosInteresses;
          this.carregouDadosAnuncioInteresse = true;
        });
  }

  /**
  * Exibe o componente tenho-interesse relacionado aos meus anuncios.
  *
  * @param anuncio
  */
  obterInteressadosMeusAnuncio(anuncio: Anuncio) {
    this.anuncio = anuncio;
    // carrega o interesse para inserção ou edição
    this.anuncioInteresseService.obterInteressadosMeusAnuncios(this.anuncio.id)
      .subscribe(
        (anunciosInteresses) => {
          this.anuncioInteresses = anunciosInteresses;
          this.carregouDadosMeusAnuncio = true;
        });
  }

  /**
   * Aciona o componente 'analisar-interesse', o qual abre uma modal para analisar o interesse.
   */
  exibirModalAnalisarInteresse(anuncioInteresse: AnuncioInteresse) {
    this.reuseAnalisarInteresse.exibirModal(anuncioInteresse);
  }

  exibirModalFinalizarAutorizacaoDoacao(idAnuncio: number, mudancaoDoacaoDto: MudancaDoacaoDto) {
    this.reuseModalFinalizarAutorizacaoDoacao.exibirModal(idAnuncio, mudancaoDoacaoDto);
  }

  /**
   * Aciona o componente 'modal-confirmar-doacao', o qual abre uma modal para
   * confirmar doação para o interessado em questão.
   */
  exibirModalConfirmarDoacao(anuncioInteresse: AnuncioInteresse) {
    // this.reuseModalConfirmarDoacao.exibirModal(anuncioInteresse, indice, totalRestanteConfirmarDoacao);
    this.reuseModalConfirmarDoacao.exibirModal(anuncioInteresse);
  }

  /**
   * Evento acionado quando componente 'analisar-interesse' finaliza a análise de um interesse.
   * Lista de interessados deve ser recarregada.
   */
  onAnaliseEfetuadaAnalisarInteresse() {
    this.obterInteressadosParaAutorizarDoacao(this.anuncio);
  }

  /**
   * Clique no botão finalizar aprovação.
   */
  onFinalizarAprovacaoInteresse() {
    this.confirmationService.confirm({
      message: 'Confirma a finalização da aprovação?',
      accept: () => {
        this.confirmarFinalizarAprovacaoInteresse();
      }
    });
  }

  private confirmarFinalizarAprovacaoInteresse(): void {
    this.anuncioInteresseService.finalizarAprovacaoInteresse(this.anuncio.id)
      .subscribe(
        (resultado) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

          // voltar para tela aprovar interesse
          this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_INTERESSES);
        });
  }

  onFinalizarAutorizarDoacao() {
    if (!this.anuncioInteresses || this.anuncioInteresses.length === 0 || !this.anuncioInteresses.some(ai => ai.situacao.id != 3)) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Analise pelo menos um interessado.' });
      return;
    }

    this.confirmationService.confirm({
      message: 'Confirma a finalização de autorização de doação?',
      accept: () => {
        this.confirmarFinalizarAutorizarDoacao();
      }
    });
  }

  private confirmarFinalizarAutorizarDoacao(): void {
    this.anuncioInteresseService.verificarNecessidadeDeJustificativaAutorizarDoacao(this.anuncio.id)
      .subscribe(
        (resultado: MudancaDoacaoDto) => {
          if (!resultado.necessitaMotivoMudancaOrdemDoacao && !resultado.necessitaMotivoMudancaQtdeDoacao) {
            const dto: MudancaDoacaoDto = new MudancaDoacaoDto();
            this.anuncioInteresseService.finalizarAutorizarDoacao(this.anuncio.id, dto)
              .subscribe(
                (res) => {
                  this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: res.mensagem });
                  // voltar para tela aprovar interesse
                  this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_INTERESSES);
                },
                (err) => {
                  console.error(err);
                  // this.messageService.add({ severity: 'error', summary: 'Erro', detail: err });
                });
          } else {
            // Exibir modal com justificativas - Criar modal similar ao modal analisar-interesse.component
            this.exibirModalFinalizarAutorizacaoDoacao(this.anuncio.id, resultado);
          }
          // this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_INTERESSES);
        },
        (err) => {
          console.error(err);
        });
  }

  public bloquearEdicao(): boolean {
    return this.anuncio.mensagemBloqueio != null || this.anuncio.anuncioSituacao.nome === "Aprovado";
  }

  public classAnuncioInteresseSituacao(situacao: AnuncioInteresseSituacao) {
    return "corAnuncioInteresseSituacao" + situacao.id;
  }

}

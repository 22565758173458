import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cpf'})
export class CpfPipe implements PipeTransform {
  constructor() { }

  transform(cpf: string) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }

}

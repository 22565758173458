<p-panel header="Fotos">

  <div *ngIf="carregouDados">
    <div class="ui-g">
      <div class="ui-g-6">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-6">
      </div>
    </div>

    <div *ngIf="fotos.length == 0">
      <p-message severity="warn" text="Nenhuma foto enviada."></p-message>
    </div>

    <p-fieldset legend="Fotos Atuais" *ngIf="fotos.length != 0">
      <p>
        <p-message severity="info"
          text="Você pode alterar a ordem das fotos, a primeira será a foto de capa do anúncio."></p-message>
      </p>

      <p-orderList [value]="fotos" dragdrop="true" dragdropScope="fotos" (onReorder)="onAlterouOrdemFoto()" #listaImagem
        [listStyle]="{'height':'100%','width':'160px'}">
        <ng-template let-foto pTemplate="item">
          <div class="ui-helper-clearfix">
            <img [src]="foto.fotoGaleria" style="max-width:100px;max-height:100px;">
            <div style="float:right;margin:5px 5px 0 0">
              <button pButton type="button" icon="fa fa-trash" class="ui-button-danger"
                (click)="onRemoverFoto(foto)"></button>
            </div>
          </div>
        </ng-template>
      </p-orderList>
      <div class="ui-helper-clearfix fotos" *ngFor="let foto of fotos">
        <img [src]="foto.fotoGaleria" style="max-width:100px;max-height:100px;">
      </div>
    </p-fieldset>

    <p-confirmDialog header="Confirmação" width="425" #cd>
      <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
          class="ui-button-secondary"></button>
        <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
          class="ui-button-success"></button>
      </p-footer>
    </p-confirmDialog>

    <br>

    <p-fieldset legend="Enviar Fotos">
      <p-fileUpload name="fotos" chooseLabel="Procurar" [url]="obterUrlInserirFotos()" multiple="multiple"
        accept="image/*" maxFileSize="3000000" auto="auto" (onUpload)="onUpload($event)"
        (onError)="onErroUpload($event)" (onBeforeSend)="onBeforeSend($event)"></p-fileUpload>
    </p-fieldset>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary" (click)="onProximo()"></button>
    </div>
  </div>
</p-panel>

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiErrorHandlerService } from 'app/anuncio/services/api-error-handler.service';
import { AuthInterceptor } from 'app/shared/services/auth-interceptor.service';
import { AuthService } from 'app/shared/services/auth.service';
import { AppPrimengModule } from '../app-primeng.module';
import { LoginComponent } from './components/login/login.component';
import { MensagensComponent } from './components/mensagens/mensagens.component';
import { AuthGuard } from './services/auth-guard.service';
import { JwtService } from './components/login/jwt.service';
import { LoggedGuardService } from './services/logged-guard.service';
import { RouteService } from './services/route.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FaqComponent } from './components/faq/faq.component';
import { SobreComponent } from './components/sobre/sobre.component';
import { IndexComponent } from './components/index/index.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { StringDTOToDatePipe } from './pipes/string-dto-to-date.pipe';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { CpfPipe } from './pipes/cpf.pipe';
import { AnuncioUtil } from './services/anuncio.util';
import { InfoSlidesComponent } from 'app/shared/components/info-slides/info-slides.component';
import { BsDatepickerModule, CarouselModule } from 'ngx-bootstrap';
import { HeaderComponent } from 'app/shared/components/header/header.component';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { FaleConoscoComponent } from './components/fale-conosco/fale-conosco.component';
import { SistemaEmManutencaoComponent } from './components/sistema-em-manutencao/sistema-em-manutencao.component';

@NgModule({
  imports: [
    AppPrimengModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),

  ],
  exports: [
    MensagensComponent,
    BreadcrumbComponent,
    StringDTOToDatePipe,
    CnpjPipe,
    CpfPipe,
    InfoSlidesComponent,
    HeaderComponent,
    FooterComponent,
    SistemaEmManutencaoComponent,
  ],
  declarations: [
    MensagensComponent,
    BreadcrumbComponent,
    LoginComponent,
    FaqComponent,
    FaleConoscoComponent,
    SobreComponent,
    IndexComponent,
    StringDTOToDatePipe,
    CnpjPipe,
    CpfPipe,
    InfoSlidesComponent,
    HeaderComponent,
    FooterComponent,
    SistemaEmManutencaoComponent,
  ],
  providers: [
    ApiErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthService,
    JwtService,
    AuthGuard,
    LoggedGuardService,
    RouteService,
    StringDTOToDatePipe,
    CnpjPipe,
    CpfPipe,
    AnuncioUtil,
    InfoSlidesComponent,
    HeaderComponent,
    FooterComponent,
    SistemaEmManutencaoComponent,
  ],
})
export class SharedModule { }

<div class="d-flex flex-column justify-content-between">
  <div class="col-12 pb-2">
    <div class="ui-g ui-fluid">
      <div class="ui-g-12">
        <label for="numeroAnuncio">Número do Anúncio</label>
        <tag-input id="numeroAnuncio" name="numeroAnuncio" [(ngModel)]='filtro.numerosAnuncios'
          [separatorKeyCodes]="separadores" [validators]="somenteNumero" placeholder="Número"
          secondaryPlaceholder="Filtrar por Número" addOnBlur="true" theme='bootstrap'></tag-input>
      </div>
      <div class="ui-g-12">
        <label for="anunciante">Anunciante</label>
        <p-multiSelect id="anunciante" name="anunciante" defaultLabel="Todos" [(ngModel)]="filtro.anuncianteTiposS"
          [options]="filtro.anuncianteTiposD" optionLabel="nome" dataKey="id"></p-multiSelect>
      </div>
      <div class="ui-g-12">
        <label for="materialTipo">Tipo</label>
        <p-multiSelect id="materialTipo" name="materialTipo" defaultLabel="Todos" [(ngModel)]="filtro.materialTiposS"
          [options]="filtro.materialTiposD" optionLabel="nome" dataKey="id" resetFilterOnHide="true"
          (onChange)="filtrarCategorias()"></p-multiSelect>
      </div>
      <div class="ui-g-12">
        <label for="materialCategoria">Categoria</label>
        <p-multiSelect id="materialCategoria" name="materialCategoria" defaultLabel="Todos"
          [(ngModel)]="filtro.materialCategoriasS" [options]="filtro.materialCategoriasD" optionLabel="nome"
          dataKey="id" resetFilterOnHide="true"></p-multiSelect>
      </div>
      <div class="ui-g-12" *ngIf="mostraSituacaoMaterial">
        <label for="situacao">Situação do Material</label>
        <p-multiSelect id="situacao" name="situacao" defaultLabel="Todos" [(ngModel)]="filtro.materialSituacoesS"
          [options]="filtro.materialSituacoesD" optionLabel="nome" dataKey="id"></p-multiSelect>
      </div>
      <div class="ui-g-12">
        <label for="uf">UF</label>
        <p-multiSelect id="uf" name="uf" defaultLabel="Todos" [(ngModel)]="filtro.ufsS" [options]="filtro.ufsD"
          optionLabel="sigla" dataKey="id"></p-multiSelect>
      </div>

      <div class="ui-g-12">
        <label for="onus">Ônus ou encargo</label>
        <p-multiSelect id="onus" name="onus" defaultLabel="Todos" [(ngModel)]="filtro.onusS" [options]="filtro.onusD"
          optionLabel="nome" dataKey="id"></p-multiSelect>
      </div>

      <div class="ui-g-12" *ngIf="authService.hasRole(['SEGES'])">
        <label for="situacoes">Situação do Anúncio</label>
        <p-multiSelect id="situacoes" name="situacoes" defaultLabel="Todos" [(ngModel)]="filtro.anuncioSituacoesS"
          [options]="filtro.anuncioSituacoesD" optionLabel="nome" dataKey="id">
        </p-multiSelect>
      </div>
    </div>
  </div>
  <div class="col-12 pt-3 mt-auto d-flex flex-sm-nowrap justify-content-between">
    <p-button label="Limpar" styleClass="br-button mr-2" icon="fa fa-trash" (click)="limparFiltros()">
    </p-button>
    <p-button label="Aplicar" styleClass="br-button primary" icon="fa fa-sliders" (click)="aplicarFiltros()">
    </p-button>
  </div>
</div>

<div class="ui-g-12">
  <p-button label="Incluir Entidade" styleClass="ui-button-success" icon="fa fa-plus-circle" (click)="onNovaEntidade()">
  </p-button>
</div>
<!-- Tabela detalhe de Entidade -->
<p-table #mainTable class="ui-g-12" [columns]="cols" [value]="entidades" [scrollable]="true" [resizableColumns]="true"
  dataKey="id" [paginator]="true" [lazy]="true" [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
  (onPage)="onPage($event)" [(first)]="inicio" [customSort]="true" (onSort)="onSort($event)">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
        <p-sortIcon [field]="col.field" ariaLabel="Sordenação ativa"
          ariaLabelDesc="Clique para ordenar de forma descendente"
          ariaLabelAsc="Clique para ordenar de forma ascendente"></p-sortIcon>
        {{col.header}}
      </th>
      <th style="width: 160px" class="text-center">Ações</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entidade>
    <tr>
      <td class="ui-resizable-column text-left txt-lista">{{!entidade.privado ? entidade.numero : entidade.numero | cnpj}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.nome}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.orgao && entidade.orgao.id != -1 ?
        entidade.orgao.id + ' - ' + entidade.orgao.nome : null}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.entidadeTipo.nome}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.entidadeEsfera.nome}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.utilizaSiads ? 'Sim' : 'Não'}}</td>
      <td class="ui-resizable-column text-left txt-lista">{{entidade.ativo ? 'Sim' : 'Não'}}</td>
      <td style="width: 130px" class="ui-resizable-column text-center">
        <button pButton type="button" title="Visualisar" class="ui-button ui-button-rounded" icon="fa fa-search"
          (click)="visualizarEntidade(entidade)"></button>
        <button *ngIf="!entidade.privado" pButton type="button" title="Alterar" class="ui-button ui-button-rounded" icon="fa fa-edit"
          (click)="alterarEntidade(entidade)"></button>
        <button *ngIf="entidade.ativo && !entidade.privado" pButton type="button" title="Inativar" class="ui-button ui-button-rounded" icon="fa fa-archive"
          (click)="inativarEntidade(entidade)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="8">
        Nenhum registro encontrado.
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog key="modalInativarEntidade" header="Confirmação" width="550" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
      class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
      class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>

import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AuthService } from 'app/shared/services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AuthGuard } from 'app/shared/services/auth-guard.service';
import { Router } from '@angular/router';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-info-slides',
  templateUrl: './info-slides.component.html',
  providers: [
    {provide: CarouselConfig, useValue: {interval: 3000, noPause: false, showIndicators: false}}
  ],
  styleUrls: ['./info-slides.component.scss']
})
export class InfoSlidesComponent implements OnInit {

  interval = 20000;


  constructor(public authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private guardService: AuthGuard,) { }

  ngOnInit() {
  }

  goFale() {
    this.router.navigate(['/fale']);
  }

  public queroDoar() {
    if (!this.authService.isLoggedIn) {
      this.goLogin();
      return;
    }

    if (this.authService.hasRole(['PRIVADO'])) {
      this.onNovoAnuncioPrivado();
    } else {
      this.onNovoAnuncioGoverno();
    }
  }

  private onNovoAnuncioGoverno():void {
	  this.router.navigate(['/'+OrigemEnum.CADASTRO_ANUNCIO_GOVERNO, 'novo']);
  }

  private onNovoAnuncioPrivado():void {
	  this.router.navigate(['/'+OrigemEnum.CADASTRO_ANUNCIO_PRIVADO, 'novo']);
  }

  goLogin() {
    // not logged in so redirect to login page with the return url and return false
    this.guardService.goLogin(window.location.href, window.location.href);
  }

}

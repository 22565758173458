import { Component, OnInit } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { AbstractCadastroAnuncio } from '../cadastro-anuncio-page/abstract-cadastro-anuncio';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { AnuncioSituacaomEnum } from '../../../shared/enum/anuncio-situacao.enum';
import { AppMessageService } from '../../../_services/app.message/app.message.service';

@Component({
  selector: 'reuse-cadastro-anuncio-resumo',
  templateUrl: './cadastro-anuncio-resumo.component.html',
  styleUrls: ['./cadastro-anuncio-resumo.component.scss']
})
export class CadastroAnuncioResumoComponent extends AbstractCadastroAnuncio implements OnInit {

  constructor(
    private anuncioService: AnuncioService,
    private cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    private router: Router,
    public appMessageService: AppMessageService,) {
      super(appMessageService);
    }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  ngOnInit() {
    this.passo = 3;
    this.anuncioService.getAnuncio(this.numeroAnuncio, OrigemEnum.MEUS_ANUNCIOS_GOVERNO)
      .subscribe(
        anuncio => {
          this.anuncio = anuncio;
        });
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onConfirmar(submitBtn: HTMLButtonElement):void {
    submitBtn.disabled = true;
    this.cadastroAnuncioService.publicarAnuncioGoverno(this.anuncio).subscribe(
      (resultado: any) => {
        this.messageService.add({severity:'success', summary:'Sucesso', detail:resultado.mensagem});

        // navega para página de meus anúncio
        this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO]);
      }, () => { submitBtn.disabled = false; });
  }

  /**
   * Só exibe botão publicar se anúncio estiver na situação de Rascunho.
   */
  public exibirBotaoPublicar(): boolean {
    return this.anuncio && this.anuncio.permissoes.podeAlterar;
  }

}

import { Component, OnInit, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { RouteService } from '../../services/route.service';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AppMessage } from 'app/_services/app.message/app.message.model';
import { AppMessageType } from 'app/_services/app.message/app.message.type';
import { AppMessageService } from 'app/_services/app.message/app.message.service';

@Component({
  selector: 'reuse-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  recaptchaKey: string;
  tokenCaptcha: string;
  captchaMocked: boolean;
  loginGovernoHabilitado: boolean = false;
  loginPrivadoHabilitado: boolean = false;
  exibirBotoesTiposLogin: boolean = true;

  @Output() finished = new EventEmitter();

  @ViewChild('captcha') captcha;

  constructor(
    private authService: AuthService,
    private routeService: RouteService,
    private route: ActivatedRoute,
    private router: Router,
    private appMessageService: AppMessageService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    this.criarBreadcrumb();
    this.addScript();
    this.verificaLoginGovBr();
    this.definirTipoLogin();

    this.recaptchaKey = environment.recaptchaKey;
    this.authService.captchaMocked().subscribe(
      resultado => {
        if (resultado) {
          this.captchaMocked = resultado;
        } else {
          this.captchaMocked = false;
        }
      }
    )
  }

  /**
  * Configuração da barra 'Breadcrumb'.
  */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [
      { icon: 'fa fa-user', label: 'Login', command: (onclick) => { this.router.navigate(['/login']) } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  cancelar() {
    this.finished.emit();
    this.goUrl(true);
  }

  showResponse(response: string) {
    this.tokenCaptcha = response;
  }

  loginGoverno(usuario, senha) {
    const tokenCaptcha = this.tokenCaptcha;
    this.tokenCaptcha = undefined;

    if (this.captcha) {
      this.captcha.reset();
    }

    this.authService.login(usuario, senha, tokenCaptcha).subscribe(
      t => {
        if (t) {
          this.notificarLogin();
          this.goUrl(false);
        }
      }
    );
  }

  buscarUrlLoginGovBr() {
    this.authService.buscarUrlLoginGovBr().subscribe(
      (resultado) => {
        this.document.location.href = resultado.mensagem;
      }
    );
  }

  verificaLoginGovBr() {
    const parametrosUrl = this.route.snapshot.queryParams;
    if (parametrosUrl['token']) {
      this.authService.loginGovBr(parametrosUrl['token']);
      this.notificarLogin();
      this.goUrl(false);
    } else if (parametrosUrl['mensagemErroLoginGovBr']) {
      this.authService.erroLoginGovBr(parametrosUrl['mensagemErroLoginGovBr']);
    }
  }

  private goUrl(iCancelButton: boolean) {
    this.finished.emit();

    let url: string;

    if (iCancelButton) {
      // se o usuario clicou em cancelar, entao volta para a url anterior
      url = this.authService.redirectUrlCancel;
    } else {
      url = this.authService.redirectUrlSuccess;
    }

    //Se for undefined, vai para a tela padrao
    if (!url) {
      url = '';
    }

    if (url.indexOf('/login') !== -1) {
      // se a url de retorno for o proprio login, entao retorna para a padrao
      url = '';
    }

    this.routeService.goUrl(url);

    this.authService.redirectUrlSuccess = undefined;
    this.authService.redirectUrlCancel = undefined;
  }

  initRecaptcha() { }

  addScript() {
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=initRecaptcha`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  exibirLoginGoverno(habilitado: boolean) {
    this.loginGovernoHabilitado = habilitado;
    this.loginPrivadoHabilitado = !habilitado;
  }

  exibirLoginPrivado(habilitado: boolean) {
    this.loginPrivadoHabilitado = habilitado;
    this.loginGovernoHabilitado = !habilitado;
  }

  definirTipoLogin() {
    if (this.authService.redirectUrlSuccess && this.authService.redirectUrlSuccess.endsWith('/tenho-interesse')) {
      this.exibirBotoesTiposLogin = false;
    } else {
      this.exibirBotoesTiposLogin = true;
    }
  }

  private notificarLogin() {
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.LOGIN));
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { environment } from 'environments/environment';
import { Orgao } from '../models/orgao.model';

@Injectable({
  providedIn: 'root'
})
export class OrgaoService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService,
  ) { }

  public listarOrgaosAtivos(): Observable<Orgao[]> {
    const url = `${environment.apiUrl}/orgao/listar-orgao-ativo`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

}

import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { AnuncioModule } from './anuncio/anuncio.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MensagemModule } from './mensagem/mensagem.module';
import { SharedModule } from './shared/shared.module';
import { AppMessageService } from './_services/app.message/app.message.service';
import { PesquisaAnuncioPrincipal } from './anuncio/provider/dados.provider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DesbloqueioAnuncioModule } from './desbloqueio-anuncio/desbloqueio-anuncio.module';
import { BloqueioUsuarioPrivadoModule } from './bloqueio-usuario-privado/bloqueio-usuario-privado.module';
import { CadastroUsuarioGovernoModule } from './cadastro-usuario-governo/cadastro-usuario-governo.module';
import { CadastroEntidadeModule } from './cadastro-entidade/cadastro-entidade.module';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AnuncioModule,
    CadastroUsuarioGovernoModule,
    CadastroEntidadeModule,
    MensagemModule,
    DesbloqueioAnuncioModule,
    BloqueioUsuarioPrivadoModule,
    SharedModule,
    AppRoutingModule,
    NgProgressModule,
    NgProgressHttpModule,

  ],
  providers: [AppMessageService, { provide: LOCALE_ID, useValue: 'pt' }, PesquisaAnuncioPrincipal],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametrosMeusInteresses } from '../../models/parametros-meus-interesses.model';
import { MaterialTipo } from '../../models/material-tipo.model';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { Entidade } from '../../models/entidade.model';
import { AnuncioChip } from '../../models/anuncio-chip.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { FiltroMeusAnunciosInteresse } from '../../models/filtro-meus-anuncios-interesse.model';
import { ParametrosMeusAnunciosInteresse } from '../../models/parametros-meus-anuncios-interesse.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-meus-anuncios-interesse-page',
  templateUrl: './meus-anuncios-interesse-page.component.html',
  styleUrls: ['./meus-anuncios-interesse-page.component.scss']
})
export class MeusAnunciosInteressePageComponent extends AnunciosInternoPage implements OnInit {

  filtro: FiltroMeusAnunciosInteresse = new FiltroMeusAnunciosInteresse();

  constructor(private anuncioService: AnuncioService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService) {
    super();
  }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosInteresse = this.anuncioService.obterParametrosMeusAnunciosInteresseUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.popularSeparadoresTagInput();
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    let breadcrumbItems = [
      { icon: 'fa fa-gavel', label: 'Autorizar', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_INTERESSES], { queryParams: { dt: new Date().getTime() } }) } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosMeusAnunciosInteresse) {
    this.anuncioService.consultarFiltrosMeusAnunciosInteresse(parametros)
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {
          this.filtro.materialTipos = resultado.materialTipos;
          this.filtro.materialCategorias = resultado.materialCategorias;
          this.filtro.entidades = resultado.entidades;

          this.definirFiltrosSelecionados(parametros);
        });
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosMeusAnunciosInteresse) {
    this.filtro.pesquisa = parametros.pesquisa;
    this.filtro.pesquisa = parametros.pesquisa;
    const materialTipos: MaterialTipo[] = parametros.materialTipos.split(',').filter(v => v != '').map((nome) => <MaterialTipo>{ nome });
    this.filtro.materialTiposSelecionados = materialTipos;

    const materialCategorias: MaterialCategoria[] = parametros.materialCategorias.split(',').filter(v => v != '').map((nome) => <MaterialCategoria>{ nome });
    this.filtro.materialCategoriasSelecionadas = materialCategorias;

    const periodoInclusao: Date[] = parametros.periodoInclusao.split(',').filter(v => v != '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInclusao.length != 0) {
      this.filtro.periodoInclusao = periodoInclusao;
    }
    const periodoInteresse: Date[] = parametros.periodoInteresse.split(',').filter(v => v != '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInteresse.length != 0) {
      this.filtro.periodoInteresse = periodoInteresse;
    }

    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v != '').map(v => <AnuncioChip>{ value: v, display: v });
    this.filtro.anuncios = anuncios;

    const entidades: Entidade[] = parametros.entidades.split(',').filter(v => v != '').map((nome) => <Entidade>{ nome });
    this.filtro.entidadesSelecionadas = entidades;
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'meus-interesses-lista' monitora a URL para carregar novos anúncios.
   */
  aplicarFiltros() {
    const materialTipos: string = this.filtro.materialTiposSelecionados.map(m => m.nome).join(',');
    const materialCategorias: string = this.filtro.materialCategoriasSelecionadas.map(m => m.nome).join(',');

    let periodoInclusao: string = '';
    if (this.filtro.periodoInclusao != null) {
      periodoInclusao = this.filtro.periodoInclusao.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }
    let periodoInteresse: string = '';
    if (this.filtro.periodoInteresse != null) {
      periodoInteresse = this.filtro.periodoInteresse.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }

    const anuncios: string = this.filtro.anuncios.map(m => m.display).join(',');
    const entidades: string = this.filtro.entidadesSelecionadas.map(m => m.nome).join(',');

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusInteresses = this.anuncioService.obterParametrosMeusInteressesUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_INTERESSES], {
      queryParams: {
        pesquisa: this.filtro.pesquisa,
        materialTipos: materialTipos,
        materialCategorias: materialCategorias,
        periodoInclusao: periodoInclusao,
        periodoInteresse: periodoInteresse,
        anuncios: anuncios,
        entidades: entidades,
        inicio: parametros.inicio,
        ordem: parametros.ordem,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.pesquisa = null;
    this.filtro.materialTiposSelecionados = [];
    this.filtro.materialCategoriasSelecionadas = [];
    this.filtro.periodoInclusao = null;
    this.filtro.periodoInteresse = null;
    this.filtro.anuncios = [];
    this.filtro.entidadesSelecionadas = [];
    this.aplicarFiltros();
  }

  limparPeriodoInclusao(input) {
    this.filtro.periodoInclusao = null;
    this.fecharCalendario(input);
  }

  limparPeriodoInteresse(input) {
    this.filtro.periodoInteresse = null;
    this.fecharCalendario(input);
  }

}

import { AnuncioChip } from '../../anuncio/models/anuncio-chip.model';
import { MensagemSituacao } from './mensagem-situacao.model';

export class FiltroMensagens {
  mensagemSituacoes: MensagemSituacao[];
  mensagemSituacoesSelecionados: MensagemSituacao[];
  periodoLeitura: Date[];
  anuncios: AnuncioChip[];
  titulo: string;





}

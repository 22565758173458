import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ParametrosRepublicarAnunciosPrivado } from 'app/anuncio/models/parametros-republicar-anuncios-privado.model';

@Component({
  selector: 'reuse-republicar-anuncios-privado-lista',
  templateUrl: './republicar-anuncios-privado-lista.component.html',
  styleUrls: ['./republicar-anuncios-privado-lista.component.scss']
})
export class RepublicarAnunciosPrivadoListaComponent extends AnunciosInternoLista implements OnInit {

  REPUBLICAR_ANUNCIOS_PRIVADO = OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
    super();
  }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosRepublicarAnunciosPrivado = this.anuncioService.obterParametrosRepublicarAnunciosPrivadoUrl(params);
        this.ordem = parametros.ordem;

        this.anuncioService.consultarRepublicarAnunciosPrivado(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
   * Consulta os meus anúncios, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosRepublicarAnunciosPrivado = this.anuncioService.obterParametrosRepublicarAnunciosPrivadoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        materialTipos: parametros.materialTipos,
        periodoInclusao: parametros.periodoInclusao,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}

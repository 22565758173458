import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPrimengModule } from '../app-primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { BloqueioUsuarioPrivadoRoutingModule } from './bloqueio-usuario-privado-routing.module';
import { BloqueioUsuarioPrivadoPageComponent } from './components/desbloqueio-anuncio-page/bloqueio-usuario-privado-page.component';
import { BloqueioUsuarioPrivadoService } from './services/bloqueio-usuario-privado.service';
import { BloqueioUsuarioPrivadoListaComponent } from './components/desbloqueio-anuncio-lista/bloqueio-usuario-privado-lista.component';


@NgModule({
  imports: [
    CommonModule,
    AppPrimengModule,
    ReactiveFormsModule,
    BloqueioUsuarioPrivadoRoutingModule,
    TagInputModule,
    SharedModule,
    MomentModule
  ],
  declarations: [
    BloqueioUsuarioPrivadoPageComponent,
    BloqueioUsuarioPrivadoListaComponent
  ],
  providers: [
    BloqueioUsuarioPrivadoService,
  ],
  exports: [
  ],
})
export class BloqueioUsuarioPrivadoModule { }

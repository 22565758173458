<p-panel header="Doação">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">

    <div class="ui-g">
      <div class="ui-g-6">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-6">
        <div class="text-right">
          <p-message severity="info" text="* Campos obrigatórios"></p-message>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('titulo')">Título*</div>
          <input type="text" pInputText formControlName="titulo" style="width:100%" maxlength="255"
            placeholder="Título" />
        </div>
      </div>

      <div class="ui-g-12">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('materialTipo')">Tipo do Material /Serviço*</div>
          <p-dropdown [options]="listaMaterialTipo" formControlName="materialTipo"
            (onChange)="onChangeMaterialCategoria()" #materialTipo optionLabel="nome" dataKey="id"
            [style]="{'width':'100%'}"></p-dropdown>
        </div>

        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('materialCategoria')">Categoria do Material / Serviço*</div>
          <p-dropdown [options]="listaMaterialCategoria" formControlName="materialCategoria" [filter]="true"
            [autoWidth]="false" [style]="{'width':'100%'}" optionLabel="nome" dataKey="id"></p-dropdown>
        </div>
      </div>

      <div class="ui-g-12">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('descricao')">Descrição*</div>
          <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="descricao"
            placeholder="Neste campo deverão ser informadas a descrição detalhada do bem ou serviço, as condições, as especificações e outras características necessárias à definição do objeto da doação."></textarea>
        </div>
      </div>
      <div class="ui-g-12">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('justificativaDoacao')">Justificativa da doação</div>
          <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="justificativaDoacao"
            placeholder="Justificativa da doação"></textarea>
        </div>
      </div>
      <div class="ui-g-12">
        <div [ngClass]="estilo()" class="ui-sm-12" *ngIf="exibeQuantidade()">
          <div [class.erro-form]="verificarCampoInvalido('quantidade')">Quantidade *</div>
          <!--<p-spinner thousandSeparator="." formControlName="quantidade" #quantidade id="quantidade" name="quantidade"
            [min]="1" [max]="999999" appBlockCopyPaste style="width:100%!important"></p-spinner>-->
          <!--<input type="number" maxlength="5" min="1" max="99999" pInputText formControlName="quantidade"
            placeholder="Quantidade"/>-->

          <p-inputMask #quantidade id="quantidade" name="quantidade" maxlength="5" mask="9?9999" name="quantidade" formControlName="quantidade"
            autocomplete="off" type="text">
          </p-inputMask>
        </div>

        <div [ngClass]="estilo()" class="ui-sm-12" *ngIf="exibeUnidadeFornecimento()">
          <div [class.erro-form]="verificarCampoInvalido('unidadeFornecimento')">Fornecimento em*</div>
          <p-dropdown [options]="listaUnidadeFornecimento" formControlName="unidadeFornecimento" optionLabel="nome"
            dataKey="id" [filter]="true" [style]="{'width':'100%'}"></p-dropdown>
        </div>

        <div [ngClass]="estilo()" class="ui-sm-12" *ngIf="exibeValorUnitarioMercado()">
          <div [class.erro-form]="verificarCampoInvalido('valorUnitarioMercado')">Valor Unitário Mercado*</div>
          <input type="text" pInputText currencyMask
            [options]="{prefix: '', thousands: '.', decimal: ',', allowNegative: false}" formControlName="valorUnitarioMercado"
            style="width: 100%" placeholder="Valor Unitário de Mercado" />
        </div>

        <div [ngClass]="estilo()" class="ui-sm-12" *ngIf="exibeDataValidade()">
          <div [class.erro-form]="verificarCampoInvalido('dataValidade')">Data Validade</div>
          <p-calendar id="dataValidade" name="dataValidade" formControlName="dataValidade" dateFormat="dd/mm/yy"
            [showIcon]="true" #dataValidade [style]="{'width':'100%'}" [inputStyle]="{'width':'calc(100% - 2em)'}"
            [minDate]="today">
          </p-calendar>
        </div>
      </div>
      <div class="ui-g-12">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('localizacao')">{{isAnuncioServico()?'Localização do bem ou
            local de prestação do serviço':'Localização do bem ou local de prestação do serviço*'}}</div>
          <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="localizacao" #localizacao
            id="localizacao" name="localizacao"
            placeholder="Localização do bem ou local de prestação do serviço"></textarea>
        </div>
      </div>

      <div class="ui-g-4 ui-sm-12" *ngIf="exibeOnusOuEncargos()">
        <div class="ui-g-12">
          <label class="ui-g-12 noPadding" for="possuiOnusOuEncargos">Possui Ônus ou Encargos*</label>
          <p-radioButton formControlName="possuiOnusOuEncargos" name="possuiOnusOuEncargos" [value]="true" label="Sim"
            (click)="onChangePossuiOnusOuEncargos()"></p-radioButton>
          <p-radioButton formControlName="possuiOnusOuEncargos" name="possuiOnusOuEncargos" [value]="false" label="Não"
            (click)="onChangePossuiOnusOuEncargos()" [style]="{'margin-left': '20px'}">
          </p-radioButton>
        </div>
      </div>

      <div class="ui-g-4 ui-sm-12">
        <div class="ui-g-12">
          <label class="ui-g-12 noPadding" for="possuiDonatario">Possui Donatário*</label>
          <p-radioButton formControlName="possuiDonatario" name="possuiDonatario" [value]="true" label="Sim"
            (click)="onChangePossuiDonatario()"></p-radioButton>
          <p-radioButton formControlName="possuiDonatario" name="possuiDonatario" [value]="false" label="Não"
            (click)="onChangePossuiDonatario()" [style]="{'margin-left': '20px'}">
          </p-radioButton>
        </div>
      </div>

      <div class="ui-g-12" *ngIf="exibeCamposOnusOuEncargos()">
        <div class="ui-g-8 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('descricaoOnusOuEncargos')">Descrição de ônus ou
            encargos*</div>
          <textarea [rows]="5" style="width: 100%" pInputTextarea formControlName="descricaoOnusOuEncargos"
            #descricaoOnusOuEncargos id="descricaoOnusOuEncargos" name="descricaoOnusOuEncargos"
            placeholder="Descrição de ônus ou encargos"></textarea>
        </div>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g-4">
        <div title={{retornaTitulo()}} [class.erro-form]="verificarCampoInvalido('dataExpiracao')">Data Limite de
          manifestação de interesse*</div>
        <p-calendar title={{retornaTitulo()}} id="dataExpiracao" name="dataExpiracao" formControlName="dataExpiracao"
          dateFormat="dd/mm/yy" [showIcon]="true" #dataExpiracao [minDate]="dataMinExpiracao"
          [maxDate]="dataMaxExpiracao" [disabledDays]="[0,6]" [disabledDates]="datasInvalidas" [readonlyInput]="true"
          [style]="{'width':'100%'}" [inputStyle]="{'width':'calc(100% - 2em)'}">
        </p-calendar>
      </div>
    </div>

    <p-fieldset legend="Contato" [style]="{'margin-top':'10px','margin-bottom':'10px'}">
      <div class="ui-g">
        <div class="ui-g-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoNome')">Nome*</div>
          <input type="text" pInputText formControlName="contatoNome" style="width:100%" placeholder="Nome"
            maxlength="255" />
        </div>
      </div>
      <div class="ui-g">
        <div class="ui-g-12 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoEmail')">Email*</div>
          <input type="text" pInputText formControlName="contatoEmail" style="width:100%" placeholder="Email"
            maxlength="255" />
        </div>
      </div>
      <div class="ui-g">
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoTelefone1')">Telefone Principal*</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="contatoTelefone1"
            placeholder="(99) 999999999"></p-inputMask>
        </div>
        <div class="ui-g-4 ui-sm-12">
          <div [class.erro-form]="verificarCampoInvalido('contatoTelefone2')">Telefone Secundário</div>
          <p-inputMask type="text" mask="(99) 99999999?9" formControlName="contatoTelefone2"
            placeholder="(99) 999999999"></p-inputMask>
        </div>
      </div>
    </p-fieldset>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary"></button>
    </div>


  </form>

</p-panel>

import { SelectItem } from 'primeng/components/common/api';
import { Anuncio } from '../../models/anuncio.model';

/**
 * Define comportamentos comuns para os componentes que listam anúncios na parte interna (não público).
 */
export abstract class AnunciosInternoLista {

  inicio = 0;
  tamanhoPagina = 12;
  totalRegistros = 0;
  origem: string;
  anuncios: Anuncio[] = [];

  // ordenações de anúncios disponíveis.
  ordenacoes: SelectItem[];

  // ordem selecionada.
  ordem: number;

  /**
   * Opções de ordenação dos meus anúncios.
   */
  montarOpcoesOrdenacao() {
    this.ordenacoes = [
      {label: 'Mais recente', value: 1},
      {label: 'Mais antigo', value: 2},
      {label: 'Mais visto', value: 3},
      {label: 'Alfabética', value: 4}
    ];
  }

  /**
   * A cada troca de página, consulta 12 novos anúncios no backend.
   *
   * @param event
   */
  paginar(event) {
    this.inicio = event.page * this.tamanhoPagina;
    //após 'inicio' sem alterado, realiza consulta
    this.consultarAnunciosComParametrosUrl();
  }

  /**
   * Realiza a ordenação dos meus anúncios no backend.
   *
   * @param event
   */
  aplicarOrdenacao(event) {
    this.inicio = 0;
    //após 'ordem' ser alterada, realiza consulta
    this.consultarAnunciosComParametrosUrl();
  }

  public abstract consultarAnunciosComParametrosUrl();
}

import { ParametrosConfirmarDoacao } from '../../models/parametros-confirmar-doacao';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { ParametrosMeusAnunciosInteresse } from '../../models/parametros-meus-anuncios-interesse.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-confirmar-doacao-lista',
  templateUrl: './confirmar-doacao-lista.component.html',
  styleUrls: ['./confirmar-doacao-lista.component.scss']
})
export class ConfirmarDoacaoListaComponent extends AnunciosInternoLista implements OnInit {

  CONFIRMAR_DOACAO = OrigemEnum.CONFIRMAR_DOACAO;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;
  tpConsulta: number;
  totalRegistros = 0;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
    super();
  }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios interesse.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosConfirmarDoacao = this.anuncioService.obterParametrosConfirmarDoacao(params);
        this.ordem = parametros.ordem;

        //Limpa os dados
        this.anuncios = [];
        this.totalRegistros = 0;
        this.carregouDados = false;

        if (!this.tpConsulta) {
          //Eh a primeira deteccao de mudanca de url (onInit).
          this.tpConsulta = 1; //Consulta "completa"
          parametros.tpConsulta = this.tpConsulta + '';
        }

        this.anuncioService.consultarAnunciosAptosParaConfirmacaoRecebimentoDoacao(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
   * Consulta os meus anúncios interesse, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosConfirmarDoacao = this.anuncioService.obterParametrosConfirmarDoacao(parametrosUrl);

    this.router.navigate([OrigemEnum.CONFIRMAR_DOACAO], {
      queryParams: {
        periodoPublicacao: parametros.periodoPublicacao,
        anuncioNumeros: parametros.anuncioNumeros,
        anuncioTitulo: parametros.anuncioTitulo,
        idDonatario: parametros.idDonatario,
        anuncioSituacoes: parametros.anuncioSituacoes,
        inicio: this.inicio,
        ordem: this.ordem,
        totalRegistros: this.totalRegistros,
        tpConsulta: 2,  // tipo de consulta, para paginacao, nao tem necessidade de total de registros
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Mensagem } from '../models/mensagem.model';
import { ResultadoPesquisaMensagem } from '../models/resultado-pesquisa-mensagem.model';
import { ParametrosMensagens } from '../models/parametros-mensagens.model';
import { ApiErrorHandlerService } from '../../anuncio/services/api-error-handler.service';

@Injectable()
export class MensagemService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  public lerMensagem(numero: number): Observable<Mensagem> {
    const url = `${environment.apiUrl}/mensagens/${numero}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));

  }

  public marcarNaoLida(numero: number): Observable<Mensagem> {
    const url = `${environment.apiUrl}/mensagens/marcarNaoLida/${numero}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));

  }

  public restaurarLida(numero: number): Observable<Mensagem> {
    const url = `${environment.apiUrl}/mensagens/restaurarLida/${numero}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));

  }

  public excluir(numero: number): Observable<Mensagem> {
    const url = `${environment.apiUrl}/mensagens/excluir/${numero}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));

  }

  public consultarFiltrosMensagens(): Observable<ResultadoPesquisaMensagem> {
    const url = `${environment.apiUrl}/mensagens/filtros`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarMensagens(parametros: ParametrosMensagens): Observable<ResultadoPesquisaMensagem> {
    const url = `${environment.apiUrl}/mensagens/lista`;
    const params = new HttpParams()
      .set('titulo', parametros.titulo)
      .set('mensagemSituacoes', parametros.mensagemSituacoes)
      .set('periodoLeitura', parametros.periodoLeitura)
      .set('anuncios', parametros.anuncios)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, {params})
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public obterParametrosUrl(params): ParametrosMensagens {
    let parametros: ParametrosMensagens = {
      mensagemSituacoes: params['mensagemSituacoes'] || '',
      periodoLeitura: params['periodoLeitura'] || '',
      anuncios: params['anuncios'] || '',
      titulo: params['titulo'] || '',
      inicio: params['inicio'] || 0,
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterContadorMensagem() : Observable<number> {
    const url = `${environment.apiUrl}/mensagens/obterContadorMensagem`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }
}

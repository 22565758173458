<div class="anuncio-list-page d-flex flex-wrap">
  
  <div class="anuncio-filters col-lg-3 p-2 col-md-4 col-sm-12">
    <reuse-anuncio-filter
      [filtro]="filtro"
      (filtroModificado)="onFiltroModificado($event)"
      style="height:100%">
    </reuse-anuncio-filter>
  </div>
  <div class="col-lg-9 col-md-8 col-sm-12 pd-5 pl-3">
    <div *ngIf="totalRegistros > 0">
      <div class="row d-flex pl-3 pr-2 align-items-end">
        
            <p-message severity="info" text="Total registros: {{totalRegistros}}"></p-message>

         
            <p-message severity="warn" text="O usuário deve fazer o login para visualizar as fotos do anúncio." *ngIf="!this.authService.isLoggedIn && totalRegistros > 0"></p-message>
            
          
          <p-dropdown [options]="ordenacoes" [(ngModel)]="ordem" class="ml-auto"
            (onChange)="aplicarOrdenacao($event)"></p-dropdown>
          <!-- <button pButton type="button" title="Compartilhar" 
          class="br-button circle small" 
          icon="fas fa-filter"
          (click)="true"
          *ngIf="true"
          ></button> -->

      </div>
      <div class="col-12 pt-3 d-flex flex-wrap anuncio-list">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 p-2" *ngFor="let anuncio of anuncios">
          <reuse-anuncio-item
            [anuncio]="anuncio" [origem]="ANUNCIOS" >
          </reuse-anuncio-item>
        </div>
      </div>
      
      <p-paginator [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
        (onPageChange)="paginar($event)" [(first)]="inicio"></p-paginator>
    </div>
    <div *ngIf="carregouDados && totalRegistros == 0">
      <p-message severity="warn" text="Nenhum registro encontrado."></p-message>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { CadastroUsuarioGovernoService } from 'app/cadastro-usuario-governo/services/cadastro-usuario-governo.service';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { UtilService } from 'app/shared/services/util.service';
import { Orgao } from 'app/anuncio/models/orgao.model';
import { ConfirmationService } from 'primeng/api';
import { CpfPipe } from 'app/shared/pipes/cpf.pipe';
import { RouteService } from 'app/shared/services/route.service';
import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { RespostaEdit } from 'app/anuncio/models/resposta-edit.model';

@Component({
  selector: 'reuse-cadastro-usuario-governo-edit-page',
  templateUrl: './cadastro-usuario-governo-edit-page.component.html',
  styleUrls: ['./cadastro-usuario-governo-edit-page.component.scss']
})
export class CadastroUsuarioGovernoEditPageComponent implements OnInit {

  menuItemCadastroUsuario = { icon: 'fa fa-newspaper-o', label: 'Cadastrar Usuários de Governo', command: (onclick) => { this.router.navigate([OrigemEnum.LISTAR_CADASTRO_USUARIO_GOVERNO]) } };

  @ViewChild('entidade')
  entidadeDropdown: Dropdown;

  @ViewChild('orgao')
  orgaoDropdown: Dropdown;

  usuarioNaoEncontrado: boolean = false;

  //usuario carregado a partir do 'idUsuario'
  usuario: Usuario;

  // guarda o id do usuario caso seja consulta ou edicao
  idUsuario: number = -1;

  // utilizado para mensagem de validacao ao salvar
  isCpfCadastrado: boolean = false;

  // verifica qual tipo da transacao
  // I - INCLUSÃO
  // A - ALTERAÇÃO
  // V - VISUALIZACAO
  tipoOperacao: string;

  //formulário
  formulario: FormGroup;

  //lista de entidades vinculadas ao usuario privado para exibir no comboboxdia
  listaEntidade: Entidade[] = [];

  //lista de orgaos disponíveis para exibir no combobox
  listaOrgao: Orgao[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cadastroUsuarioGovernoService: CadastroUsuarioGovernoService,
    private messageService: MessageService,
    private utilService: UtilService,
    private appMessageService: AppMessageService,
    private confirmationService: ConfirmationService,
    private routeService: RouteService,
  ) { }

  ngOnInit() {
    this.monitorarParametros();
  }

  get usuarioLogado(): Usuario {
    return this.authService.usuario;
  }

  private monitorarParametros(): void {
    this.route.paramMap.subscribe(params => {
      const numero = params.get('numero');

      if (numero == 'novo') {
        this.criarBreadcrumb();
        this.obterCadastroUsuario();
        this.tipoOperacao = 'I'; // INCLUSÃO
      } else {
        //verifica se 'numero' é valor válido
        if (isNaN(+numero)) {
          //número inválido
          this.usuarioNaoEncontrado = true;
        } else {
          if (this.routeService.isUrl(OrigemEnum.ALTERAR_CADASTRO_USUARIO_GOVERNO)) {
            this.tipoOperacao = 'A'; // ALTERAÇÃO
          } else if (this.routeService.isUrl(OrigemEnum.VISUALIZAR_CADASTRO_USUARIO_GOVERNO)) {
            this.tipoOperacao = 'V'; // VISUALIZACAO
          } else {
            this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Opção inválida, favor realize a operação novamente.' });
            this.navegarConsultarUsuario();
          }
          this.idUsuario = parseInt(numero);
          this.obterCadastroUsuario();
        }
      }
    });
  }

  /**
 * Configuração da barra 'Breadcrumb' para Alteração do Anúncio.
 */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [this.menuItemCadastroUsuario, { label: this.tituloFormulario() }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  private obterCadastroUsuario(): void {
    this.cadastroUsuarioGovernoService.obterCadastroUsuario(this.idUsuario, this.tipoOperacao)
      .subscribe(
        (dados) => {
          this.usuario = dados.usuario;
          if (this.usuario) {
            this.criarFormulario();
            if (this.tipoOperacao != 'V') {

              // preeche a lista com os orgaos
              for (let orgao of dados.listaOrgao) {
                orgao.idNome = orgao.id + ' - ' + orgao.nome;
                this.listaOrgao.push(orgao);
              }

              //adiciona opção 'Selecione' (vazia)
              this.listaOrgao.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });

              this.carregarEntidade();
            }
            this.criarBreadcrumb();

            this.carregouDados = true;
            window.scrollTo(0, 0);
          } else {
            //não existe anúncio para ALTERAÇÃO
            this.usuarioNaoEncontrado = true;
          }
        }
      );
  }

  private isEntidade(entidade: Entidade): boolean {
    if (!entidade || !entidade.id || entidade.id == -1) {
      return false;
    }
    return true;
  }

  /**
   * Cria o formulário para inserção ou ALTERAÇÃO.
   */
  private criarFormulario() {

    const entidade: Entidade = (this.isEntidade(this.usuario.entidade) ? this.usuario.entidade : null);
    const orgao: Orgao = (this.isEntidade(entidade) ? (entidade.orgao ? entidade.orgao : null) : null);
    const cpf: string = this.usuario.cpf;
    const nome: string = this.usuario.nome;
    const emailGoverno: string = this.usuario.emailGoverno;
    const telefone1Governo: string = this.usuario.telefone1Governo;

    this.formulario = this.formBuilder.group({
      entidade: [entidade, [Validators.required, this.utilService.validarIdEntidade]],
      orgao: [orgao, [Validators.required, this.utilService.validarIdEntidade]],
      cpf: [cpf, [Validators.required, Validators.maxLength(14)]],
      nome: [nome, [Validators.required, Validators.maxLength(255)]],
      emailGoverno: [emailGoverno, [Validators.required, Validators.email, Validators.maxLength(255)]],
      telefone1Governo: [telefone1Governo, [Validators.required, this.utilService.validarTelefone, Validators.maxLength(20)]],
    });

    // bloqueia o formulario de acordo com o tipo de operacao
    if (this.tipoOperacao == 'I') {
      this.bloqueioFormularioInserir();
    } else if (this.tipoOperacao == 'A') {
      this.bloqueioFormularioEdicao();
    } else if (this.tipoOperacao == 'V') {
      this.bloqueioFormularioConsultar();
    }
  }

  private bloqueioFormularioConsultar() {
    this.formulario.get('cpf').disable();
    this.bloqueioFormulario();
  }

  private bloqueioFormularioInserir() {
    this.bloqueioFormulario();
  }

  private bloqueioFormularioEdicao() {
    this.formulario.get('cpf').disable();
  }

  private bloqueioFormulario() {
    this.formulario.get('entidade').disable();
    this.formulario.get('orgao').disable();
    this.formulario.get('nome').disable();
    this.formulario.get('emailGoverno').disable();
    this.formulario.get('telefone1Governo').disable();
  }

  private desbloqueioFormulario() {
    this.formulario.get('orgao').enable();
    this.formulario.get('nome').enable();
    this.formulario.get('emailGoverno').enable();
    this.formulario.get('telefone1Governo').enable();

    //Caso nao encontre nenhum orgao na busca com filtro apresenta a mensagem abaixo
    this.orgaoDropdown.emptyFilterMessage = 'Nenhum orgão encontrado.';
  }

  public onChangeCpf(): void {
    if (this.tipoOperacao == 'I') {
      // bloqueia o formulario sempre que realizar uma nova troca de cpf
      this.bloqueioFormularioInserir();
      this.isCpfCadastrado = false;
      const cpf: any = this.formulario.get('cpf').value;
      if ((!cpf || !this.utilService.validaCpf(cpf))) {
        this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'CPF inválido.' });
      } else {
        this.cadastroUsuarioGovernoService.obterUsuarioExcluidoPorCpf(cpf)
          .subscribe(
            (usuario) => {
              this.usuario = usuario;
              if (!this.usuario) {
                this.desbloqueioFormulario();
              } else if (this.usuario.excluido) {
                this.confirmationService.confirm({
                  key: `modalInclusaoUsuarioStatusExcluido`,
                  message: 'Usuário já existe com o status excluído. Deseja realizar a alteração? <br> Nome: ' + usuario.nome + ' <br> CPF: ' + new CpfPipe().transform(usuario.cpf) + '?',
                  header: 'Confirmação',
                  icon: 'pi pi-info-circle',
                  accept: () => {
                    this.idUsuario = usuario.id;
                    this.desbloqueioFormulario();
                    this.bloqueioFormularioEdicao();
                    this.obterCadastroUsuario();
                    this.tipoOperacao = 'A'; // ALTERAÇÃO
                  }
                });
              } else if (!this.usuario.governo) { // caso usuario esteja incluido como privado
                this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuário já cadastrado como usuário privado.' });
                this.idUsuario = usuario.id;
                this.desbloqueioFormulario();
                this.bloqueioFormularioEdicao();
                this.obterCadastroUsuario();
                this.tipoOperacao = 'A'; // ALTERAÇÃO
              } else if (!this.usuario.excluido) {
                this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'CPF já cadastrado.' });
                this.isCpfCadastrado = true;
              }
            }
          );
      }
    }
  }

  /**
  * onChange para para dropdown 'Entidade'.
  */
  public onChangeEntidade(): void {
    this.formulario.get('entidade').setValue({ 'id': null });
    // limpa o filtro do drowpdown da entidade, caso o orgao seja modificado.
    this.entidadeDropdown.filterValue = '';
    // limpa o filtro do drowpdown do orgão, caso o orgao seja modificado.
    this.orgaoDropdown.filterValue = '';
    //Caso nao encontre nenhuma entidade na busca com filtro apresenta a mensagem abaixo
    this.entidadeDropdown.emptyFilterMessage = 'Nenhuma entidade encontrada.';
    this.carregarEntidade();
  }

  /**
   * Carrega a opção de 'Entidade' a partir do 'Orgao' selecionado.
   */
  private carregarEntidade(): void {
    const orgaoSelecionado: any = this.formulario.get('orgao').value;
    if (orgaoSelecionado == null || orgaoSelecionado.id == null) {
      //caso não exista 'orgao' selecionado, remove as opção de 'entidades'
      this.listaEntidade = [];
      this.formulario.get('entidade').disable();
      this.formulario.get('entidade').setValue(<Entidade>{ id: null, nomeNumero: 'Selecione' });
    } else {
      this.cadastroUsuarioGovernoService.obterEntidadePorOrgao(orgaoSelecionado.id)
        .subscribe(
          (entidades) => {
            this.listaEntidade = [];
            for (let entidade of entidades) {
              entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
              this.listaEntidade.push(entidade);
            }
            this.listaEntidade.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
            this.formulario.get('entidade').enable();
          }
        );
    }
  }

  /**
 * Clique botão 'Confirmar'.
 */
  public onSubmit(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);
    if (!this.formulario.get('cpf').value || !this.utilService.validaCpf(this.formulario.get('cpf').value)) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'CPF inválido.' });
    } else if (this.tipoOperacao == 'I' && this.isCpfCadastrado) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'CPF já cadastrado.' });
    } else if (!this.formulario.get('nome').valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Nome inválido.' });
    } else if (!this.formulario.get('emailGoverno').valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Formato de e-mail inválido.' });
    } else if (!this.formulario.get('telefone1Governo').valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Telefone inválido.' });
    } else if (!this.formulario.get('entidade').valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Entidade inválida.' });
    } else if (this.formulario.valid) {
      // verifica se é uma inserção ou alteração
      document.getElementById('submitBtn').setAttribute('disabled', 'true');
      if (this.tipoOperacao == 'I') {
        this.cadastroUsuarioGovernoService.incluirUsuario(this.formulario).subscribe(
          (resultado: RespostaEdit) => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
            this.navegarConsultarUsuario();
          }, () => { document.getElementById('submitBtn').removeAttribute('disabled'); });
      } else if (this.tipoOperacao == 'A') {
        this.cadastroUsuarioGovernoService.alterarUsuario(this.formulario, this.usuario).subscribe(
          (resultado: RespostaEdit) => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
            this.navegarConsultarUsuario();
          }, () => { document.getElementById('submitBtn').removeAttribute('disabled'); });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  /**
 * Clique botão 'Cancelar'.
 */
  public onCancelar(): void {
    // navega para página de anterior
    this.onVoltar();
  }

  /**
   * Clique botão 'Voltar'.
   */
  public onVoltar(): void {
    // navega para página anterior
    this.routeService.voltar(OrigemEnum.LISTAR_CADASTRO_USUARIO_GOVERNO);
  }

  /**
   * Clique botão 'Cancelar'.
   */
  public navegarConsultarUsuario(): void {
    // navega para página anterior
    this.onVoltar();
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  verificarCampoInvalido(nomeCampo: string) {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  public podeAlterarDados(): boolean {
    return !(this.tipoOperacao == 'V');
  }

  public isInclusao(): boolean {
    return this.tipoOperacao == 'I';
  }

  public tituloFormulario(): string {
    if (this.tipoOperacao == 'I') {
      return 'Inserir Usuário';
    } else if (this.tipoOperacao == 'A') {
      return 'Alterar Usuário';
    } else if (this.tipoOperacao == 'V') {
      return 'Visualizar Usuário';
    }
  }

  public retornaLstPerfis(): String {
    if (this.usuario && this.usuario.lstPerfis && this.usuario.lstPerfis.length > 0) {
      let perfis = '';
      this.usuario.lstPerfis.forEach(perfil => {
        perfis = perfis + perfil.nome + ' - ';
      });
      return perfis.substring(0, perfis.length - 3);
    } else {
      return 'Sem perfil cadastrado.'
    }
  }
}

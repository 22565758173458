import { FormGroup, AbstractControl, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/components/common/messageservice";
import { BloqueioRegistro } from "../../anuncio/models/bloqueio-registro.model";
import { Field } from "app/anuncio/models/field";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private messageService: MessageService,
  ) { }

  /**
   * Marca todos campos como alterado, para caso sejam inválidos, exibir aviso de
   * campo inválido no html.
   *
   * @param formGroup
   */
  public marcarCamposComoAlterado(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      // marca campo como alterado
      controle.markAsDirty();
      if (controle instanceof FormGroup) {
        this.marcarCamposComoAlterado(controle);
      }
    });
  }

  /**
   * Valida se o número do telefone possui no mínimo 10 dígitos.
   *
   * @param control
   */
  public validarTelefone(control: AbstractControl): any {
    const valor = control.value;
    if (valor) {
      const valorNumerico = valor.match(/\d+/g);
      if (valorNumerico && valorNumerico.join('').length < 10) {
        return { telefoneValido: false };
      }
    }
  }

  /**
 * Valida se o cep do endereco possui no mínimo 8 dígitos.
 *
 * @param control
 */
  public validarCep(control: AbstractControl): any {
    const valor = control.value;
    if (valor) {
      const valorNumerico = valor.match(/\d+/g);
      if (valorNumerico && valorNumerico.join('').length < 8) {
        return { cep: false };
      }
    }
  }

  public validarIdEntidade(control: AbstractControl): any {
    if (!control.value || !control.value.id) {
      return { idValido: false };
    }
  }

  public validarNumerico(control: AbstractControl): any {
    if (control.value && !UtilService.isNumeric(control.value)) {
      return { numerico: false };
    }
  }

  public validarIdEntidadePrivado(control: AbstractControl): any {
    if (!control.value || !control.value.id || control.value.id == -1) {
      return { idValido: false };
    }
  }

  public validarCheckboxPublicacaoAnuncioPrivado(control: AbstractControl): any {
    if (!control.value || !control.value) {
      return { idValido: false };
    }
  }

  public verificarRegistroBloqueado(registro: BloqueioRegistro, isEdit: boolean): boolean {
    if (registro.mensagemBloqueio != null) {
      this.messageService.add({
        severity: 'error',
        summary: 'Atenção',
        detail: (isEdit ? 'Edição' : 'Exclusão') + ' não permitida. ' + registro.mensagemBloqueio
      });
      return true;
    }

    return false;
  }

  public isNumber(valor: string) {
    if (valor == '0') {
      return false;
    }
    const regex = new RegExp('[0-9]{11}');
    return !regex.test(valor);
  }

  public static isNumeric(valor: string) {
    const regex = new RegExp('[0-9]{11}');
    return !regex.test(valor);
  }

  /**
    * Valida se o CPF é valido. Deve-se ser informado o cpf sem máscara.
   */
  public validaCpf(cpf: string): boolean {
    // retira a mascara
    cpf = cpf.replace(/\D/g, '');

    let soma: number = 0;
    let resto: number;
    //let valido: boolean;
    const regex = new RegExp('[0-9]{11}');

    if (cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      !regex.test(cpf)
    ) {
      return false;
    } else {
      for (let i = 1; i <= 9; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) {
        resto = 0;
      }
      if (resto != parseInt(cpf.substring(9, 10))) {
        return false;
      }
      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) {
        resto = 0;
      }

      if (resto != parseInt(cpf.substring(10, 11))) {
        return false;
      }

      return true;
    }
  }

  public validaCnpj(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      let n = Number(numeros.charAt(tamanho - i));
      soma += n * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    let strResultado = resultado || '';
    if (strResultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      let n = Number(numeros.charAt(tamanho - i));
      soma += n * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    strResultado = resultado || '';
    if (strResultado != digitos.charAt(1))
      return false;

    return true;
  }

  public removerNaoNumerico(valor: string) {
    return valor.replace(/[^0-9]/g, '');
  }

  public numberOnlyEvent(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public bloquearControlesFormulario(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].disable();
    });
  }

  public desbloquearControlesFormulario(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].enable();
    });
  }

  public validarFormulario(form: FormGroup, fields: Field[]): boolean {
    for (let field of fields) {
      if (!form.controls[field.name].valid) {
        const vogal: string = field.gender === 'M' ? 'o' : 'a';
        this.messageService.add({ severity: 'error', summary: 'Validação', detail: field.label + ' inválid' + vogal + '.' });
        return false;
      }
    }

    if (!form.valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
      return false;
    }

    return true;
  }

}

<p-dialog modal=true header="Autorizar Doação" [(visible)]="exibirModalAnalisarInteresse" [width]="600">

  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="exibirModalAnalisarInteresse">

    <div class="ui-g">
      <div class="ui-g-6">
        <div>Interessado:</div>
        {{anuncioInteresse.entidade.numero}} - {{anuncioInteresse.entidade.nome}}
      </div>
      <div class="ui-g-6">
        <div>Quantidade Solicitada:</div>
        {{anuncioInteresse.quantidadeInteresse}}
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12">
        <div>Observação Interesse:</div>
        {{anuncioInteresse.observacaoInteresse}}
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-4">
        <div [class.erro-form]="verificarCampoInvalido('quantidadeAvaliacao')">Quantidade Autorizada:</div>
        <p-spinner size="5" thousandSeparator="." formControlName="quantidadeAvaliacao" [min]="0" appBlockCopyPaste>
        </p-spinner>
      </div>
      <div class="ui-g-8">
        <div [class.erro-form]="verificarCampoInvalido('observacaoAvaliacao')">Observação Doação:</div>
        <textarea [rows]="2" style="width: 100%" pInputTextarea formControlName="observacaoAvaliacao"
          placeholder="Observações"></textarea>
      </div>
    </div>


    <p-footer>
      <div class="buttons text-right">
        <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"></button>
        <button type="submit" pButton label="Confirmar" class="ui-button-success" [disabled]="form.invalid"></button>
      </div>
    </p-footer>

  </form>

</p-dialog>

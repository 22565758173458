import { ResultadoPesquisaRelatorioAnuncio } from './../models/resultado-pesquisa-relatorio-anuncio.model';
import { ParametrosRelatorioAnuncios } from './../models/parametros-relatorio-anuncios';
import { ParametrosConfirmarDoacao } from '../models/parametros-confirmar-doacao';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ResultadoPesquisaInternaAnuncio } from 'app/anuncio/models/resultado-pesquisa-anuncio.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AuthService } from 'app/shared/services/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnuncioDadosBasicos } from '../models/anuncio-dados-basicos.model';
import { AnuncioFoto } from '../models/anuncio-foto.model';
import { Anuncio } from '../models/anuncio.model';
import { ParametrosAnuncios } from '../models/parametros-anuncios.model';
import { ParametrosAutorizarPublicacao } from '../models/parametros-autorizar-publicacao.model';
import { ParametrosConsultarAnuncioPrivado } from '../models/parametros-consultar-anuncio-privado.model';
import { ParametrosMeusAnunciosGoverno } from '../models/parametros-meus-anuncios-governo.model';
import { ParametrosMeusAnunciosInteresse } from '../models/parametros-meus-anuncios-interesse.model';
import { ParametrosMeusAnunciosPrivado } from '../models/parametros-meus-anuncios-privado.model';
import { ParametrosMeusInteresses } from '../models/parametros-meus-interesses.model';
import { ParametrosRepublicarAnunciosPrivado } from '../models/parametros-republicar-anuncios-privado.model';
import { ResultadoPesquisaAnuncioAutorizar } from '../models/resultado-pesquisa-anuncio-autorizar.model';
import { ResultadoPesquisaAnuncioConsultarPrivado } from '../models/resultado-pesquisa-anuncio-consultar-privado.model';
import { ApiErrorHandlerService } from './api-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AnuncioService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService,
    private authService: AuthService,
  ) { }

  public getAnuncio(id: number, origem: string): Observable<Anuncio> {
    const url = `${environment.apiUrl}/${origem}/${id}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  public consultarAnunciosRelatorio(filtroAnuncio: ParametrosRelatorioAnuncios): Observable<ResultadoPesquisaRelatorioAnuncio> {
    const url = `${environment.apiUrl}/anuncios/${OrigemEnum.RELATORIO_ANUNCIO}`;
    const params = new HttpParams()
      .set('anuncioCodigos', filtroAnuncio.anuncioCodigos)
      .set('anuncianteTipos', filtroAnuncio.anuncianteTipos)
      .set('anuncioSituacoes', filtroAnuncio.anuncioSituacoes)
      .set('materialTipos', filtroAnuncio.materialTipos)
      .set('materialCategorias', filtroAnuncio.materialCategorias)
      .set('periodoInclusao', filtroAnuncio.periodoInclusao)
      .set('periodoAutorizacaoDoacao', filtroAnuncio.periodoAutorizacaoDoacao)
      .set('idOrgaoDonatario', filtroAnuncio.idOrgaoDonatario)
      .set('idDonatario', filtroAnuncio.idDonatario)
      .set('inicio', filtroAnuncio.inicio)
      .set('sortField', filtroAnuncio.sortField)
      .set('sortOrder', filtroAnuncio.sortOrder)
      .set('tpConsulta', filtroAnuncio.tpConsulta);
    return this.http.get(url, { params }).pipe(catchError(this.errorHandler.handle()));
  }

  public obterFotoCapa(anuncio: Anuncio): Observable<AnuncioFoto> {
    const url = `${environment.apiUrl}/${OrigemEnum.ANUNCIOS}/foto-capa/${anuncio.id}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  public list(pesquisa: string, anuncianteTipos: string, materialTipos: string, materialCategorias: string,
    materialSituacoes: string, ufs: string, inicio: number, ordem: number, tpConsulta: number, onus: string,
    anuncioSituacoes: string, numerosAnuncios: string): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.ANUNCIOS}/listar`;
    const params = new HttpParams()
      .set('pesquisa', pesquisa)
      .set('anuncianteTipos', anuncianteTipos)
      .set('materialTipos', materialTipos)
      .set('materialCategorias', materialCategorias)
      .set('materialSituacoes', materialSituacoes)
      .set('ufs', ufs)
      .set('possuiOnusOuEncargos', onus)
      .set('numerosAnuncios', numerosAnuncios)
      .set('inicio', String(inicio))
      .set('ordem', String(ordem))
      .set('tpConsulta', String(tpConsulta))
      .set('anuncioSituacoes', String(anuncioSituacoes));
    return this.http.get(url, { params }).pipe(catchError(this.errorHandler.handle()));
  }

  public consultarMeusAnunciosGoverno(parametros: ParametrosMeusAnunciosGoverno): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_GOVERNO}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('materialTipos', parametros.materialTipos)
      .set('materialCategorias', parametros.materialCategorias)
      .set('materialSituacoes', parametros.materialSituacoes)
      .set('ufs', parametros.ufs)
      .set('possuiOnusOuEncargos', parametros.onus)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('periodoExpiracao', parametros.periodoExpiracao)
      .set('anuncios', parametros.anuncios)
      .set('entidades', parametros.entidades)
      .set('analisadoPor', parametros.analisadoPor)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarMeusAnunciosPrivado(parametros: ParametrosMeusAnunciosPrivado): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_PRIVADO}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('materialTipos', parametros.materialTipos)
      .set('anuncioSituacoes', parametros.anuncioSituacoes)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('anuncios', parametros.anuncios)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarRepublicarAnunciosPrivado(parametros: ParametrosRepublicarAnunciosPrivado): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('materialTipos', parametros.materialTipos)
      .set('anuncioSituacoes', parametros.anuncioSituacoes)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('anuncios', parametros.anuncios)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }


  public consultarMeusInteresses(parametros: ParametrosMeusInteresses): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_INTERESSES}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('materialTipos', parametros.materialTipos)
      .set('materialCategorias', parametros.materialCategorias)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('periodoInteresse', parametros.periodoInteresse)
      .set('anuncios', parametros.anuncios)
      .set('entidades', parametros.entidades)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarMeusAnunciosInteresse(parametros: ParametrosMeusAnunciosInteresse): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_INTERESSES}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('materialTipos', parametros.materialTipos)
      .set('materialCategorias', parametros.materialCategorias)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('periodoInteresse', parametros.periodoInteresse)
      .set('anuncios', parametros.anuncios)
      .set('entidades', parametros.entidades)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarAnunciosAptosParaConfirmacaoRecebimentoDoacao(parametros:
    ParametrosConfirmarDoacao): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.CONFIRMAR_DOACAO}/listagem`;
    const params = new HttpParams()
      .set('periodoPublicacao', parametros.periodoPublicacao)
      .set('anuncioNumeros', parametros.anuncioNumeros)
      .set('anuncioTitulo', parametros.anuncioTitulo)
      .set('idDonatario', parametros.idDonatario)
      .set('anuncioSituacoes', parametros.anuncioSituacoes)
      .set('inicio', parametros.inicio)
      .set('ordem', parametros.ordem.toString())
      .set('totalRegistros', parametros.totalRegistros ? parametros.totalRegistros.toString() : '0')
      .set('tpConsulta', parametros.tpConsulta);

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarAutorizarPublicacao(parametros: ParametrosAutorizarPublicacao): Observable<ResultadoPesquisaAnuncioAutorizar> {
    const url = `${environment.apiUrl}/${OrigemEnum.AUTORIZAR_PUBLICACAO}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('anuncioSituacoes', parametros.anuncioSituacoes)
      .set('materialTipos', parametros.materialTipos)
      .set('materialCategorias', parametros.materialCategorias)
      .set('materialSituacoes', parametros.materialSituacoes)
      .set('ufs', parametros.ufs)
      .set('possuiOnusOuEncargos', parametros.onus)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('periodoExpiracao', parametros.periodoExpiracao)
      .set('anuncios', parametros.anuncios)
      .set('entidades', parametros.entidades)
      .set('analisadoPor', parametros.analisadoPor)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public consultarAnunciosPrivados(parametros: ParametrosConsultarAnuncioPrivado): Observable<ResultadoPesquisaAnuncioConsultarPrivado> {
    const url = `${environment.apiUrl}/${OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO}/listagem`;
    const params = new HttpParams()
      .set('pesquisa', parametros.pesquisa)
      .set('anuncioSituacoes', parametros.anuncioSituacoes)
      .set('materialTipos', parametros.materialTipos)
      .set('periodoInclusao', parametros.periodoInclusao)
      .set('possuiOnusOuEncargos', parametros.possuiOnusOuEncargos)
      .set('anuncios', parametros.anuncios)
      .set('inicio', String(parametros.inicio))
      .set('ordem', String(parametros.ordem));

    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle()));
  }


  public consultarFiltrosAutorizarPublicacao(parametros: ParametrosAutorizarPublicacao): Observable<ResultadoPesquisaAnuncioAutorizar> {
    const url = `${environment.apiUrl}/${OrigemEnum.AUTORIZAR_PUBLICACAO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosConsultarAnuncioPrivado(parametros: ParametrosConsultarAnuncioPrivado):
    Observable<ResultadoPesquisaAnuncioConsultarPrivado> {
    const url = `${environment.apiUrl}/${OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosMeusAnunciosGoverno(parametros: ParametrosMeusAnunciosGoverno): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_GOVERNO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosMeusAnunciosPrivado(parametros: ParametrosMeusAnunciosPrivado): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_PRIVADO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosRepublicarAnunciosPrivado(parametros: ParametrosRepublicarAnunciosPrivado):
    Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosMeusInteresses(parametros: ParametrosMeusInteresses): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_INTERESSES}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosMeusAnunciosInteresse(parametros: ParametrosMeusAnunciosInteresse): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.MEUS_ANUNCIOS_INTERESSES}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosConfirmarDoacao(): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/${OrigemEnum.CONFIRMAR_DOACAO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public consultarFiltrosRelatorioDeAnuncios(): Observable<ResultadoPesquisaInternaAnuncio> {
    const url = `${environment.apiUrl}/anuncios/${OrigemEnum.RELATORIO_ANUNCIO}/filtros`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterParametrosMeusAnunciosGovernoUrl(params): ParametrosMeusAnunciosGoverno {
    const parametros: ParametrosMeusAnunciosGoverno = {
      pesquisa: params['pesquisa'] || '',
      anuncianteTipos: params['anuncianteTipos'] || '',
      materialTipos: params['materialTipos'] || '',
      materialCategorias: params['materialCategorias'] || '',
      materialSituacoes: params['materialSituacoes'] || '',
      ufs: params['ufs'] || '',
      onus: params['onus'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      periodoExpiracao: params['periodoExpiracao'] || '',
      anuncios: params['anuncios'] || '',
      entidades: params['entidades'] || '',
      analisadoPor: params['analisadoPor'] || '',
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterParametrosRelatorioAnuncios(params): ParametrosRelatorioAnuncios {
    const parametros: ParametrosRelatorioAnuncios = {
      anuncioCodigos: params['anuncioCodigos'] || '',
      anuncianteTipos: params['anuncianteTipos'] || '',
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      materialTipos: params['materialTipos'] || '',
      materialCategorias: params['materialCategorias'] || '',
      periodoInclusao: params['periodoInclusao'] || '',
      periodoAutorizacaoDoacao: params['periodoAutorizacaoDoacao'] || '',
      idOrgaoDonatario: params['idOrgaoDonatario'] || 0,
      idDonatario: params['idDonatario'] || 0,
      inicio: params['inicio'] || '',
      sortField: params['sortField'] || '',
      sortOrder: params['sortOrder'] || '',
      totalRegistros: params['totalRegistros'] || 0,
      tpConsulta: params['tpConsulta'] || 1
    };
    return parametros;
  }

  public obterParametrosMeusAnunciosPrivadoUrl(params): ParametrosMeusAnunciosPrivado {
    const parametros: ParametrosMeusAnunciosPrivado = {
      pesquisa: params['pesquisa'] || '',
      materialTipos: params['materialTipos'] || '',
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      anuncios: params['anuncios'] || '',
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterParametrosRepublicarAnunciosPrivadoUrl(params): ParametrosRepublicarAnunciosPrivado {
    const parametros: ParametrosRepublicarAnunciosPrivado = {
      pesquisa: params['pesquisa'] || '',
      materialTipos: params['materialTipos'] || '',
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      anuncios: params['anuncios'] || '',
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterParametrosAutorizarPublicacaoUrl(params): ParametrosAutorizarPublicacao {
    const parametros: ParametrosAutorizarPublicacao = {
      pesquisa: params['pesquisa'] || '',
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      anuncianteTipos: params['anuncianteTipos'] || '',
      materialTipos: params['materialTipos'] || '',
      materialCategorias: params['materialCategorias'] || '',
      materialSituacoes: params['materialSituacoes'] || '',
      ufs: params['ufs'] || '',
      onus: params['onus'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      periodoExpiracao: params['periodoExpiracao'] || '',
      anuncios: params['anuncios'] || '',
      entidades: params['entidades'] || '',
      analisadoPor: params['analisadoPor'] || '',
      ordem: params['ordem'] || 2
    };
    return parametros;
  }

  public obterParametrosConsultarAnuncioPrivadoUrl(params): ParametrosConsultarAnuncioPrivado {
    const parametros: ParametrosConsultarAnuncioPrivado = {
      pesquisa: params['pesquisa'] || '',
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      anuncianteTipos: params['anuncianteTipos'] || '',
      materialTipos: params['materialTipos'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      possuiOnusOuEncargos: params['possuiOnusOuEncargos'] || '',
      anuncios: params['anuncios'] || '',
      ordem: params['ordem'] || 2
    };
    return parametros;
  }

  public obterParametrosMeusInteressesUrl(params): ParametrosMeusInteresses {
    const parametros: ParametrosMeusInteresses = {
      pesquisa: params['pesquisa'] || '',
      materialTipos: params['materialTipos'] || '',
      materialCategorias: params['materialCategorias'] || '',
      anuncioInteresseSituacoes: params['anuncioInteresseSituacoes'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      periodoInteresse: params['periodoAprovacao'] || '',
      anuncios: params['anuncios'] || '',
      entidades: params['entidades'] || '',
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterParametrosMeusAnunciosInteresseUrl(params): ParametrosMeusAnunciosInteresse {
    const parametros: ParametrosMeusAnunciosInteresse = {
      pesquisa: params['pesquisa'] || '',
      materialTipos: params['materialTipos'] || '',
      materialCategorias: params['materialCategorias'] || '',
      inicio: params['inicio'] || 0,
      periodoInclusao: params['periodoInclusao'] || '',
      periodoInteresse: params['periodoInteresse'] || '',
      anuncios: params['anuncios'] || '',
      entidades: params['entidades'] || '',
      ordem: params['ordem'] || 1
    };
    return parametros;
  }

  public obterParametrosConfirmarDoacao(params): ParametrosConfirmarDoacao {
    const parametros: ParametrosConfirmarDoacao = {
      periodoPublicacao: params['periodoPublicacao'] || '',
      anuncioNumeros: params['anuncioNumeros'] || '',
      anuncioTitulo: params['anuncioTitulo'] || '',
      idDonatario: params['idDonatario'] || 0,
      anuncioSituacoes: params['anuncioSituacoes'] || '',
      inicio: params['inicio'] || 0,
      ordem: params['ordem'] || 1,
      totalRegistros: params['totalRegistros'] || 0,
      tpConsulta: params['tpConsulta'] || 1,
    };
    return parametros;
  }

  public obterParametrosAnunciosUrl(params): ParametrosAnuncios {
    const parametros: ParametrosAnuncios = {
      pesquisa: params['pesquisa'] || '',
      anuncianteTiposD: params['anuncianteTiposD'] || '',
      anuncianteTiposS: params['anuncianteTiposS'] || '',
      materialTiposD: params['materialTiposD'] || '',
      materialTiposS: params['materialTiposS'] || '',
      materialCategoriasD: params['materialCategoriasD'] || '',
      materialCategoriasS: params['materialCategoriasS'] || '',
      materialSituacoesD: params['materialSituacoesD'] || '',
      materialSituacoesS: params['materialSituacoesS'] || '',
      ufsD: params['ufsD'] || '',
      ufsS: params['ufsS'] || '',
      ordem: params['ordem'] || 1,
      inicio: params['inicio'] || 0,
      tpConsulta: params['tpConsulta'] || 1,
      onusD: params['onusD'] || '',
      onusS: params['onusS'] || '',
      anuncioSituacoesD: params['anuncioSituacoesD'] || '',
      anuncioSituacoesS: params['anuncioSituacoesS'] || '',
      numerosAnuncios: params['numerosAnuncios'] || '',
    };
    return parametros;
  }

  public obterContadorAutorizarDoacao(): Observable<number> {
    const url = `${environment.apiUrl}/meus-anuncios-interesse/obter-contador-autorizar-doacao`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterContadorConfirmarDoacao(): Observable<number> {
    const url = `${environment.apiUrl}/confirmar-doacao/obter-contador-confirmar-doacao`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterContadorAutorizarPublicacao(): Observable<number> {
    const url = `${environment.apiUrl}/autorizar-publicacao/obter-contador-autorizar-publicacao`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public publicarAnuncioPrivado(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/autorizar-publicacao/publicar-anuncio-privado/${idAnuncio}`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public excluirAnuncio(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/excluir/${idAnuncio}`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public inativarAnuncio(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/inativar/${idAnuncio}`;
    return this.http.get(url).pipe(
      catchError(this.errorHandler.handle()));
  }

  public excluirAnuncioPrivadoCentralCompras(form: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/excluir-privado-central-compras`;

    const dadosBasicos = <AnuncioDadosBasicos>{
      anuncio: anuncio,
      motivo: form.get('motivo').value,
    };

    return this.http.post(url, dadosBasicos)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public republicarAnuncioPrivado(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/republicar-anuncios-privado/republicar-anuncio/${idAnuncio}`;

    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }


  public indeferirAnuncioPrivado(form: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/autorizar-publicacao/indeferir-anuncio-privado`;

    const dadosBasicos = <AnuncioDadosBasicos>{
      anuncio: anuncio,
      motivo: form.get('motivo').value,
    };

    return this.http.post(url, dadosBasicos)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public solicitarComplementacao(form: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/autorizar-publicacao/solicitar-complementacao`;

    const dadosBasicos = <AnuncioDadosBasicos>{
      anuncio: anuncio,
      complementacao: form.get('complementacao').value,
    };

    return this.http.post(url, dadosBasicos)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public denunciarAnuncio(form: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/denuncia/${anuncio.id}?motivo=${form.get('motivo').value}`;
    return this.http.post(url, null).pipe(catchError(this.errorHandler.handle()));
  }

  public aceitarDenucia(form: FormGroup, idDenuncia: number): Observable<any> {
    const url = `${environment.apiUrl}/denuncia/${idDenuncia}/aceitar?motivo=${form.get('motivo').value}`;
    return this.http.post(url, null).pipe(catchError(this.errorHandler.handle()));
  }

  public rejeitarDenucia(form: FormGroup, idDenuncia: number): Observable<any> {
    const url = `${environment.apiUrl}/denuncia/${idDenuncia}/rejeitar?motivo=${form.get('motivo').value}`;
    return this.http.post(url, null).pipe(catchError(this.errorHandler.handle()));
  }

  public obterAnunciosPorEntidade(idEntidade: number) {
    const url = `${environment.apiUrl}/${OrigemEnum.ANUNCIOS}/anuncios-por-entidade/${idEntidade}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

}

import { Uf } from 'app/anuncio/models/uf.model';
import { MaterialTipo } from 'app/anuncio/models/material-tipo.model';
import { MaterialCategoria } from 'app/anuncio/models/material-categoria.model';
import { Entidade } from './entidade.model';
import { AnuncioChip } from './anuncio-chip.model';

export class FiltroMeusAnunciosInteresse {
  pesquisa: string;
  materialTipos: MaterialTipo[];
  materialTiposSelecionados: MaterialTipo[];
  materialCategorias: MaterialCategoria[];
  materialCategoriasSelecionadas: MaterialCategoria[];
  periodoInclusao: Date[];
  periodoInteresse: Date[];
  anuncios: AnuncioChip[];
  entidades: Entidade[];
  entidadesSelecionadas: Entidade[];

}

import { EntidadeEsfera } from './entidade-esfera.model';
import { EntidadeTipo } from './entidade-tipo.model';
import { Municipio } from './municipio.model';
import { Orgao } from './orgao.model';
import { Uf } from './uf.model';
import { Usuario } from './usuario.model';

export class Entidade {

  constructor(id: number) {
    this.id = id;
  }

  id: number;
  numero: string;
  nome: string;
  sigla: string;
  telefone1: string;
  telefone2: string;
  email: string;
  endereco: string;
  enderecoNumero: string;
  bairro: string;
  cep: string;
  usuarioResponsavel: Usuario;
  municipio: Municipio;
  orgao: Orgao;
  uf: Uf;
  entidadeTipo: EntidadeTipo;
  entidadeEsfera: EntidadeEsfera;
  utilizaSiads: boolean;
  ativo: boolean;
  privado: boolean;

  nomeNumero: string;

  //Listas para escolher numa combo
  listaEntidadeEsfera: EntidadeEsfera[];
  listaEntidadeTipo: EntidadeTipo[];
  listaOrgao: Orgao[];
  listaUf: Uf[];
  listaUsuario: Usuario[];
}

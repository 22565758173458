import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { CadastroUsuarioGovernoService } from 'app/cadastro-usuario-governo/services/cadastro-usuario-governo.service';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { ResultadoPesquisaCadastroUsuarioGoverno } from 'app/cadastro-usuario-governo/models/resultado-pesquisa-cadastro-usuario-governo.model';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { UtilService } from 'app/shared/services/util.service';
import { Table } from 'primeng/table';
import { Perfil } from 'app/anuncio/models/perfil.model';
import { CpfPipe } from 'app/shared/pipes/cpf.pipe';
import { ParametrosCadastroUsuarioGoverno } from 'app/cadastro-usuario-governo/models/parametros-cadastro-usuario-governo.model';

@Component({
  selector: 'reuse-cadastro-usuario-governo-lista',
  templateUrl: './cadastro-usuario-governo-lista.component.html',
  styleUrls: ['./cadastro-usuario-governo-lista.component.scss']
})
export class CadastroUsuarioGovernoListaComponent implements OnInit {

  @ViewChild('mainTable') mainTable: Table;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  //Status do botão "Remover". Habilitado ou não.
  buttonStatus: boolean = false;
  tpConsulta: number;
  totalRegistros = 0;
  cols: any[];
  usuarios: Usuario[];
  usuarioSelecionado: Usuario;

  sortField: string;
  sortOrder: string;
  inicio = 0;
  tamanhoPagina = 10;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private utilService: UtilService,
    private cadastroUsuarioGovernoService: CadastroUsuarioGovernoService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.cols = [
      { field: 'cpf', header: 'CPF', width: '10%' },
      { field: 'nome', header: 'Nome', width: '20%' },
      { field: 'entidade', header: 'Entidade', width: '35%' },
      { field: 'orgao', header: 'Órgão', width: '23%' },
    ];

    this.monitorarUrl();
  }


  /**
   * Consultar usuarios
   * -Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de usuarios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosCadastroUsuarioGoverno = this.cadastroUsuarioGovernoService.obterParametrosCadastroUsuarioGovernoUrl(params);

        //Limpa os dados
        this.usuarios = [];
        this.totalRegistros = 0;
        this.carregouDados = false;

        if ((parametros.cpf) && (!this.utilService.validaCpf(parametros.cpf))) {
          this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'CPF inválido.' });
        }

        if (!this.tpConsulta) {
          //Eh a primeira deteccao de mudanca de url (onInit).
          this.tpConsulta = 1; //Consulta "completa"
          parametros.tpConsulta = this.tpConsulta + '';
        }

        if (parametros.tpConsulta == '1') { //Consulta completa
          this.mainTable.reset();
        }

        this.cadastroUsuarioGovernoService.consultarUsuario(parametros).subscribe(
          (resultado: ResultadoPesquisaCadastroUsuarioGoverno) => {
            this.mainTable.first = resultado.inicio; //bug do component, reatribui paginacao
            this.usuarios = resultado.usuarios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.sortField = resultado.sortField;
            this.sortOrder = resultado.sortOrder;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
 * Clique botão 'Excluir usuário'.
 */
  public excluirUsuario(usuario: Usuario): void {
    this.confirmationService.confirm({
      key: `modalExcluirUsuario`,
      message: 'Confirma a exclusão do usuário?' + this.retornaDadosUsuario(usuario),
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmouExclusaoUsuario(usuario.id);
      }
    });
  }

  public retornaDadosUsuario(usuario: Usuario): string {
    return '<br><br><b>CPF:</b> ' + new CpfPipe().transform(usuario.cpf) +
      '<br><b>Nome:</b> ' + usuario.nome +
      '<br><b>Telefone:</b> ' + usuario.telefone1Governo +
      '<br><b>E-mail:</b> ' + usuario.emailGoverno +
      '<br><b>Orgão:</b> ' + usuario.entidade.orgao.id + ' - ' + usuario.entidade.orgao.nome +
      '<br><b>Entidade:</b> ' + usuario.entidade.numero + ' - ' + usuario.entidade.nome +
      '<br><b>Perfis:</b> ' + this.converterLstPerfis(usuario.lstPerfis);
  }

  /**
   * excluir usuario
   */
  private confirmouExclusaoUsuario(idUsuario: number) {
    this.cadastroUsuarioGovernoService.excluirUsuario(idUsuario).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

        //Recarrega a página após exluir usuario.
        this.monitorarUrl();
      });
  }

  /**
   * Clique botão 'Alterar usuário'.
   */
  public alterarUsuario(usuario: Usuario): void {
    this.router.navigate(['/' + OrigemEnum.ALTERAR_CADASTRO_USUARIO_GOVERNO, usuario.id]);
  }

  /**
   * Clique botão 'Visualizar usuário'.
   */
  public visualizarUsuario(usuario: Usuario): void {
    this.router.navigate(['/' + OrigemEnum.VISUALIZAR_CADASTRO_USUARIO_GOVERNO, usuario.id]);
  }

  /**
   * Clique botão 'Incluir Usuário'.
   */
  public incluirUsuario(): void {
    this.router.navigate(['/' + OrigemEnum.INCLUIR_CADASTRO_USUARIO_GOVERNO]);
  }

  consultarComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosCadastroUsuarioGoverno = this.cadastroUsuarioGovernoService.obterParametrosCadastroUsuarioGovernoUrl(parametrosUrl);

    this.router.navigate(['/' + OrigemEnum.LISTAR_CADASTRO_USUARIO_GOVERNO], {
      queryParams: {
        nome: parametros.nome,
        cpf: parametros.cpf,
        idEntidade: parametros.idEntidade,
        idOrgao: parametros.idOrgao,
        inicio: this.inicio,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        totalRegistros: this.totalRegistros,
        tpConsulta: 2,  // tipo de consulta, para paginacao, nao tem necessidade de total de registros
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
 * A cada troca de página, consulta 10 novas mensagens no backend.
 *
 * @param event
 */
  onPage(event) {
    this.inicio = event.first;
    //após 'inicio' sem alterado, realiza consulta
    this.consultarComParametrosUrl();
  }

  /**
  * A cada troca de página, consulta 10 novas mensagens no backend.
  *
  * @param event
  */
  onSort($event: SortEvent) {
    this.sortField = $event.field;
    this.sortOrder = $event.order == -1 ? 'DESC' : 'ASC';
    this.consultarComParametrosUrl();
  }

  private converterLstPerfis(lstPerfis: Perfil[]): String {
    if (lstPerfis && lstPerfis.length > 0) {
      let perfis = '';
      lstPerfis.forEach(perfil => {
        perfis = perfis + perfil.nome + ' - ';
      });
      return perfis.substring(0, perfis.length - 3);
    } else {
      return 'Sem perfil cadastrado.'
    }
  }

  public onNovoUsuario(): void {
    this.router.navigate(['/' + OrigemEnum.INCLUIR_CADASTRO_USUARIO_GOVERNO, 'novo']);
  }
}

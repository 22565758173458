import { RouteService } from 'app/shared/services/route.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { SelectItem } from 'primeng/components/common/api';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AnuncioInteresse } from '../../models/anuncio-interesse.model';
import { Anuncio } from '../../models/anuncio.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnuncioInteresseService } from '../../services/anuncio-interesse.service';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';

@Component({
  selector: 'reuse-modal-confirmar-doacao',
  templateUrl: './modal-confirmar-doacao.component.html',
  styleUrls: ['./modal-confirmar-doacao.component.scss']
})
export class ModalConfirmarDoacaoComponent extends AnunciosInternoPage implements OnInit {

  @Input()
  anuncio: Anuncio;

  @Input()
  anuncioInteresse: AnuncioInteresse;

  // Evento para avisar que finalizou a análise
  @Output()
  analiseEfetuada = new EventEmitter<AnuncioInteresse>();

  // indica se deve exibir o modal de analisar interesse
  exibirModalConfirmarDoacao = false;

  // Formulário 'Confirmar Doação'
  formulario: FormGroup;

  // Anuncio interesse a ser analisado
  // anuncioInteresse: AnuncioInteresse;

  formasUtilizacao: SelectItem[];

  pt: any;

  indiceTabela: number;
  totalRestanteConfirmarDoacao: number;

  carregouInteresseAnuncio = false;

  today = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private messageService: MessageService,
    private anuncioInteresseService: AnuncioInteresseService,
    private routeService: RouteService
  ) {
    super();
  }

  ngOnInit() {
    this.formasUtilizacao = [
      { label: 'Imediatamente', value: 1 },
      { label: 'Médio Prazo', value: 2 },
      { label: 'Longo Prazo', value: 3 }
    ];

    this.configurarCalendario();
  }

  /**
   * Exibir modal.
   */
  exibirModal(anuncioInteresse: AnuncioInteresse) {
    this.anuncioInteresse = anuncioInteresse;
    this.carregouInteresseAnuncio = true;
    this.criarFormulario(anuncioInteresse);
    this.exibirModalConfirmarDoacao = true;
  }

  onCancelar() {
    this.ocultarModal();
  }

  /**
   * Ocultar modal.
   */
  ocultarModal() {
    this.exibirModalConfirmarDoacao = false;
  }

  onSubmit() {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (this.formulario.valid) {
      this.anuncioInteresse.id = this.anuncio.interesses[0].id;
      this.anuncioInteresse.dataEntrega = this.dataParaString(this.formulario.get('dataEntrega').value, 'YYYY-MM-DD') + '-00-00-00';
      this.anuncioInteresse.quantidadeEntregue = this.formulario.get('quantidadeEntregue').value;
      this.anuncioInteresse.observacaoEntrega = this.formulario.get('observacaoEntrega').value;
      this.anuncioInteresse.anuncioInteresseUtilizacao = this.formulario.get('anuncioInteresseUtilizacao').value;
      this.anuncioInteresseService.confirmarDoacao(this.anuncioInteresse).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
          this.routeService.voltar(OrigemEnum.CONFIRMAR_DOACAO);
          this.ocultarModal();
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro de Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  private criarFormulario(anuncioInteresse: AnuncioInteresse) {
    this.anuncioInteresse = anuncioInteresse;
    this.formulario = this.formBuilder.group({
      quantidadeEntregue: [this.anuncioInteresse.quantidadeAvaliacao || 0,
      [Validators.required, Validators.min(0), Validators.max(this.anuncioInteresse.quantidadeAvaliacao)]],
      observacaoEntrega: [],
      dataEntrega: [ new Date(), [Validators.required]],
      anuncioInteresseUtilizacao: [1, [Validators.required]]
    });
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  verificarCampoInvalido(nomeCampo: string) {
    const campo = this.formulario.get(nomeCampo);
    // inválido e alterado
    return campo.invalid && campo.dirty;
  }

  public limparDataEntrega(input): void {
    this.anuncioInteresse.dataEntrega = null;
    this.formulario.get('dataEntrega').setValue(null);
    this.fecharCalendario(input);
  }
}

import { EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { Anuncio } from "../../models/anuncio.model";
import { CadastroAnuncioService } from "../../services/cadastro-anuncio.service";

/**
 * Define comportamentos comuns para os componentes de cadastro de anúncio.
 */
export abstract class AbstractCadastroAnuncioPrivado {

  //número do anúncio a ser modificado
  numeroAnuncio: number;

  //anúncio carregado a partir do 'numeroAnuncio'
  anuncio: Anuncio;

  passo: number;

  @Output()
  onNavegarPasso = new EventEmitter<number[]>();

  public cadastroAnuncioService: CadastroAnuncioService;

  constructor(cadastroAnuncioService: CadastroAnuncioService) {
    this.cadastroAnuncioService = cadastroAnuncioService;
  }

  protected abstract getRouter(): Router;

  protected abstract getCadastroAnuncioService(): CadastroAnuncioService;


  public navegarPasso(passo: number) {
    this.cadastroAnuncioService.verificarBloqueado(this.anuncio.id).subscribe(
      () => {
        //informa ao pai que houve uma mudanca de pagina
        this.onNavegarPasso.emit([this.numeroAnuncio, passo]);
      });
  }

  /**
   * Clique botão 'Voltar'.
   */
  public onVoltar() {
    this.navegarPasso(this.passo - 1);
  }

  /**
 * Clique botão 'Próximo'.
 */
  public onProximo() {
    this.navegarPasso(this.passo + 1);
  }

  stringParaData(data: string, formato: string) {
    return moment(data, formato).toDate();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Municipio } from '../models/municipio.model';


@Injectable({
  providedIn: 'root'
})
export class MunicipioService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  public listarMunicipiosAtivos(idUf: number): Observable<Municipio[]> {
    const url = `${environment.apiUrl}/municipio/listar-municipios-ativos/${idUf}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { ParametrosMeusInteresses } from '../../models/parametros-meus-interesses.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { ParametrosMeusAnunciosInteresse } from '../../models/parametros-meus-anuncios-interesse.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-meus-anuncios-interesse-lista',
  templateUrl: './meus-anuncios-interesse-lista.component.html',
  styleUrls: ['./meus-anuncios-interesse-lista.component.scss']
})
export class MeusAnunciosInteresseListaComponent extends AnunciosInternoLista implements OnInit {

  MEUS_ANUNCIOS_INTERESSES = OrigemEnum.MEUS_ANUNCIOS_INTERESSES;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
      super();
    }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios interesse.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosMeusAnunciosInteresse = this.anuncioService.obterParametrosMeusAnunciosInteresseUrl(params);
        this.ordem = parametros.ordem;

        this.anuncioService.consultarMeusAnunciosInteresse(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0,0);
          });
      });
  }

  /**
   * Consulta os meus anúncios interesse, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosInteresse = this.anuncioService.obterParametrosMeusAnunciosInteresseUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_INTERESSES], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        materialTipos: parametros.materialTipos,
        materialCategorias: parametros.materialCategorias,
        periodoInclusao: parametros.periodoInclusao,
        periodoInteresse: parametros.periodoInteresse,
        anuncios: parametros.anuncios,
        entidades: parametros.entidades,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MaterialSituacao } from '../../models/material-situacao.model';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { AnuncioDetalhe } from '../../models/anuncio-detalhe.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';
import { AbstractCadastroAnuncio } from '../cadastro-anuncio-page/abstract-cadastro-anuncio';
import { AppMessageService } from '../../../_services/app.message/app.message.service';

@Component({
  selector: 'reuse-cadastro-anuncio-detalhes',
  templateUrl: './cadastro-anuncio-detalhes.component.html',
  styleUrls: ['./cadastro-anuncio-detalhes.component.scss']
})
export class CadastroAnuncioDetalhesComponent extends AbstractCadastroAnuncio implements OnInit {

  //formulário
  formulario: FormGroup;

  //lista de materiais situação disponíveis para exibir no combobox
  listaMaterialSituacao: MaterialSituacao[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private cadastroAnuncioService: CadastroAnuncioService,
    private utilService: UtilService,
    private messageService: MessageService,
    private router: Router,
    public appMessageService: AppMessageService,) {
    super(appMessageService);
   }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

   ngOnInit() {
    this.passo = 1;
    this.obterDetalhes();
  }

  private obterDetalhes(): void {

    this.cadastroAnuncioService.obterAnuncioDetalhes(this.numeroAnuncio)
    .subscribe(
      (dados) => {
        //dados do anúncio
        this.anuncio = dados.anuncio;

        this.listaMaterialSituacao = dados.listaMaterialSituacao;
        //adiciona opção 'Selecione' (vazia)
        this.listaMaterialSituacao.unshift(<MaterialSituacao>{id:null, nome:'Selecione'});

        this.criarFormulario(dados.detalhes);

        this.carregouDados = true;
        window.scrollTo(0,0);

        this.utilService.verificarRegistroBloqueado(this.anuncio, true);
      });
  }

  /**
   * Todos os itens de Detalhe do formulário.
   */
  public itens(): FormArray {
    return this.formulario.get('itens') as FormArray;
  }
  /**
   * Cria o formulário para inserção ou edição.
   *
   * Cada linha é um formulário FormGroup, as linhas são adicionadas a um FormArray.
   */
  private criarFormulario(detalhes: AnuncioDetalhe[]) {
    this.formulario = this.formBuilder.group({
      itens: this.formBuilder.array([])
    });

    //adiciona os detalhes carregados do backend
    let itens:FormArray = this.itens();
    detalhes.forEach(detalhe => {
      itens.push(this.criarItem(detalhe));
    });

    this.controleDesabilitarFormulario();
  }

  /**
   * Verifica se deve desabilitar campos do formulário.
   */
  private controleDesabilitarFormulario():void {
    if (!this.podeAlterarDados()) {
      this.formulario.disable();
    }
  }

  /**
   * Adiciona um item de Detalhe vazio.
   */
  public adicionarItem(): void {
    let itens = this.itens();
    itens.push(this.criarItem(<AnuncioDetalhe>{}));
  }

  /**
   * Remove o item de Detalhe.
   */
  public removerItem(indice: number): void {
    this.itens().removeAt(indice);
  }

  /**
   * Cria um item de Detalhe, a partir do parâmetero 'detalhe'.
   */
  private criarItem(detalhe:AnuncioDetalhe):FormGroup {
    return this.formBuilder.group({
      id: [detalhe.id],
      materialSituacao: [detalhe.materialSituacao, [Validators.required, this.utilService.validarIdEntidade]],
      quantidade: [detalhe.quantidade, [Validators.required]],
      valorAvaliacao: [detalhe.valorAvaliacao],
      dadosComplementares: [detalhe.dadosComplementares],
      patrimonio: [detalhe.patrimonio],
    });
  }



  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit():void {
    this.marcarCamposComoAlterado(this.itens());

    // Nesse primeiro caso nao precisa salvar, pois somente pode alterar as fotos.
    if(!this.podeAlterarDados()) {
      this.onProximo();
    } else if (this.formulario.valid) {
      this.cadastroAnuncioService.salvarDetalhes(this.formulario, this.anuncio).subscribe(
        (resultado: any) => {
          this.messageService.add({severity:'success', summary:'Sucesso', detail:resultado.mensagem});

          this.onProximo();
        });
    }
  }

  /**
   * Cada linha de Detalhe é um FormGroup, para todas as linhas chama a validação de formulário.
   * @param formArray
   */
  public marcarCamposComoAlterado(formArray: FormArray):void {
    formArray.controls.forEach(form => {
      this.utilService.marcarCamposComoAlterado(form as FormGroup);
    });
  }


}

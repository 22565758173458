import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { ParametrosDesbloqueioAnuncio } from 'app/desbloqueio-anuncio/models/parametros-desbloqueio-anuncio.model';
import { ResultadoPesquisaDesbloqueioAnuncio } from 'app/desbloqueio-anuncio/models/resultado-pesquisa-desbloqueio-anuncio.model';
import { DesbloqueioAnuncioService } from 'app/desbloqueio-anuncio/services/desbloqueio-anuncio.service';
import { BloqueioAnuncio } from 'app/desbloqueio-anuncio/models/bloqueio-anuncio.model';

@Component({
  selector: 'reuse-debloqueio-anuncio-lista',
  templateUrl: './debloqueio-anuncio-lista.component.html',
  styleUrls: ['./debloqueio-anuncio-lista.component.scss']
})
export class DesbloqueioAnuncioListaComponent implements OnInit {

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  //Status do botão "Remover". Habilitado ou não.
  buttonStatus: boolean = false;

  //Armazena resposta do Backend.
  bloqueios: BloqueioAnuncio[] = [];

  totalRegistros = 0;
  cols: any[];

  //Lista de bloqueios selecionados para remoção.
  bloqueiosSelecionados: BloqueioAnuncio[] = null;

  constructor(private route: ActivatedRoute,
    private bloqueioService: DesbloqueioAnuncioService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.monitorarUrl();

    this.cols = [
      { field: 'bloqueioTipo', header: 'Tipo de Bloqueio', width: '40%' },
      { field: 'anuncio', header: 'Anúncio', width: '10%' },
      { field: 'dataAcesso', header: 'Bloqueado em', width: '20%' },
      { field: 'cpf', header: 'Usuário', width: '10%' }
    ];
    this.habilitarBotaoRemover();
  }

  /**
   * Ação ao selecionar checkbox da linha.
   * @param event
   */
  onRowSelect(event) {
    this.habilitarBotaoRemover();
  }

  /**
   * Ação ao não selecionar checkbox da linha.
   * @param event
   */
  onRowUnselect(event) {
    this.habilitarBotaoRemover();
  }

  /**
   * Ação ao selecionar/não selecionar checkbox da linha do cabeçalho da tabela.
   * @param event
   */
  onTableHeaderCheckboxToggle(event: any) {
    this.habilitarBotaoRemover();
  }

  /**
   * Verifica se algum bloqueio foi selecionado.
   */
  isBloqueiosSelecionadosVazio(): boolean {
    return this.bloqueiosSelecionados == null || this.bloqueiosSelecionados.length == 0;
  }

  /**
   * Consultar bloqueios
   * -Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de bloqueio.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosDesbloqueioAnuncio = this.bloqueioService.obterParametrosBloqueioUrl(params);
        this.bloqueioService.consultarBloqueio(parametros).subscribe(
          (resultado: ResultadoPesquisaDesbloqueioAnuncio) => {
            this.limparCheckbox();

            this.bloqueios = resultado.bloqueios;
            this.totalRegistros = resultado.totalRegistros;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
   * Remover bloqueios selecionados.
   */
  removerBloqueio(submitBtn: HTMLButtonElement) {
    if (this.bloqueiosSelecionados.length > 0) {
      submitBtn.disabled = true;
      this.bloqueioService.removerBloqueio(this.bloqueiosSelecionados).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

          //Recarrega a página após remover registro de bloqueio.
          this.monitorarUrl();
          this.limparCheckbox();
          submitBtn.disabled = true;
        }, () => { submitBtn.disabled = false; });
    }
  }

  /**
   * Limpa lista de bloqueios selecionados.
   */
  limparCheckbox() {
    this.bloqueiosSelecionados = null;
    this.buttonStatus = this.isBloqueiosSelecionadosVazio();
  }

  /**
 * Verifica se deve habilitar/desabilitar o botão "Remover"
 */
  habilitarBotaoRemover() {
    this.buttonStatus = this.isBloqueiosSelecionadosVazio();
  }
}

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {MessageService} from 'primeng/components/common/messageservice';
import { RouteService } from './shared/services/route.service';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderComponent } from './shared/components/header/header.component';
import { HttpClient } from '@angular/common/http';
//import cssVars from 'css-vars-ponyfill';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public title: string = 'Doações.gov';
  public hideContentPadding = ["", "/login"]

  @ViewChild('reuseHeader') reuseHeader: HeaderComponent;

  constructor(private messageService: MessageService,
    private routeService: RouteService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    private location: Location){
      router.events.forEach((event) => {
        if(event instanceof NavigationEnd) {
          this.alterarCor();
        }
      });
    }

  @HostListener("document:mousedown", ["$event"])
    onDocumentClicked(ev) {
      //apaga as mensagens (parou de funcionar quando trocou para p-growl)
      this.messageService.clear();
  }

  ngOnInit() {
    //cssVars();
    this.loadScripts();
  }

  public get exibePageContentPadding():boolean {
    let route = this.location.path();
    let vetRoute = route.split('?');
    return (this.hideContentPadding.indexOf(vetRoute[0]) == -1);
  }

  private alterarCor() {
    if (this.authService.hasRole(['PRIVADO'])) {
      document.documentElement.style.setProperty('--appColor', 'rgba(22,69,30,0.9)'); //$color_green_dark
      document.documentElement.style.setProperty('--appColorSolid', 'rgba(22,69,30,1)'); //$color_green_dark_solid
      document.documentElement.style.setProperty('--appColorShadow', 'rgba(15, 92, 0, 0.2)'); //$color_green_shadow
    } else {
      document.documentElement.style.setProperty('--appColor', 'rgba(26,57,89,0.9)'); //$color_blue_dark
      document.documentElement.style.setProperty('--appColorSolid', 'rgba(26,57,89,0.99)'); //$color_blue_dark_solid
      document.documentElement.style.setProperty('--appColorShadow', 'rgba(48, 76, 105, 0.2)'); //$color_blue_dark_shadow
    }
  }

  //Carrega a barra brasil programaticamente
  loadScripts() {
    const dynamicScripts = [
     '//barra.brasil.gov.br/barra_2.0.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}

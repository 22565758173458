import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FiltroMensagens } from '../../models/filtro-mensagens.model';
import { MensagemService } from '../../services/mensagem.service';
import { ParametrosMensagens } from '../../models/parametros-mensagens.model';
import { MensagensIntenoPage } from '../commom/mensagens-inteno-page';
import { ResultadoPesquisaMensagem } from '../../models/resultado-pesquisa-mensagem.model';
import { MensagemSituacao } from '../../models/mensagem-situacao.model';
import { AnuncioChip } from '../../../anuncio/models/anuncio-chip.model';
import { MenuItem } from 'primeng/api';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';


@Component({
  selector: 'reuse-mensagens-page',
  templateUrl: './mensagens-page.component.html',
  styleUrls: ['./mensagens-page.component.scss']
})
export class MensagensPageComponent extends MensagensIntenoPage implements OnInit {

  filtro: FiltroMensagens = new FiltroMensagens();

  constructor( private mensagemService: MensagemService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService) {
      super();
    }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMensagens = this.mensagemService.obterParametrosUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.popularSeparadoresTagInput();
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    const telaMensagens: MenuItem = {
      icon: 'fa fa-envelope',
      label: 'Mensagens',
      command: () => { this.router.navigate(['mensagens'],{ queryParams: {mensagemSituacoes: 'Lida,Não lida', dt: new Date().getTime()}});}};

    let breadcrumbItems = [ telaMensagens ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosMensagens) {
    this.mensagemService.consultarFiltrosMensagens()
      .subscribe(
        (resultado: ResultadoPesquisaMensagem) => {
          this.filtro.mensagemSituacoes = resultado.mensagemSituacoes;
          this.definirFiltrosSelecionados(parametros);
        });
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosMensagens) {
    const mensagemSituacoes: MensagemSituacao[] = parametros.mensagemSituacoes.split(',').filter(v => v!='').map((nome) => <MensagemSituacao>{nome});
    this.filtro.mensagemSituacoesSelecionados = mensagemSituacoes;

    const periodoLeitura:Date[] = parametros.periodoLeitura.split(',').filter(v => v!='').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoLeitura.length!=0) {
      this.filtro.periodoLeitura = periodoLeitura;
    }

    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v!='').map(v => <AnuncioChip>{value:v, display:v});
    this.filtro.anuncios = anuncios;
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'mensagens-lista' monitora a URL para carregar novos anúncios.
   */
  aplicarFiltros() {
    const mensagemSituacoes:string = this.filtro.mensagemSituacoesSelecionados.map(m => m.nome).join(',');

    let periodoLeitura: string = '';
    if (this.filtro.periodoLeitura!=null) {
      periodoLeitura = this.filtro.periodoLeitura.filter(v => v !=null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }
    const anuncios:string = this.filtro.anuncios.map(m => m.display).join(',');

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMensagens = this.mensagemService.obterParametrosUrl(parametrosUrl);

    this.router.navigate(['/mensagens'], {
      queryParams: {
        mensagemSituacoes: mensagemSituacoes,
        titulo: this.filtro.titulo,
        periodoLeitura: periodoLeitura,
        anuncios: anuncios,
        ordem: parametros.ordem,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.mensagemSituacoesSelecionados = [];
    this.filtro.periodoLeitura = null;
    this.filtro.titulo = null;
    this.filtro.anuncios = [];
    this.aplicarFiltros();
  }

  limparPeriodoLeitura(input) {
    this.filtro.periodoLeitura = null;
    this.fecharCalendario(input);
  }

}

<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label for="cpf">CPF</label>
          <p-inputMask id="cpf" name="cpf" [(ngModel)]="filtro.cpf" unmask="true" mask="999.999.999-99" autocomplete="off" placeholder="CPF" title="Entre com o CPF" type="text" styleClass="width100" (onBlur)="onBlurCpf($event)"></p-inputMask>
        </div>
        <div class="ui-g-12">
          <label for="nome">Nome</label>
          <input pInputText id="nome" name="nome" [(ngModel)]="filtro.nome" maxlength="200" placeholder="Nome" type="text"/>
        </div>
        <div class="ui-g-12">
          <label for="orgao">Órgão</label>
          <p-dropdown #orgao id="orgao" [options]="listaOrgao"   [autoWidth]="false" (onChange)="onChangeEntidade()" [(ngModel)]="filtro.orgao" [filter]="true" optionLabel="idNome" dataKey="id"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <label for="entidade">Entidade</label>
          <p-dropdown #entidade id="entidade" [options]="listaEntidade"  [autoWidth]="false" [(ngModel)]="filtro.entidade" [filter]="true" optionLabel="nomeNumero" dataKey="id" [disabled]="listaEntidade.length == 0"></p-dropdown>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar"  styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()"></p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="consultar()"></p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnuncioService } from '../../services/anuncio.service';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { AbstractCadastroAnuncioPrivado } from '../cadastro-anuncio-privado-page/abstract-cadastro-anuncio-privado';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { UtilService } from '../../../shared/services/util.service';
import { MaterialTipoEnum } from 'app/shared/enum/material-tipo.enum';
import { AnuncioUtil } from '../../../shared/services/anuncio.util';

@Component({
  selector: 'reuse-cadastro-anuncio-privado-resumo',
  templateUrl: './cadastro-anuncio-privado-resumo.component.html',
  styleUrls: ['./cadastro-anuncio-privado-resumo.component.scss']
})
export class CadastroAnuncioPrivadoResumoComponent extends AbstractCadastroAnuncioPrivado implements OnInit {

  //formulário
  formulario: FormGroup;

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private anuncioService: AnuncioService,
    public cadastroAnuncioService: CadastroAnuncioService,
    private messageService: MessageService,
    private router: Router,
    public anuncioUtil: AnuncioUtil,
    private utilService: UtilService) {
    super(cadastroAnuncioService);
  }

  ngOnInit(): void {
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  public atualizaAnuncio(numeroAnuncio: number, apresentaAbaDonatario: boolean): void {
    // this.passo = 4;
    this.passo = (apresentaAbaDonatario ? 4 : 3);
    this.numeroAnuncio = numeroAnuncio;
    if (this.anuncio) {
      this.anuncio.id = numeroAnuncio;
    }
    this.obterAnuncio();
  }

  private obterAnuncio(): void {
    this.anuncioService.getAnuncio(this.numeroAnuncio, OrigemEnum.MEUS_ANUNCIOS_PRIVADO)
      .subscribe(
        anuncio => {
          this.anuncio = anuncio;

          this.criarFormulario();

          this.carregouDados = true;
          window.scrollTo(0, 0);
        });
  }

  public isAnuncioServico(): boolean {
    if (this.anuncio.materialCategoria.materialTipo.id == MaterialTipoEnum.SERVICO) {
      return true;
    }
    return false;
  }

  // Só exibe frase Declaro que a doação não é um bem público ou de empresa privada se tipo pessoa não for Pessoa Juridica.
  public isPessoaJuridica(): boolean {
    if (!this.anuncio.entidade || !this.anuncio.entidade.id || this.anuncio.entidade.id == -1) {
      return false;
    }
    return true;
  }

  /**
 * Cria o formulário para inserção ou edição.
 */
  private criarFormulario() {
    this.formulario = this.formBuilder.group({
      validacaoProprietario: [false, !this.isAnuncioServico() ? [Validators.required, this.utilService.validarCheckboxPublicacaoAnuncioPrivado] : null],
      validacaoBem: [false, (!this.isAnuncioServico() && !this.isPessoaJuridica()) ? [Validators.required, this.utilService.validarCheckboxPublicacaoAnuncioPrivado] : null],
      validacaoDemanda: [false, !this.isAnuncioServico() ? [Validators.required, this.utilService.validarCheckboxPublicacaoAnuncioPrivado] : null],
      validacaoConteudo: [false, !this.isAnuncioServico() ? [Validators.required, this.utilService.validarCheckboxPublicacaoAnuncioPrivado] : null],
      validacaoServico: [false, [Validators.required, this.utilService.validarCheckboxPublicacaoAnuncioPrivado]],
    });
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onConfirmar(): void {
    this.cadastroAnuncioService.publicarAnuncioPrivado(this.anuncio).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

        // navega para página de meus anúncio
        this.router.navigate(['/' + OrigemEnum.MEUS_ANUNCIOS_PRIVADO]);
      });
  }

  /**
 * Clique botão 'Confirmar'.
 */
  public onSubmit(submitBtn: string): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    document.getElementById(submitBtn).setAttribute('disabled', 'true');

    if (!this.anuncio.possuiDonatario) {
      this.anuncio.entidadeFavorecida = null;
    }

    this.cadastroAnuncioService.publicarAnuncioPrivado(this.anuncio).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

        // navega para página de meus anúncio
        this.router.navigate(['/' + OrigemEnum.MEUS_ANUNCIOS_PRIVADO]);
      }, () => { document.getElementById(submitBtn).removeAttribute('disabled'); });
  }

  /**
   * Só exibe botão publicar se anúncio estiver na situação de Rascunho.
   */
  public exibirBotaoPublicar(): boolean {
    return this.anuncio && this.anuncio.permissoes.podeAlterar;
  }

  public nomeBotaoSubmit(): string {
    if (this.anuncioUtil.aguardandoComplementacao(this.anuncio)) {
      return 'Enviar complementação';
    } else {
      return 'Enviar para autorização de publicação';
    }
  }
}

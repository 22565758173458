<div class="divMain">
  <div class="divCard br-card">
    <div class="item">
      <p>Dúvidas e solicitações relacionadas ao sistema Doações, acesso de usuários privados, de governo ou habilitação como cadastrador no Senha Rede, entre em contato com a nossa Central de Atendimento aos Sistemas Estruturantes:</p>
      <p><i class="fa fa-external-link-alt"></i>&nbsp;<a
        href="https://portaldeservicos.economia.gov.br" target="_blank">&nbsp;https://portaldeservicos.economia.gov.br</a></p>
    </div>
    <div class="item">
      <p>Em caso de dificuldade de acesso ao portal da Central de Atendimento, favor relatar essa dificuldade à Coordenação Geral de Serviços aos Sistemas Estruturantes, pelo e-mail:</p>
      <p><i class="fa fa-envelope"></i>&nbsp;<a href="mailto:seges.cgses@economia.gov.br">seges.cgses@economia.gov.br</a>.</p>
    </div>

  </div>
</div>

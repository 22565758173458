<p-dialog modal=true header="Finalização de Autorização (Justificativas)"
  [(visible)]="exibirModalFinalizarAutorizacaoDoacao" [width]="600" [width]="1200">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="exibirModalFinalizarAutorizacaoDoacao">
    <div class="ui-g-12" *ngIf="mudancaDoacaoDto.necessitaMotivoMudancaQtdeDoacao">
      <div class="mensagemErro" [class.erro-form]="verificarCampoInvalido('motivoMudancaQtdeDoacao')">Quantidade informada para o primeiro interessado difere da quantidade do anúncio. Informe o motivo do rateio abaixo:</div>
      <textarea [rows]="3" style="width: 100%" pInputTextarea formControlName="motivoMudancaQtdeDoacao"
        placeholder="Motivo do Rateio"></textarea>
    </div>

    <div class="ui-g-12" *ngIf="mudancaDoacaoDto.necessitaMotivoMudancaOrdemDoacao">
      <div class="mensagemErro" [class.erro-form]="verificarCampoInvalido('motivoMudancaQtdeDoacao')">Doação deverá ser justificada, pois não está seguindo a ordem de interessados. Informe a justificativa abaixo:</div>
      <textarea [rows]="3" style="width: 100%" pInputTextarea formControlName="motivoMudancaOrdemDoacao"
        placeholder="Justificativa"></textarea>
    </div>

    <p-footer>
      <div class="buttons text-right">
        <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onCancelar()"></button>
        <button type="submit" pButton label="Confirmar" class="ui-button-success" [disabled]="form.invalid"></button>
      </div>
    </p-footer>
  </form>
</p-dialog>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CadastroUsuarioGovernoPageComponent } from './components/cadastro-usuario-governo-page/cadastro-usuario-governo-page.component';
import { CadastroUsuarioGovernoListaComponent } from './components/cadastro-usuario-governo-lista/cadastro-usuario-governo-lista.component';
import { CadastroUsuarioGovernoEditPageComponent } from './components/cadastro-usuario-governo-edit-page/cadastro-usuario-governo-edit-page.component';


const routes: Routes = [
  {
    path: 'listar-cadastro-usuario-governo',
    component: CadastroUsuarioGovernoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    children: [
      {
        path: '',
        component: CadastroUsuarioGovernoListaComponent,
        data: {
          origem: 'listar-cadastro-usuario-governo'
        }
      },
    ]
  },
  {
    path: 'alterar-cadastro-usuario-governo/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroUsuarioGovernoEditPageComponent,
  },
  {
    path: 'visualizar-cadastro-usuario-governo/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroUsuarioGovernoEditPageComponent,
  },
  {
    path: 'incluir-cadastro-usuario-governo/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroUsuarioGovernoEditPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastroUsuarioGovernoRoutingModule { }

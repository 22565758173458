
import { Component } from '@angular/core';



@Component({
  selector: 'reuse-sistema-em-manutencao',
  templateUrl: './sistema-em-manutencao.component.html',
  styleUrls: ['./sistema-em-manutencao.component.scss']
})
export class SistemaEmManutencaoComponent {

  constructor() {
  }

}

import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametrosMeusAnunciosGoverno } from '../../models/parametros-meus-anuncios-governo.model';
import { MaterialTipo } from '../../models/material-tipo.model';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { Uf } from '../../models/uf.model';
import { AnuncioChip } from '../../models/anuncio-chip.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { MaterialSituacao } from '../../models/material-situacao.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AuthService } from 'app/shared/services/auth.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AnuncioSituacao } from 'app/anuncio/models/anuncio-situacao.model';
import { ResultadoPesquisaAnuncioAutorizar } from 'app/anuncio/models/resultado-pesquisa-anuncio-autorizar.model';
import { RouteService } from 'app/shared/services/route.service';
import { PesquisaAnuncioPrincipal } from 'app/anuncio/provider/dados.provider';
import { ParametrosAutorizarPublicacao } from 'app/anuncio/models/parametros-autorizar-publicacao.model';
import { FiltroAutorizarPublicacao } from 'app/anuncio/models/filtro-autorizar-publicacao.model';

@Component({
  selector: 'reuse-autorizar-publicacao-page',
  templateUrl: './autorizar-publicacao-page.component.html',
  styleUrls: ['./autorizar-publicacao-page.component.scss']
})
export class AutorizarPublicacaoPageComponent extends AnunciosInternoPage implements OnInit {

  filtro: FiltroAutorizarPublicacao = new FiltroAutorizarPublicacao();

  mostraSituacaoMaterial: boolean;

  constructor(private anuncioService: AnuncioService,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private routeService: RouteService, //necessario para o this.routeService.voltar no anuncio-detail
    private appMessageService: AppMessageService,
    private dadosPesquisaAnuncioPrincipal: PesquisaAnuncioPrincipal) {
    super();
  }



  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosAutorizarPublicacao = this.anuncioService.obterParametrosAutorizarPublicacaoUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.popularSeparadoresTagInput();
    this.mostraSituacaoMaterial = true;
  }

  mostrarSituacaoMaterial() {
    this.mostraSituacaoMaterial = true;
    if ((this.filtro.materialTiposSelecionados.length === 1) && (this.filtro.materialTiposSelecionados[0].nome === 'Serviço')) {
      this.mostraSituacaoMaterial = false;
      this.filtro.materialSituacoesSelecionadas = [];
    }
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [
      { icon: 'fa fa-gavel', label: 'Autorizar Publicação', command: (onclick) => { this.router.navigate([OrigemEnum.AUTORIZAR_PUBLICACAO], { queryParams: { dt: new Date().getTime() } }) } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  private consultarFiltros(parametros: ParametrosAutorizarPublicacao): void {
    this.anuncioService.consultarFiltrosAutorizarPublicacao(parametros)
      .subscribe(
        (resultado: ResultadoPesquisaAnuncioAutorizar) => {
          this.filtro.anuncioSituacoes = resultado.anuncioSituacoes;
          this.filtro.materialTipos = resultado.materialTipos;
          this.filtro.materialCategorias = resultado.materialCategorias;
          this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria = resultado.materialCategorias;
          this.filtro.materialSituacoes = resultado.materialSituacoes;
          this.filtro.ufs = resultado.ufs;
          this.filtro.onus = resultado.onus;
          this.filtro.analisadoPor = resultado.analisadoPor;
          this.filtro.entidades = resultado.entidades;

          this.definirFiltrosSelecionados(parametros);
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changes_filtro = changes['filtro'];
    if (changes_filtro) {
      this.filtro = changes_filtro.currentValue;
    }
    this.filtrarCategorias();
  }

  filtrarCategorias() {
    if (this.filtro.materialTiposSelecionados !== undefined) {
      this.mostrarSituacaoMaterial();
      if (this.filtro.materialTiposSelecionados.length > 0) {
        // Filtrando as categorias por tipo
        this.filtro.materialCategorias = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria.filter(obj =>
          this.filtro.materialTiposSelecionados.map(obj2 => obj2.id).includes(obj.materialTipo.id)
        );

        // Recebe todas as categorias filtrando as selecionadas
        // Para evitar erro quando o materialCategoriasS só possui id e não possui o obj TipoMaterial
        if (this.filtro.materialCategoriasSelecionadas.length > 0) {

          this.filtro.materialCategoriasSelecionadas = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria.filter(obj =>
            this.filtro.materialCategoriasSelecionadas.map(obj2 => obj2.id).includes(obj.id)
          );
        }

        // Filtrando as categorias selecionadas por tipo
        this.filtro.materialCategoriasSelecionadas = this.filtro.materialCategoriasSelecionadas.filter(obj =>
          this.filtro.materialTiposSelecionados.map(obj2 => obj2.id).includes(obj.materialTipo.id));

      } else {
        this.filtro.materialCategorias = this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria;
      }
    }
  }


  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  private definirFiltrosSelecionados(parametros: ParametrosAutorizarPublicacao): void {

    this.filtro.pesquisa = parametros.pesquisa;

    const anuncioSituacoes: AnuncioSituacao[] = parametros.anuncioSituacoes.split(',').filter(v => v != '').map((nome) => <AnuncioSituacao>{ nome });
    this.filtro.anuncioSituacoesSelecionadas = anuncioSituacoes;
    if (this.filtro.anuncioSituacoesSelecionadas.length > 0) {
      this.filtro.anuncioSituacoesSelecionadas = this.filtro.anuncioSituacoes.filter(obj =>
        this.filtro.anuncioSituacoesSelecionadas.map(obj2 => obj2.nome).includes(obj.nome)
      );
    } else {
      this.filtro.anuncioSituacoesSelecionadas = [];
    }

    const materialTipos: MaterialTipo[] = parametros.materialTipos.split(',').filter(v => v != '').map((nome) => <MaterialTipo>{ nome });
    this.filtro.materialTiposSelecionados = materialTipos;

    const materialCategorias: MaterialCategoria[] = parametros.materialCategorias.split(',').filter(v => v != '').map((nome) => <MaterialCategoria>{ nome });
    this.filtro.materialCategoriasSelecionadas = materialCategorias;

    const materialSituacoes: MaterialSituacao[] = parametros.materialSituacoes.split(',').filter(v => v != '').map((nome) => <MaterialSituacao>{ nome });
    this.filtro.materialSituacoesSelecionadas = materialSituacoes;

    const ufs: Uf[] = parametros.ufs.split(',').filter(v => v != '').map((sigla) => <Uf>{ sigla });
    this.filtro.ufsSelecionadas = ufs;

    const periodoInclusao: Date[] = parametros.periodoInclusao.split(',').filter(v => v != '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInclusao.length != 0) {
      this.filtro.periodoInclusao = periodoInclusao;
    }

    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v != '').map(v => <AnuncioChip>{ value: v, display: v });
    this.filtro.anuncios = anuncios;
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'meus-anuncios-lista' monitora a URL para carregar novos anúncios.
   */
  public aplicarFiltros(): void {
    const anuncioSituacoes: string = this.filtro.anuncioSituacoesSelecionadas.map(m => m.nome).join(',');
    const materialTipos: string = this.filtro.materialTiposSelecionados.map(m => m.nome).join(',');
    const materialCategorias: string = this.filtro.materialCategoriasSelecionadas.map(m => m.nome).join(',');
    const materialSituacoes: string = this.filtro.materialSituacoesSelecionadas.map(m => m.nome).join(',');
    const ufs: string = this.filtro.ufsSelecionadas.map(m => m.sigla).join(',');

    let periodoInclusao: string = '';
    if (this.filtro.periodoInclusao != null) {
      periodoInclusao = this.filtro.periodoInclusao.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }
    const anuncios: string = this.filtro.anuncios.map(m => m.display).join(',');

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosGoverno = this.anuncioService.obterParametrosAutorizarPublicacaoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.AUTORIZAR_PUBLICACAO], {
      queryParams: {
        pesquisa: this.filtro.pesquisa,
        anuncioSituacoes: anuncioSituacoes,
        materialTipos: materialTipos,
        materialCategorias: materialCategorias,
        materialSituacoes: materialSituacoes,
        ufs: ufs,
        periodoInclusao: periodoInclusao,
        anuncios: anuncios,
        ordem: parametros.ordem,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  public limparFiltros(): void {
    this.filtro.pesquisa = null;
    this.filtro.anuncioSituacoesSelecionadas = [];
    this.filtro.materialTiposSelecionados = [];
    this.filtro.materialCategoriasSelecionadas = [];
    this.filtro.materialSituacoesSelecionadas = [];
    this.filtro.ufsSelecionadas = [];
    this.filtro.periodoInclusao = null;
    this.filtro.anuncios = [];
    this.aplicarFiltros();
    this.mostraSituacaoMaterial = true;
  }

  public limparPeriodoInclusao(input): void {
    this.filtro.periodoInclusao = null;
    this.fecharCalendario(input);
  }


}

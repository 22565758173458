import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from 'primeng/components/common/messageservice';
import { AuthGuard } from './auth-guard.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private guardService: AuthGuard,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq;

    if (req.url.endsWith('/login')) {
      // nao modifica a requisicao para o endpoint de login
      modifiedReq = req;
    } else {
      // para todas as outras requisicoes, adiciona o cabecalho de autorizacao
      const authHeader = this.auth.getAuthorizationHeader();
      const authReq = req.clone({ setHeaders: { Authorization: authHeader } });
      modifiedReq = authReq;
    }

    return next.handle(modifiedReq)
      .pipe(
          tap(
              (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.atualizarTokenResponse(event);
                }
              },
              (err: any) => {
                  if (err instanceof HttpErrorResponse) {

                      if (err.status === 401) {
                        //token inválido, expirado ou não enviado na requisiçãowe
                          this.auth.logoutFrontend();

                          this.messageService.add({
                            severity: 'error',
                            summary: 'Proibido',
                            detail: 'Precisa entrar no sistema.'});

                        this.guardService.goLogin(window.location.href, '');

                      } else if (err.status === 403) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Proibido',
                            detail: 'Usuário sem permissão.'});

                        return false;
                    }
                  }
              }
          )
      );
    }

    /**
     * Cada response do backend traz um novo token, com uma nova data de expiração.
     * Token deve ser atualizado no Local Storage.
     *
     * @param response
     */
    private atualizarTokenResponse(response: HttpResponse<any>):void {
      let novoToken = response.headers.get('Authorization');
      if (novoToken && this.authService.isLoggedIn) {
        console.log('novo token');
        //remove o prefixo 'Bearer '
        novoToken = novoToken.replace('Bearer ', '');
        //atualiza em Local Storage
        this.authService.alterarToken(novoToken);
      }
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { Uf } from '../../models/uf.model';
import { Municipio } from '../../models/municipio.model';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';
import { AbstractCadastroAnuncioPrivado } from '../cadastro-anuncio-privado-page/abstract-cadastro-anuncio-privado';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { PessoaTipo } from 'app/anuncio/models/pessoa-tipo.model';
import { RespostaSalvarAnuncioIdentificacaoDoador } from 'app/anuncio/models/resposta-salvar-anuncio-identificacao-doador.model';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { RouteService } from 'app/shared/services/route.service';
import { MunicipioService } from 'app/anuncio/services/municipio.service';

@Component({
  selector: 'reuse-cadastro-anuncio-privado-identificacao-doador',
  templateUrl: './cadastro-anuncio-privado-identificacao-doador.component.html',
  styleUrls: ['./cadastro-anuncio-privado-identificacao-doador.component.scss']
})
export class CadastroAnuncioPrivadoIdentificacaoDoadorComponent extends AbstractCadastroAnuncioPrivado implements OnInit {

  //formulário
  formulario: FormGroup;

  //lista de entidades vinculadas ao usuario privado para exibir no comboboxdia
  listaEntidadeUsuarioPrivado: Entidade[] = [];

  //lista de entidades vinculadas ao usuario privado para exibir no comboboxdia
  listaPessoaTipo: PessoaTipo[] = [
    { id: 1, sigla: 'PESSOA FÍSICA - PF' },
    { id: 2, sigla: 'PESSOA JURÍDICA - PJ' }];

  //lista de ufs disponíveis para exibir no combobox
  listaUf: Uf[] = [];
  //lista de municípios disponíveis para exibir no combobox
  listaMunicipio: Municipio[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public cadastroAnuncioService: CadastroAnuncioService,
    private municipioService: MunicipioService,
    private utilService: UtilService,
    private messageService: MessageService,
    private router: Router,
    private routeService: RouteService) {
    super(cadastroAnuncioService);
  }

  ngOnInit(): void {
    if (this.router.url == '/cadastro-anuncio-privado/novo') {
      this.atualizaAnuncio(this.numeroAnuncio);
    }
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  public atualizaAnuncio(numeroAnuncio: number): void {
    this.passo = 0;
    this.numeroAnuncio = numeroAnuncio;
    if (this.anuncio) {
      this.anuncio.id = numeroAnuncio;
    }
    this.obterIdentificacaoDoador();
  }

  private obterIdentificacaoDoador(): void {
    this.cadastroAnuncioService.obterAnuncioIdentificacaoDoador(this.numeroAnuncio)
      .subscribe(
        (dados) => {
          //dados do anúncio
          this.anuncio = dados.anuncio;

          this.listaUf = dados.listaUf;
          //adiciona opção 'Selecione' (vazia)
          this.listaUf.unshift(<Uf>{ id: null, sigla: 'Selecione' });

          this.criarFormulario();
          this.carregarEntidadeUsuarioPrivado();
          this.carregarMunicipio();

          this.carregouDados = true;
          window.scrollTo(0, 0);

          this.utilService.verificarRegistroBloqueado(this.anuncio, true);
        }
      );
  }

  private isEntidade(entidade: Entidade): boolean {
    if (!entidade || !entidade.id || entidade.id == -1) {
      return false;
    }
    return true;
  }

  private isMunicipioValido(municipio: Municipio): boolean {
    if (!municipio || !municipio.id || municipio.id == -1) {
      return false;
    }
    return true;
  }

  public isPessoaJuridica(): boolean {
    const pessoaTipoSelecionado: any = this.formulario.get('pessoaTipo').value;
    if (pessoaTipoSelecionado.id == 2) {
      return true;
    }
    return false;
  }

  /**
   * Cria o formulário para inserção ou edição.
   */
  private criarFormulario() {

    const entidadeSelAnuncio: Entidade = this.anuncio.entidade;
    const usuarioSelAnuncio: Usuario = this.anuncio.usuario;
    const municipio: Municipio = (this.isMunicipioValido(this.anuncio.municipio) ? this.anuncio.municipio : (this.isEntidade(entidadeSelAnuncio) ? this.isMunicipioValido(entidadeSelAnuncio.municipio) ? entidadeSelAnuncio.municipio : null : usuarioSelAnuncio.municipioPrivado));
    const uf: Uf = (municipio ? municipio.uf : null);
    const nome: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.nome : usuarioSelAnuncio.nome);
    const email: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.email : usuarioSelAnuncio.emailPrivado);
    const telefone1: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.telefone1 : usuarioSelAnuncio.telefone1Privado);
    const telefone2: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.telefone2 : usuarioSelAnuncio.telefone2Privado);
    const endereco: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.endereco : usuarioSelAnuncio.enderecoPrivado);
    const enderecoNumero: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.enderecoNumero : usuarioSelAnuncio.enderecoNumeroPrivado);
    const bairro: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.bairro : usuarioSelAnuncio.bairroPrivado);
    const cep: string = (this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio.cep : usuarioSelAnuncio.cepPrivado);
    const pessoaTipo: PessoaTipo = (this.isEntidade(entidadeSelAnuncio) ? { id: 2, sigla: 'PESSOA JURÍDICA - PJ' } : { id: 1, sigla: 'PESSOA FÍSICA - PF' });

    this.formulario = this.formBuilder.group({
      entidadeUsuarioPrivado: [this.isEntidade(entidadeSelAnuncio) ? entidadeSelAnuncio : null],
      pessoaTipo: [pessoaTipo, [Validators.required, this.utilService.validarIdEntidade]],
      cpfSocioMajoritario: [this.anuncio.cpfSocioMajoritario, [Validators.maxLength(11)]],
      municipio: [municipio, [Validators.required, this.utilService.validarIdEntidade]],
      uf: [uf, [Validators.required, this.utilService.validarIdEntidade]],
      nome: [nome],
      email: [email, [Validators.required, Validators.email, Validators.maxLength(255)]],
      telefone1: [telefone1, [Validators.required, this.utilService.validarTelefone, Validators.maxLength(20)]],
      telefone2: [telefone2, [this.utilService.validarTelefone, Validators.maxLength(20)]],
      endereco: [endereco, [Validators.required, Validators.maxLength(255)]],
      enderecoNumero: [enderecoNumero, [Validators.required, Validators.maxLength(40)]],
      bairro: [bairro, [Validators.required, Validators.maxLength(100)]],
      cep: [cep, [Validators.required, this.utilService.validarCep, Validators.maxLength(9)]],
    });
  }

  private preencherFormularioUsuario(dadosUsuario: Usuario) {

    this.formulario.get('municipio').setValue(dadosUsuario ? (this.isMunicipioValido(dadosUsuario.municipioPrivado) ? dadosUsuario.municipioPrivado : null) : null);
    this.formulario.get('uf').setValue(dadosUsuario ? (this.isMunicipioValido(dadosUsuario.municipioPrivado) ? dadosUsuario.municipioPrivado.uf : null) : null);
    this.formulario.get('nome').setValue(dadosUsuario ? (dadosUsuario.nome) : null);
    this.formulario.get('email').setValue(dadosUsuario ? (dadosUsuario.emailPrivado) : null);
    this.formulario.get('telefone1').setValue(dadosUsuario ? (dadosUsuario.telefone1Privado) : null);
    this.formulario.get('telefone2').setValue(dadosUsuario ? (dadosUsuario.telefone2Privado) : null);
    this.formulario.get('endereco').setValue(dadosUsuario ? (dadosUsuario.enderecoPrivado) : null);
    this.formulario.get('enderecoNumero').setValue(dadosUsuario ? (dadosUsuario.enderecoNumeroPrivado) : null);
    this.formulario.get('bairro').setValue(dadosUsuario ? (dadosUsuario.bairroPrivado) : null);
    this.formulario.get('cep').setValue(dadosUsuario ? (dadosUsuario.cepPrivado) : null);

    this.carregarMunicipio();
  }

  private preencherFormularioEntidade(dadosEntidade: Entidade) {

    this.formulario.get('municipio').setValue(dadosEntidade ? (this.isMunicipioValido(dadosEntidade.municipio) ? dadosEntidade.municipio : null) : null);
    this.formulario.get('uf').setValue(dadosEntidade ? (this.isMunicipioValido(dadosEntidade.municipio) ? dadosEntidade.municipio.uf : null) : null);
    this.formulario.get('nome').setValue(dadosEntidade ? (dadosEntidade.nome) : null);
    this.formulario.get('email').setValue(dadosEntidade ? (dadosEntidade.email) : null);
    this.formulario.get('telefone1').setValue(dadosEntidade ? (dadosEntidade.telefone1) : null);
    this.formulario.get('telefone2').setValue(dadosEntidade ? (dadosEntidade.telefone2) : null);
    this.formulario.get('endereco').setValue(dadosEntidade ? (dadosEntidade.endereco) : null);
    this.formulario.get('enderecoNumero').setValue(dadosEntidade ? (dadosEntidade.enderecoNumero) : null);
    this.formulario.get('bairro').setValue(dadosEntidade ? (dadosEntidade.bairro) : null);
    this.formulario.get('cep').setValue(dadosEntidade ? (dadosEntidade.cep) : null);
    this.formulario.get('cpfSocioMajoritario').setValue(dadosEntidade ? (this.anuncio.cpfSocioMajoritario) : null);

    this.carregarMunicipio();
  }

  private bloqueioDesbloqueioFormulario(bloqueio: boolean) {
    if (bloqueio) {
      this.formulario.get('cpfSocioMajoritario').disable();
      this.formulario.get('municipio').disable();
      this.formulario.get('uf').disable();
      this.formulario.get('nome').disable();
      this.formulario.get('email').disable();
      this.formulario.get('telefone1').disable();
      this.formulario.get('telefone2').disable();
      this.formulario.get('endereco').disable();
      this.formulario.get('enderecoNumero').disable();
      this.formulario.get('bairro').disable();
      this.formulario.get('cep').disable();
    } else {
      this.formulario.get('cpfSocioMajoritario').enable();
      this.formulario.get('municipio').enable();
      this.formulario.get('uf').enable();
      this.formulario.get('nome').enable();
      this.formulario.get('email').enable();
      this.formulario.get('telefone1').enable();
      this.formulario.get('telefone2').enable();
      this.formulario.get('endereco').enable();
      this.formulario.get('enderecoNumero').enable();
      this.formulario.get('bairro').enable();
      this.formulario.get('cep').enable();
    }
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    // Nesse primeiro caso nao precisa salvar, pois somente pode alterar as fotos.
    if (this.isPessoaJuridica() && !this.isEntidade(this.formulario.get('entidadeUsuarioPrivado').value)) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo doador deve ser preenchido, caso tipo doador seja pessoa jurídica.' });
    } else if (this.isPessoaJuridica() && (!this.formulario.get('cpfSocioMajoritario') || !this.utilService.validaCpf(this.formulario.get('cpfSocioMajoritario').value))) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo CPF sócio majoritário deve ser preenchido com valor valido, caso tipo doador seja pessoa jurídica.' });
    } else if (this.formulario.valid) {
      this.cadastroAnuncioService.salvarIdentificacaoDoador(this.formulario, this.anuncio).subscribe(
        (resultado: RespostaSalvarAnuncioIdentificacaoDoador) => {
          this.numeroAnuncio = resultado.idAnuncio;
          if (this.anuncio) {
            this.anuncio.id = this.numeroAnuncio;
          }
          // if (this.anuncio == null) {
          //   this.novoNumeroAnuncio.emit(resultado.idAnuncio);
          // }
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
          this.onProximo();
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  /**
   * onChange para para dropdown 'Município'.
   */
  public onChangeMunicipio(): void {
    this.formulario.get('municipio').setValue({ 'id': null });
    this.carregarMunicipio();
  }

  /**
   * Carrega a opção de 'Municípios' a partir da 'Uf' selecionada.
   */
  private carregarMunicipio(): void {
    // const idUf:number = this.formulario.get('uf').value.id;
    const ufSelecionado: any = this.formulario.get('uf').value;
    if (ufSelecionado == null || ufSelecionado.id == null) {
      //caso não exista 'uf' selecionado, remove as opção de 'municípios'
      this.listaMunicipio = [];
      this.formulario.get('municipio').disable();
    } else {
      this.municipioService.listarMunicipiosAtivos(ufSelecionado.id)
        .subscribe(
          (municipios) => {
            this.listaMunicipio = municipios;
            this.listaMunicipio.unshift(<Municipio>{ id: null, nome: 'Selecione' });
            this.formulario.get('municipio').enable();
          }
        );
    }
  }

  /**
    * onChange para para dropdown 'Entidade Usuario Privado'.
   */
  public onChangeEntidadeUsuarioPrivado(): void {
    this.formulario.get('entidadeUsuarioPrivado').setValue({ 'id': null });
    this.carregarEntidadeUsuarioPrivado();
  }

  /**
   * Carrega a opção de 'Entidade usuario privado' a partir da pessoa tipo selecionada.
   */
  private carregarEntidadeUsuarioPrivado(): void {
    const pessoaTipoSelecionado: any = this.formulario.get('pessoaTipo').value;
    const entidadeFormulario: any = this.formulario.get('entidadeUsuarioPrivado').value;
    if (pessoaTipoSelecionado.id == 1) {

      // desconsidera os campos obrigatorios para pessoa juridica, quando for pessoa fisica.
      this.formulario.get('entidadeUsuarioPrivado').setValue(null);
      this.formulario.get('cpfSocioMajoritario').setValue(null);
      this.formulario.controls.entidadeUsuarioPrivado.setValidators(null);
      this.formulario.controls.cpfSocioMajoritario.setValidators(null);
      this.formulario.controls.entidadeUsuarioPrivado.setErrors(null);
      this.formulario.controls.cpfSocioMajoritario.setErrors(null);
      this.formulario.get('entidadeUsuarioPrivado').disable();
      this.formulario.get('cpfSocioMajoritario').disable();

      // preenche o formulario com os dados do usuario privado logado.
      const usuarioPrivado: Usuario = this.anuncio.usuario;
      this.preencherFormularioUsuario(usuarioPrivado);
      this.bloqueioDesbloqueioFormulario(false);

    } else if (pessoaTipoSelecionado.id == 2) {

      // Valida os campos que sao obrigatorios caso seja pessoa juridica.
      this.formulario.get('entidadeUsuarioPrivado').setValue(this.isEntidade(this.anuncio.entidade) ? this.anuncio.entidade : null);
      this.formulario.get('cpfSocioMajoritario').setValue(this.anuncio.cpfSocioMajoritario);
      this.formulario.controls.entidadeUsuarioPrivado.setValidators([Validators.required, this.utilService.validarIdEntidade]);
      this.formulario.controls.cpfSocioMajoritario.setValidators([Validators.required]);
      this.formulario.controls.cpfSocioMajoritario.setErrors(null);
      this.formulario.controls.entidadeUsuarioPrivado.setErrors(null);

      this.cadastroAnuncioService.obterEntidadeUsuarioPrivado()
        .subscribe(
          (entidades) => {

            this.listaEntidadeUsuarioPrivado = [];
            for (let entidade of entidades) {
              entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
              this.listaEntidadeUsuarioPrivado.push(entidade);
            }

            this.listaEntidadeUsuarioPrivado.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
            this.formulario.get('entidadeUsuarioPrivado').enable();
            this.formulario.get('cpfSocioMajoritario').enable();

            if (this.isEntidade(entidadeFormulario)) {
              // limpa o formulario, pois o usuario ainda vai selecionar a entidade desejada
              this.preencherFormularioEntidade(entidadeFormulario);
              this.bloqueioDesbloqueioFormulario(false);
            } else {
              this.bloqueioDesbloqueioFormulario(true);
              this.preencherFormularioEntidade(null);
            }
          }
        );
    }
  }

  public onChangeCpfSocioMajoritario(): void {
    const entidadeUsuarioPrivado: any = this.formulario.get('entidadeUsuarioPrivado').value;
    if (entidadeUsuarioPrivado == null || entidadeUsuarioPrivado.id == null) {
      this.bloqueioDesbloqueioFormulario(true);
      // limpa o formulario, pois o usuario ainda vai selecionar a entidade desejada
      this.preencherFormularioEntidade(null);
    } else {
      // preenche o formulario com os dados da entidade vinculada ao usuario privado logado
      this.preencherFormularioEntidade(entidadeUsuarioPrivado);

      this.bloqueioDesbloqueioFormulario(false);
    }
  }

  /**
   * Clique botão 'Cancelar'.
   */
  public onCancelar(): void {
    // navega para página de meus anúncio
    this.routeService.voltar(OrigemEnum.MEUS_ANUNCIOS_PRIVADO);
  }

  numberOnlyEvent(event): boolean {
    return this.utilService.numberOnlyEvent(event);
  }
}

import { environment } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthGuard } from 'app/shared/services/auth-guard.service';
import { AuthService } from 'app/shared/services/auth.service';
import { Usuario } from '../../../anuncio/models/usuario.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AnuncioService } from '../../../anuncio/services/anuncio.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { MensagemService } from '../../../mensagem/services/mensagem.service';
import { ParametrosAnuncios } from '../../../anuncio/models/parametros-anuncios.model';
import { PesquisaAnuncioPrincipal } from 'app/anuncio/provider/dados.provider';
import { OrigemEnum } from 'app/shared/enum/origem.enum';

@Component({
  selector: 'reuse-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public pesquisa: string;
  contadorAutorizarPublicacao: number = 0;
  contadorAutorizarDoacao: number = 0;
  contadorConfirmarDoacao: number = 0;
  contadorMensagem: number = 0;
  contadorSomatario: number = 0;
  loadingContadorAutorizarPublicacao: boolean = false;
  loadingContadorAutorizarDoacao: boolean = false;
  loadingContadorConfirmarDoacao: boolean = false;
  loadingContadorMensagem: boolean = false;
  nomeAmbiente: String;
  activeBar: number = 0;

  ANUNCIOS = OrigemEnum.ANUNCIOS;
  MEUS_ANUNCIOS_GOVERNO = OrigemEnum.MEUS_ANUNCIOS_GOVERNO;
  MEUS_ANUNCIOS_PRIVADO = OrigemEnum.MEUS_ANUNCIOS_PRIVADO;

  REPUBLICAR_ANUNCIOS_PRIVADO = OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO;

  MEUS_INTERESSES = OrigemEnum.MEUS_INTERESSES;
  MEUS_ANUNCIOS_INTERESSES = OrigemEnum.MEUS_ANUNCIOS_INTERESSES;
  AUTORIZAR_PUBLICACAO = OrigemEnum.AUTORIZAR_PUBLICACAO;
  CONSULTAR_ANUNCIO_PRIVADO = OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO;
  LISTAR_CADASTRO_USUARIO_GOVERNO = OrigemEnum.LISTAR_CADASTRO_USUARIO_GOVERNO;
  LISTAR_CADASTRO_ENTIDADE = OrigemEnum.LISTAR_CADASTRO_ENTIDADE;

  CONFIRMAR_DOACAO = OrigemEnum.CONFIRMAR_DOACAO;
  RELATORIO_ANUNCIO = OrigemEnum.RELATORIO_ANUNCIO;

  // Variaveis layout DSGOV
  showFunctionsDropdown = false;
  showLinksDropdown = false;
  showMenu = false;
  showSearch = false;
  showNotification = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private guardService: AuthGuard,
    private anuncioService: AnuncioService,
    private mensagemService: MensagemService,
    private appMessageService: AppMessageService,
    private messageService: MessageService,
    private dadosPesquisaAnuncioPrincipal: PesquisaAnuncioPrincipal,) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.selecionarBarraAtivaUrl();
      }
    });
  }

  ngOnInit() {
    this.nomeAmbiente = environment.nomeAmbiente;
    this.monitorarUrl();
    this.monitorarAppMessage();
    if (this.isLoggedIn) {
      this.loadContadorMensagem();
      this.loadContadorAutorizarDoacao();
      this.loadContadorConfirmarDoacao();
      this.loadContadorAutorizarPublicacao();
    }
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get usuarioLogado(): Usuario {
    return this.authService.usuario;
  }

  get usuarioMenu(): String {
    return this.mascaraCpf(this.usuarioLogado.cpf)
      + ' - ' + this.usuarioLogado.nome.toUpperCase()
      + " / " + this.usuarioLogado.numeroEntidade + ' - ' + this.usuarioLogado.nomeEntidade.toUpperCase();
  }

  get hasNotification(): boolean {
    return this.totalNotificacoes > 0;
  }

  get totalNotificacoes(): number {
    return this.contadorAutorizarDoacao + this.contadorConfirmarDoacao + this.contadorAutorizarPublicacao + this.contadorMensagem;
  }

  mascaraCpf(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  }

  /**
   * Chama o logout do backend, passando o token.
   * Após receber resposta realiza logout do frontend, destroi o token e redireciona para página inicial.
   */
  logout() {
    this.authService.logoutBackend()
      .subscribe((resultado) => {
        this.pesquisar();
      });
  }

  goPrincipal() {
    // this.pesquisarParametro('');
    this.pesquisa = "";
    this.router.navigate(['/']);
  }

  goAnuncios() {
    this.pesquisarParametro('');
  }

  goFaq() {
    this.router.navigate(['/faq']);
  }

  goFale() {
    this.router.navigate(['/fale']);
  }

  goSobre() {
    this.router.navigate(['/sobre']);
  }

  pesquisar() {
    this.pesquisarParametro(this.pesquisa);
  }

  pesquisarParametro(pesquisa: string) {
    // Limpa os dados armazenados para consulta principal.
    this.dadosPesquisaAnuncioPrincipal.listaAnuncianteTipo = null;
    this.dadosPesquisaAnuncioPrincipal.listaMaterialCategoria = null;
    this.dadosPesquisaAnuncioPrincipal.listaMaterialSituacao = null;
    this.dadosPesquisaAnuncioPrincipal.listaMaterialTipo = null;
    this.dadosPesquisaAnuncioPrincipal.listaUf = null;
    this.dadosPesquisaAnuncioPrincipal.listaOnus = null;
    this.dadosPesquisaAnuncioPrincipal.anuncioSituacoes = null;

    this.router.navigate([OrigemEnum.ANUNCIOS], {
      queryParams: {
        pesquisa: pesquisa,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.PESQUISAR));
  }

  goLogin() {
    // not logged in so redirect to login page with the return url and return false
    this.guardService.goLogin(window.location.href, window.location.href);
  }

  public queroDoar() {
    if (!this.isLoggedIn) {
      this.goLogin();
      return;
    }

    if (this.authService.hasRole(['PRIVADO'])) {
      this.onNovoAnuncioPrivado();
    } else {
      this.onNovoAnuncioGoverno();
    }
  }

  private onNovoAnuncioGoverno(): void {
    this.router.navigate(['/' + OrigemEnum.CADASTRO_ANUNCIO_GOVERNO, 'novo']);
  }

  private onNovoAnuncioPrivado(): void {
    this.router.navigate(['/' + OrigemEnum.CADASTRO_ANUNCIO_PRIVADO, 'novo']);
  }

  private atualizarContadores() {
    this.loadContadorMensagem();
    this.loadContadorAutorizarDoacao();
    this.loadContadorConfirmarDoacao();
    this.loadContadorAutorizarPublicacao();
  }

  private loadContadorMensagem() {
    if (this.loadingContadorMensagem) {
      return;
    }

    if (!this.isLoggedIn) {
      setTimeout(() => { this.loadContadorMensagem(); }, 30000);
      return;
    }

    //Recupera a quantidade de mensagens...
    this.loadingContadorMensagem = true;
    this.mensagemService.obterContadorMensagem()
      .subscribe((resultado: number) => {
        this.contadorMensagem = resultado;
        this.loadingContadorMensagem = false;
        setTimeout(() => { this.loadContadorMensagem(); }, 60000);
      }, (e: any) => {
        //se hove erro, nao exibe o contador
        this.contadorMensagem = 0;
        this.loadingContadorMensagem = false;
        console.error('Erro ao carregar contador Mensagem: ' + e);
        console.error('Aguardando 1 minuto...');
        setTimeout(() => { this.loadContadorMensagem(); }, 60000);
      }
      )
  }

  private loadContadorAutorizarDoacao() {
    if (this.loadingContadorAutorizarDoacao) {
      return;
    }

    if (!this.authService.hasRole(['GESTOR', 'GOVERNO', 'SEGES', 'PRIVADO'])) {
      setTimeout(() => { this.loadContadorAutorizarDoacao(); }, 30000);
      return;
    }

    //Recupera a quantidade de anuncios a autorizar...
    this.loadingContadorAutorizarDoacao = true;
    this.anuncioService.obterContadorAutorizarDoacao()
      .subscribe((resultado: number) => {
        this.contadorAutorizarDoacao = resultado;
        this.loadingContadorAutorizarDoacao = false;
        setTimeout(() => { this.loadContadorAutorizarDoacao(); }, 60000);
      }, (e: any) => {
        //se hove erro, nao exibe o contador
        this.contadorAutorizarDoacao = 0;
        this.loadingContadorAutorizarDoacao = false;
        console.error('Erro ao carregar contador Autorizar Doação: ' + e);
        console.error('Aguardando 1 minuto...');
        setTimeout(() => { this.loadContadorAutorizarDoacao(); }, 60000);
      }
      );
  }

  private loadContadorConfirmarDoacao() {
    if (this.loadingContadorConfirmarDoacao) {
      return;
    }

    if (!this.authService.hasRole(['GOVERNO'])) {
      setTimeout(() => { this.loadContadorConfirmarDoacao(); }, 30000);
      return;
    }

    //Recupera a quantidade de anuncios a Confirmar...
    this.loadingContadorConfirmarDoacao = true;
    this.anuncioService.obterContadorConfirmarDoacao()
      .subscribe((resultado: number) => {
        this.contadorConfirmarDoacao = resultado;
        this.loadingContadorConfirmarDoacao = false;
        setTimeout(() => { this.loadContadorConfirmarDoacao(); }, 60000);
      }, (e: any) => {
        //se hove erro, nao exibe o contador
        this.contadorConfirmarDoacao = 0;
        this.loadingContadorConfirmarDoacao = false;
        console.error('Erro ao carregar contador Confirmar Doação: ' + e);
        console.error('Aguardando 1 minuto...');
        setTimeout(() => { this.loadContadorConfirmarDoacao(); }, 60000);
      }
      );
  }

  private loadContadorAutorizarPublicacao() {
    if (this.loadingContadorAutorizarPublicacao) {
      return;
    }

    if (!this.authService.hasRole(['CENTRALCOM', 'GOVERNO', 'SEGES'])) {
      setTimeout(() => { this.loadContadorAutorizarPublicacao(); }, 30000);
      return;
    }

    //Recupera a quantidade de anuncios pendentes, aguardando complementacao, complementacao enviada... a autorizar...
    this.loadingContadorAutorizarPublicacao = true;
    this.anuncioService.obterContadorAutorizarPublicacao()
      .subscribe((resultado: number) => {
        this.contadorAutorizarPublicacao = resultado;
        this.loadingContadorAutorizarPublicacao = false;
        setTimeout(() => { this.loadContadorAutorizarPublicacao(); }, 60000);
      }, (e: any) => {
        //se hove erro, nao exibe o contador
        this.contadorAutorizarPublicacao = 0;
        this.loadingContadorAutorizarPublicacao = false;
        console.error('Erro ao carregar contador Autorizar Publicação: ' + e);
        console.error('Aguardando 1 minuto...');
        setTimeout(() => { this.loadContadorAutorizarPublicacao(); }, 60000);
      }
      );
  }

  onShowMenuDeslogado() {
    //document.getElementById('menuUsuarioDeslogadoU1').parentElement.parentElement.style.zIndex = '999999';
  }

  public goUrl(url: string) {
    this.router.navigate(['/' + url], {
      queryParams: {
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  public goMensagens() {
    this.router.navigate(['/mensagens'], {
      queryParams: {
        mensagemSituacoes: 'Lida,Não lida',
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        this.recuperarTextoPesquisaUrl(params);
      });
  }

  private monitorarAppMessage() {
    this.appMessageService.getMessage().subscribe(appMessage => {
      if (appMessage.messageType == AppMessageType.LOGIN) {
        this.atualizarContadores();
      }
    });
  }

  private recuperarTextoPesquisaUrl(params) {
    if (this.router.url.startsWith('/anuncios?')) {
      const parametros: ParametrosAnuncios = this.anuncioService.obterParametrosAnunciosUrl(params);
      this.pesquisa = parametros.pesquisa;
    }
  }

  public selecionarBarraAtivaUrl() {
    let url = this.router.url.substring(1);
    if (url == '') {
      this.activeBar = 0;
    } else if (url.startsWith('faq')) {
      this.activeBar = 2;
    } else if (url.startsWith('fale')) {
      this.activeBar = 3;
    } else if (url.startsWith('sobre')) {
      this.activeBar = 4;
    } else if (url.startsWith('login')) {
      this.activeBar = 5;
    } else if (url.startsWith(OrigemEnum.CADASTRO_ANUNCIO_GOVERNO) || url.startsWith(OrigemEnum.CADASTRO_ANUNCIO_PRIVADO)
      || url.startsWith(OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_GOVERNO) || url.startsWith(OrigemEnum.ALTERAR_CADASTRO_ANUNCIO_PRIVADO)) {
      this.activeBar = 6;
    } else if (url.startsWith(this.ANUNCIOS) || url.startsWith(this.MEUS_ANUNCIOS_GOVERNO)
      || url.startsWith(this.MEUS_ANUNCIOS_PRIVADO) || url.startsWith(this.MEUS_ANUNCIOS_INTERESSES)
      || url.startsWith(this.MEUS_INTERESSES) || url.startsWith(this.AUTORIZAR_PUBLICACAO)) {
      this.activeBar = 1;
    } else {
      //nao vai ativar nenhum menu
      this.activeBar = -1;
    }
  }

  entidadeUsuarioLogado(): string {
    if (this.usuarioLogado.numeroEntidade != '99999999999999') {
      return this.usuarioLogado.numeroEntidade + ' - ' + this.usuarioLogado.siglaEntidade;
    }

    return '';
  }

  toggleFunctionsDropdown() {
    this.showFunctionsDropdown = !this.showFunctionsDropdown;
  }
  closeFunctionsDropdown() {
    this.showFunctionsDropdown = false;
  }
  toggleLinksDropdown() {
    this.showLinksDropdown = !this.showLinksDropdown;
  }
  closeLinksDropdown() {
    this.showLinksDropdown = false;
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  closeMenu() {
    this.showMenu = false;
  }
  openSearch() {
    this.showSearch = true;
  }
  closeSearch() {
    this.showSearch = false;
  }
  toggleNotification() {
    this.showNotification = !this.showNotification;
    if (this.showNotification) {
      this.atualizarContadores();
    }
  }
  closeNotification() {
    this.showNotification = false;
  }
}

//declare var $: any;
import * as  moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { BloqueioUsuarioPrivadoService } from 'app/bloqueio-usuario-privado/services/bloqueio-usuario-privado.service';
import { ParametrosBloqueioUsuarioPrivado } from 'app/bloqueio-usuario-privado/models/parametros-bloqueio-usuario-privado.model';
import { ResultadoPesquisaBloqueioUsuarioPrivado } from 'app/bloqueio-usuario-privado/models/resultado-pesquisa-bloqueio-usuario-privado.model';
import { BloqueioUsuarioEntidadeRegistro } from 'app/bloqueio-usuario-privado/models/bloqueio-usuario-entidade-registro.model';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UtilService } from 'app/shared/services/util.service';
import { BloqueioUsuarioEntidadeSave } from 'app/bloqueio-usuario-privado/models/bloqueio-usuario-entidade-save';
import { CpfPipe } from 'app/shared/pipes/cpf.pipe';
import { BloqueioUsuarioEntidade } from 'app/bloqueio-usuario-privado/models/bloqueio-usuario-entidade';
import { CnpjPipe } from 'app/shared/pipes/cnpj.pipe';


@Component({
  selector: 'reuse-bloqueio-usuario-privado-lista',
  templateUrl: './bloqueio-usuario-privado-lista.component.html',
  styleUrls: ['./bloqueio-usuario-privado-lista.component.scss']
})
export class BloqueioUsuarioPrivadoListaComponent implements OnInit {

  @ViewChild('mainTable') mainTable: Table;
  @ViewChild('motivo') motivoElement: ElementRef;

  //Guarda o tipo de pesquisa
  tipoPesquisa: string;

  // indicador para informar que os dados estao sendo recuperados no backend
  carregandoDados: boolean = false;

  //Armazena resposta do Backend.
  registros: BloqueioUsuarioEntidadeRegistro[] = [];
  registro: BloqueioUsuarioEntidadeRegistro;
  bloqueio: BloqueioUsuarioEntidadeSave;
  historico: BloqueioUsuarioEntidade[];

  algumFiltroPreenchido: boolean = false;

  tpConsulta: number;
  sortField: string;
  sortOrder: string;
  inicio = 0;
  tamanhoPagina = 10;
  totalRegistros = 0;
  colsPai: any[];
  colsFilhos: any[];
  colsHistorico: any[];

  //formulário
  formulario: FormGroup;
  exibirModalConfirmacao: boolean = false;
  exibirModalHistorico: boolean = false;
  textoHeaderModalConfirmacao: string;
  textoHeaderModalHistorico: string;

  constructor(private route: ActivatedRoute,
    private bloqueioService: BloqueioUsuarioPrivadoService,
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.montarColunas();
    this.montarColunasHistorico();
    this.monitorarUrl();
  }


  /**
   * Consultar bloqueios
   * -Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de bloqueio.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        //Limpa os dados
        this.registros = [];
        this.totalRegistros = 0;
        this.carregandoDados = true;

        const parametros: ParametrosBloqueioUsuarioPrivado = this.bloqueioService.obterParametrosUrl(params);
        this.tipoPesquisa = !params.tipoPesquisa ? 'CPF' : params.tipoPesquisa;
        this.montarColunas();

        if (!this.tpConsulta) {
          //Eh a primeira deteccao de mudanca de url (onInit).
          this.tpConsulta = 1; //Consulta "completa"
          parametros.tpConsulta = this.tpConsulta + '';
        }

        //So prossegue se tiver algum campo preenchido
        if (this.tipoPesquisa == 'CPF' && !params.cpf && !params.nome) {
          this.algumFiltroPreenchido = false;
          this.carregandoDados = false;
          return false;
        }
        if (this.tipoPesquisa == 'CNPJ' && !params.cnpj && !params.razaoSocial) {
          this.algumFiltroPreenchido = false;
          this.carregandoDados = false;
          return false;
        }

        this.algumFiltroPreenchido = true;

        if (parametros.tpConsulta == '1') { //Consulta completa
          this.mainTable.reset();
        }

        this.bloqueioService.consultar(parametros).subscribe(
          (resultado: ResultadoPesquisaBloqueioUsuarioPrivado) => {
            this.mainTable.first = resultado.inicio; //bug do component, reatribui paginacao
            this.registros = resultado.registros;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.sortField = resultado.sortField;
            this.sortOrder = resultado.sortOrder;
            this.carregandoDados = false;
            window.scrollTo(0, 0);
          },
          () => this.carregandoDados = false);
      });
  }

  /**
   * A cada troca de página, consulta 10 novas mensagens no backend.
   *
   * @param event
   */
  onPage(event) {
    this.inicio = event.first;
    //após 'inicio' ser alterado, realiza consulta
    this.tpConsulta = 2; //consulta "parcial"
    this.consultarComParametrosUrl();
  }

  /**
  * A cada troca de página, consulta 10 novas mensagens no backend.
  *
  * @param event
  */
  onSort($event: SortEvent) {
    this.sortField = $event.field;
    this.sortOrder = $event.order == -1 ? 'DESC' : 'ASC';
    this.tpConsulta = 2; //consulta "parcial"
    this.consultarComParametrosUrl();
  }

  montarColunas() {
    this.colsPai = [
      { field: 'codigo', header: this.tipoPesquisa, width: '20%' },
      { field: 'nome', header: this.tipoPesquisa == 'CPF' ? 'Nome' : 'Razão Social', width: '40%' },
      { field: 'bloqueado', header: 'Bloqueado', width: '10%' },
      { field: 'motivo', header: 'Motivo', width: '25%' }
    ];

    this.colsFilhos = [
      { field: 'codigo', header: this.tipoPesquisa == 'CPF' ? 'CNPJ' : 'CPF', width: '20%' },
      { field: 'nome', header: this.tipoPesquisa == 'CNPJ' ? 'Nome' : 'Razão Social', width: '40%' },
      { field: 'bloqueado', header: 'Bloqueado', width: '10%' },
      { field: 'motivo', header: 'Motivo', width: '25%' }
    ];
  }

  montarColunasHistorico() {
    this.colsHistorico = [
      { field: 'usuarioOperacao.cpf', header: 'Usuário', width: '20%' },
      { field: 'dataOperacao', header: 'Data', width: '20%' },
      { field: 'bloqueado', header: 'Bloqueado', width: '20%' },
      { field: 'motivo', header: 'Motivo', width: '40%' }
    ];
  }

  public tipoPesquisaCpf() {
    return this.tipoPesquisa == 'CPF';
  }

  public tipoPesquisaCnpj() {
    return this.tipoPesquisa == 'CNPJ';
  }

  public expandirRegistro(registo: BloqueioUsuarioEntidadeRegistro) {
    registo.expandido = !registo.expandido;
  }

  consultarComParametrosUrl() {
    const params = this.route.snapshot.queryParams;
    const parametros: ParametrosBloqueioUsuarioPrivado = this.bloqueioService.obterParametrosUrl(params);

    this.router.navigate(['/bloqueio-usuario-privado'], {
      queryParams: {
        tpConsulta: this.tpConsulta,
        tipoPesquisa: parametros.tipoPesquisa,
        cpf: parametros.cpf,
        nome: parametros.nome,
        cnpj: parametros.cnpj,
        razaoSocial: parametros.razaoSocial,
        inicio: this.inicio,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        totalRegistros: this.totalRegistros,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  public prepararBloquearDesbloquear(registro: BloqueioUsuarioEntidadeRegistro): void {
    this.registro = registro;

    this.bloqueio = {
      bloqueioTipo: registro.tipo,
      codigo: registro.codigo,
      bloquear: !registro.bloqueado, //se tiver bloqueado, vai desbloquear e vice-versa
      motivo: ''
    };

    if (registro.bloqueado) {
      this.textoHeaderModalConfirmacao = 'Confirma o desbloqueio?';
    } else {
      this.textoHeaderModalConfirmacao = 'Confirma o bloqueio?';
    }

    this.criarFormularioConfirmacao();
    this.exibirModalConfirmacao = true;
    setTimeout(() => { this.motivoElement.nativeElement.focus(); }, 0);
  }

  public bloquearDesbloquear(submitBtn: HTMLButtonElement): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (!this.formulario.valid) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
      return;
    }

    submitBtn.disabled = true;
    this.bloqueio.motivo = this.formulario.get('motivo').value;
    this.bloqueioService.bloquearDesbloquear(this.bloqueio).subscribe(
      (resultado: BloqueioUsuarioEntidadeRegistro) => {
        let textoAcao = this.bloqueio.bloquear ? 'Bloqueio' : 'Desbloqueio';
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: textoAcao + ' realizado com sucesso.' });

        //this.consultarComParametrosUrl(); forçava recarregar a página, mas não fica bom se tiver registros expandidos na tela
        this.registro.bloqueado = this.bloqueio.bloquear;
        this.registro.bloqueio = resultado.bloqueio;

        submitBtn.disabled = false;
        this.ocultarModalConfirmacao();
      }, () => { submitBtn.disabled = false; });
  }

  public bloqueioTipoCpf(): boolean {
    return this.bloqueio.bloqueioTipo == 'CPF';
  }

  public bloqueioTipoCnpj(): boolean {
    return this.bloqueio.bloqueioTipo == 'CNPJ';
  }

  public ocultarModalConfirmacao() {
    this.exibirModalConfirmacao = false;
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  private criarFormularioConfirmacao() {
    this.formulario = this.formBuilder.group({
      motivo: [this.bloqueio.motivo, [Validators.required]],
    });
  }

  public showTooltip(registro: BloqueioUsuarioEntidadeRegistro) {
    if (registro.bloqueio) {
      const acao = registro.bloqueio.bloqueado ? 'Bloqueado' : 'Desbloqueado';
      const cpf = new CpfPipe().transform(registro.bloqueio.usuarioOperacao.cpf);
      const nome = registro.bloqueio.usuarioOperacao.nome;
      const data = moment(registro.bloqueio.dataOperacao).format('DD-MM-YYYY HH:mm');
      return acao + ' por ' + cpf + ' - ' + nome + ' em ' + data;
    } else {
      return '';
    }
  }

  public prepararExibirHistorico(registro: BloqueioUsuarioEntidadeRegistro) {
    if (registro.tipo == 'CPF') {
      this.textoHeaderModalHistorico = 'Histórico de bloqueios do Usuário ' + new CpfPipe().transform(registro.codigo);
    } else {
      this.textoHeaderModalHistorico = 'Histórico de bloqueios da Empresa : ' + new CnpjPipe().transform(registro.codigo);
    }

    this.bloqueioService.consultarHistorico(registro).subscribe(
      (resultado: BloqueioUsuarioEntidade[]) => {
        /*tentativa de resetar a ordenacao. bug no componente
        this.tableHistorico.sortOrder = 0;
        this.tableHistorico.sortField = '';
        this.tableHistorico.reset();
        */
        this.historico = resultado;
        this.exibirModalHistorico = true;
      });
  }

  public ocultarModalHistorico() {
    this.exibirModalHistorico = false;
  }
}

import { RelatorioAnuncioListaComponent } from './components/relatorio-anuncio-lista/relatorio-anuncio-lista.component';
import { RelatorioAnuncioPageComponent } from './components/relatorio-anuncio-page/relatorio-anuncio-page.component';
import { ConfirmarDoacaoListaComponent } from './components/confirmar-doacao-lista/confirmar-doacao-lista.component';
import { ConfirmarDoacaoPageComponent } from './components/confirmar-doacao-page/confirmar-doacao-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnuncioListPageComponent } from './components/anuncio-list-page/anuncio-list-page.component';
import { AnuncioDetailPageComponent } from 'app/anuncio/components/anuncio-detail-page/anuncio-detail-page.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MeusAnunciosPrivadoPageComponent } from './components/meus-anuncios-privado-page/meus-anuncios-privado-page.component';
import { MeusInteressesPageComponent } from './components/meus-interesses-page/meus-interesses-page.component';
import { MeusInteressesListaComponent } from './components/meus-interesses-lista/meus-interesses-lista.component';
import { MeusAnunciosInteressePageComponent } from './components/meus-anuncios-interesse-page/meus-anuncios-interesse-page.component';
import { MeusAnunciosInteresseListaComponent } from './components/meus-anuncios-interesse-lista/meus-anuncios-interesse-lista.component';
import { TenhoInteresseComponent } from './components/tenho-interesse/tenho-interesse.component';
import { CadastroAnuncioPageComponent } from './components/cadastro-anuncio-page/cadastro-anuncio-page.component';
import { OrigemEnum } from '../shared/enum/origem.enum';
import { MeusAnunciosGovernoPageComponent } from './components/meus-anuncios-governo-page/meus-anuncios-governo-page.component';
import { MeusAnunciosGovernoListaComponent } from './components/meus-anuncios-governo-lista/meus-anuncios-governo-lista.component';
import { MeusAnunciosPrivadoListaComponent } from './components/meus-anuncios-privado-lista/meus-anuncios-privado-lista.component';
import { CadastroAnuncioPrivadoPageComponent } from './components/cadastro-anuncio-privado-page/cadastro-anuncio-privado-page.component';
import { AutorizarPublicacaoListaComponent } from './components/autorizar-publicacao-lista/autorizar-publicacao-lista.component';
import { AutorizarPublicacaoPageComponent } from './components/autorizar-publicacao-page/autorizar-publicacao-page.component';
import { RepublicarAnunciosPrivadoPageComponent } from './components/republicar-anuncios-privado-page/republicar-anuncios-privado-page.component';
import { RepublicarAnunciosPrivadoListaComponent } from './components/republicar-anuncios-privado-lista/republicar-anuncios-privado-lista.component';
import { ConsultarAnuncioPrivadoPageComponent } from './components/consultar-anuncio-privado-page/consultar-anuncio-privado-page.component';
import { ConsultarAnuncioPrivadoListaComponent } from './components/consultar-anuncio-privado-lista/consultar-anuncio-privado-lista.component';

const routes: Routes = [
  {
    path: 'anuncios',
    component: AnuncioListPageComponent
  },
  {
    path: 'meus-anuncios-governo',
    component: MeusAnunciosGovernoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
    children: [
      {
        path: '',
        component: MeusAnunciosGovernoListaComponent
      },
    ]
  },
  {
    path: 'meus-anuncios-privado',
    component: MeusAnunciosPrivadoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['PRIVADO']
    },
    children: [
      {
        path: '',
        component: MeusAnunciosPrivadoListaComponent
      },
    ]
  },
  {
    path: 'confirmar-doacao',
    component: ConfirmarDoacaoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GOVERNO']
    },
    children: [
      {
        path: '',
        component: ConfirmarDoacaoListaComponent
      },
    ]
  },

  {
    path: 'republicar-anuncios-privado',
    component: RepublicarAnunciosPrivadoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['PRIVADO']
    },
    children: [
      {
        path: '',
        component: RepublicarAnunciosPrivadoListaComponent
      },
    ]
  },
  {
    path: 'autorizar-publicacao',
    component: AutorizarPublicacaoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['CENTRALCOM', 'GOVERNO', 'SEGES']
    },
    children: [
      {
        path: '',
        component: AutorizarPublicacaoListaComponent
      },
    ]
  },
  {
    path: 'consultar-anuncio-privado',
    component: ConsultarAnuncioPrivadoPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['CENTRALCOM', 'SEGES']
    },
    children: [
      {
        path: '',
        component: ConsultarAnuncioPrivadoListaComponent
      },
    ]
  },
  {
    path: 'meus-interesses',
    component: MeusInteressesPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
    children: [
      {
        path: '',
        component: MeusInteressesListaComponent
      },
    ]
  },
  {
    path: 'meus-anuncios-interesse',
    component: MeusAnunciosInteressePageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO', 'SEGES', 'PRIVADO']
    },
    children: [
      {
        path: '',
        component: MeusAnunciosInteresseListaComponent,
      },
    ]
  },
  {
    path: OrigemEnum.RELATORIO_ANUNCIO,
    component: RelatorioAnuncioPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO', 'CENTRALCOM', 'SEGES']
    },
    children: [
      {
        path: '',
        component: RelatorioAnuncioListaComponent
      },
    ]
  },
  {
    path: 'anuncios/:numero',
    component: AnuncioDetailPageComponent,
    data: { origem: OrigemEnum.ANUNCIOS },
    children: [
      {
        path: 'tenho-interesse',
        component: TenhoInteresseComponent,
      },
    ]
  },
  {
    path: 'meus-anuncios-governo/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.MEUS_ANUNCIOS_GOVERNO,
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
  },
  {
    path: 'meus-anuncios-privado/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.MEUS_ANUNCIOS_PRIVADO,
      expectedRoles: ['PRIVADO']
    },
  },
  {
    path: 'confirmar-doacao/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.CONFIRMAR_DOACAO,
      expectedRoles: ['GOVERNO']
    },
  },
  {
    path: 'republicar-anuncios-privado/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.REPUBLICAR_ANUNCIOS_PRIVADO,
      expectedRoles: ['PRIVADO']
    },
  },
  {
    path: 'meus-anuncios-privado/excluir/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.MEUS_ANUNCIOS_PRIVADO,
      expectedRoles: ['PRIVADO']
    },
  },
  {
    path: 'autorizar-publicacao/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.AUTORIZAR_PUBLICACAO,
      expectedRoles: ['CENTRALCOM', 'GOVERNO', 'SEGES']
    },
  },
  {
    path: 'consultar-anuncio-privado/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO,
      expectedRoles: ['CENTRALCOM', 'SEGES']
    },
  },
  {
    path: 'meus-interesses/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.MEUS_INTERESSES,
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
    children: [
      {
        path: 'tenho-interesse',
        component: TenhoInteresseComponent
      },
    ]
  },
  {
    path: 'meus-anuncios-interesse/:numero',
    canActivate: [AuthGuard],
    component: AnuncioDetailPageComponent,
    data: {
      origem: OrigemEnum.MEUS_ANUNCIOS_INTERESSES,
      expectedRoles: ['GESTOR', 'GOVERNO', 'SEGES', 'PRIVADO']
    },
  },
  {
    path: 'cadastro-anuncio-governo/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
    component: CadastroAnuncioPageComponent,
  },
  {
    path: 'alterar-cadastro-anuncio-governo/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO']
    },
    component: CadastroAnuncioPageComponent,
  },
  {
    path: 'cadastro-anuncio-privado/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['PRIVADO']
    },
    component: CadastroAnuncioPrivadoPageComponent,
  },
  {
    path: 'alterar-cadastro-anuncio-privado/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['PRIVADO']
    },
    component: CadastroAnuncioPrivadoPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnuncioRoutingModule { }


/**
 * Mesmos valores da enum do backend.
 */
export enum AnuncioSituacaomEnum {
  ATIVO = 1,
  APROVADO = 2,
  EM_RASCUNHO = 3,
  EXCLUIDO = 4,
  PENDENTE_AUTORIZACAO = 5,
  AGUARDANDO_COMPLEMENTACAO = 6,
  COMPLEMENTACAO_ENVIADA = 7,
  ANUNCIO_SEM_INTERESSADO = 8,
  ANUNCIO_INDEFERIDO = 9,
  ANUNCIO_FINALIZADO = 10,
  EXCLUIDO_PRIVADO = 11,
  EXCLUIDO_CENTRAL_COMPRAS = 12,
  INATIVO = 13,
  EM_ANALISE_DENUNCIA = 14,
  DOACAO_CONFIRMADA = 15
}

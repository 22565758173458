<p-panel header="Detalhes">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmit()" *ngIf="carregouDados">
    <div class="ui-g">
      <div class="ui-g-4">
        <b *ngIf="anuncio.id">Número do anúncio: {{ anuncio.id }}</b>
      </div>
      <div class="ui-g-4">
        <b *ngIf="anuncio.id">Quantidade: {{ anuncio.quantidade }}</b>
      </div>
      <div class="ui-g-4">
        <div class="text-right">
          <p-message severity="info" text="* Campos obrigatórios"></p-message>
        </div>
      </div>
    </div>

    <p-table formArrayName="itens" [value]="itens().controls">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 150px">Situação*</th>
          <th style="width: 150px">Quantidade*</th>
          <th style="width: 150px">Valor Avaliação</th>
          <th>Dados Complementares</th>
          <th style="width: 150px">Patrimônio</th>
          <th style="width: 50px" *ngIf="podeAlterarDados()"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-detalhe let-i="rowIndex">
        <tr [formGroupName]="i">
          <td>
            <input type="hidden" formControlName="id" />
            <p-dropdown [autoWidth]="false" [style]="{'width':'100%'}" [options]="listaMaterialSituacao"
              formControlName="materialSituacao" optionLabel="nome" dataKey="id" *ngIf="podeAlterarDados()">
            </p-dropdown>
            <span *ngIf="!podeAlterarDados()">{{detalhe.get('materialSituacao').value.nome}}</span>
          </td>
          <td>
            <p-inputMask id="quantidade" maxlength="5" mask="9?9999" name="quantidade" formControlName="quantidade"
              autocomplete="off" type="text" *ngIf="podeAlterarDados()">
            </p-inputMask>
            <span *ngIf="!podeAlterarDados()">{{detalhe.get('quantidade').value}}</span>
          </td>
          <td>
            <input type="text" pInputText currencyMask
              [options]="{prefix: '', thousands: '.', decimal: ',', allowNegative: false}"
              formControlName="valorAvaliacao" style="width: 100%" *ngIf="podeAlterarDados()" />
            <span *ngIf="!podeAlterarDados()">{{detalhe.get('valorAvaliacao').value}}</span>
          </td>
          <td>
            <input type="text" pInputText formControlName="dadosComplementares" style="width: 100%"
              *ngIf="podeAlterarDados()" maxlength="240" />
            <span *ngIf="!podeAlterarDados()">{{detalhe.get('dadosComplementares').value}}</span>
          </td>
          <td>
            <input type="text" pInputText formControlName="patrimonio" style="width: 100%" *ngIf="podeAlterarDados()"
              maxlength="50" />
            <span *ngIf="!podeAlterarDados()">{{detalhe.get('patrimonio').value}}</span>
          </td>
          <td *ngIf="podeAlterarDados()">
            <button type="button" pButton (click)="removerItem(i)" icon="fa fa-trash" class="ui-button-danger"></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="6">
            Sem registros.
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="summary" *ngIf="podeAlterarDados()">
        <button type="button" pButton (click)="adicionarItem()" label="Novo Detalhe" class="ui-button-primary"
          icon="pi pi-plus"> </button>
      </ng-template>

    </p-table>

    <div class="col-12 buttons justify-content-between p-3 flex">
      <button type="button" pButton label="Voltar" class="ui-button-secondary" (click)="onVoltar()"></button>
      <button type="submit" pButton label="Próximo" class="ui-button-primary" [disabled]="form.invalid"></button>
    </div>

  </form>


</p-panel>

import { Component, OnInit } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterialTipo } from '../../models/material-tipo.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AuthService } from 'app/shared/services/auth.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ParametrosMeusAnunciosPrivado } from 'app/anuncio/models/parametros-meus-anuncios-privado.model';
import { FiltroMeusAnunciosPrivado } from 'app/anuncio/models/filtro-meus-anuncios-privado.model';
import { AnuncioSituacao } from 'app/anuncio/models/anuncio-situacao.model';
import { RouteService } from 'app/shared/services/route.service';
import { AnuncioChip } from 'app/anuncio/models/anuncio-chip.model';

@Component({
  selector: 'reuse-meus-anuncios-privado-page',
  templateUrl: './meus-anuncios-privado-page.component.html',
  styleUrls: ['./meus-anuncios-privado-page.component.scss']
})
export class MeusAnunciosPrivadoPageComponent extends AnunciosInternoPage implements OnInit {

  filtro: FiltroMeusAnunciosPrivado = new FiltroMeusAnunciosPrivado();

  constructor(private anuncioService: AnuncioService,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService,
    private routeService: RouteService) {
    super();
  }



  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosPrivado = this.anuncioService.obterParametrosMeusAnunciosPrivadoUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.popularSeparadoresTagInput();

  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [
      { icon: 'fa fa-newspaper-o', label: 'Meus Anúncios', command: (onclick) => { this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_PRIVADO], { queryParams: { dt: new Date().getTime() } }) } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  private consultarFiltros(parametros: ParametrosMeusAnunciosPrivado): void {
    this.anuncioService.consultarFiltrosMeusAnunciosPrivado(parametros)
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {
          this.filtro.materialTipos = resultado.materialTipos;
          this.filtro.anuncioSituacoes = resultado.anuncioSituacoes;
          this.definirFiltrosSelecionados(parametros);
        });
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  private definirFiltrosSelecionados(parametros: ParametrosMeusAnunciosPrivado): void {
    this.filtro.pesquisa = parametros.pesquisa;

    const materialTipos: MaterialTipo[] = parametros.materialTipos.split(',').filter(v => v != '').map((nome) => <MaterialTipo>{ nome });
    this.filtro.materialTiposSelecionados = materialTipos;

    const anuncioSituacoes: AnuncioSituacao[] = parametros.anuncioSituacoes.split(',').filter(v => v != '').map((nome) => <AnuncioSituacao>{ nome });
    this.filtro.anuncioSituacoesSelecionados = anuncioSituacoes;

    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v != '').map(v => <AnuncioChip>{ value: v, display: v });
    this.filtro.anuncios = anuncios;

    const periodoInclusao: Date[] = parametros.periodoInclusao.split(',').filter(v => v != '').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInclusao.length != 0) {
      this.filtro.periodoInclusao = periodoInclusao;
    }
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'meus-anuncios-lista' monitora a URL para carregar novos anúncios.
   */
  public aplicarFiltros(): void {
    const anuncios: string = this.filtro.anuncios.map(m => m.display).join(',');
    const materialTipos: string = this.filtro.materialTiposSelecionados.map(m => m.nome).join(',');
    const anuncioSituacoes: string = this.filtro.anuncioSituacoesSelecionados.map(m => m.nome).join(',');

    let periodoInclusao: string = '';
    if (this.filtro.periodoInclusao != null) {
      periodoInclusao = this.filtro.periodoInclusao.filter(v => v != null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosPrivado = this.anuncioService.obterParametrosMeusAnunciosPrivadoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_PRIVADO], {
      queryParams: {
        pesquisa: this.filtro.pesquisa,
        materialTipos: materialTipos,
        anuncioSituacoes: anuncioSituacoes,
        periodoInclusao: periodoInclusao,
        anuncios: anuncios,
        ordem: parametros.ordem,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  public limparFiltros(): void {
    this.filtro.pesquisa = null;
    this.filtro.materialTiposSelecionados = [];
    this.filtro.anuncioSituacoesSelecionados = [];
    this.filtro.periodoInclusao = null;
    this.filtro.anuncios = [];
    this.aplicarFiltros();
  }

  public limparPeriodoInclusao(input): void {
    this.filtro.periodoInclusao = null;
    this.fecharCalendario(input);
  }

}

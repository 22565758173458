import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppMessage } from './app.message.model';


@Injectable()
export class AppMessageService {
    private subject = new Subject<AppMessage>();

    sendMessage(appMessage: AppMessage) {
        this.subject.next(appMessage);
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<AppMessage> {
        return this.subject.asObservable();
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RouteService } from './route.service';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
      private authService: AuthService,
      private router: Router,
      private messageService: MessageService,
      private routeService: RouteService) {
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isLoggedIn) {
      this.goLogin(state.url, '');
      return false;
    }

    let expectedRoles = route.data.expectedRoles;

    if (!this.authService.hasRole(expectedRoles)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Operação não permitida',
        detail: 'Usuário não autorizado.'});

        this.authService.redirectUrlSuccess = undefined;
        this.router.navigate(['/']);

        return false;
    }

    return true;
  }

  goLogin(redirectUrlSuccess: string, redirectUrlCancel: string) {
    //armazena a url de retorno apenas se nao for a propria tela de login
    if (redirectUrlSuccess.indexOf('/login') == -1) {
      this.authService.redirectUrlSuccess = redirectUrlSuccess;
      this.authService.redirectUrlCancel = redirectUrlCancel;
    }

    // Change the URL without navigate:
    window.history.replaceState('', '', 'login');

    this.router.navigate(['/login']);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { UtilService } from '../../../shared/services/util.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Router } from '@angular/router';
import { AbstractCadastroAnuncioPrivado } from '../cadastro-anuncio-privado-page/abstract-cadastro-anuncio-privado';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { Orgao } from 'app/anuncio/models/orgao.model';

@Component({
  selector: 'reuse-cadastro-anuncio-privado-favorecido',
  templateUrl: './cadastro-anuncio-privado-favorecido.component.html',
  styleUrls: ['./cadastro-anuncio-privado-favorecido.component.scss']
})
export class CadastroAnuncioPrivadoFavorecidoComponent extends AbstractCadastroAnuncioPrivado implements OnInit {

  //formulário
  formulario: FormGroup;

  //lista orgao disponiveis para favorecido
  listaOrgaoFavorecida: Orgao[] = [];

  //lista entidades disponiveis para favorecido
  listaEntidadeFavorecida: Entidade[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public cadastroAnuncioService: CadastroAnuncioService,
    private utilService: UtilService,
    private messageService: MessageService,
    private router: Router) {
    super(cadastroAnuncioService);
  }

  ngOnInit(): void {
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  public atualizaAnuncio(numeroAnuncio: number): void {
    this.passo = 2;
    this.numeroAnuncio = numeroAnuncio;
    if (this.anuncio) {
      this.anuncio.id = numeroAnuncio;
    }
    this.obterFavorecido();
  }

  private obterFavorecido(): void {
    this.cadastroAnuncioService.obterAnuncioFavorecido(this.numeroAnuncio)
      .subscribe(
        (dados) => {
          //dados do anúncio
          this.anuncio = dados.anuncio;

          this.listaOrgaoFavorecida = [];
          for (let orgao of dados.listaOrgaoFavorecida) {
            orgao.idNome = orgao.id + ' - ' + orgao.nome;
            this.listaOrgaoFavorecida.push(orgao);
          }

          //adiciona opção 'Selecione' (vazia)
          this.listaOrgaoFavorecida.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });

          this.criarFormulario();
          this.carregarEntidadeFavorecida();

          this.carregouDados = true;
          window.scrollTo(0, 0);

          this.utilService.verificarRegistroBloqueado(this.anuncio, true);
        }
      );
  }


  /**
   * Cria o formulário para inserção ou edição.
   */
  private criarFormulario() {
    const entidadeFavorecida: Entidade = this.anuncio.entidadeFavorecida ? this.anuncio.entidadeFavorecida : null;
    const orgaoFavorecida: Orgao = (entidadeFavorecida ? (entidadeFavorecida.orgao ? entidadeFavorecida.orgao : null) : null);

    this.formulario = this.formBuilder.group({
      mensagemFavorecida: [this.anuncio.mensagemFavorecida],
      orgaoFavorecida: [orgaoFavorecida, [Validators.required, this.utilService.validarIdEntidade]],
      entidadeFavorecida: [entidadeFavorecida, [Validators.required, this.utilService.validarIdEntidade]],
    });
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    // Nesse primeiro caso nao precisa salvar, pois somente pode alterar as fotos.
    if (this.formulario.valid) {
      this.cadastroAnuncioService.salvarFavorecido(this.formulario, this.anuncio).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
          this.onProximo();
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  /**
   * onChange para para dropdown 'Entidade'.
   */
  public onChangeMensagemFavorecida(): void {
    const entidadeFavorecida: any = this.formulario.get('entidadeFavorecida').value;
    if (entidadeFavorecida != null && entidadeFavorecida.id != null) {
      this.formulario.get('mensagemFavorecida').enable();
    } else {
      this.formulario.get('mensagemFavorecida').disable();
    }
  }

  /**
  * onChange para para dropdown 'Entidade'.
  */
  public onChangeEntidadeFavorecida(): void {
    this.formulario.get('entidadeFavorecida').setValue({ 'id': null });
    this.carregarEntidadeFavorecida();
  }

  /**
   * Carrega a opção de 'Entidade' a partir do 'Orgao' selecionado.
   */
  private carregarEntidadeFavorecida(): void {
    const orgaoSelecionado: any = this.formulario.get('orgaoFavorecida').value;
    if (orgaoSelecionado == null || orgaoSelecionado.id == null) {
      //caso não exista 'uf' selecionado, remove as opção de 'municípios'
      this.listaEntidadeFavorecida = [];
      this.formulario.get('entidadeFavorecida').disable();
      this.formulario.get('mensagemFavorecida').disable();
      this.formulario.get('entidadeFavorecida').setValue({ 'id': null });
      this.formulario.get('mensagemFavorecida').setValue(null);
    } else {
      this.cadastroAnuncioService.obterEntidadePorOrgaoUsuarioPrivado(orgaoSelecionado.id)
        .subscribe(
          (entidades) => {

            this.listaEntidadeFavorecida = [];
            for (let entidade of entidades) {
              entidade.nomeNumero = entidade.numero + ' - ' + entidade.nome;
              this.listaEntidadeFavorecida.push(entidade);
            }
            this.listaEntidadeFavorecida.unshift(<Entidade>{ id: null, nomeNumero: 'Selecione' });
            this.formulario.get('entidadeFavorecida').enable();
          }
        );
    }
  }
}

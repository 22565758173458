import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import {ToolbarModule} from 'primeng/toolbar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {InputMaskModule} from 'primeng/inputmask';
import {MessageService} from 'primeng/components/common/messageservice';
import {CalendarModule} from 'primeng/calendar';
import {ChipsModule} from 'primeng/chips';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CaptchaModule} from 'primeng/captcha';
import {FieldsetModule} from 'primeng/fieldset';
import {SpinnerModule} from 'primeng/spinner';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {GrowlModule} from 'primeng/growl';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogModule} from 'primeng/dialog';
import {StepsModule} from 'primeng/steps';
import {FileUploadModule} from 'primeng/fileupload';
import {CardModule} from 'primeng/card';
import {OrderListModule} from 'primeng/orderlist';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';

@NgModule({
  imports: [
    ButtonModule,
    TableModule,
    MenubarModule,
    PanelModule,
    PaginatorModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    ToolbarModule,
    OverlayPanelModule,
    InputTextModule,
    InputMaskModule,
    CalendarModule,
    ChipsModule,
    BreadcrumbModule,
    CaptchaModule,
    FieldsetModule,
    SpinnerModule,
    InputTextareaModule,
    GrowlModule,
    ProgressSpinnerModule,
    DialogModule,
    StepsModule,
    FileUploadModule,
    CardModule,
    OrderListModule,
    ConfirmDialogModule,
    AccordionModule,
    CheckboxModule,
    RadioButtonModule,
  ],
  exports: [
    ButtonModule,
    TableModule,
    MenubarModule,
    PanelModule,
    PaginatorModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    ToolbarModule,
    OverlayPanelModule,
    InputTextModule,
    InputMaskModule,
    CalendarModule,
    ChipsModule,
    BreadcrumbModule,
    CaptchaModule,
    FieldsetModule,
    SpinnerModule,
    InputTextareaModule,
    GrowlModule,
    ProgressSpinnerModule,
    DialogModule,
    StepsModule,
    FileUploadModule,
    CardModule,
    OrderListModule,
    ConfirmDialogModule,
    AccordionModule,
    CheckboxModule,
    RadioButtonModule,
  ],
  declarations: [],
  providers: [MessageService],
})
export class AppPrimengModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPrimengModule } from '../app-primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DesbloqueioAnuncioRoutingModule } from './desbloqueio-anuncio-routing.module';
import { DesbloqueioAnuncioPageComponent } from './components/desbloqueio-anuncio-page/desbloqueio-anuncio-page.component';
import { DesbloqueioAnuncioListaComponent } from './components/desbloqueio-anuncio-lista/debloqueio-anuncio-lista.component';
import { TagInputModule } from 'ngx-chips';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { DesbloqueioAnuncioService } from './services/desbloqueio-anuncio.service';


@NgModule({
  imports: [
    CommonModule,
    AppPrimengModule,
    ReactiveFormsModule,
    DesbloqueioAnuncioRoutingModule,
    TagInputModule,
    SharedModule,
    MomentModule
  ],
  declarations: [
    DesbloqueioAnuncioPageComponent,
    DesbloqueioAnuncioListaComponent
  ],
  providers: [
    DesbloqueioAnuncioService,
  ],
  exports: [
  ],
})
export class DesbloqueioAnuncioModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AnuncioFotoOrdem } from '../models/anuncio-foto-ordem.model';
import { catchError } from 'rxjs/operators';
import { AnuncioDadosBasicos } from '../models/anuncio-dados-basicos.model';
import { MaterialCategoria } from '../models/material-categoria.model';
import { FormGroup } from '@angular/forms';
import { Anuncio } from '../models/anuncio.model';
import { AnuncioDetalhes } from '../models/anuncio-detalhes.model';
import { AnuncioFotos } from '../models/anuncio-fotos.model';
import { RespostaSalvarAnuncioDadosBasicos } from '../models/resposta-salvar-anuncio-dados-basicos.model';
import { AnuncioIdentificacaoDoador } from '../models/anuncio-identificacao-doador.model';
import { Entidade } from '../models/entidade.model';
import { RespostaSalvarAnuncioIdentificacaoDoador } from '../models/resposta-salvar-anuncio-identificacao-doador.model';
import { AnuncioDoacao } from '../models/anuncio-doacao.model';
import { AnuncioFavorecido } from '../models/anuncio-favorecido.model';


@Injectable({
  providedIn: 'root'
})
export class CadastroAnuncioService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  /** Metodos comuns a Governo e Privado **/

  public alterarOrdemFotos(numeroAnuncio: number, ordem: AnuncioFotoOrdem[]): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/alterar-ordem-fotos/${numeroAnuncio}`;
    return this.http.post(url, ordem)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public removerFoto(idAnuncioFoto: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/remover-foto/${idAnuncioFoto}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterMaterialCategorias(idMaterialTipo: number): Observable<MaterialCategoria[]> {
    const url = `${environment.apiUrl}/cadastro-anuncio/listar-material-categoria/${idMaterialTipo}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  /** Metodos comuns a Governo e Privado **/



  /** Metodos do Anúncio de Governo **/

  public obterAnuncioDadosBasicos(numeroAnuncio: number): Observable<AnuncioDadosBasicos> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-dados-basicos`;
    let params = new HttpParams();
    if (numeroAnuncio) {
      //quando anúncio é novo, ainda não tem 'numeroAnuncio'
      params = params.set('numeroAnuncio', String(numeroAnuncio));
    }
    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterAnuncioFotosGoverno(numeroAnuncio: number): Observable<AnuncioFotos> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-fotos-governo/${numeroAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterAnuncioDetalhes(numeroAnuncio: number): Observable<AnuncioDetalhes> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-detalhes`;
    const params = new HttpParams()
      .set('numeroAnuncio', String(numeroAnuncio));
    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public salvarDadosBasicos(formulario: FormGroup, anuncio: Anuncio): Observable<RespostaSalvarAnuncioDadosBasicos> {
    const url = `${environment.apiUrl}/cadastro-anuncio/salvar-dados-basicos`;
    const dadosBasicos = <AnuncioDadosBasicos>{
      anuncio: anuncio,
      titulo: formulario.get('titulo').value,
      descricao: formulario.get('descricao').value,
      materialCategoria: formulario.get('materialCategoria').value,
      codigoMaterial: formulario.get('codigoMaterial').value,
      quantidade: formulario.get('quantidade').value,
      unidadeFornecimento: formulario.get('unidadeFornecimento').value,
      municipio: formulario.get('municipio').value,
      contatoNome: formulario.get('contatoNome').value,
      contatoEmail: formulario.get('contatoEmail').value,
      contatoTelefone1: formulario.get('contatoTelefone1').value,
      contatoTelefone2: formulario.get('contatoTelefone2').value,
      possuiOnusOuEncargos: formulario.get('possuiOnusOuEncargos').value,
      possuiDonatario: formulario.get('possuiDonatario').value,
      descricaoOnusOuEncargos: formulario.get('descricaoOnusOuEncargos').value,
      justificativaDoacao: formulario.get('justificativaDoacao').value,
    };
    return this.http.post(url, dadosBasicos)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public salvarDetalhes(formulario: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/salvar-detalhes`;
    const detalhes = <AnuncioDetalhes>{
      anuncio: anuncio,
      detalhes: formulario.get('itens').value,
    };
    return this.http.post(url, detalhes)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public publicarAnuncioGoverno(anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/publicar-anuncio-governo`;
    return this.http.post(url, anuncio)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public cancelarCadastroAnuncio(numeroAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/cancelar-cadastro-anuncio/${numeroAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public removerBloqueioAnuncioGoverno(idAnuncio: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/remover-bloqueio-anuncio-governo/${idAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public anuncioAlterado(idAnuncio: number): Observable<boolean> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-alterado/${idAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public bloquearAnuncioPrivado(idAnuncio: number): Observable<boolean> {
    const url = `${environment.apiUrl}/cadastro-anuncio/bloquear-anuncio-privado/${idAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public desbloquearAnuncioPrivado(idAnuncio: number): Observable<boolean> {
    const url = `${environment.apiUrl}/cadastro-anuncio/desbloquear-anuncio-privado/${idAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public verificarBloqueado(idAnuncio: number): Observable<boolean> {
    const url = `${environment.apiUrl}/cadastro-anuncio/verificar-bloqueado/${idAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  /** Metodos do Anúncio de Governo **/



  /** Metodos do Anúncio Privado **/

  public obterAnuncioFotosPrivado(numeroAnuncio: number): Observable<AnuncioFotos> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-fotos-privado/${numeroAnuncio}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterAnuncioIdentificacaoDoador(numeroAnuncio: number): Observable<AnuncioIdentificacaoDoador> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-identificacao-doador`;
    let params = new HttpParams();
    if (numeroAnuncio) {
      //quando anúncio é novo, ainda não tem 'numeroAnuncio'
      params = params.set('numeroAnuncio', String(numeroAnuncio));
    }
    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterAnuncioDoacao(numeroAnuncio: number): Observable<AnuncioDoacao> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-doacao`;
    const params = new HttpParams()
      .set('numeroAnuncio', String(numeroAnuncio));
    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterAnuncioFavorecido(numeroAnuncio: number): Observable<AnuncioFavorecido> {
    const url = `${environment.apiUrl}/cadastro-anuncio/anuncio-favorecido`;
    const params = new HttpParams()
      .set('numeroAnuncio', String(numeroAnuncio));
    return this.http.get(url, { params })
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterEntidadeUsuarioPrivado(): Observable<Entidade[]> {
    const url = `${environment.apiUrl}/cadastro-anuncio/listar-entidade-usuario-privado`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterEntidadePorOrgaoUsuarioPrivado(idOrgao: number): Observable<Entidade[]> {
    const url = `${environment.apiUrl}/cadastro-anuncio/listar-entidade-por-orgao-usuario-privado/${idOrgao}`;
    return this.http.get(url)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public salvarDoacao(formulario: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/salvar-doacao`;
    const doacao = <AnuncioDoacao>{
      anuncio: anuncio,
      titulo: formulario.get('titulo').value,
      descricao: formulario.get('descricao').value,
      materialCategoria: formulario.get('materialCategoria').value,
      quantidade: formulario.get('quantidade').value,
      unidadeFornecimento: formulario.get('unidadeFornecimento').value,
      municipio: formulario.get('municipio').value,
      contatoNome: formulario.get('contatoNome').value,
      contatoEmail: formulario.get('contatoEmail').value,
      contatoTelefone1: formulario.get('contatoTelefone1').value,
      contatoTelefone2: formulario.get('contatoTelefone2').value,
      localizacao: formulario.get('localizacao').value,
      valorUnitarioMercado: formulario.get('valorUnitarioMercado').value,
      dataValidade: formulario.get('dataValidade').value,
      dataExpiracao: formulario.get('dataExpiracao').value,
      possuiDonatario: formulario.get('possuiDonatario').value,
      possuiOnusOuEncargos: formulario.get('possuiOnusOuEncargos').value,
      descricaoOnusOuEncargos: formulario.get('descricaoOnusOuEncargos').value,
      justificativaDoacao: formulario.get('justificativaDoacao').value,
    };
    return this.http.post(url, doacao)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public salvarIdentificacaoDoador(formulario: FormGroup, anuncio: Anuncio): Observable<RespostaSalvarAnuncioIdentificacaoDoador> {
    const url = `${environment.apiUrl}/cadastro-anuncio/salvar-identificacao-doador`;
    const identificacaoDoador = <AnuncioIdentificacaoDoador>{
      anuncio: anuncio,
      entidade: formulario.get('entidadeUsuarioPrivado').value,
      cpfSocioMajoritario: formulario.get('cpfSocioMajoritario').value,
      municipio: formulario.get('municipio').value,
      nome: formulario.get('nome').value,
      email: formulario.get('email').value,
      telefone1: formulario.get('telefone1').value,
      telefone2: formulario.get('telefone2').value,
      endereco: formulario.get('endereco').value,
      enderecoNumero: formulario.get('enderecoNumero').value,
      bairro: formulario.get('bairro').value,
      cep: formulario.get('cep').value,
    };
    return this.http.post(url, identificacaoDoador)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public salvarFavorecido(formulario: FormGroup, anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/salvar-favorecido`;
    const favorecido = <AnuncioFavorecido>{
      anuncio: anuncio,
      mensagemFavorecida: formulario.get('mensagemFavorecida').value,
      entidadeFavorecida: formulario.get('entidadeFavorecida').value,
    };
    return this.http.post(url, favorecido)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public publicarAnuncioPrivado(anuncio: Anuncio): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-anuncio/publicar-anuncio-privado`;
    return this.http.post(url, anuncio)
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  /** Metodos do Anúncio Privado **/
}

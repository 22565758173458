import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reuse-anuncio-nao-encontrado',
  templateUrl: './anuncio-nao-encontrado.component.html',
  styleUrls: ['./anuncio-nao-encontrado.component.scss']
})
export class AnuncioNaoEncontradoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { MensagemSituacao } from './mensagem-situacao.model';
import { Funcionalidade } from './funcionalidade.model';
import { Entidade } from '../../anuncio/models/entidade.model';
import { Usuario } from '../../anuncio/models/usuario.model';
import { MensagemTipo } from './mensagem-tipo.model';
import { Anuncio } from 'app/anuncio/models/anuncio.model';


export class Mensagem {
  id: number;
  situacao: MensagemSituacao;
  tipo: MensagemTipo;
  entidadeDestinatario: Entidade;
  usuarioDestinatatio: Usuario;
  anuncio:  Anuncio;
  titulo: string;
  corpoMensagem: string;
  funcionalidadeOrigem: Funcionalidade;
  dataMensagem: string;
  dataLeitura: string;
  dataExclusao: string;
  usuarioMensagem: Usuario;
  usuarioLeitura: Usuario;
  usuarioExclusao: Usuario;
}

import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { CadastroEntidadeService } from 'app/cadastro-entidade/services/cadastro-entidade.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { FiltroCadastroEntidade } from 'app/cadastro-entidade/models/filtro-cadastro-entidade.model';
import { Orgao } from 'app/anuncio/models/orgao.model';
import { UtilService } from 'app/shared/services/util.service';
import { RouteService } from 'app/shared/services/route.service';
import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { ParametrosCadastroEntidade } from 'app/cadastro-entidade/models/parametros-cadastro-entidade.model';
import { EntidadeEsfera } from 'app/anuncio/models/entidade-esfera.model';
import { EntidadeTipo } from 'app/anuncio/models/entidade-tipo.model';
import { OrgaoService } from 'app/anuncio/services/orgao.service';
import { SimNao } from 'app/anuncio/models/sim-nao';

@Component({
  selector: 'reuse-cadastro-entidade-page',
  templateUrl: './cadastro-entidade-page.component.html',
  styleUrls: ['./cadastro-entidade-page.component.scss']
})
export class CadastroEntidadePageComponent implements OnInit {

  filtro: FiltroCadastroEntidade = new FiltroCadastroEntidade();

  //lista orgaos disponiveis para pesquisa
  listaOrgao: Orgao[] = [];

  //lista esferas disponiveis para pesquisa
  listaEntidadeEsfera: EntidadeEsfera[] = [];

  //lista tipos disponiveis para pesquisa
  listaEntidadeTipo: EntidadeTipo[] = [];

  //lista Sim/Não
  listaSimNao: SimNao[] = [];

  @ViewChild('entidadeTipo')
  entidadeTipoDropdown: Dropdown;

  @ViewChild('entidadeEsfera')
  entidadeEsferaDropdown: Dropdown;

  @ViewChild('ativo')
  ativoDropdown: Dropdown;

  @ViewChild('utilizaSiads')
  utilizaSiadsDropdown: Dropdown;

  @ViewChild('orgao')
  orgaoDropdown: Dropdown;

  constructor(
    private cadastroEntidadeService: CadastroEntidadeService,
    private orgaoService: OrgaoService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private appMessageService: AppMessageService,
    private routeService: RouteService,) {
  }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosCadastroEntidade = this.cadastroEntidadeService.obterParametrosCadastroEntidadeUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.listarOrgao();
    this.listarEntidadeTipo();
    this.listarEntidadeEsfera();
    this.listarSimNao();
    this.consultarFiltros(parametros);
    this.entidadeTipoDropdown.emptyFilterMessage = 'Nenhum tipo encontrado.';
    this.entidadeEsferaDropdown.emptyFilterMessage = 'Nenhuma esfera encontrada.';
    this.ativoDropdown.emptyFilterMessage = 'Nenhuma situação encontrada.';
    this.orgaoDropdown.emptyFilterMessage = 'Nenhum orgão encontrado.';
  }

  private listarOrgao(): void {
    this.orgaoService.listarOrgaosAtivos()
      .subscribe(
        (orgaos) => {
          this.listaOrgao = [];
          for (let orgao of orgaos) {
            orgao.idNome = orgao.id + ' - ' + orgao.nome;
            this.listaOrgao.push(orgao);
          }

          //adiciona opção 'Selecione' (vazia)
          this.listaOrgao.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });
        }
      );
  }

  private listarEntidadeTipo(): void {
    this.cadastroEntidadeService.listarEntidadeTipo()
      .subscribe(
        (tipos) => {
          this.listaEntidadeTipo = tipos;

          //adiciona opção 'Selecione' (vazia)
          this.listaEntidadeTipo.unshift(<EntidadeTipo>{ id: null, nome: 'Selecione' });
        }
      );
  }

  private listarEntidadeEsfera(): void {
    this.cadastroEntidadeService.listarEntidadeEsfera()
      .subscribe(
        (tipos) => {
          this.listaEntidadeEsfera = tipos;

          //adiciona opção 'Selecione' (vazia)
          this.listaEntidadeEsfera.unshift(<EntidadeEsfera>{ id: null, nome: 'Selecione' });
        }
      );
  }

  private listarSimNao(): void {
    this.listaSimNao = [{ id: 1, nome: 'Sim' }, { id: 2, nome: 'Não' }];

    //adiciona opção 'Selecione' (vazia)
    this.listaSimNao.unshift(<SimNao>{ id: null, nome: 'Selecione' });
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    let breadcrumbItems = [
      { icon: 'fa fa-building', label: 'Cadastrar Entidades', command: (onclick) => { this.consultarComParametrosFiltro(); } }
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
 * Chama o backend para carregar todos os filtros utilizados na página.
 * Realizado somente quando a página é iniciada.
 *
 * @param parametros
 */
  consultarFiltros(parametros: ParametrosCadastroEntidade) {
    this.definirFiltrosSelecionados(parametros);
  }

  /**
   * Marca os  selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosCadastroEntidade) {
    this.filtro.numero = parametros.numero;
    this.filtro.nome = parametros.nome;
    this.filtro.ativo = parametros.idAtivo ? new SimNao(parametros.idAtivo) : null;
    this.filtro.utilizaSiads = parametros.idUtilizaSiads ? new SimNao(parametros.idUtilizaSiads) : null;
    this.filtro.entidadeTipo = parametros.idEntidadeTipo ? new EntidadeTipo(parametros.idEntidadeTipo) : null;
    this.filtro.entidadeEsfera = parametros.idEntidadeEsfera ? new EntidadeEsfera(parametros.idEntidadeEsfera) : null;
    this.filtro.orgao = parametros.idOrgao ? new Orgao(parametros.idOrgao) : null;
  }

  /**
  * Chama uma nova URL adicionando todos os filtros selecionados.
  * Componente '-lista' monitora a URL para carregar novos bloqueios.
  */
  consultar() {
    this.validarCampos();
    this.consultarComParametrosFiltro();
  }

  /**
    * Chama uma nova URL adicionando todos os filtros selecionados.
    * Componente 'cadastro-entidade-lista' monitora a URL para carregar novos bloqueios.
    */
  consultarComParametrosFiltro() {
    this.router.navigate(['/' + OrigemEnum.LISTAR_CADASTRO_ENTIDADE], {
      queryParams: {
        numero: this.filtro.numero,
        nome: this.filtro.nome,
        idEntidadeTipo: (this.filtro.entidadeTipo && this.filtro.entidadeTipo.id) ? this.filtro.entidadeTipo.id : null,
        idEntidadeEsfera: (this.filtro.entidadeEsfera && this.filtro.entidadeEsfera.id) ? this.filtro.entidadeEsfera.id : null,
        idOrgao: (this.filtro.orgao && this.filtro.orgao.id) ? this.filtro.orgao.id : null,
        idAtivo: (this.filtro.ativo && this.filtro.ativo.id) ? this.filtro.ativo.id : null,
        idUtilizaSiads: (this.filtro.utilizaSiads && this.filtro.utilizaSiads.id) ? this.filtro.utilizaSiads.id : null,
        tpConsulta: 1,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  validarCampos() {
    this.filtro.numero = this.utilService.removerNaoNumerico(this.filtro.numero);
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.numero = "";
    this.filtro.nome = "";
    this.filtro.utilizaSiads = null;
    this.filtro.ativo = null;
    this.filtro.entidadeTipo = null;
    this.filtro.entidadeEsfera = null;
    this.filtro.municipio = null;
    this.filtro.orgao = null;
    this.listaEntidadeTipo = [];
    this.listaEntidadeEsfera = [];
    this.listaSimNao = [];
    this.listaOrgao = [];
    this.consultarComParametrosFiltro();
  }

  public onBlurNumero(event) {
    this.filtro.numero = this.utilService.removerNaoNumerico(event.target.value);
  }
}

<p-panel header="Interessado(s):" *ngIf="carregaDados">
  <div>
    <div *ngIf="anuncio" class="ui-g">
      <!-- tabela detalhes adicionais -->
      <p-table class="ui-g-12" [value]="anuncioInteresses">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 30%;">Órgão / Entidade</th>
            <th style="width: 30%;">Interessado</th>
            <th style="width: 10%;">Qtde. Interesse</th>
            <th style="width: 10%;">Qtde. Autorizada</th>
            <th style="width: 10%;">Qtde. Doada</th>
            <th style="width: 15%;">Data / Hora Interesse</th>
            <th style="width: 15%;">Data / Hora Autorização</th>
            <th style="width: 15%;">Data / Hora Doação</th>
            <th style="width: 15%;">Situação</th>
            <th *ngIf="carregouDadosAnuncioInteresse" style="width: 10%;">Restante</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-interesseAnuncio>
          <tr [ngClass]="{'contentBold' : interesseAnuncio.favorecida}">
            <td class="font-smaller">
              {{interesseAnuncio.entidade.numero}} -
              {{interesseAnuncio.entidade.nome}} <span *ngIf="interesseAnuncio.favorecida" class="badge badge-info">
                Favorecida</span></td>

            <td class="font-smaller">
              {{interesseAnuncio.nomeContato}} - {{interesseAnuncio.telefoneContato}} -
              {{interesseAnuncio.emailContato}}</td>


            <td class="font-smaller text-center">{{interesseAnuncio.quantidadeInteresse != null ?
              interesseAnuncio.quantidadeInteresse : 0 | number:'1.0'}} </td>

            <td class="font-smaller text-center">{{interesseAnuncio.quantidadeAvaliacao != null ?
              interesseAnuncio.quantidadeAvaliacao : 0 | number:'1.0'}}</td>

            <td class="font-smaller text-center">{{interesseAnuncio.quantidadeEntregue != null ?
              interesseAnuncio.quantidadeEntregue : 0 | number:'1.0'}}</td>

            <td class="font-smaller text-center">{{interesseAnuncio.dataInteresse}}</td>

            <td class="font-smaller text-center">{{interesseAnuncio.dataAvaliacao}}</td>

            <td class="font-smaller text-center">{{interesseAnuncio.dataEntrega}}</td>

            <td class="font-smaller" [ngClass]="classAnuncioInteresseSituacao(interesseAnuncio.situacao)">
              {{interesseAnuncio.situacao.nome}}</td>

              <td class="font-smaller" *ngIf="carregouDadosAnuncioInteresse">
              <button id="botaoAutorizarDoacao" type="text" (click)="exibirModalAnalisarInteresse(interesseAnuncio)"
                pButton icon="fa fa-gavel" title="Autorizar Doação" [disabled]="bloquearEdicao()"
                class="ui-button ui-button-rounded"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td class="text-right espaco-10 contentBold" colspan="2">Total:</td>
            <td class="text-center espaco-10 contentBold">{{totalQuantidadeInteresse != null?
              totalQuantidadeInteresse : 0 | number:'1.0'}}</td>
            <td class="text-center espaco-10 contentBold">{{totalQuantidadeAvaliacao != null?
              totalQuantidadeAvaliacao : 0 | number:'1.0'}}</td>
            <td class="text-center espaco-10 contentBold">{{totalQuantidadeDoada != null?
              totalQuantidadeDoada : 0 | number:'1.0'}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center espaco-10 contentBold" colspan="2">
              Qtde. não autorizada: {{totalRestante != null? totalRestante : 0 | number:'1.0'}}</td>
            <td></td>
          </tr>
          <tr>
           <th class="font-smaller text-right espaco-10" colspan="10">
              <button id="botaoFinalizarAutorizacao" type="text"
                *ngIf="carregouDadosAnuncioInteresse"
                (click)="onFinalizarAutorizarDoacao()" pButton icon="pi pi-check" label="Finalizar Autorização"
                [disabled]="bloquearEdicao()" class="ui-button-primary"></button>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="carregouDadosAnuncioInteresse ? 6 : 5">
              Nenhum registro encontrado.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>


<reuse-analisar-interesse #reuseAnalisarInteresse [anuncio]="anuncio"
  (analiseEfetuada)="onAnaliseEfetuadaAnalisarInteresse()">
</reuse-analisar-interesse>

<reuse-modal-finalizar-autorizacao-doacao #reuseModalFinalizarAutorizacaoDoacao [anuncio]="anuncio"
  (analiseEfetuada)="onAnaliseEfetuadaAnalisarInteresse()">
</reuse-modal-finalizar-autorizacao-doacao>

<reuse-modal-confirmar-doacao #reuseModalConfirmarDoacao [anuncio]="anuncio"
  (analiseEfetuada)="onAnaliseEfetuadaAnalisarInteresse()">
</reuse-modal-confirmar-doacao>

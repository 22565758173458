import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { OrigemEnum } from '../../../shared/enum/origem.enum';
import { CadastroEntidadeService } from 'app/cadastro-entidade/services/cadastro-entidade.service';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { Municipio } from 'app/anuncio/models/municipio.model';
import { UtilService } from 'app/shared/services/util.service';
import { Orgao } from 'app/anuncio/models/orgao.model';
import { ConfirmationService } from 'primeng/api';
import { RouteService } from 'app/shared/services/route.service';
import { Dropdown } from 'primeng/components/dropdown/dropdown';
import { Usuario } from 'app/anuncio/models/usuario.model';
import { EntidadeEsfera } from 'app/anuncio/models/entidade-esfera.model';
import { EntidadeTipo } from 'app/anuncio/models/entidade-tipo.model';
import { SimNao } from 'app/anuncio/models/sim-nao';
import { MunicipioService } from 'app/anuncio/services/municipio.service';
import { Uf } from 'app/anuncio/models/uf.model';
import { RespostaEdit } from 'app/anuncio/models/resposta-edit.model';
import { Field } from 'app/anuncio/models/field';

@Component({
  selector: 'reuse-cadastro-entidade-edit-page',
  templateUrl: './cadastro-entidade-edit-page.component.html',
  styleUrls: ['./cadastro-entidade-edit-page.component.scss']
})
export class CadastroEntidadeEditPageComponent implements OnInit {

  menuItemCadastroEntidade = { icon: 'fa fa-building-o', label: 'Cadastrar Entidades', command: (onclick) => { this.router.navigate([OrigemEnum.LISTAR_CADASTRO_ENTIDADE]) } };

  entidadeNaoEncontrada: boolean = false;

  //entidade carregado a partir do 'idEntidade'
  entidade: Entidade;

  // guarda o id da entidade caso seja consulta ou edicao
  idEntidade: number = -1;

  // verifica qual tipo da transacao
  // I - INCLUSÃO
  // A - ALTERAÇÃO
  // V - VISUALIZACAO
  tipoOperacao: string;

  // formulário
  formulario: FormGroup;

  @ViewChild('uf')
  ufDropdown: Dropdown;

  @ViewChild('municipio')
  municipioDropdown: Dropdown;

  //lista de orgaos disponíveis para exibir no combobox
  listaOrgao: Orgao[] = [];

  //lista de tipos disponíveis para exibir no combobox
  listaEntidadeTipo: EntidadeTipo[] = [];

  //lista esferas disponíveis para exibir no combobox
  listaEntidadeEsfera: EntidadeEsfera[] = [];

  //lista municipios disponíveis para exibir no combobox
  listaUf: Uf[] = [];

  // lista municipios disponíveis para exibir no combobox
  listaMunicipio: Municipio[] = [];

  // lista usuarios disponíveis para exibir no combobox
  listaUsuario: Usuario[] = [];

  // lista Sim/Não
  listaSimNao: SimNao[] = [];

  // indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  fields: Field[];

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cadastroEntidadeService: CadastroEntidadeService,
    private municipioService: MunicipioService,
    private messageService: MessageService,
    private utilService: UtilService,
    private appMessageService: AppMessageService,
    private confirmationService: ConfirmationService,
    private routeService: RouteService,
  ) { }

  ngOnInit() {
    this.monitorarParametros();
  }

  get usuarioLogado(): Usuario {
    return this.authService.usuario;
  }

  private monitorarParametros(): void {
    this.route.paramMap.subscribe(params => {
      const numero = params.get('numero');

      if (numero === 'novo') {
        this.criarBreadcrumb();
        this.obterCadastroEntidade();
        this.tipoOperacao = 'I'; // INCLUSÃO
      } else {
        // verifica se 'numero' é valor válido
        if (isNaN(+numero)) {
          // número inválido
          this.entidadeNaoEncontrada = true;
        } else {
          if (this.routeService.isUrl(OrigemEnum.ALTERAR_CADASTRO_ENTIDADE)) {
            this.tipoOperacao = 'A'; // ALTERAÇÃO
          } else if (this.routeService.isUrl(OrigemEnum.VISUALIZAR_CADASTRO_ENTIDADE)) {
            this.tipoOperacao = 'V'; // VISUALIZACAO
          } else {
            this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Opção inválida, favor realize a operação novamente.' });
            this.navegarConsultarUsuario();
          }
          this.idEntidade = parseInt(numero);
          this.obterCadastroEntidade();
        }
      }
    });
  }

  /**
 * Configuração da barra 'Breadcrumb' para Alteração do Anúncio.
 */
  private criarBreadcrumb(): void {
    let breadcrumbItems = [this.menuItemCadastroEntidade, { label: this.tituloFormulario() }];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  private obterCadastroEntidade(): void {
    this.cadastroEntidadeService.obterCadastroEntidade(this.idEntidade, this.tipoOperacao)
      .subscribe(
        (ent) => {
          this.entidade = ent;
          if (this.entidade) {
            this.criarFormulario();
            if (this.tipoOperacao !== 'V') {

              // preeche a lista com os orgaos
              for (let orgao of ent.listaOrgao) {
                orgao.idNome = orgao.id + ' - ' + orgao.nome;
                this.listaOrgao.push(orgao);
              }

              //adiciona opção 'Selecione' (vazia)
              this.listaOrgao.unshift(<Orgao>{ id: null, nome: null, idNome: 'Selecione' });

              // preeche a lista com os tipos
              this.listaEntidadeTipo = ent.listaEntidadeTipo;
              this.listaEntidadeTipo.unshift(<EntidadeTipo>{ id: null, nome: 'Selecione' });

              // preeche a lista com as esferas
              this.listaEntidadeEsfera = ent.listaEntidadeEsfera;
              this.listaEntidadeEsfera.unshift(<EntidadeEsfera>{ id: null, nome: 'Selecione' });

              // preeche a lista com as UFs
              this.listaUf = ent.listaUf;
              this.listaUf.unshift(<Uf>{ id: null, sigla: 'Selecione' });

              // preeche a lista com os Usuarios
              this.listaUsuario = ent.listaUsuario;
              this.listaUsuario.unshift(<Usuario>{ id: null, nome: 'Selecione' });

              this.listaSimNao = [{ id: 1, nome: 'Sim' }, { id: 2, nome: 'Não' }];
              this.listaSimNao.unshift(<SimNao>{ id: null, nome: 'Selecione' });

              this.carregarMunicipio();
            }
            this.criarBreadcrumb();

            this.carregouDados = true;
            window.scrollTo(0, 0);
          } else {
            //não existe anúncio para ALTERAÇÃO
            this.entidadeNaoEncontrada = true;
          }
        }
      );
  }

  /**
   * Cria o formulário para inserção ou ALTERAÇÃO.
   */
  private criarFormulario() {

    this.fields = [{ name: "id", label: "ID", gender: "M", },
    { name: "numero", label: "Número", gender: "M", },
    { name: "nome", label: "Nome", gender: "M" },
    { name: "sigla", label: "Sigla", gender: "F" },
    { name: "endereco", label: "Endereço", gender: "M" },
    { name: "enderecoNumero", label: "Nº Endereço", gender: "M" },
    { name: "bairro", label: "Bairro", gender: "M" },
    { name: "cep", label: "CEP", gender: "M" },
    { name: "telefone1", label: "Telefone 1", gender: "M" },
    { name: "telefone2", label: "Telefone 2", gender: "M" },
    { name: "usuarioResponsavel", label: "Usuário Responsável", gender: "M" },
    { name: "entidadeTipo", label: "Tipo", gender: "M" },
    { name: "entidadeEsfera", label: "Esfera", gender: "F" },
    { name: "orgao", label: "Órgão", gender: "M" },
    { name: "municipio", label: "Município", gender: "M" },
    { name: "utilizaSiads", label: "Utiliza SIADS", gender: "M" },
    { name: "ativo", label: "Ativo", "gender": "M" }];

    this.formulario = this.formBuilder.group({
      id: [this.entidade.id],
      numero: [this.entidade.numero, [Validators.required, Validators.maxLength(10), this.utilService.validarNumerico]],
      nome: [this.entidade.nome, [Validators.required, Validators.maxLength(255)]],
      sigla: [this.entidade.sigla, [Validators.required, Validators.maxLength(20)]],
      orgao: [this.entidade.orgao, [Validators.required]],
      entidadeTipo: [this.entidade.entidadeTipo, [Validators.required, this.utilService.validarIdEntidade]],
      entidadeEsfera: [this.entidade.entidadeEsfera, [Validators.required, this.utilService.validarIdEntidade]],
      uf: [this.entidade.uf, [Validators.required, this.utilService.validarIdEntidade]],
      municipio: [this.entidade.municipio, [Validators.required, this.utilService.validarIdEntidade]],
      bairro: [this.entidade.bairro, [Validators.maxLength(100)]],
      endereco: [this.entidade.endereco, [Validators.maxLength(255)]],
      enderecoNumero: [this.entidade.enderecoNumero, [Validators.maxLength(40)]],
      cep: [this.entidade.cep, this.utilService.validarCep],
      telefone1: [this.entidade.telefone1, [this.utilService.validarTelefone]],
      telefone2: [this.entidade.telefone2, [this.utilService.validarTelefone]],
      email: [this.entidade.email, [Validators.required, Validators.email, Validators.maxLength(255)]],
      usuarioResponsavel: [this.entidade.usuarioResponsavel],
      utilizaSiads: [this.entidade.utilizaSiads ? new SimNao(1, 'Sim') : new SimNao(2, 'Não'), [this.utilService.validarIdEntidade]],
      ativo: [this.entidade.ativo ? new SimNao(1, 'Sim') : new SimNao(2, 'Não'), [this.utilService.validarIdEntidade]],
    });

    // bloqueia o formulario de acordo com o tipo de operacao
    if (this.tipoOperacao == 'I') {
      this.bloqueioFormularioInserir();
    } else if (this.tipoOperacao == 'A') {
      this.bloqueioFormularioEdicao();
    } else if (this.tipoOperacao == 'V') {
      this.bloqueioFormularioVisualizar();
    }
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit(): void {

    if (this.entidade.id === null || this.entidade.id === undefined) {
      this.confirmarAlteracao();
    } else {
      this.utilService.marcarCamposComoAlterado(this.formulario);
      if (!this.utilService.validarFormulario(this.formulario, this.fields)) {
        return;
      }
      this.confirmationService.confirm({
        key: `modalInclusaoStatusExcluido`,
        message: `Confirma o registro das alterações na entidade (${this.entidade.numero})?`,
        accept: () => {
          this.confirmarAlteracao();
        }
      });
    }
  }

  /**
 * Clique botão 'Confirmar'.
 */
  public confirmarAlteracao(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    if (!this.utilService.validarFormulario(this.formulario, this.fields)) {
      return;
    }

    document.getElementById('submitBtn').setAttribute('disabled', 'true');

    this.cadastroEntidadeService.salvarEntidade(this.formulario, this.tipoOperacao).subscribe(
      (resultado: RespostaEdit) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
        this.navegarConsultarUsuario();
      },
      err => {
        document.getElementById('submitBtn').removeAttribute('disabled');
      });
  }

  /**
 * Clique botão 'Cancelar'.
 */
  public onCancelar(): void {
    // navega para página de anterior
    this.onVoltar();
  }

  /**
   * Clique botão 'Voltar'.
   */
  public onVoltar(): void {
    // navega para página anterior
    this.routeService.voltar(OrigemEnum.LISTAR_CADASTRO_ENTIDADE);
  }

  /**
   * Clique botão 'Cancelar'.
   */
  public navegarConsultarUsuario(): void {
    // navega para página anterior
    this.onVoltar();
  }

  private bloqueioFormularioVisualizar() {
    this.utilService.bloquearControlesFormulario(this.formulario);
  }

  private bloqueioFormularioInserir() {
    this.utilService.desbloquearControlesFormulario(this.formulario);
  }

  private bloqueioFormularioEdicao() {
    this.utilService.desbloquearControlesFormulario(this.formulario);
  }

  private desbloqueioFormulario() {
    //Caso nao encontre nenhum orgao na busca com filtro apresenta a mensagem abaixo
    //this.orgaoDropdown.emptyFilterMessage = 'Nenhum orgão encontrado.';
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  verificarCampoInvalido(nomeCampo: string) {
    let campo = this.formulario.get(nomeCampo);
    // inválido e alterado
    return campo.invalid && campo.dirty;
  }

  public podeAlterarDados(): boolean {
    return !(this.tipoOperacao === 'V');
  }

  public isInclusao(): boolean {
    return this.tipoOperacao === 'I';
  }

  public tituloFormulario(): string {
    if (this.tipoOperacao === 'I') {
      return 'Inserir Entidade';
    } else if (this.tipoOperacao === 'A') {
      return 'Alterar Entidade';
    } else if (this.tipoOperacao === 'V') {
      return 'Visualizar Entidade';
    }
  }

  /**
* onChange para para dropdown 'Uf'.
*/
  public onChangeUf(): void {
    this.formulario.get('municipio').setValue({ 'id': null });

    // limpa o filtro do drowpdown do municipio, caso a uf seja modificada.
    this.municipioDropdown.filterValue = '';

    //Caso nao encontre nenhum municipio na busca com filtro apresenta a mensagem abaixo
    this.municipioDropdown.emptyFilterMessage = 'Nenhum município encontrado.';

    this.carregarMunicipio();
  }

  /**
   * Carrega a opção de 'Municipio' a partir da 'UF' selecionada.
   */
  private carregarMunicipio(): void {
    const ufSelecionado: any = this.formulario.get('uf').value;
    if (ufSelecionado == null || ufSelecionado.id == null) {
      //caso não exista 'uf' selecionado, remove as opção de 'entidades'
      this.listaMunicipio = [];
      this.formulario.get('municipio').disable();
      this.formulario.get('municipio').setValue(<Municipio>{ id: null, nome: 'Selecione' });
    } else {
      this.municipioService.listarMunicipiosAtivos(ufSelecionado.id)
        .subscribe(
          (municipios) => {
            this.listaMunicipio = municipios;
            this.listaMunicipio.unshift(<Municipio>{ id: null, nome: 'Selecione' });
            this.formulario.get('municipio').enable();
          }
        );
    }
  }

  numberOnlyEvent(event): boolean {
    return this.utilService.numberOnlyEvent(event);
  }

}


import { Component, AfterViewChecked, ChangeDetectorRef, } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { Router } from '@angular/router';


@Component({
  selector: 'reuse-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements AfterViewChecked {
  breadcrumbItems: MenuItem[];
  breadcrumbHome: MenuItem = { icon: 'pi pi-home', command: (onclick) => { this.router.navigate(['/']) } };

  constructor(
    private appMessageService: AppMessageService,
    private router: Router,
    private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewChecked(): void {
    this.monitorarAppMessage();
  }

  private monitorarAppMessage() {
    this.appMessageService.getMessage().subscribe(appMessage => {
      if (appMessage.messageType == AppMessageType.BREADCRUMB) {
        this.breadcrumbItems = appMessage.data;
        this.cdRef.detectChanges();
      }
    });
  }

}

<div class="anuncio-detalhe col-12 d-flex flex-wrap" *ngIf="anuncio">
  <div class="br-card col-sm-12 col-md-7 d-flex px-5 py-4 flex-wrap">
    <div class="col-8">
      <span class="titulo" [ngClass]="anuncioUtil.anuncioGoverno(anuncio) ? 'corGoverno' : 'corPrivado'">
        {{anuncio.titulo}}
      </span>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <div class="br-tag">{{anuncio.anuncioSituacao.nome}}</div>
      <div *ngIf="anuncioUtil.anuncioGoverno(anuncio)" class="br-tag ml-1 governo"><span>Governo</span></div>
      <div *ngIf="anuncioUtil.anuncioPrivado(anuncio)" class="br-tag ml-1 privado"><span>Privado</span></div>
    </div>
    <div class="col-12 d-flex mt-3 flex-wrap">
      <div class="col-lg-4 my-2 ">
        <i class="mr-3 fas fa-asterisk"></i>
        <span> Anúncio: {{anuncio.id}} </span>
      </div>
      <div class="col-12 my-2 mb-3">
        <i class="mr-3 fas fa-calendar"></i>
        <span>{{anuncioUtil.dataAnuncioDetalhe(anuncio)}}</span>
      </div>
      <div class="col-lg-4 my-2 ">
        <i class="mr-3 fas fa-chart-bar"></i>
        <span *ngIf="anuncio.quantidade > 1; then mostrarQuantidades else mostrarQuantidade"></span>
        <ng-template #mostrarQuantidade>
          <span><b>{{anuncio.quantidade}}</b> disponível</span>
        </ng-template>
        <ng-template #mostrarQuantidades>
          <span><b>{{anuncio.quantidade}}</b> disponíveis</span>
        </ng-template>
      </div>
      <div class="col-lg-8 my-2">
        <i class="mr-3 fas fa-tape"></i>
        {{anuncio.unidadeFornecimento.nome}}
      </div>
      <div class="col-12 mt-2 d-flex justify-content-end" *ngIf="exibirDadosVisualizacao">
        <b>{{anuncio.quantidadeVisualizacoes}} &nbsp;</b> Visita(s) / Mais recente:&nbsp;<b>
          <span *ngIf="anuncio.dataUltimaVisualizacao">
            {{anuncio.dataUltimaVisualizacao|StringDTOToDate|amDateFormat:'DD/MM/YYYY'}}
          </span>
        </b>
      </div>

      <div class="col-12" *ngIf="anuncio.denunciaEmAnalise && authService.hasRole(['SEGES'])">
        <p class="titulo text-danger" *ngIf="!anuncio.denunciaEmAnalise.usuarioAnalise">Denúncia em análise</p>
        <p class="titulo text-danger" *ngIf="anuncio.denunciaEmAnalise.usuarioAnalise">Última denúncia analisada:
          {{anuncio.denunciaEmAnalise.acatada ? 'ACEITA' : 'REJEITADA'}}</p>

        <div class="col-12">
          <i class="mr-3 fa fa-user"></i> Denunciante
          <span>{{anuncio.denunciaEmAnalise.usuarioOperacao.cpf}} -
            {{anuncio.denunciaEmAnalise.usuarioOperacao.nome}}</span>
        </div>

        <div class="col-12">
          <i class="mr-3 fa fa-calendar"></i> Data da denúncia
          <span>{{anuncio.denunciaEmAnalise.dataOperacao}}</span>
        </div>

        <div class="col-12">
          <i class="mr-3 fa fa-edit"></i> Motivo
          <span>{{anuncio.denunciaEmAnalise.motivo}}</span>
        </div>

        <div class="col-12" *ngIf="anuncio.denunciaEmAnalise.usuarioAnalise">
          <div class="col-6">
            <span class="label">
              <i class="mr-3 fa fa-user"></i> Analisado por
            </span>
            <p>{{anuncio.denunciaEmAnalise.usuarioAnalise.cpf}} -
              {{anuncio.denunciaEmAnalise.usuarioAnalise.nome}}</p>
          </div>

          <div class="col-6">
            <span class="label">
              <i class="mr-3 fa fa-calendar"></i> Data da análise
            </span>
            <p>{{anuncio.denunciaEmAnalise.dataAnalise}}</p>
          </div>
        </div>

        <div class="col-12" *ngIf="anuncio.denunciaEmAnalise.usuarioAnalise">
          <div class="col-12">
            <span class="label">
              <i class="mr-3 fa fa-edit"></i> Justificativa da análise
            </span>
            <p>{{anuncio.denunciaEmAnalise.justificativaAnalise}}</p>
          </div>
        </div>


        <div class="col-12 d-flex justify-content-end" *ngIf="anuncio.permissoes.podeAnalisarDenuncia">
          <button (click)="prepararAnalisarDenuncia(true)" title="Aceitar denúncia" #botaoAceitarDenuncia
            label="Aceitar" pButton class="ui-button-danger button-small"></button>
          <button (click)="prepararAnalisarDenuncia(false)" title="Rejeitar denúncia" #botaoRejeitarDenuncia
            label="Rejeitar" pButton class="ui-button-success button-small"></button>
        </div>
      </div>

      <div class="col-12 d-flex flex-wrap mt-3">
        <div class="col-12 pt-2">
          <span class="label"> Anunciante</span>
          <hr>
        </div>
        <div *ngIf="anuncioUtil.anuncioPrivado(anuncio)" class="col-12 mb-3">
          <i class="mr-3 fas fa-id-card"></i>
          <span>{{anuncio.cpfUsuario | cpf}} - {{anuncio.nomeUsuario}}</span>
        </div>
        <div *ngIf="exibeEntidade()" class="col-12 mb-3">
          <i class="mr-3 fa fa-building"></i>
          <span *ngIf="anuncioUtil.anuncioPrivado(anuncio) ">{{anuncio.entidade.numero | cnpj}} -
            {{anuncio.entidade.nome}}</span>
          <span *ngIf="anuncioUtil.anuncioGoverno(anuncio)">{{anuncio.entidade.numero}} -
            {{anuncio.entidade.nome}}</span>
        </div>
        <div class="col-12 mb-3">
          <i class="mr-3 fa fa-map-marker-alt"></i>
          <span>{{anuncio?.municipio.nome}} - {{anuncio?.municipio.uf.sigla}}</span>
        </div>
        <div *ngIf="exibirDadosContato()" class="col-12">
          <div class="col-12 mb-3">
            <i class="mr-3 fa fa-phone"></i>
            <span>{{anuncio?.contatoTelefone1}}
              <span *ngIf="anuncio.contatoTelefone1 && anuncio.contatoTelefone2">/</span>{{anuncio?.contatoTelefone2}}
            </span>
          </div>
          <div class="col-12">
            <i class="mr-3 fa fa-envelope"></i>
            <span>{{anuncio.contatoEmail}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-5 pl-lg-4 d-flex">
    <div class="galeria br-card col-12">
      <ngx-gallery class="img" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
    </div>
  </div>
  <div class="br-card col-12 px-5 py-4 mt-3 d-flex flex-wrap">
    <div class="col-12">
      <span class="label">Descrição</span>
      <p>{{anuncio.descricao}}</p>
    </div>
    <div class="col-12">
      <span class="label">Justificativa da doação</span>
      <p>{{anuncio.justificativaDoacao}}</p>
    </div>
    <div *ngIf="anuncioUtil.anuncioPrivado(anuncio)" class="col-23">
      <span *ngIf="anuncioUtil.servico(anuncio)" class="label">Local de prestação de serviço</span>
      <span *ngIf="!anuncioUtil.servico(anuncio)" class="label">Localização do bem</span>
      <p>{{anuncio.localizacao}}</p>
    </div>
    <div
      *ngIf="anuncioUtil.anuncioPrivado(anuncio) && (anuncio.donoAnuncio || authService.hasRole(['GOVERNO','CENTRALCOM','GESTOR','SEGES']))"
      class="col-6">
      <span class="label">Donatário</span>
      <p>{{anuncio?.entidadeFavorecida?.numero}} - {{anuncio?.entidadeFavorecida?.nome}}</p>
    </div>
    <div
      *ngIf="anuncioUtil.anuncioPrivado(anuncio) && (anuncio.donoAnuncio || authService.hasRole(['GOVERNO','CENTRALCOM','GESTOR','SEGES']))"
      class="col-3">
      <span class="label">Valor Unitário Mercado</span>
      <p>R$ {{anuncio.valorUnitarioMercado}}</p>
    </div>
    <div
      *ngIf="anuncioUtil.anuncioPrivado(anuncio) && (anuncio.donoAnuncio || authService.hasRole(['GOVERNO','CENTRALCOM','GESTOR','SEGES']))"
      class="col-3">
      <span class="label">Valor Total Mercado</span>
      <p>R$ {{anuncio.valorTotalMercado}}</p>
    </div>
    <div class="col-12 d-flex flex-wrap">
      <div class="col-12">
        <span class="label">Características</span>
        <hr>
      </div>
      <div class="col-lg-4 com-md-12">
        <span class="label">Tipo</span>
        <p>{{anuncio.materialCategoria.materialTipo.nome}}</p>
      </div>
      <div class="col-lg-5 com-md-12">
        <span class="label">Categoria</span>
        <p>{{anuncio.materialCategoria.nome}}</p>
      </div>
      <div class="col-lg-3 com-md-12"
        *ngIf="anuncio.donoAnuncio || authService.hasRole(['GOVERNO','CENTRALCOM','GESTOR','SEGES'])">
        <span class="label">Possui ônus ou encargos </span>
        <p>{{ anuncio.possuiOnusOuEncargos ? 'Sim' : 'Não'}}</p>
      </div>
      <div class="col-12"
        *ngIf="(anuncio.donoAnuncio || authService.hasRole(['GOVERNO','CENTRALCOM','GESTOR','SEGES'])) && anuncio.possuiOnusOuEncargos">
        <span class="label">Descrição de ônus ou encargos </span>
        <p>{{anuncio.descricaoOnusOuEncargos}}</p>
      </div>
    </div>
  </div>

  <div *ngIf="apresentaInformacoesDemonstracaoInteresse()" class="br-card col-12 px-5 py-4 mt-3 d-flex flex-wrap">
    <div class="col-6">
      <span class="label">Interessado</span>
      <p>{{anuncioInteresseUsuario.nomeContato}}</p>
    </div>
    <div class="col-6">
      <span class="label">Data / Hora da demonstração do interesse</span>
      <p>{{anuncioInteresseUsuario.dataInteresse}}</p>
    </div>
    <div class="col-6">
      <span class="label">Quantidade Interesse</span>
      <p>{{anuncioInteresseUsuario.quantidadeInteresse}}</p>
    </div>

    <div class="col-6">
      <span class="label">Quantidade Autorizada</span>
      <p>{{anuncioInteresseUsuario.quantidadeAvaliacao}}</p>
    </div>

    <div class="col-12">
      <span class="label">Observação</span>
      <p>{{anuncioInteresseUsuario.observacaoInteresse}}</p>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-end mt-2 mb-3">

    <button *ngIf="exibirBotaoConfirmarDoacao && anuncioInteresseUsuario.situacao.id === 1" (click)="confirmarDoacao()" label="Confirmar Doação" pButton
      icon="fa fa-handshake-o" class="ui-button-primary button-small"></button>

    <button *ngIf="exibirBotaoTenhoInteresse" (click)="onTenhoInteresse()" label="Tenho Interesse" pButton
      icon="fa fa-heart" class="ui-button-primary button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotaoEditar" (click)="alterarAnuncio($event)" title="Editar Anúncio" label="Editar" pButton
      icon="fa fa-edit" class="ui-button-danger button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotaoExcluir" (click)="prepararExcluir()" title="Excluir Anúncio" label="Excluir" pButton
      icon="fa fa-remove" class="ui-button-danger button-small"></button>

    <button *ngIf="exibirBotaoExcluirAnuncioPrivadoCentralCompras"
      (click)="prepararExcluirAnuncioPrivadoCentralCompras()" title="Inativar Anúncio" label="Excluir" pButton
      icon="fa fa-trash" class="ui-button-danger button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotaoInativar" (click)="prepararInativarAnuncio()" title="Inativar Anúncio" label="Inativar"
      pButton icon="fa fa-archive" class="ui-button-danger button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotaoRepublicacao" (click)="prepararRepublicarAnuncio()" label="Republicar" pButton
      icon="fa fa-refresh" class="ui-button-primary button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotoesAutorizarPublicacao" (click)="prepararComplementacaoAnuncioPrivado()"
      title="Solicitar Complementação" label="Complementar" pButton icon="fa fa-exclamation"
      class="ui-button-warning button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotoesAutorizarPublicacao" (click)="prepararIndeferirAnuncioPrivado()"
      title="Indeferir Anúncio" label="Indeferir" pButton icon="fa fa-remove"
      class="ui-button-danger button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="exibirBotoesAutorizarPublicacao" (click)="prepararPublicarAnuncioPrivado()" title="Publicar Anúncio"
      label="Publicar" pButton icon="fa fa-check" class="ui-button-success button-small"></button>

    <span class="espaco-r-5"></span>
    <button *ngIf="anuncio.permissoes.podeDenunciar" (click)="prepararDenunciarAnuncio()" title="Denunciar anúncio"
      #botaoDenunciar label="Denunciar" pButton icon="fa fa-flag" class="ui-button-danger button-small"></button>
  </div>

</div>

<!-- tabela deatlhes adicionais -->
<p-panel header="Detalhes:" *ngIf="exibirDetalhes()" class="layout-tabela">
  <div class="ui-g">
    <p-table class="ui-g-12" [value]="anuncio.detalhes">
      <ng-template pTemplate="header">
        <tr>
          <th>Situação</th>
          <th>Quantidade</th>
          <th>Valor Avaliado (R$)</th>
          <th>Informações Complementares</th>
          <th>Patrimônio</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-anuncioDetalhe>
        <tr>
          <td>{{anuncioDetalhe.materialSituacao.nome}}</td>
          <td class="text-center">{{anuncioDetalhe.quantidade}}</td>
          <td class="text-right">{{anuncioDetalhe.valorAvaliacao | number:'1.2'}} </td>
          <td>{{anuncioDetalhe.dadosComplementares}}</td>
          <td class="text-right">{{anuncioDetalhe.patrimonio}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="5">
            Nenhum registro encontrado.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>

<!-- tabela interessados -->
<p-panel header="Interessado(s):" *ngIf="exibirInteressados" class="layout-tabela">
  <div class="ui-g">
    <p-table class="ui-g-12" [value]="anuncio.interesses">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Órgão / Entidade</th>
          <th style="width: 20%;">Interessado</th>
          <th style="width: 15%;">Qtde. Interesse</th>
          <th style="width: 15%;">Qtde. Autorizada</th>
          <th style="width: 10%;">Qtde. Doada</th>
          <th style="width: 15%;">Valor Total Doado</th>
          <th style="width: 15%;">Data / Hora Interesse</th>
          <th style="width: 15%;">Data / Hora Autorização</th>
          <th style="width: 15%;">Data / Hora Doação</th>
          <th style="width: 15%;">Situação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-interesseAnuncio>
        <tr [ngClass]="{'contentBold' : interesseAnuncio.favorecida}">

          <td class="font-smaller">
            {{interesseAnuncio.entidade.numero}} -
            {{interesseAnuncio.entidade.nome}} <span *ngIf="interesseAnuncio.favorecida" class="badge badge-info">
              Favorecida</span></td>

          <td class="font-smaller">
            {{interesseAnuncio.nomeContato}} - {{interesseAnuncio.telefoneContato}} -
            {{interesseAnuncio.emailContato}}</td>


          <td class="font-smaller text-center">{{interesseAnuncio.quantidadeInteresse != null ?
            interesseAnuncio.quantidadeInteresse : 0 | number:'1.0'}} </td>

          <td class="font-smaller text-center">{{interesseAnuncio.quantidadeAvaliacao != null ?
            interesseAnuncio.quantidadeAvaliacao : 0 | number:'1.0'}}</td>

          <td class="font-smaller text-center">{{interesseAnuncio.quantidadeEntregue != null ?
            interesseAnuncio.quantidadeEntregue : 0 | number:'1.0'}}</td>

          <td class="font-smaller text-center">{{interesseAnuncio.valorTotalDoado != null ?
            interesseAnuncio.valorTotalDoado : 0}}</td>

          <td class="font-smaller text-center">{{interesseAnuncio.dataInteresse}}</td>

          <td class="font-smaller text-center">{{interesseAnuncio.dataAvaliacao}}</td>
          <td class="font-smaller text-center">{{interesseAnuncio.dataEntrega}}</td>
          <td class="font-smaller" [ngClass]="classAnuncioInteresseSituacao(interesseAnuncio.situacao)">
            {{interesseAnuncio.situacao.nome}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td class="text-right espaco-10 contentBold" colspan="2">Total:</td>
          <td class="text-center espaco-10 contentBold">{{totalQuantidadeInteresse != null?
            totalQuantidadeInteresse : 0 | number:'1.0'}}</td>
          <td class="text-center espaco-10 contentBold">{{totalQuantidadeAvaliacao != null?
            totalQuantidadeAvaliacao : 0 | number:'1.0'}}</td>
          <td class="text-center espaco-10 contentBold">{{totalQuantidadeDoada != null?
            totalQuantidadeDoada : 0 | number:'1.0'}}</td>
          <td class="text-center espaco-10 contentBold">{{totalValorDoado != null?
            totalValorDoado : 0 | number:'1.2' }}</td>
          <td></td>
          <td></td>
          <td class="text-center espaco-10 contentBold" colspan="2">
            Qtde. não autorizada: {{totalRestante != null? totalRestante : 0 | number:'1.0'}}</td>
          <td></td>
        </tr>
        <tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2">
            Nenhum registro encontrado.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>

<p-confirmDialog key="modal_{{anuncio.id}}" header="Confirmação" width="425" #cd [closable]="false">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject();"
      class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
      class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>

<p-dialog modal=true [(visible)]="exibirModalSolicitarComplementacao" header="Solicitar Complementação" width="425"
  [closable]="false">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmitSolicitarComplementacao(submitBtn)"
    *ngIf="exibirModalSolicitarComplementacao">
    <div [class.erro-form]="verificarCampoInvalido('complementacao')">Complementação*</div>
    <textarea #complementacao id="complementacao" name="complementacao" [rows]="5" style="width: 100%" pInputTextarea
      formControlName="complementacao" placeholder="Informe ao doador o que precisa ser complementado."></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="ocultarModalSolicitarComplementacao()"
        class="ui-button-secondary button-dialog"></button>
      <span class="espaco-r-5"></span>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>

<p-dialog modal=true [(visible)]="exibirModalIndeferirAnuncio" header="Motivo do Indeferimento" width="425"
  [closable]="false">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmitIndeferirAnuncio(submitBtn)"
    *ngIf="exibirModalIndeferirAnuncio">
    <div [class.erro-form]="verificarCampoInvalido('motivo')">Motivo*</div>
    <textarea #motivoIndeferimento id="motivoIndeferimento" name="motivoIndeferimento" [rows]="5" style="width: 100%"
      pInputTextarea formControlName="motivo"
      placeholder="Informe ao doador o motivo do indeferimento do anúncio."></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="ocultarModalIndeferirAnuncio()"
        class="ui-button-secondary button-dialog"></button>
      <span class="espaco-r-5"></span>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>

<p-dialog modal=true [(visible)]="exibirModalExcluirAnuncioPrivadoCentralCompras" header="Motivo da Exclusão"
  width="425" [closable]="false">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmitExcluirAnuncioPrivadoCentralCompras(submitBtn)"
    *ngIf="exibirModalExcluirAnuncioPrivadoCentralCompras">
    <div [class.erro-form]="verificarCampoInvalido('motivo')">Motivo*</div>
    <textarea #motivoExclusao id="motivoExclusao" name="motivoExclusao" [rows]="5" style="width: 100%" pInputTextarea
      formControlName="motivo" placeholder="Informe ao doador o motivo da exclusão do anúncio."></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar"
        (click)="ocultarModalExcluirAnuncioPrivadoCentralCompras()" class="ui-button-secondary button-dialog"></button>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>

<p-dialog modal=true [(visible)]="exibirModalDenunciarAnuncio" header="Motivo da denúncia" width="425"
  [closable]="false">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmitDenunciarAnuncio(submitBtn)"
    *ngIf="exibirModalDenunciarAnuncio">
    <div [class.erro-form]="verificarCampoInvalido('motivo')">Motivo*</div>
    <textarea #motivoDenuncia id="motivoDenuncia" name="motivoDenuncia" [rows]="5" style="width: 100%" pInputTextarea
      formControlName="motivo" placeholder="Informe o motivo da denúncia do anúncio."></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="ocultarModalDenunciarAnuncio()"
        class="ui-button-secondary button-dialog"></button>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>

<p-dialog modal=true [(visible)]="exibirModalAnalisarDenuncia" header="{{ titutloAnalisarDenuncia() }}" width="425"
  [closable]="false">
  <form [formGroup]="formulario" #form="ngForm" (ngSubmit)="onSubmitAnalisarDenuncia(submitBtn)"
    *ngIf="exibirModalAnalisarDenuncia">
    <div [class.erro-form]="verificarCampoInvalido('motivo')">Motivo*</div>
    <textarea #motivoDenuncia id="motivo" name="motivo" [rows]="5" style="width: 100%" pInputTextarea
      formControlName="motivo" placeholder="{{ titutloAnalisarDenuncia() }}"></textarea>

    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Cancelar" (click)="ocultarModalAnalisarDenuncia()"
        class="ui-button-secondary button-dialog"></button>
      <button #submitBtn type="submit" pButton icon="pi pi-check" label="Ok" class="ui-button-success button-dialog"
        [disabled]="formulario.invalid"></button>
    </p-footer>
  </form>
</p-dialog>


<reuse-modal-confirmar-doacao #reuseModalConfirmarDoacao [anuncio]="anuncio"
  [anuncioInteresse]="anuncioInteresseUsuario">
</reuse-modal-confirmar-doacao>

import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AnuncioChip } from '../../../anuncio/models/anuncio-chip.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { DesbloqueioAnuncioService } from 'app/desbloqueio-anuncio/services/desbloqueio-anuncio.service';
import { ParametrosDesbloqueioAnuncio } from 'app/desbloqueio-anuncio/models/parametros-desbloqueio-anuncio.model';
import { FiltroDesbloqueioAnuncio } from 'app/desbloqueio-anuncio/models/filtro-desbloqueio-anuncio.model';
import { DesbloqueioAnuncioIntenoPage } from '../common/desbloqueio-anuncio-inteno-page';

@Component({
  selector: 'reuse-desbloqueio-anuncio-page',
  templateUrl: './desbloqueio-anuncio-page.component.html',
  styleUrls: ['./desbloqueio-anuncio-page.component.scss']
})
export class DesbloqueioAnuncioPageComponent extends DesbloqueioAnuncioIntenoPage implements OnInit {

  filtro: FiltroDesbloqueioAnuncio = new FiltroDesbloqueioAnuncio();

  constructor(
    private bloqueioService: DesbloqueioAnuncioService,
    private router: Router,
    private route: ActivatedRoute,
    private appMessageService: AppMessageService) {
      super();
    }

  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosDesbloqueioAnuncio = this.bloqueioService.obterParametrosBloqueioUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.consultarFiltros(parametros);
  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  criarBreadcrumb() {
    let breadcrumbItems = [
      {icon:'fa fa-unlock', label:'Desbloquear Anúncios', command: (onclick)=>{this.router.navigate(['desbloqueio-anuncio'], {queryParams: { dt: new Date().getTime()}})}}
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  consultarFiltros(parametros: ParametrosDesbloqueioAnuncio) {
    //TODO Criar retorno filtros caso necessário.

    this.definirFiltrosSelecionados(parametros);
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  definirFiltrosSelecionados(parametros: ParametrosDesbloqueioAnuncio) {
    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v!='').map(v => <AnuncioChip>{value:v, display:v});
    this.filtro.anuncios = anuncios;
  }

 /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'desbloqueio-anuncio-lista' monitora a URL para carregar novos bloqueios.
   */
  aplicarFiltros() {
    const anuncios:string = this.filtro.anuncios.map(m => m.display).join(',');

    this.router.navigate(['/desbloqueio-anuncio'], {
      queryParams: {
        anuncios: anuncios,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  limparFiltros() {
    this.filtro.anuncios = [];
    this.aplicarFiltros();
  }
}

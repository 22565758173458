
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DesbloqueioAnuncioPageComponent } from './components/desbloqueio-anuncio-page/desbloqueio-anuncio-page.component';
import { DesbloqueioAnuncioListaComponent } from './components/desbloqueio-anuncio-lista/debloqueio-anuncio-lista.component';


const routes: Routes = [
  {
    path: 'desbloqueio-anuncio',
    component: DesbloqueioAnuncioPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR','GOVERNO']
    },
    children:[
      {
        path: '',
        component: DesbloqueioAnuncioListaComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DesbloqueioAnuncioRoutingModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from '../../anuncio/services/api-error-handler.service';
import { ParametrosBloqueioUsuarioPrivado } from '../models/parametros-bloqueio-usuario-privado.model';
import { ResultadoPesquisaBloqueioUsuarioPrivado } from '../models/resultado-pesquisa-bloqueio-usuario-privado.model';
import { BloqueioUsuarioEntidadeRegistro } from '../models/bloqueio-usuario-entidade-registro.model';
import { BloqueioUsuarioEntidade } from '../models/bloqueio-usuario-entidade';
import { BloqueioUsuarioEntidadeSave } from '../models/bloqueio-usuario-entidade-save';

@Injectable()
export class BloqueioUsuarioPrivadoService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  /**
   * Consultar bloqueios existentes
   */
  public consultar(parametros: ParametrosBloqueioUsuarioPrivado): Observable<ResultadoPesquisaBloqueioUsuarioPrivado> {
    const url = `${environment.apiUrl}/bloqueio-usuario-privado/listagem`;
    const params = new HttpParams()
    .set('tpConsulta', parametros.tpConsulta)
    .set('tipoPesquisa', parametros.tipoPesquisa)
    .set('cpf', parametros.cpf)
    .set('nome', parametros.nome)
    .set('cnpj', parametros.cnpj)
    .set('razaoSocial', parametros.razaoSocial)
    .set('inicio', parametros.inicio)
    .set('sortField', parametros.sortField)
    .set('sortOrder', parametros.sortOrder)
    .set('totalRegistros', parametros.totalRegistros);

    return this.http.get(url, {params})
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public bloquearDesbloquear(bloqueio:BloqueioUsuarioEntidadeSave): Observable<any> {
    const url = `${environment.apiUrl}/bloqueio-usuario-privado/bloquearDesbloquear`;
    return this.http.post(url, bloqueio)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  /**
   * Consultar bloqueios existentes
   */
  public consultarHistorico(registro: BloqueioUsuarioEntidadeRegistro): Observable<BloqueioUsuarioEntidade[]> {
    const url = `${environment.apiUrl}/bloqueio-usuario-privado/historico`;
    const params = new HttpParams()
    .set('tipoBloqueio',registro.tipo)
    .set('codigo', registro.codigo);

    return this.http.get(url, {params})
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public obterParametrosUrl(params): ParametrosBloqueioUsuarioPrivado {
    let parametros: ParametrosBloqueioUsuarioPrivado = {
      tpConsulta: params['tpConsulta'] || '',
      tipoPesquisa: params['tipoPesquisa'] || '',
      cpf: params['cpf'] || '',
      nome: params['nome'] || '',
      cnpj: params['cnpj'] || '',
      razaoSocial: params['razaoSocial'] || '',
      inicio: params['inicio'] || '',
      sortField: params['sortField'] || '',
      sortOrder: params['sortOrder'] || '',
      totalRegistros: params['totalRegistros'] || '',
    };
    return parametros;
  }
}

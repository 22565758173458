import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from '../../anuncio/services/api-error-handler.service';
import { ParametrosDesbloqueioAnuncio } from '../models/parametros-desbloqueio-anuncio.model';
import { ResultadoPesquisaDesbloqueioAnuncio } from '../models/resultado-pesquisa-desbloqueio-anuncio.model';
import { BloqueioAnuncio } from '../models/bloqueio-anuncio.model';

@Injectable()
export class DesbloqueioAnuncioService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  /**
   * Consultar bloqueios existentes
   */
  public consultarBloqueio(parametros: ParametrosDesbloqueioAnuncio): Observable<ResultadoPesquisaDesbloqueioAnuncio> {
    const url = `${environment.apiUrl}/desbloqueio-anuncio/listagem`;
    const params = new HttpParams()
    .set('anuncios', parametros.anuncios);

    return this.http.get(url, {params})
      .pipe(
        catchError(this.errorHandler.handle()));
  }

  public removerBloqueio(bloqueio:BloqueioAnuncio[]): Observable<any> {
    const url = `${environment.apiUrl}/desbloqueio-anuncio/remover-bloqueio`;
    return this.http.post(url,bloqueio)
      .pipe(
        catchError(this.errorHandler.handle())
      );
  }

  public obterParametrosBloqueioUrl(params): ParametrosDesbloqueioAnuncio {
    let parametros: ParametrosDesbloqueioAnuncio = {
      anuncios: params['anuncios'] || ''
    };
    return parametros;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aviso-responsabilidade',
  templateUrl: './aviso-responsabilidade.component.html',
  styleUrls: ['./aviso-responsabilidade.component.scss']
})
export class AvisoResponsabilidadeConteudo implements OnInit {

  //exibirModalResponsabilidade: boolean = true; //monitoracao automatizada suspensa
  exibirModalResponsabilidade: boolean = false;

  ngOnInit() {
  }

  public fecharModalResponsabilidade() {
    this.exibirModalResponsabilidade = false;
  }

}

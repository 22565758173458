import { Component, OnInit } from '@angular/core';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FiltroMeusAnunciosGoverno } from '../../models/filtro-meus-anuncios-governo.model';
import { ParametrosMeusAnunciosGoverno } from '../../models/parametros-meus-anuncios-governo.model';
import { MaterialTipo } from '../../models/material-tipo.model';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { Uf } from '../../models/uf.model';
import { Entidade } from '../../models/entidade.model';
import { Usuario } from '../../models/usuario.model';
import { AnuncioChip } from '../../models/anuncio-chip.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { MaterialSituacao } from '../../models/material-situacao.model';
import { AppMessageService } from '../../../_services/app.message/app.message.service';
import { AppMessageType } from '../../../_services/app.message/app.message.type';
import { AppMessage } from '../../../_services/app.message/app.message.model';
import { AuthService } from 'app/shared/services/auth.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { RouteService } from 'app/shared/services/route.service';

@Component({
  selector: 'reuse-meus-anuncios-governo-page',
  templateUrl: './meus-anuncios-governo-page.component.html',
  styleUrls: ['./meus-anuncios-governo-page.component.scss']
})
export class MeusAnunciosGovernoPageComponent extends AnunciosInternoPage implements OnInit {

  filtro: FiltroMeusAnunciosGoverno = new FiltroMeusAnunciosGoverno();

  constructor(private anuncioService: AnuncioService,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private routeService: RouteService, //necesário para o boltao voltar no Cadastro Anuncio
    private appMessageService: AppMessageService) {
      super();
    }



  ngOnInit() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosGoverno = this.anuncioService.obterParametrosMeusAnunciosGovernoUrl(parametrosUrl);

    this.criarBreadcrumb();
    this.configurarCalendario();
    this.consultarFiltros(parametros);
    this.popularSeparadoresTagInput();

  }

  /**
   * Configuração da barra 'Breadcrumb'.
   */
  private criarBreadcrumb():void {
    let breadcrumbItems = [
      {icon:'fa fa-newspaper-o', label:'Meus Anúncios', command: (onclick)=>{this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO], {queryParams: { dt: new Date().getTime()}})}}
    ];
    this.appMessageService.sendMessage(new AppMessage(AppMessageType.BREADCRUMB, breadcrumbItems));
  }

  /**
   * Chama o backend para carregar todos os filtros utilizados na página.
   * Realizado somente quando a página é iniciada.
   *
   * @param parametros
   */
  private consultarFiltros(parametros: ParametrosMeusAnunciosGoverno):void {
    this.anuncioService.consultarFiltrosMeusAnunciosGoverno(parametros)
      .subscribe(
        (resultado: ResultadoPesquisaInternaAnuncio) => {
          if (this.authService.hasRole(['GOVERNO'])){
             this.filtro.materialTipos = resultado.materialTipos.filter(obj => obj.nome !== 'Serviço');
          } else {
             this.filtro.materialTipos = resultado.materialTipos;
          }
          this.filtro.materialCategorias = resultado.materialCategorias;
          this.filtro.materialSituacoes = resultado.materialSituacoes;
          this.filtro.ufs = resultado.ufs;
          this.filtro.onus
          this.filtro.analisadoPor = resultado.analisadoPor;
          this.filtro.entidades = resultado.entidades;

          this.definirFiltrosSelecionados(parametros);
        });
  }

  /**
   * Marca os filtros selecionados a partir dos parâmetros da url, caso existam.
   *
   * @param parametros
   */
  private definirFiltrosSelecionados(parametros: ParametrosMeusAnunciosGoverno):void {
    this.filtro.pesquisa = parametros.pesquisa;

    const materialTipos: MaterialTipo[] = parametros.materialTipos.split(',').filter(v => v!='').map((nome) => <MaterialTipo>{nome});
    this.filtro.materialTiposSelecionados = materialTipos;

    const materialCategorias: MaterialCategoria[] = parametros.materialCategorias.split(',').filter(v => v!='').map((nome) => <MaterialCategoria>{nome});
    this.filtro.materialCategoriasSelecionadas = materialCategorias;

    const materialSituacoes: MaterialSituacao[] = parametros.materialSituacoes.split(',').filter(v => v!='').map((nome) => <MaterialSituacao>{nome});
    this.filtro.materialSituacoesSelecionadas = materialSituacoes;

    const ufs: Uf[] = parametros.ufs.split(',').filter(v => v!='').map((sigla) => <Uf>{sigla});
    this.filtro.ufsSelecionadas = ufs;

    const periodoInclusao:Date[] = parametros.periodoInclusao.split(',').filter(v => v!='').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoInclusao.length!=0) {
      this.filtro.periodoInclusao = periodoInclusao;
    }
    const periodoExpiracao:Date[] = parametros.periodoExpiracao.split(',').filter(v => v!='').map((data) => this.stringParaData(data, 'DD-MM-YYYY'));
    if (periodoExpiracao.length!=0) {
      this.filtro.periodoExpiracao = periodoExpiracao;
    }

    const anuncios: AnuncioChip[] = parametros.anuncios.split(',').filter(v => v!='').map(v => <AnuncioChip>{value:v, display:v});
    this.filtro.anuncios = anuncios;

    const entidades: Entidade[] = parametros.entidades.split(',').filter(v => v!='').map((nome) => <Entidade>{nome});
    this.filtro.entidadesSelecionadas = entidades;

    const analisadoPor: Usuario[] = parametros.analisadoPor.split(',').filter(v => v!='').map((nome) => <Usuario>{nome});
    this.filtro.analisadoPorSelecionados = analisadoPor;
  }

  /**
   * Chama uma nova URL adicionando todos os filtros selecionados.
   * Componente 'meus-anuncios-lista' monitora a URL para carregar novos anúncios.
   */
  public aplicarFiltros():void {
    const materialTipos:string = this.filtro.materialTiposSelecionados.map(m => m.nome).join(',');
    const materialCategorias:string = this.filtro.materialCategoriasSelecionadas.map(m => m.nome).join(',');
    const materialSituacoes:string = this.filtro.materialSituacoesSelecionadas.map(m => m.nome).join(',');
    const ufs:string = this.filtro.ufsSelecionadas.map(m => m.sigla).join(',');

    let periodoInclusao: string = '';
    if (this.filtro.periodoInclusao!=null) {
      periodoInclusao = this.filtro.periodoInclusao.filter(v => v !=null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }
    let periodoExpiracao: string = '';
    if (this.filtro.periodoExpiracao!=null) {
      periodoExpiracao = this.filtro.periodoExpiracao.filter(v => v !=null).map(m => this.dataParaString(m, 'DD-MM-YYYY')).join(',');
    }

    const anuncios:string = this.filtro.anuncios.map(m => m.display).join(',');
    const entidades:string = this.filtro.entidadesSelecionadas.map(m => m.nome).join(',');
    const analisadoPor:string = this.filtro.analisadoPorSelecionados.map(m => m.nome).join(',');

    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosGoverno = this.anuncioService.obterParametrosMeusAnunciosGovernoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO], {
      queryParams: {
        pesquisa: this.filtro.pesquisa,
        materialTipos: materialTipos,
        materialCategorias: materialCategorias,
        materialSituacoes: materialSituacoes,
        ufs: ufs,
        periodoInclusao: periodoInclusao,
        periodoExpiracao: periodoExpiracao,
        anuncios: anuncios,
        entidades: entidades,
        analisadoPor: analisadoPor,
        ordem: parametros.ordem,
        dt: new Date().getTime(), //altera a url para forcar recarregar a pagina
      }
    });
  }

  /**
   * Limpa os filtros selecionados e chama a consulta.
   */
  public limparFiltros():void {
    this.filtro.pesquisa = null;
    this.filtro.materialTiposSelecionados = [];
    this.filtro.materialCategoriasSelecionadas = [];
    this.filtro.materialSituacoesSelecionadas = [];
    this.filtro.ufsSelecionadas = [];
    this.filtro.periodoInclusao = null;
    this.filtro.periodoExpiracao = null;
    this.filtro.anuncios = [];
    this.filtro.entidadesSelecionadas = [];
    this.filtro.analisadoPorSelecionados = [];
    this.aplicarFiltros();
  }

  public limparPeriodoInclusao(input):void {
    this.filtro.periodoInclusao = null;
    this.fecharCalendario(input);
  }

  public limparPeriodoExpiracao(input):void {
    this.filtro.periodoExpiracao = null;
    this.fecharCalendario(input);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiErrorHandlerService } from '../../anuncio/services/api-error-handler.service';
import { ResultadoPesquisaCadastroEntidade } from '../models/resultado-pesquisa-cadastro-entidade.model';
import { ParametrosCadastroEntidade } from '../models/parametros-cadastro-entidade.model';
import { EntidadeTipo } from 'app/anuncio/models/entidade-tipo.model';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { FormGroup } from '@angular/forms';
import { Orgao } from 'app/anuncio/models/orgao.model';

@Injectable()
export class CadastroEntidadeService {

  constructor(
    private http: HttpClient,
    private errorHandler: ApiErrorHandlerService
  ) { }

  /**
 * Consultar entidade por id
 */
  public obterCadastroEntidade(idEntidade: number, tipoOperacao: string): Observable<Entidade> {
    const url = `${environment.apiUrl}/cadastro-entidade/obter-entidade/${idEntidade}/${tipoOperacao}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
   * Excluir Entidade por ID
   */
  public excluirEntidade(idEntidade: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-entidade/excluir-entidade/${idEntidade}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  public inativarEntidade(idEntidade: number): Observable<any>{
    const url = `${environment.apiUrl}/cadastro-entidade/inativar-entidade/${idEntidade}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  public listarEntidadeTipo(): Observable<EntidadeTipo[]> {
    const url = `${environment.apiUrl}/cadastro-entidade/listar-entidade-tipo`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle())
    );
  }

  public listarEntidadeEsfera(): Observable<EntidadeTipo[]> {
    const url = `${environment.apiUrl}/cadastro-entidade/listar-entidade-esfera`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
 * Incluir Entidade com status de excluido por ID
 */
  public confirmouInclusaoEntidadeStatusExcluido(idEntidade: number): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-entidade/inclusao-entidade-status-excluido/${idEntidade}`;
    return this.http.get(url).pipe(catchError(this.errorHandler.handle()));
  }

  /**
 * Salvar Entidade
 */
  public salvarEntidade(formulario: FormGroup, tipoOperacao: string): Observable<any> {
    const url = `${environment.apiUrl}/cadastro-entidade/salvar-entidade`;
    const entidade = <Entidade>{
      id: tipoOperacao == 'I' ? null : formulario.get('id').value,
      numero: formulario.get('numero').value,
      nome: formulario.get('nome').value,
      sigla: formulario.get('sigla').value,
      telefone1: formulario.get('telefone1').value,
      telefone2: formulario.get('telefone2').value,
      email: formulario.get('email').value,
      endereco: formulario.get('endereco').value,
      enderecoNumero: formulario.get('enderecoNumero').value,
      bairro: formulario.get('bairro').value,
      cep: formulario.get('cep').value,
      usuarioResponsavel: formulario.get('usuarioResponsavel').value,
      municipio: formulario.get('municipio').value,
      orgao: new Orgao(formulario.get('orgao').value.id), //So funcionou usando new Orgao
      uf: formulario.get('uf').value,
      entidadeTipo: formulario.get('entidadeTipo').value,
      entidadeEsfera: formulario.get('entidadeEsfera').value,
      utilizaSiads: formulario.get('utilizaSiads').value.id == 1 ? true : false,
      ativo: formulario.get('ativo').value.id == 1 ? true : false,
    };
    return this.http.post(url, entidade).pipe(catchError(this.errorHandler.handle()));
  }

  public obterParametrosCadastroEntidadeUrl(params): ParametrosCadastroEntidade {
    let parametros: ParametrosCadastroEntidade = {
      numero: params['numero'] || '',
      nome: params['nome'] || '',
      idOrgao: params['idOrgao'] || 0,
      idEntidadeEsfera: params['idEntidadeEsfera'] || 0,
      idEntidadeTipo: params['idEntidadeTipo'] || 0,
      idAtivo: params['idAtivo'] || 0,
      idUtilizaSiads: params['idUtilizaSiads'] || 0,

      inicio: params['inicio'] || '',
      sortField: params['sortField'] || '',
      sortOrder: params['sortOrder'] || '',
      totalRegistros: params['totalRegistros'] || 0,
      tpConsulta: params['tpConsulta'] || 1
    };

    return parametros;
  }

  /**
   * Consultar entidades pelo filtro
   */
  public consultarEntidade(parametros: ParametrosCadastroEntidade): Observable<ResultadoPesquisaCadastroEntidade> {
    const url = `${environment.apiUrl}/cadastro-entidade/listagem`;
    const params = new HttpParams()
      .set('numero', parametros.numero)
      .set('nome', parametros.nome)
      .set('idOrgao', String(parametros.idOrgao))
      .set('idEntidadeEsfera', String(parametros.idEntidadeEsfera))
      .set('idEntidadeTipo', String(parametros.idEntidadeTipo))
      .set('idAtivo', String(parametros.idAtivo))
      .set('idUtilizaSiads', String(parametros.idUtilizaSiads))
      .set('inicio', parametros.inicio)
      .set('sortField', parametros.sortField)
      .set('sortOrder', parametros.sortOrder)
      .set('totalRegistros', parametros.totalRegistros)
      .set('tpConsulta', parametros.tpConsulta);

    return this.http.get(url, { params }).pipe(catchError(this.errorHandler.handle()));
  }
}

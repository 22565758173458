import { SimNao } from 'app/anuncio/models/sim-nao';
import { Uf } from 'app/anuncio/models/uf.model';
import { MaterialTipo } from 'app/anuncio/models/material-tipo.model';
import { MaterialCategoria } from 'app/anuncio/models/material-categoria.model';
import { Usuario } from './usuario.model';
import { Entidade } from './entidade.model';
import { AnuncioChip } from './anuncio-chip.model';
import { MaterialSituacao } from './material-situacao.model';

export class FiltroMeusAnunciosGoverno {
  pesquisa: string;
  materialTipos: MaterialTipo[];
  materialTiposSelecionados: MaterialTipo[];
  materialCategorias: MaterialCategoria[];
  materialCategoriasSelecionadas: MaterialCategoria[];
  materialSituacoes: MaterialSituacao[];
  materialSituacoesSelecionadas: MaterialSituacao[];
  ufs: Uf[];
  ufsSelecionadas: Uf[];
  onus: SimNao[];
  onusSelecionados: SimNao[];
  periodoInclusao: Date[];
  periodoExpiracao: Date[];
  anuncios: AnuncioChip[];
  analisadoPor: Usuario[];
  analisadoPorSelecionados: Usuario[];
  entidades: Entidade[];
  entidadesSelecionadas: Entidade[];

}

<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label id="numeroAnuncio">Número do Anúncio</label>
          <tag-input id="numeroAnuncio" name="numeroAnuncio" [(ngModel)]='filtro.anuncios'
            [separatorKeyCodes]="separadores" [validators]="somenteNumero" placeholder="Número"
            secondaryPlaceholder="Filtrar por Número" addOnBlur="true" theme='bootstrap'></tag-input>
        </div>
        <div class="ui-g-12">
          <label for="pesquisa">Título/Descrição</label>
          <input ngModel id="pesquisa" name="pesquisa" type="text" pInputText [(ngModel)]="filtro.pesquisa"
            placeholder="Filtrar por Título ou Descrição" maxlength="200">
        </div>
        <div class="ui-g-12">
          <label for="materialTipo">Tipo</label>
          <p-multiSelect id="materialTipo" name="materialTipo" defaultLabel="Todos"
            [(ngModel)]="filtro.materialTiposSelecionados" [options]="filtro.materialTipos" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>
        <div class="ui-g-12">
          <label for="anuncioSituacao">Situação</label>
          <p-multiSelect id="anuncioSituacao" name="anuncioSituacao" defaultLabel="Todos"
            [(ngModel)]="filtro.anuncioSituacoesSelecionados" [options]="filtro.anuncioSituacoes" optionLabel="nome"
            dataKey="nome"></p-multiSelect>
        </div>
        <div class="ui-g-12">
          <label for="periodoInclusao">Período</label>
          <p-calendar id="periodoInclusao" name="periodoInclusao" [(ngModel)]="filtro.periodoInclusao"
            selectionMode="range" readonlyInput="true" [locale]="pt" dateFormat="dd/mm/yy" [showIcon]="true"
            #periodoInclusao>
            <p-footer>
              <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker">
                <p-button icon="pi pi-check" class="button-datapicker-ok" (onClick)="fecharCalendario(periodoInclusao)">
                </p-button>
                <p-button icon="pi pi-times" class="button-datapicker-cancel"
                  (onClick)="limparPeriodoInclusao(periodoInclusao)"></p-button>
              </div>
            </p-footer>
          </p-calendar>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()">
        </p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="aplicarFiltros()">
        </p-button>
      </div>

    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class LoggedGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }

  private checkLogin(): boolean {
    if (!this.authService.isLoggedIn) { return true; }
    this.goDefault();
    return false;
  }

  private goDefault() {
    this.authService.redirectUrlSuccess = undefined;
    this.router.navigate(['/']);
  }
}

import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { AnuncioFoto } from '../../models/anuncio-foto.model';
import { AnuncioFotoOrdem } from '../../models/anuncio-foto-ordem.model';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AuthService } from '../../../shared/services/auth.service';
import { OrderList } from 'primeng/orderlist';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { UtilService } from '../../../shared/services/util.service';
import { AbstractCadastroAnuncio } from '../cadastro-anuncio-page/abstract-cadastro-anuncio';
import { Router } from '@angular/router';
import { AppMessageService } from '../../../_services/app.message/app.message.service';

@Component({
  selector: 'reuse-cadastro-anuncio-fotos',
  templateUrl: './cadastro-anuncio-fotos.component.html',
  styleUrls: ['./cadastro-anuncio-fotos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadastroAnuncioFotosComponent extends AbstractCadastroAnuncio implements OnInit {

  //fotos cadastradas para o anúncio
  fotos: AnuncioFoto[];

  //componente que exibe as fotos cadastradas para o anúncio
  @ViewChild('listaImagem') listaImagem: OrderList;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(
    private cadastroAnuncioService: CadastroAnuncioService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private auth: AuthService,
    private router: Router,
    private utilService: UtilService,
    public appMessageService: AppMessageService,) {
      super(appMessageService);
    }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  ngOnInit() {
    this.passo = 2;
    this.carregarFotos();
  }

  /**
   * Carrega as fotos já cadastradas para o anúncio.
   */
  private carregarFotos():void {
    this.cadastroAnuncioService.obterAnuncioFotosGoverno(this.numeroAnuncio)
      .subscribe(
        dados => {
          //dados do anúncio
          this.anuncio = dados.anuncio;

          //fotos existentes
          this.fotos = dados.fotos;

          this.carregouDados = true;
          window.scrollTo(0,0);

          this.removerSelecaoItem();

          this.utilService.verificarRegistroBloqueado(this.anuncio, true);
        }
      );
  }

  /**
   * Verifica se registro está bloqueado.
   */
  public podeAlterarDados():boolean {
    return !this.anuncio.mensagemBloqueio;
  }

  /**
   * Desmarca o item selecionado, para evitar problemas após a lista ser alterada.
   */
  private removerSelecaoItem():void {
    if (this.listaImagem) {
      this.listaImagem.selectedItems = [];
    }
  }

  /**
   * Indica se existe alguma foto cadastrada.
   */
  public possuiDados():boolean {
    return this.carregouDados && this.fotos.length != 0;
  }

  /**
   * Url do backend que insere as fotos.
   */
  public obterUrlInserirFotos():string {
    return `${environment.apiUrl}/cadastro-anuncio/inserir-fotos/${this.numeroAnuncio}`;
  }

  /**
   * * Evento disparado pelo componente de upload, após ser efetuado o upload de fotos.
   *
   * @param event
   */
  public onUpload(event:any):void {
    this.carregarFotos();
    this.messageService.add({severity:'success', summary:'Sucesso', detail:'Foto(s) salvas(s) com sucesso.'});
  }

  /**
   * Evento disparado pelo componente de upload, antes dos dados serem enviados.
   * Adiciona o token de autenticação.
   *
   * @param event
   */
  public onBeforeSend(event:any):void {
    event.xhr.setRequestHeader("Authorization", this.auth.getAuthorizationHeader());
  }

  /**
   * Evento disparado pelo componente de upload, quando ocorre qualquer erro no upload do backend.
   *
   * @param event
   */
  public onErroUpload(event:any):void {
    const erro:any = eval(event.xhr.response);
    const mensagem:string = erro.pop().mensagensErro.pop();

    this.messageService.add({
      severity:'error',
      summary: 'Erro no upload',
      detail: mensagem});

    this.carregarFotos();
  }

  /**
   * Evento disparado ao clicar no botão de remover foto.
   *
   * @param foto
   */
  public onRemoverFoto(foto: AnuncioFoto):void {
    this.confirmationService.confirm({
        message: 'Confirma a remoção da foto?',
        accept: () => {
          this.confirmouRemoverFoto(foto);
        }
    });
  }

  /**
   * Confirmou a remoção, chama o backend para remover foto.
   */
  private confirmouRemoverFoto(foto:AnuncioFoto):void {
    this.cadastroAnuncioService.removerFoto(foto.id)
      .subscribe(
        () => {
          //para remover do frontend, remove diretamente da lista
          this.fotos = this.fotos.filter(f => f.id != foto.id);
          this.removerSelecaoItem();
          this.messageService.add({severity:'success', summary:'Sucesso', detail:'Foto removida com sucesso.'});
        }
      );
  }

  /**
   * Evento disparado pelo componente que exibe as fotos cadastradas.
   * Ao alterar ordem, chama backend para persistir alteração.
   */
  public onAlterouOrdemFoto():void {
    if (this.listaImagem.selectedItems.length == 0) {
      //se não possui item selecionado, não tenta alterar ordem
      return;
    }

    //cria um array com a nova ordem das fotos
    const novaOrdem:AnuncioFotoOrdem[] = this.fotos.map((f, index) => <AnuncioFotoOrdem>{
      id: f.id, ordem: index
    });

    //salva nova ordem no backend
    this.cadastroAnuncioService.alterarOrdemFotos(this.numeroAnuncio, novaOrdem)
      .subscribe(
        () => {
          this.messageService.add({severity:'success', summary:'Sucesso', detail:'Dados alterados com sucesso.'});
        }
      );
  }

}

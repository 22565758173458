<p-panel header="Tenho Interesse"
  *ngIf="anuncioInteresse">
  <form [formGroup]="formularioInteresse" #form="ngForm"
    (ngSubmit)="onSubmit()" >
    <div class="ui-g">
      <div *ngIf="!anuncioUtil.servico(anuncio)" class="ui-g-2">
        <div [class.erro-form]="verificarCampoInvalido('quantidadeInteresse')">Quantidade Interessada*</div>
        <p-spinner thousandSeparator="." formControlName="quantidadeInteresse"
          [min]="0" [size]="5" appBlockCopyPaste></p-spinner>
      </div>
      <div [ngClass]="anuncioUtil.servico(anuncio) ? 'ui-g-12' : 'ui-g-10'">
        <div [class.erro-form]="verificarCampoInvalido('observacaoInteresse')">Observações</div>
        <textarea [rows]="2" style="width: 100%"
          pInputTextarea formControlName="observacaoInteresse"
          placeholder="Observações"></textarea>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-5">
        <div [class.erro-form]="verificarCampoInvalido('nomeContato')">Interessado - Nome*</div>
        <input type="text" pInputText style="width: 100%" maxlength="120"
          formControlName="nomeContato" placeholder="Nome"/>
      </div>
      <div class="ui-g-3">
        <div [class.erro-form]="verificarCampoInvalido('telefoneContato')">Telefone*</div>
        <p-inputMask type="text" mask="(99) 99999999?9"
          formControlName="telefoneContato" placeholder="(99) 999999999" [style]="{'width': '100%'}"></p-inputMask>
      </div>
      <div class="ui-g-4">
          <div [class.erro-form]="verificarCampoInvalido('emailContato')">Email*</div>
          <input type="email" pInputText style="width: 100%" maxlength="80"
            formControlName="emailContato" placeholder="email@dominio.com"/>
      </div>
    </div>

    <div id='Confirmar' *ngIf="modoEdicao" class="buttons text-right">
      <button type="button" pButton label="Cancelar" class="ui-button-secondary" (click)="onVoltarInteresse()"></button>
      <button type="submit" pButton [label]="nomeBotaoSubmit()" [disabled]="form.invalid" class="ui-button-success"></button>
    </div>

    <div id='Excluir' *ngIf="modoExclusao" class="buttons text-right">
      <button type="button" pButton label="Voltar" class="ui-button-secondary"
      (click)="onVoltarInteresse()" ></button>
      <button type="button" (click)="onExcluirInteresse(anuncio.id)" pButton icon="fa fa-trash"
        class="ui-button-danger" label="Cancelar Interesse" [disabled]="form.invalid"></button>
    </div>
  </form>
</p-panel>

<p-confirmDialog header="Confirmação" width="425" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()" class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()" class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>

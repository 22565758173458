import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { CadastroEntidadePageComponent } from './components/cadastro-entidade-page/cadastro-entidade-page.component';
import { CadastroEntidadeListaComponent } from './components/cadastro-entidade-lista/cadastro-entidade-lista.component';
import { CadastroEntidadeEditPageComponent } from './components/cadastro-entidade-edit-page/cadastro-entidade-edit-page.component';


const routes: Routes = [
  {
    path: 'listar-cadastro-entidade',
    component: CadastroEntidadePageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    children: [
      {
        path: '',
        component: CadastroEntidadeListaComponent,
        data: {
          origem: 'listar-cadastro-entidade'
        }
      },
    ]
  },
  {
    path: 'alterar-cadastro-entidade/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroEntidadeEditPageComponent,
  },
  {
    path: 'visualizar-cadastro-entidade/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroEntidadeEditPageComponent,
  },
  {
    path: 'incluir-cadastro-entidade/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR']
    },
    component: CadastroEntidadeEditPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastroEntidadeRoutingModule { }

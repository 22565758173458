import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnuncioInteresseService } from '../../services/anuncio-interesse.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Anuncio } from '../../models/anuncio.model';
import { ConfirmationService } from 'primeng/api';
import { AnuncioInteresse } from '../../models/anuncio-interesse.model';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Location } from '@angular/common';
import { UtilService } from '../../../shared/services/util.service';
import { AuthService } from 'app/shared/services/auth.service';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { AnuncioUtil } from 'app/shared/services/anuncio.util';

@Component({
  selector: 'reuse-tenho-interesse',
  templateUrl: './tenho-interesse.component.html',
  styleUrls: ['./tenho-interesse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TenhoInteresseComponent implements OnInit {

  @Output()
  concluiuAlteracao = new EventEmitter();

  modoVisualizacao: boolean = false;
  modoEdicao: boolean = false;
  modoExclusao: boolean = false;

  //anúncio ao qual será registrado o interesse
  anuncio: Anuncio;

  //registro de interesse para inserção ou alteração de interesse
  anuncioInteresse: AnuncioInteresse;

  //formulário 'Tenho Interesse'
  formularioInteresse: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    private anuncioInteresseService: AnuncioInteresseService,
    private confirmationService: ConfirmationService,
    private _scrollToService: ScrollToService,
    private _location: Location,
    private utilService: UtilService,
    public anuncioUtil: AnuncioUtil,
  ) { }

  ngOnInit() {
    this.verificarModoExibicao();
  }

  verificarModoExibicao() {
    this.verificarModoVisualizacao();
    this.verificarModoEdicao();
    this.verificarModoExclusao();
  }

  /**
   * Exibe o componente tenho-interesse.
   *
   * @param anuncio
   */
  public exibirComponente(anuncio: Anuncio) {
    this.verificarModoEdicao();

    if (this.modoVisualizacao || this.modoEdicao || this.modoExclusao) {
      this.anuncio = anuncio;

      const deveBloquear = this.deveBloquearRegistro();

      if (!this.verificarPermissao()) {
        return;
      }

      //carrega o interesse para inserção, edição ou exclusão
      this.anuncioInteresseService.obterAnuncioInteressado(this.anuncio.id, deveBloquear)
        .subscribe(
          anuncioInteresse => {
            this.anuncioInteresse = anuncioInteresse;
            this.controleBloqueioRegistro(anuncioInteresse);
            this.criarFormulario();
            this.moverParaComponenteTenhoInteresse();
          });
    }
  }

  private controleBloqueioRegistro(anuncioInteresse: AnuncioInteresse): void {
    const bloqueado = this.utilService.verificarRegistroBloqueado(anuncioInteresse, this.modoEdicao);
    if (bloqueado) {
      this.modoVisualizacao = true;
      this.modoEdicao = false;
      this.modoExclusao = false;
    }
  }

  /**
   * Caso url contenha subrota 'tenho-interesse', permitir edição.
   */
  private verificarModoEdicao(): void {
    const url = this.router.url;
    this.modoEdicao = url.indexOf('tenho-interesse') > 0;
  }

  /**
   * Caso exista parametro com valor 'excluir'.
   */
  private verificarModoExclusao(): void {
    this.modoExclusao = this.route.snapshot.queryParams["parametro"] == 'excluir';
  }

  /**
   * Caso exista parametro com valor 'excluir'.
   */
  private verificarModoVisualizacao(): void {
    this.modoVisualizacao = this.route.snapshot.url[0].path == OrigemEnum.MEUS_INTERESSES;
  }

  /**
   * Deve bloquear registro quando está alterando ou excluindo o registro.
   */
  private deveBloquearRegistro(): boolean {
    return this.modoEdicao || this.modoExclusao;
  }

  /**
   * Cria o formulário para inserção ou edição do interesse.
   */
  private criarFormulario() {
    this.formularioInteresse = this.formBuilder.group({
      quantidadeInteresse: [this.anuncioInteresse.quantidadeInteresse, this.anuncioUtil.servico(this.anuncio) ? null : [Validators.required, Validators.min(1), Validators.max(this.anuncio.quantidade)]],
      observacaoInteresse: [this.anuncioInteresse.observacaoInteresse],
      nomeContato: [this.anuncioInteresse.nomeContato, [Validators.required]],
      telefoneContato: [this.anuncioInteresse.telefoneContato, [Validators.required, this.utilService.validarTelefone]],
      emailContato: [this.anuncioInteresse.emailContato, [Validators.required, Validators.email]]
    });

    this.controleDesabilitarFormulario();
  }

  private controleDesabilitarFormulario(): void {
    if (!this.modoEdicao && (this.modoExclusao || this.modoVisualizacao)) {
      //se não está alterando e está excluindo ou visualizando, desabilitar campo do formulário
      this.formularioInteresse.disable();
    }
  }

  /**
   * Realiza o scroll da página até a div 'divTenhoInteresse',
   * a qual possui o componente tenho-interesse.
   */
  private moverParaComponenteTenhoInteresse(): void {
    const config: ScrollToConfigOptions = {
      target: 'divTenhoInteresse'

    };
    this._scrollToService.scrollTo(config);
  }

  /**
   * Voltar para rota pai.
   */
  private ocultarComponente() {
    this.anuncioInteresse = null;
    this.concluiuAlteracao.emit();
  }

  /**
   * Clique botão 'Voltar'
   */
  public onVoltarInteresse(): void {
    this.anuncioInteresseService.cancelarEdicao(this.anuncioInteresse.id).subscribe(
      () => {
        this.anuncioInteresse = null;
        this.locationBack();
      }
    );
  }

  //TODO Excluir médoto - Utilizado em testes para voltar do detalhamento
  // public onVoltarInteresse():void{
  //   if (this.deveBloquearRegistro()){
  //     // Elimina registro de bloqueio
  //     this.anuncioInteresseService.cancelarEdicao(this.anuncioInteresse.id).subscribe(
  //       () => {
  //         this.anuncioInteresse = null;
  //         this.locationBack();
  //       }
  //     );
  //   }else{
  //     //No modo de visualização, apenas volta sem eliminar registro de bloqueio.
  //     this.locationBack();
  //   }
  // }

  /**
   * Voltar para a URL anterior.
   * "Back" api
   * https://stackoverflow.com/questions/35446955/how-to-go-back-last-page/41953992
   */
  public locationBack(): void {
    this._location.back();
  }

  /**
   * Clique botão 'Confirmar Interesse'.
   */
  public onSubmit(): void {
    if (!this.verificarPermissao()) {
      return;
    }

    this.utilService.marcarCamposComoAlterado(this.formularioInteresse);
    if (this.formularioInteresse.valid) {
      this.confirmationService.confirm({
        message: 'Confirma o interesse?',
        accept: () => {
          this.confirmouEnvioDados();
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro de Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  private confirmouEnvioDados(): void {
    this.anuncioInteresseService.salvarEdicao(this.formularioInteresse, this.anuncio.id, this.anuncioInteresse.id).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
        this.ocultarComponente();
      });
  }

  private verificarPermissao(): boolean {
    if (!this.authService.hasRole(['GESTOR', 'GOVERNO'])) {
      this.messageService.add({ severity: 'error', summary: 'Operação não permitida', detail: 'Usuário não autorizado.' });
      return false;
    }

    return true;
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formularioInteresse.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  /**
   * Exibe Dialog para confirmar exclusão de interesse por anúncio.
   */
  public onExcluirInteresse(): void {
    this.confirmationService.confirm({
      message: 'Confirma a exclusão do interesse?',
      header: 'Confirmação',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.confirmouExclusaoInteresse();
      }
    });
  }

  private confirmouExclusaoInteresse(): void {
    this.anuncioInteresseService.excluirInteresse(this.anuncio.id, this.anuncioInteresse.id).subscribe(
      (resultado: any) => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });
      });
    this._location.back();
  }

  public nomeBotaoSubmit(): string {
    if (this.estaAlterando()) {
      return "Salvar Alterações";
    } else {
      return "Confirmar Interesse";
    }
  }

  /**
   * Se carregou um interesse do banco, então está em modo de edição.
   */
  private estaAlterando(): boolean {
    return this.anuncioInteresse.id != null;
  }
}

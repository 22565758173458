import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MensagensPageComponent } from './components/mensagem-page/mensagens-page.component';
import { MensagemDetailPageComponent } from './components/mensagem-datail-page/mensagem-detail-page.component';
import { MensagensListaComponent } from './mensagens-lista/mensagens-lista.component';

const routes: Routes = [
  {
    path: 'mensagens',
    component: MensagensPageComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO', 'PRIVADO', 'CENTRALCOM', 'SEGES']
    },
    children: [
      {
        path: '',
        component: MensagensListaComponent,
      }
    ]
  },
  {
    path: 'mensagem/:numero',
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ['GESTOR', 'GOVERNO', 'PRIVADO', 'CENTRALCOM', 'SEGES']
    },
    component: MensagemDetailPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MensagemRoutingModule { }

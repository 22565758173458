import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Mensagem } from '../../models/mensagem.model';

@Component({
  selector: 'reuse-mensagem-item',
  templateUrl: './mensagem-item.component.html',
  styleUrls: ['./mensagem-item.component.scss']
})
export class MensagemItemComponent implements OnInit {

  @Input() mensagem: Mensagem;

  constructor(private router: Router) {

  }

  ngOnInit() {
  }

  clicouMensagem() {
    this.router.navigate(['/mensagem', this.mensagem.id]);
  }

}

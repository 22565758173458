import { UsuarioTipo } from './anuncio-usuario.tipo.model';
import { AnuncioSituacao } from './anuncio-situacao.model';
import { AnuncioInteresseSituacao } from './anuncio-interesse-situacao.model';
import { MaterialCategoria } from './material-categoria.model';
import { MaterialTipo } from './material-tipo.model';
import { AnuncioChip } from './anuncio-chip.model';
import { Entidade } from 'app/anuncio/models/entidade.model';
import { Orgao } from 'app/anuncio/models/orgao.model';

export class FiltroRelatorioAnuncio {
  periodoInclusao: Date[];
  anuncioCodigos: AnuncioChip[];
  anuncianteTipos: UsuarioTipo[];
  anuncianteTiposSelecionados: UsuarioTipo[];
  materialTipos: MaterialTipo[];
  materialTiposSelecionados: MaterialTipo[];
  materialCategorias: MaterialCategoria[];
  materialCategoriasSelecionadas: MaterialCategoria[];
  anuncioSituacoes: AnuncioSituacao[];
  anuncioSituacoesSelecionadas: AnuncioSituacao[];
  periodoAutorizacaoDoacao: Date[];
  entidade: Entidade;
  orgao: Orgao;
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ParametrosConsultarAnuncioPrivado } from 'app/anuncio/models/parametros-consultar-anuncio-privado.model';
import { ResultadoPesquisaAnuncioConsultarPrivado } from 'app/anuncio/models/resultado-pesquisa-anuncio-consultar-privado.model';


@Component({
  selector: 'reuse-consultar-anuncio-privado-lista',
  templateUrl: './consultar-anuncio-privado-lista.component.html',
  styleUrls: ['./consultar-anuncio-privado-lista.component.scss']
})
export class ConsultarAnuncioPrivadoListaComponent extends AnunciosInternoLista implements OnInit {

  CONSULTAR_ANUNCIO_PRIVADO: OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO;

  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
    super();
  }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosConsultarAnuncioPrivado = this.anuncioService.obterParametrosConsultarAnuncioPrivadoUrl(params);
        this.ordem = parametros.ordem;

        this.anuncioService.consultarAnunciosPrivados(parametros).subscribe(
          (resultado: ResultadoPesquisaAnuncioConsultarPrivado) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0, 0);
          });
      });
  }

  /**
   * Consulta os anúncios, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosConsultarAnuncioPrivado = this.anuncioService.obterParametrosConsultarAnuncioPrivadoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.CONSULTAR_ANUNCIO_PRIVADO], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        anuncioSituacoes: parametros.anuncioSituacoes,
        materialTipos: parametros.materialTipos,
        periodoInclusao: parametros.periodoInclusao,
        anuncios: parametros.anuncios,
        possuiOnusOuEncargos: parametros.possuiOnusOuEncargos,
        //materialCategorias: parametros.materialCategorias,
        //materialSituacoes: parametros.materialSituacoes,
        // ufs: parametros.ufs,
        //periodoExpiracao: parametros.periodoExpiracao,
        //entidades: parametros.entidades,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }
}

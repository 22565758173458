import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MunicipioService } from 'app/anuncio/services/municipio.service';
import { MaterialTipoEnum } from 'app/shared/enum/material-tipo.enum';
import { AuthService } from 'app/shared/services/auth.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { UtilService } from '../../../shared/services/util.service';
import { MaterialCategoria } from '../../models/material-categoria.model';
import { MaterialTipo } from '../../models/material-tipo.model';
import { Municipio } from '../../models/municipio.model';
import { Uf } from '../../models/uf.model';
import { UnidadeFornecimento } from '../../models/unidadeFornecimento.model';
import { CadastroAnuncioService } from '../../services/cadastro-anuncio.service';
import { AbstractCadastroAnuncioPrivado } from '../cadastro-anuncio-privado-page/abstract-cadastro-anuncio-privado';

@Component({
  selector: 'reuse-cadastro-anuncio-privado-doacao',
  templateUrl: './cadastro-anuncio-privado-doacao.component.html',
  styleUrls: ['./cadastro-anuncio-privado-doacao.component.scss']
})
export class CadastroAnuncioPrivadoDoacaoComponent extends AbstractCadastroAnuncioPrivado implements OnInit {

  @Output()
  onExibirAbaDonatario = new EventEmitter<boolean>();

  //formulário
  formulario: FormGroup;

  //lista de tipo de materiais disponíveis para exibir no combobox
  listaMaterialTipo: MaterialTipo[] = [];

  //lista de categorias disponíveis para exibir no combobox
  listaMaterialCategoria: MaterialCategoria[] = [];

  //lista de unidades de fornecimento disponíveis para exibir no combobox
  listaUnidadeFornecimento: UnidadeFornecimento[] = [];

  //lista de ufs disponíveis para exibir no combobox
  listaUf: Uf[] = [];

  //lista de municípios disponíveis para exibir no combobox
  listaMunicipio: Municipio[] = [];

  //indica se carregou dados utilizados na criação do formulário
  carregouDados: boolean = false;

  dataMinExpiracao: Date;
  dataMaxExpiracao: Date;
  dataMinExpiracaoSemOnusComDonatario: Date;
  dataMinExpiracaoDemaisCasos: Date;
  datasInvalidas: Date[];
  today = new Date();

  constructor(
    private formBuilder: FormBuilder,
    public cadastroAnuncioService: CadastroAnuncioService,
    public authService: AuthService,
    private municipioService: MunicipioService,
    private utilService: UtilService,
    private messageService: MessageService,
    private router: Router) {
    super(cadastroAnuncioService);
  }

  ngOnInit(): void {
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getCadastroAnuncioService(): CadastroAnuncioService {
    return this.cadastroAnuncioService;
  }

  public atualizaAnuncio(numeroAnuncio: number): void {
    this.passo = 1;
    this.numeroAnuncio = numeroAnuncio;
    if (this.anuncio) {
      this.anuncio.id = numeroAnuncio;
    }
    this.obterDoacao();
  }

  private obterDoacao(): void {
    this.cadastroAnuncioService.obterAnuncioDoacao(this.numeroAnuncio)
      .subscribe(
        (anuncioDoacao) => {
          //dados do anúncio
          this.anuncio = anuncioDoacao.anuncio;

          this.listaMaterialTipo = anuncioDoacao.listaMaterialTipo;
          //adiciona opção 'Selecione' (vazia)
          this.listaMaterialTipo.unshift(<MaterialTipo>{ id: null, nome: 'Selecione' });

          this.listaUnidadeFornecimento = anuncioDoacao.listaUnidadeFornecimento;
          //adiciona opção 'Selecione' (vazia)
          this.listaUnidadeFornecimento.unshift(<UnidadeFornecimento>{ id: null, nome: 'Selecione' });

          this.listaUf = anuncioDoacao.listaUf;
          //adiciona opção 'Selecione' (vazia)
          this.listaUf.unshift(<Uf>{ id: null, sigla: 'Selecione' });

          this.criarFormulario();
          this.carregarMaterialCategoria();
          this.carregarMunicipio();

          this.defineRegrasMaterialTipo();

          this.dataMaxExpiracao = this.stringParaData(anuncioDoacao.dataMaxExpiracao, 'YYYY-MM-DD');
          this.dataMinExpiracaoSemOnusComDonatario = this.stringParaData(anuncioDoacao.dataMinExpiracaoSemOnusComDonatario, 'YYYY-MM-DD');
          this.dataMinExpiracaoDemaisCasos = this.stringParaData(anuncioDoacao.dataMinExpiracaoDemaisCasos, 'YYYY-MM-DD');

          this.datasInvalidas = [];
          for (let dateStr of anuncioDoacao.datasInvalidas) {
            this.datasInvalidas.push(this.stringParaData(dateStr, 'YYYY-MM-DD'));
          }

          //Configura a data de expiracao
          this.onChangePossuiOnusOuEncargos();

          this.carregouDados = true;
          window.scrollTo(0, 0);

          this.utilService.verificarRegistroBloqueado(this.anuncio, true);
        }
      );
  }

  private isValidoMaterialTipo(): boolean {
    if (!this.anuncio.materialCategoria || this.anuncio.materialCategoria.materialTipo.id == -1) {
      return false;
    }
    return true;
  }

  private isValidoMaterialCategoria(): boolean {
    if (!this.anuncio.materialCategoria || this.anuncio.materialCategoria.id == -1) {
      return false;
    }
    return true;
  }

  private isValidoUnidadeFornecimento(): boolean {
    if (!this.anuncio.unidadeFornecimento || this.anuncio.unidadeFornecimento.id == -1) {
      return false;
    }
    return true;
  }

  private isValidoTitulo(): boolean {
    if (this.anuncio.titulo == 'ANÚNCIO PRIVADO') {
      return false;
    }
    return true;
  }

  private isMaterialTipoSelecionado(): boolean {
    let materialTipoSelecionado: any;
    if (this.formulario) {
      materialTipoSelecionado = this.formulario.get('materialTipo').value;
    } else {
      materialTipoSelecionado = this.anuncio.materialCategoria;
    }

    if (!materialTipoSelecionado || !materialTipoSelecionado.id) {
      return false;
    }

    return true;
  }

  public isAnuncioServico(): boolean {
    let materialTipoSelecionado: any;
    if (this.formulario) {
      materialTipoSelecionado = this.formulario.get('materialTipo').value;
    } else {
      materialTipoSelecionado = (this.anuncio.materialCategoria ? this.anuncio.materialCategoria.materialTipo : null);
    }

    if ((materialTipoSelecionado && materialTipoSelecionado.id && materialTipoSelecionado.id == MaterialTipoEnum.SERVICO) || !materialTipoSelecionado || !materialTipoSelecionado.id) {
      return true;
    }
    return false;
  }

  private isAnuncioConsumo(): boolean {
    let materialTipoSelecionado: any;
    if (this.formulario) {
      materialTipoSelecionado = this.formulario.get('materialTipo').value;
    } else {
      materialTipoSelecionado = (this.anuncio.materialCategoria ? this.anuncio.materialCategoria.materialTipo : null);
    }

    if ((materialTipoSelecionado && materialTipoSelecionado.id && materialTipoSelecionado.id == MaterialTipoEnum.CONSUMO) || !materialTipoSelecionado || !materialTipoSelecionado.id) {
      return true;
    }
    return false;
  }

  private isQuantidadeInvalida(): boolean {
    return (!this.isAnuncioServico() && this.formulario.get('quantidade').value <= 0);
  }

  private isValorUnitarioMercadoInvalido(): boolean {
    return this.formulario.get('valorUnitarioMercado').value <= 0;
  }

  private isLocalizacaoInvalida(): boolean {
    return (!this.isAnuncioServico() && !this.formulario.get('localizacao').value);
  }

  private isPossuiOnusOuEncargosInvalido(): boolean {
    return this.formulario.get('possuiOnusOuEncargos').value == null;
  }

  private isDescricaoOnusOuEncargosInvalida(): boolean {
    return (this.formulario.get('possuiOnusOuEncargos').value && !this.formulario.get('descricaoOnusOuEncargos').value);
  }

  private isPossuiDonatarioInvalido(): boolean {
    return this.formulario.get('possuiDonatario').value == null;
  }

  /**
   * Cria o formulário para inserção ou edição.
   */
  private criarFormulario() {

    const materialCategoria: MaterialCategoria = this.anuncio.materialCategoria;
    const municipio: Municipio = this.anuncio.municipio;

    this.formulario = this.formBuilder.group({
      titulo: [this.isValidoTitulo() ? this.anuncio.titulo : null, [Validators.required, Validators.maxLength(255)]],
      descricao: [this.anuncio.descricao, [Validators.required]],
      materialTipo: [this.isValidoMaterialCategoria() ? (materialCategoria ? (this.isValidoMaterialTipo() ? materialCategoria.materialTipo : null) : null) : null, [Validators.required, this.utilService.validarIdEntidade]],
      materialCategoria: [this.isValidoMaterialCategoria ? materialCategoria : null, [Validators.required, this.utilService.validarIdEntidade]],
      quantidade: [this.anuncio.quantidade ? this.anuncio.quantidade : 0],
      unidadeFornecimento: [this.isValidoUnidadeFornecimento() ? this.anuncio.unidadeFornecimento : null],
      uf: [municipio ? municipio.uf : null, [Validators.required, this.utilService.validarIdEntidade]],
      municipio: [municipio, [Validators.required, this.utilService.validarIdEntidade]],
      contatoNome: [this.anuncio.contatoNome, [Validators.required, Validators.maxLength(255)]],
      contatoEmail: [this.anuncio.contatoEmail, [Validators.required, Validators.email, Validators.maxLength(255)]],
      contatoTelefone1: [this.anuncio.contatoTelefone1, [Validators.required, this.utilService.validarTelefone, Validators.maxLength(20)]],
      contatoTelefone2: [this.anuncio.contatoTelefone2, [Validators.maxLength(20)]],
      valorUnitarioMercado: [this.anuncio.valorUnitarioMercado != null ? parseFloat(this.anuncio.valorUnitarioMercado.
        toString().replace(/\./g, '').replace(',', '.')) : 0, [Validators.required, Validators.min(0.01)]],
      localizacao: [this.anuncio.localizacao],
      dataValidade: [this.anuncio.dataValidade ? this.stringParaData(this.anuncio.dataValidade, 'YYYY-MM-DD') : null],
      dataExpiracao: [this.anuncio.dataExpiracao ? this.stringParaData(this.anuncio.dataExpiracao, 'YYYY-MM-DD') : null],
      possuiOnusOuEncargos: [this.anuncio.possuiOnusOuEncargos],
      possuiDonatario: [this.anuncio.possuiDonatario],
      descricaoOnusOuEncargos: [this.anuncio.descricaoOnusOuEncargos],
      justificativaDoacao: [this.anuncio.justificativaDoacao],
    });


    this.formulario.get('possuiOnusOuEncargos').valueChanges.subscribe(possuiOnusOuEncargos => {
      const descricao = this.formulario.get('descricaoOnusOuEncargos');
      if (possuiOnusOuEncargos) {
        descricao.setValidators([Validators.required]);
      } else {
        descricao.setValidators(null);
        descricao.setValue('');
      }
      descricao.updateValueAndValidity();
    });
  }

  /**
   * Clique botão 'Confirmar'.
   */
  public onSubmit(): void {
    this.utilService.marcarCamposComoAlterado(this.formulario);

    // Nesse primeiro caso nao precisa salvar, pois somente pode alterar as fotos.
    if (this.isQuantidadeInvalida()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Quantidade" deve ser maior que zero.' });
    } else if (this.isLocalizacaoInvalida()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Localização" do bem ou local de prestação do serviço é obrigatório para bens de consumo e permanente.' });
    } else if (this.isPossuiOnusOuEncargosInvalido()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Possui ônus ou encargos" é obrigatório.' });
    } else if (this.isDescricaoOnusOuEncargosInvalida()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Descrição de ônus ou encargos" é obrigatória.' });
    } else if (this.isPossuiDonatarioInvalido()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Possui Donatário" é obrigatório.' });
    } else if (this.isValorUnitarioMercadoInvalido()) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Campo "Valor unitário de mercado" deve ser maior que zero.' });
    } else if (this.formulario.valid) {
      this.cadastroAnuncioService.salvarDoacao(this.formulario, this.anuncio).subscribe(
        (resultado: any) => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: resultado.mensagem });

          this.onProximo();
        });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: 'Verifique os campos com erro.' });
    }
  }

  /**
   * Verifica se o campo está inválido.
   *
   * @param nomeCampo
   */
  public verificarCampoInvalido(nomeCampo: string): boolean {
    let campo = this.formulario.get(nomeCampo);
    //inválido e alterado
    return campo.invalid && campo.dirty;
  }

  /**
   * onChange para para dropdown 'Categoria do Material / Serviço'.
   */
  public onChangeMaterialCategoria(): void {
    this.defineRegrasMaterialTipo();
    this.formulario.get('materialCategoria').setValue({ 'id': null });
    this.carregarMaterialCategoria();
  }


  // Mudanca na validacao caso seja um tipo servico
  private defineRegrasMaterialTipo() {
    if (this.isMaterialTipoSelecionado()) {
      if (!this.isAnuncioServico()) {
        this.formulario.get('quantidade').setValue(this.anuncio.quantidade);
        this.formulario.get('unidadeFornecimento').setValue(this.isValidoUnidadeFornecimento() ? this.anuncio.unidadeFornecimento : null);
        this.formulario.controls.quantidade.setValidators([Validators.required, Validators.min(1)]);
        this.formulario.controls.unidadeFornecimento.setValidators([Validators.required, this.utilService.validarIdEntidade]);
        if (!this.isAnuncioConsumo()) {
          this.formulario.get('dataValidade').setValue(null);
        }
        this.formulario.controls.localizacao.setValidators([Validators.required]);
      } else {
        this.formulario.get('quantidade').setValue(0);
        this.formulario.get('unidadeFornecimento').setValue(null);
        this.formulario.controls.quantidade.setValidators(null);
        this.formulario.controls.quantidade.setErrors(null);
        this.formulario.controls.unidadeFornecimento.setValidators(null);
        this.formulario.controls.unidadeFornecimento.setErrors(null);
        this.formulario.controls.localizacao.setValidators(null);
        this.formulario.controls.localizacao.setErrors(null);
        this.formulario.get('dataValidade').setValue(null);
      }
    }
  }

  /**
   * Carrega a opção de 'Categorias' a partir do 'Material Tipo' selecionado.
   */
  private carregarMaterialCategoria(): void {
    const materialTipoSelecionado: any = this.formulario.get('materialTipo').value;
    if (materialTipoSelecionado == null || materialTipoSelecionado.id == null) {
      //caso não exista 'tipo' selecionado, remove as opção de 'categoria'
      this.listaMaterialCategoria = [];
      this.formulario.get('materialCategoria').disable();
    } else {
      this.cadastroAnuncioService.obterMaterialCategorias(materialTipoSelecionado.id)
        .subscribe(
          (categorias) => {
            this.listaMaterialCategoria = categorias;
            this.listaMaterialCategoria.unshift(<MaterialCategoria>{ id: null, nome: 'Selecione' });
            this.formulario.get('materialCategoria').enable();
          }
        );
    }
  }

  /**
   * onChange para para dropdown 'Município'.
   */
  public onChangeMunicipio(): void {
    this.formulario.get('municipio').setValue({ 'id': null });
    this.carregarMunicipio();
  }

  /**
   * Carrega a opção de 'Municípios' a partir da 'Uf' selecionada.
   */
  private carregarMunicipio(): void {
    // const idUf:number = this.formulario.get('uf').value.id;
    const ufSelecionado: any = this.formulario.get('uf').value;
    if (ufSelecionado == null || ufSelecionado.id == null) {
      //caso não exista 'uf' selecionado, remove as opção de 'municípios'
      this.listaMunicipio = [];
      this.formulario.get('municipio').disable();
    } else {
      this.municipioService.listarMunicipiosAtivos(ufSelecionado.id)
        .subscribe(
          (municipios) => {
            this.listaMunicipio = municipios;
            this.listaMunicipio.unshift(<Municipio>{ id: null, nome: 'Selecione' });
            this.formulario.get('municipio').enable();
          }
        );
    }
  }

  public estilo() {
    let total: number = 0;
    if (this.exibeQuantidade) {
      total++;
    }
    if (this.exibeUnidadeFornecimento) {
      total++;
    }
    if (this.exibeValorUnitarioMercado) {
      total++;
    }
    if (this.exibeDataValidade) {
      total++;
    }

    return 'ui-g-' + (12 / total);
  }

  public exibeQuantidade(): boolean {
    return !this.isAnuncioServico() && this.isMaterialTipoSelecionado();
  }

  public exibeUnidadeFornecimento(): boolean {
    return !this.isAnuncioServico() && this.isMaterialTipoSelecionado();
  }

  public exibeValorUnitarioMercado(): boolean {
    return true;
  }

  public exibeDataValidade(): boolean {
    return this.isAnuncioConsumo() && this.isMaterialTipoSelecionado();
  }

  public exibeOnusOuEncargos() {
    //return !this.isAnuncioServico() && (this.anuncio.donoAnuncio || this.authService.hasRole(['GOVERNO', 'CENTRALCOM', 'GESTOR', 'SEGES']));
    return (this.anuncio.donoAnuncio || this.authService.hasRole(['GOVERNO', 'CENTRALCOM', 'GESTOR', 'SEGES']));
  }

  public exibeCamposOnusOuEncargos(): boolean {
    return this.formulario.get('possuiOnusOuEncargos').value;
  }

  public onChangePossuiOnusOuEncargos() {
    this.alterarDataExpiracao();
  }

  public onChangePossuiDonatario() {
    this.onExibirAbaDonatario.emit(this.formulario.get('possuiDonatario').value);
    this.anuncio.possuiDonatario = this.formulario.get('possuiDonatario').value;
    if (!this.anuncio.possuiDonatario) {
      this.anuncio.entidadeFavorecida = null;
    }
    this.alterarDataExpiracao();
  }

  private alterarDataExpiracao() {
    // Sem Ônus e Donatário = mín 2 / máx 99 dias úteis
    // Demais = mín 8 / máx 99 dias úteis
    this.dataMinExpiracao = !this.formulario.get('possuiOnusOuEncargos').value && this.formulario.get('possuiDonatario').value ? this.dataMinExpiracaoSemOnusComDonatario : this.dataMinExpiracaoDemaisCasos;
    this.formulario.get('dataExpiracao').setValue(this.dataMinExpiracao);
  }

  public retornaTitulo(): string {
    if (this.formulario.get('possuiOnusOuEncargos').value && this.formulario.get('possuiDonatario').value) {
      return 'O prazo mínimo com ônus e encargos e com donatário indicado é de 8 dias úteis e o prazo máximo é de 99 dias úteis.';
    }
    if (this.formulario.get('possuiOnusOuEncargos').value && !this.formulario.get('possuiDonatario').value) {
      return 'O prazo mínimo com ônus e encargos e sem donatário indicado é de 8 dias úteis e o prazo máximo é de 99 dias úteis.';
    }
    if (!this.formulario.get('possuiOnusOuEncargos').value && this.formulario.get('possuiDonatario').value) {
      return 'O prazo mínimo sem ônus e encargos e com donatário indicado é de 2 dias úteis e o prazo máximo é de 99 dias úteis.';
    }
    if (!this.formulario.get('possuiOnusOuEncargos').value && !this.formulario.get('possuiDonatario').value) {
      return 'O prazo mínimo sem ônus e encargos e sem donatário indicado é de 8 dias úteis e o prazo máximo é de 99 dias úteis.';
    }
  }

}

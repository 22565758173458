<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label>Número do Anúncio</label>
          <tag-input [(ngModel)]='filtro.anuncios' [separatorKeyCodes]="separadores" [validators]="somenteNumero"
            placeholder="Número" secondaryPlaceholder="Filtrar por Número" addOnBlur="true" theme='bootstrap'>
          </tag-input>
        </div>
        <div class="ui-g-12">
          <label>Título</label>
          <input [(ngModel)]="filtro.titulo" name="titulo" type="text" pInputText maxlength="200">
        </div>
        <div class="ui-g-12">
          <label>Situação</label>
          <p-multiSelect defaultLabel="Todos" [(ngModel)]="filtro.mensagemSituacoesSelecionados"
            [options]="filtro.mensagemSituacoes" optionLabel="nome" dataKey="nome"></p-multiSelect>
        </div>
        <div class="ui-g-12">
          <label>Período de Leitura</label>
          <p-calendar [(ngModel)]="filtro.periodoLeitura" selectionMode="range" readonlyInput="true" [locale]="pt"
            dateFormat="dd/mm/yy" [showIcon]="true" #periodoLeitura>
            <p-footer>
              <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker">
                <p-button icon="pi pi-check" class="button-datapicker-ok" (onClick)="fecharCalendario(periodoLeitura)">
                </p-button>
                <p-button icon="pi pi-times" class="button-datapicker-cancel"
                  (onClick)="limparPeriodoLeitura(periodoLeitura)"></p-button>
              </div>
            </p-footer>
          </p-calendar>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()">
        </p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="aplicarFiltros()">
        </p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

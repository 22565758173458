<div class="barraListagem">
  <div class="barraFiltro">
    <div class="barraFiltroInterna">
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <label for="numero">Número/CNPJ</label>
          <input pInputText id="numero" name="numero" [(ngModel)]="filtro.numero" placeholder="Número/CNPJ"
            title="Entre com o Número" type="text" styleClass="width100" (onBlur)="onBlurNumero($event)" />
        </div>
        <div class="ui-g-12">
          <label for="nome">Nome</label>
          <input pInputText id="nome" name="nome" [(ngModel)]="filtro.nome" maxlength="200" placeholder="Nome"
            type="text" />
        </div>
        <div class="ui-g-12">
          <label for="orgao">Órgão</label>
          <p-dropdown #orgao id="orgao" [options]="listaOrgao" [autoWidth]="false"
            [(ngModel)]="filtro.orgao" [filter]="true" optionLabel="idNome" dataKey="id"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <label for="entidadeTipo">Tipo</label>
          <p-dropdown #entidadeTipo id="entidadeTipo" [options]="listaEntidadeTipo"
            [autoWidth]="false" [(ngModel)]="filtro.entidadeTipo" [filter]="true" optionLabel="nome" dataKey="id"
            [disabled]="listaEntidadeTipo.length == 0"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <label for="entidadeEsfera">Esfera</label>
          <p-dropdown #entidadeEsfera id="entidadeEsfera" [options]="listaEntidadeEsfera"
            [autoWidth]="false" [(ngModel)]="filtro.entidadeEsfera" [filter]="true" optionLabel="nome" dataKey="id"
            [disabled]="listaEntidadeEsfera.length == 0"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <label for="utilizaSiads">Utiliza Siads</label>
          <p-dropdown #utilizaSiads id="utilizaSiads" [options]="listaSimNao"
            [autoWidth]="false" [(ngModel)]="filtro.utilizaSiads" [filter]="true" optionLabel="nome" dataKey="id"
            [disabled]="listaSimNao.length == 0"></p-dropdown>
        </div>
        <div class="ui-g-12">
          <label for="ativo">Ativo</label>
          <p-dropdown #ativo id="ativo" [options]="listaSimNao" [autoWidth]="false"
            [(ngModel)]="filtro.ativo" [filter]="true" optionLabel="nome" dataKey="id"
            [disabled]="listaSimNao.length == 0"></p-dropdown>
        </div>
      </div>
      <div class="ui-g-12 text-center">
        <p-button label="Limpar" styleClass="ui-button-secondary" icon="fa fa-trash" (click)="limparFiltros()">
        </p-button>
        <span class="espaco-r-5"></span>
        <p-button label="Aplicar" styleClass="ui-button-success" icon="fa fa-sliders" (click)="consultar()">
        </p-button>
      </div>
    </div>
  </div>
  <div class="barraItens">
    <router-outlet></router-outlet>
  </div>
</div>

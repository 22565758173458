import { Injectable } from '@angular/core';
import { Usuario } from '../../../anuncio/models/usuario.model';


@Injectable()
export class JwtService {

  constructor() { }

  public urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: { break; }
      case 2: { output += '=='; break; }
      case 3: { output += '='; break; }
      default: {
        throw Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  private b64decode(str: string): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error('"atob" failed: The string to be decoded is not correctly encoded.');
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      buffer = str.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      // tslint:disable-next-line:no-bitwise
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        // tslint:disable-next-line:no-bitwise
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(Array.prototype.map.call(this.b64decode(str), (c: any) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  public decodeToken(token: string): any {

    if (!token) {
      throw new Error('Token is null');
    }
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded: any = this.decodeToken(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public getUsuario(token: string): Usuario {
    if (token) {
      const decoded: any = this.decodeToken(token);
      const usuario = new Usuario();

      usuario.id = decoded.sub;
      usuario.nome = decoded.name;

      usuario.cpf = decoded.params && decoded.params.cpfUsuario;
      usuario.numeroEntidade = decoded.params && decoded.params.numeroEntidade;
      usuario.nomeEntidade = decoded.params && decoded.params.nomeEntidade;
      usuario.siglaEntidade = decoded.params && decoded.params.siglaEntidade;
      usuario.entidadeUtilizaSiads = (decoded.params && decoded.params.entidadeUtilizaSiads === 'true');
      usuario.perfis = decoded.params && decoded.params.roles && decoded.params.roles.split(',');

      let nomeUsuarioArray = usuario.nome.split(" ");
      if (nomeUsuarioArray.length > 1) {
        usuario.nomeAbreviado = nomeUsuarioArray[0] + " " + nomeUsuarioArray[nomeUsuarioArray.length - 1];
      } else {
        usuario.nomeAbreviado = nomeUsuarioArray[0];
      }

      // usuario.lstComandos = decoded.transactions;
      return usuario;
    }

    return null;
  }

}

